import React, { useState } from "react";
import styled from "styled-components";
import Moment from "moment";
import ProjectModify from "../ProjectModify";
import { Link } from "react-router-dom";
import projectStatus from "../../../constants/projectStatus";

// ----------------------------------------------------------------------------

function ProjectBox(props) {
  const { className, user, body } = props;
  return (
    <Link to={`/project/${body.id}`}>
      <div className={className}>
        <button className="boxProject">
          <div className="titleProject">{body.name}</div>
          <div className="description"> {body.description}</div>
          <div className="customer">
            Cliente : {body?.customer?.businessName}
          </div>
          <div className="footer">
            <div className="deadLine">
              Data scadenza:{`   `}
              {Moment(body.deadLine).format("DD-MM-YY")}
            </div>
            <div className="state"> {projectStatus[body.status]}</div>
          </div>
        </button>
      </div>
    </Link>
  );
}

// ----------------------------------------------------------------------------

const StyledProjectBox = styled(ProjectBox)`
  & {
    .boxProject {
      background-color: rgba(240, 240, 240, 0.25);
      // padding: 10px 5px;
      height: 200px;
      border-radius: 20px;
      box-shadow: 1px 1px #ffa500;

      width: 100%;
      transition: 1s;

      border: 1px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      :hover {
        transform: rotateY(10deg);
        box-shadow: 2px 2px #ffa500;
        background-color: rgba(240, 240, 240, 0.7);
        color: black;
      }
    }
    .titleProject {
      font-size: 30px;
      padding: 5px;
    }
    .description {
      font-size: 14px;
      padding-left: 10px;
    }
    .customer {
      font-size: 12px;
      padding-left: 10px;
    }
    .deadLine {
      display: table-cell;
      font-size: 12px;
      padding-left: 10px;
      text-align: left;
    }
    .state {
      display: table-cell;
      font-size: 12px;
      text-align: right;
      padding-right: 10px;
    }
    .footer {
      display: table;
      width: 100%; /*Optional*/
      table-layout: fixed; /*Optional*/
      border-spacing: 10px; /*Optional*/
    }
  }
`;
export default StyledProjectBox;
