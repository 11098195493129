import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Select, InputNumber, Row, Col } from "antd";

import AppButton from "../../../../_shared/components/AppButton";
import closeIcon from "../../../../_shared/assets/icons/close-icn.svg";
import AppInput from "../../../../_shared/components/AppInput";

// ----------------------------------------------------------------------------

function _ProductSelection(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------
    const { className, products, onChange, id, onCancel, ...productProps } =
        props;

    // -------------------------------------
    // Hooks (e.g. useState, useMemo ...)
    // -------------------------------------
    const { Option } = Select;

    const [product, setProduct] = useState(
        productProps.product
            ? products?.findIndex((prod) => prod.id === productProps.product)
            : undefined
    );
    const [price, setPrice] = useState(
        productProps.price ? productProps.price : 0
    );
    const [quantity, setQuantity] = useState(
        productProps.quantity ? productProps.quantity : 1
    );

    const [discount, setDiscount] = useState(
        productProps.discount ? productProps.discount : 0
    );

    const [description, setDescription] = useState(
        productProps.description ? productProps.description : ""
    );

    // -------------------------------------
    // Effects
    // -------------------------------------

    useEffect(() => {
        if (product !== undefined) {
            onChange(
                quantity,
                price,
                products[product]?.id,
                description,
                discount,
                id
            );
        }
    }, [product, quantity, discount, price]);

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function handleOnChange(selected) {
        console.log("selected: ", selected);
        setProduct(selected);
        setPrice(products[selected].unitPrice);
        setDescription(products[selected].description);
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    // -------------------------------------

    return (
        <div className={className}>
            <Row gutter={8}>
                <Col span={11}>
                    <Select
                        className="product-select"
                        showSearch
                        placeholder="Prodotto"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option?.children
                                ?.toLowerCase()
                                .indexOf(input?.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                            optionA?.children
                                ?.toLowerCase()
                                .localeCompare(optionB?.children.toLowerCase())
                        }
                        value={product}
                        onSelect={handleOnChange}
                    >
                        {products?.map((product, index) => (
                            <Option value={index} key={index}>
                                {product.name ? product.name : product.code}
                            </Option>
                        ))}
                    </Select>
                </Col>
                <Col span={4}>
                    <InputNumber
                        min={1}
                        value={quantity}
                        onChange={setQuantity}
                        className="inputQuantity"
                    />
                </Col>
                <Col span={6}>
                    <InputNumber
                        disabled
                        value={price}
                        onChange={setPrice}
                        className="inputPrice"
                        precision={2}
                        step={0.5}
                        formatter={(value) => `${value}€`}
                        parser={(value) => value?.replace("€", "")}
                    />
                </Col>
                {/* <Col span={6}>
                    <InputNumber
                        value={discount}
                        onChange={setDiscount}
                        className="inputPrice"
                        precision={2}
                        step={0.5}
                    />
                </Col> */}
                <Col span={1} className="delete-col">
                    <AppButton
                        uxType="delete"
                        className="close-icn"
                        onClick={(e) => onCancel(e, id)}
                    >
                        {""}
                    </AppButton>
                </Col>
            </Row>
            {productProps?.showDiscount && (
                <AppInput
                    style={{ width: "50%", height: "35px" }}
                    label="Sconto (%)"
                    placeholder="Sconto"
                    value={discount}
                    onChange={setDiscount}
                />
            )}

            <span className="description">
                DESCRIZIONE: {description?.toLowerCase()}
            </span>
        </div>
    );
}

// ----------------------------------------------------------------------------

const ProductSelection = styled(_ProductSelection)`
    & {
        margin-top: 10px;
        margin-bottom: 20px;
        padding-bottom: 5px;
        border-bottom: 1px solid ${({ theme }) => theme.colors.grey200};
        display: flex;
        flex-direction: column;
        .product-select {
            width: 100%;
        }

        .inputQuantity {
            width: inherit;
        }

        .inputPrice {
            width: inherit;
        }
        .close-icn {
            margin-right: 20px;
            padding: 0;
            border: none;
            width: 14px;
            height: 14px;
            background-image: url(${closeIcon});
            background-size: 14px 14px;
            background-repeat: no-repeat;
            background-origin: content-box;
            :hover {
                background-color: ${({ theme }) => theme.colors.grey200};
            }
        }
        .delete-col {
            margin: auto;
        }
        .description {
            margin-top: 10px;
            font-size: 12px;
            font-weight: 400;
        }
    }
`;

// ----------------------------------------------------------------------------

export default ProductSelection;
