import React, { useRef } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { DatePicker } from "antd"
import moment from "moment"

// ----------------------------------------------------------------------------

const RangePicker = DatePicker.RangePicker

// ----------------------------------------------------------------------------

function TableDateRangeFilterDropdown(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { visible, onChange, onOk, key } = props

    // -------------------------------------
    // Hooks (e.g. useState, useMemo ...)
    // -------------------------------------

    const containerRef = useRef()

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function handleOkAction(range) {
        const newRange = range?.map((momentDate) => momentDate.toISOString())

        onOk && onOk(newRange)
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={props.className} ref={containerRef}>
            <RangePicker
                key={`${key}-range-picker`}
                showTime={{ format: "HH:mm" }}
                getPopuContainer={() => containerRef?.current}
                ranges={null}
                format="DD/MM/YYYY HH:mm"
                onOk={handleOkAction}
                open={visible}
                defaultValue={[moment().subtract(1, "month"), moment()]}
                onChange={onChange}
            />
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

TableDateRangeFilterDropdown.propTypes = {
    className: PropTypes.string,
    onChange: PropTypes.func,
    //onOk: PropTypes.onOk,
    id: PropTypes.any.isRequired,
    key: PropTypes.any,
    visible: PropTypes.bool,
}

TableDateRangeFilterDropdown.defaultProps = {}

// ----------------------------------------------------------------------------

const StyledTableDateRangeFilterDropdown = styled(TableDateRangeFilterDropdown)`
    & {
    }
`
// ----------------------------------------------------------------------------

export default StyledTableDateRangeFilterDropdown
