import React, { useState, useEffect} from "react"
import { message } from "antd"
import styled from "styled-components"
import { useParams, useNavigate} from "react-router-dom"
import { useDispatch, useSelector} from "react-redux"

import * as api from "../../../../api"
import { login } from "../../../../redux/slices/authSlice"

import AppInput from "../../../_shared/components/AppInput"
import AppButton from "../../../_shared/components/AppButton"
import Logo from "../../../_shared/components/LoginItems/Logo"
import Heading from "../../../_shared/components/LoginItems/Heading"
import SubHeading from "../../../_shared/components/LoginItems/SubHeading"
import LogForm from "../../../_shared/components/LoginItems/LogForm"
import FormDiv from "../../../_shared/components/LoginItems/FormDiv"
import useForm from "../../../_shared/hooks/useForm"
import useRestResource from "../../../_shared/hooks/useRestResource"




// ----------------------------------------------------------------------------

function InvitedLogin(props) {
	// -------------------------------------
	// Props destructuring
	// -------------------------------------

	const { className } = props

	// -------------------------------------
	// Hooks (e.g. useState, ...)
	// -------------------------------------
    const params = useParams()
    const navigate = useNavigate()
    const [form, , setMany, clearForm] = useForm()
    const dispatch = useDispatch()
    const user = useSelector((state) => (state?.auth.token ? state.auth : null))

    const [invite] = useRestResource(
        "/invites",
        params.inviteId,
    )
    // -------------------------------------
    // Effects
    // -------------------------------------

    useEffect(() => {
        if(invite.state === "impending"){
            message.info("Invito già accettato")
            navigate(-1)
        }
            
        // eslint-disable-next-line
    }, [invite])

	// -------------------------------------
	// Component functions
	// -------------------------------------

	async function handleSubmit(e) {
		e.preventDefault()
        if( form?.password === form?.confirmPassword){
            try {
                let logUser
                const gettedUser = await api.logIn(invite.email, "102938")
                if(gettedUser.user){
                    const response = await api.updateUser(gettedUser.user.id, {organizations: invite.organization.id, password: form?.password}, gettedUser.token)
                    const see = await api.updateInvite(params.inviteId, {state: 'impending'})
                    logUser = {
                        fullName: response.fullName,
                        token: gettedUser.token,
                        email: response.email,
                        organization: invite.organization.id,
                    }
                } else {
                    const response = await api.signUp(invite.email, form?.password, form?.name, form?.surname)
                    const updatedUser = await api.updateUser(response.user.id, {organizations: invite.organization.id}, response.token)
                    const organization = await api.getOrganization( invite.organization.id, response.token)
                    await api.updateOrganization(
                        invite.organization.id,
                        { users: [...organization.users, updatedUser.id] },
                        response.token
                    )
                    const see = await api.updateInvite(params.inviteId, {state: 'impending'})
                    logUser = {
                        fullName: response.user.fullName,
                        token: response.token,
                        email: response.user.email,
                        organization: invite.organization.id,
                    }
                }
               
                dispatch(login(logUser))
                
            } catch (error) {
                console.log("[ERROR] in invitedLogin " + error)
            }
        }
        else
            message.error("password discordanti")
		
	}

    function update(field) {
        return (value) => {
            setMany({
                ...form,
                [field]: value,
            })}
}

    function onKeyUp(){
        if( form?.password !== form?.confirmPassword )
            update("passwordState")("wrong")
        else 
        update("passwordState")("")
        
    }

	return (
		<div className={`${className}`}>
			<FormDiv>
				<Logo />
				<LogForm onSubmit={handleSubmit}>
					<Heading>Completa profilo</Heading>
					<SubHeading>
						Modifica i seguenti dati per poter effettuare il tuo primo accesso
					</SubHeading>
					<AppInput
						value={form?.name ?? ""}
						onChange={update("name")}
						label="Nome"
						placeholder="Inserisci il tuo nome"
						required
					></AppInput>
                    <AppInput
						value={form?.surname ?? ""}
						onChange={update("surname")}
						label="Cognome"
						placeholder="Inserisci il tuo cognome"
						required
					></AppInput>
					<AppInput
						value={form?.password ?? ""}
						onChange={update("password")}
                        onKeyUp={onKeyUp}
						label="Password"
						placeholder="Inserisci la nuova password"
						type="password"
						required
					></AppInput>
                    <AppInput
						value={form?.confirmPassword ?? ""}
						onChange={update("confirmPassword")}
                        onKeyUp={onKeyUp}
						label="Conferma password"
						placeholder="Conferma la nuova password"
						type="password"
                        state={form?.passwordState ?? ""}
                        error={form?.passwordState === 'wrong' ? 'Le due password non corrispondono' : ''}
						required
					></AppInput>
					{form?.passwordState !== 'wrong' ? '' : ''}
					<AppButton 
                        uxType="secondary" 
                        htmlType="submit" 
                        className="confirmBtn">
						CONFERMA
					</AppButton>
				</LogForm>
			</FormDiv>
		</div>
	)
}

const StyledInvitedLogin = styled(InvitedLogin)`
	& {
        .confirmBtn {
            margin-top: 20px;
        }
    }
`
// ----------------------------------------------------------------------------
export default StyledInvitedLogin
