import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

function _Heading (props) {

    const { className, children} = props

    return (
            <h1 className={`${className}`}>{ children }</h1>
    )
}
_Heading.propTypes = {
    className: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired
}

_Heading.defaultProps = {}

const Heading = styled(_Heading) `
    & {
        margin: 0;
        padding: 0;
        font-weight: 700;
        font-size: 28px;
        line-height: 36px;
        letter-spacing: -0.01em;
        color: ${({ theme }) => theme.spacing.dark};
        margin-bottom: ${({ theme }) => theme.spacing.xs}px; 
    }
`
export default Heading