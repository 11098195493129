import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { message, Select, Tabs, Popover, Radio } from "antd";

import { useSelector } from "react-redux";
import { HTML5Backend } from "react-dnd-html5-backend";
import * as api from "../../../../api";

import usePaginatedRestResource from "../../../_shared/hooks/usePaginatedRestResource";
// import useForm from "../../../../../_shared/hooks/useForm";
import useForm from "../../../_shared/hooks/useForm";

import { ReactComponent as SortIcon } from "../../../_shared/assets/icons/sortIcon.svg";
import { Link, Routes, Route, useNavigate } from "react-router-dom";

import AppButton from "../../../_shared/components/AppButton";
import MilestonesCreation from "./MilestoneCreation";
import MilestoneCard from "./MilestoneCard";

import { useParams } from "react-router-dom";
import { isArray } from "lodash";
import { DndProvider } from "react-dnd";
import DragDrop from "./DragDrop";
import { resolveOnChange } from "antd/lib/input/Input";

// ----------------------------------------------------------------------------

function Milestones(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { className, project, getProject } = props;
  const { TabPane } = Tabs;

  const { Option } = Select;

  // -------------------------------------
  // Hooks (e.g. useState, ...)
  // -------------------------------------
  const user = useSelector((state) => (state?.auth.user ? state.auth : null));

  const [form, setForm] = useState();

  const [milestones, setMilestones] = useState();

  const [milestonesSorted, setMilestonesSorted] = useState();

  const navigate = useNavigate();

  const [selectedMilestone, setSelectedMilestone] = useState();
  const [change, setChange] = useState();

  const [tasks, setTasks] = useState();

  useEffect(() => {
    project && setMilestones(project?.milestones);
  }, []);

  useEffect(() => {
    setMilestonesSorted(milestones);
  }, [milestones]);

  async function handleConfirmMilestone(values) {
    try {
      !values?.project
        ? (values.project = project?.id)
        : (values.project = values.project.id);

      const body = {
        ...values,
        assignedTo: project.id,
      };
      let response = {};
      if (values?.id) {
        response = await api.updateMilestone(values.id, body, user.token);
        const newMilestones = milestones.filter((mil) => mil.id !== values.id);

        const milToModify = milestones.find((mil) => mil.id === values.id);
        const index = milestones.indexOf(milToModify);

        newMilestones.splice(index, 0, response);

        newMilestones[index].tasks = milToModify.tasks;

        setMilestones(newMilestones);
      } else {
        response = await api.createMilestone(body, user.token);
        setMilestones([...milestones, response]);
      }

      setSelectedMilestone(null);
      message.success("Operazione eseguita con successo");
    } catch (error) {
      console.log("[Error] in confirm taskList: ", error);

      message.error("Errore durante l'operazione");
    }
  }
  const [open, setOpen] = useState(false);

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };
  const [sortFilter, setSortFilter] = useState(1);

  useEffect(() => {
    let newMile;
    switch (sortFilter) {
      case 1:
        setMilestonesSorted(milestones);
        break;
      case 2:
        newMile = [...milestones].sort((a, b) =>
          a.deadLine > b.deadLine ? 1 : -1
        );
        setMilestonesSorted(newMile);
        break;

      default:
        break;
    }
  }, [sortFilter]);

  const onChange = (e) => {
    setSortFilter(e.target.value);
  };

  function renderContentSortFilter() {
    return (
      <div>
        {" "}
        <Radio.Group onChange={onChange} value={sortFilter}>
          <Radio value={1}>Default</Radio>
          <Radio value={2}>Scadenza</Radio>
        </Radio.Group>
      </div>
    );
  }

  function handleNewMilestone(e) {
    setSelectedMilestone({});
  }
  async function onConfirm(values) {
    try {
      const body = {
        ...form,
      };

      let response = {};
      if (form?.id) {
        response = await api.updateTask(form.id, body, user.token);
      } else {
        response = await api.createTask(body, user.token);
      }

      setSelectedMilestone(response);
      message.success("Operazione eseguita con successo");
      setChange();
    } catch (error) {
      console.log("[Error] in confirm taskcard: ", error);

      message.error("Errore durante l'operazione");
    }
  }
  function renderHeader() {
    return (
      <div className="information">
        <div className="header-div">
          <div className="projectName">
            Milestones progetto
            <Popover
              content={renderContentSortFilter()}
              title="Ordina per :"
              trigger="click"
              open={open}
              onOpenChange={handleOpenChange}
            >
              <SortIcon className="icon" style={{ marginLeft: "10px" }} />
            </Popover>
          </div>

          <AppButton onClick={handleNewMilestone}>
            Crea nuova Milestone
          </AppButton>
          {
            <MilestonesCreation
              open={!!selectedMilestone}
              onCancel={() => setSelectedMilestone(null)}
              onConfirm={handleConfirmMilestone}
              defaultValues={selectedMilestone}
            />
          }
        </div>
      </div>
    );
  }

  function getTasks() {
    const tasksArray = [];
    for (const element of project.taskLists) {
      for (const task of element.tasks) {
        tasksArray.push(task);
      }
    }

    return tasksArray;
  }

  return (
    <div className={className}>
      <DndProvider backend={HTML5Backend}>
        {renderHeader()}
        <DragDrop
          milestones={milestonesSorted}
          tasks={getTasks()}
          taskLists={project.taskLists}
          setSelectedMilestone={setSelectedMilestone}
        />
      </DndProvider>
    </div>
  );

  //
}

// ----------------------------------------------------------------------------

const StyledMilestones = styled(Milestones)`
  & {
    .containerRowsMilestones {
      display: flex;
      justify-content: space-evenly;
    }
  }
`;
// ----------------------------------------------------------------------------

export default StyledMilestones;
