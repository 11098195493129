import React, { useState, useEffect } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import styled from "styled-components";
import { message, Tooltip, Progress, Popconfirm, Avatar, Badge } from "antd";
import { useSelector } from "react-redux";
import useRestResource from "../../../../_shared/hooks/useRestResource";
import * as api from "../../../../../api";
import { ApiFilled } from "@ant-design/icons";

export function Item(props) {
  const { obj, handleModifyTask } = props;

  const style = {
    height: 120,
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    margin: "5px",
    borderRadius: 4,
    background: "white",
    paddingLeft: "10px",
    paddingTop: "5px",
    cursor: "pointer",
  };
  const title = {
    fontSize: "12px",
  };
  const description = {
    fontSize: "10px",
  };
  const user = useSelector((state) => (state?.auth.user ? state.auth : null));

  const [users] = useRestResource("/users", "", user.token, {
    organizations: user.organization,
  });

  function getAvatarName(id) {
    const user = users?.rows?.filter((user) => user.id === id);
    return user && user !== []
      ? user[0]?.name?.charAt(0)?.toUpperCase() +
          user[0]?.surname?.charAt(0)?.toUpperCase()
      : "";
  }

  return (
    <div style={style} onClick={(e) => handleModifyTask(e, obj)}>
      <div style={title}>{obj?.name}</div>
      <div style={description}>{obj?.description}</div>
      {obj?.assigned ? <Avatar>{getAvatarName(obj?.assigned)}</Avatar> : ""}
      {/* {console.log("chat -> ", chat)}
      <Badge count={chat?.comments?.length} /> */}
    </div>
  );
}

function SortableItem(props) {
  const { children, id, handleModifyTaskList } = props;

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    flex: 1,
    position: "relative",
  };

  return (
    <div
      onClick={(e) => handleModifyTaskList(e, id)}
      ref={setNodeRef}
      style={style}
      {...listeners}
      {...attributes}
    >
      {children}
    </div>
  );
}

const StyledSortableItem = styled(SortableItem)`
  & {
  }
`;

export default StyledSortableItem;
