import styled from "styled-components"
import { useState } from "react"
import { message } from "antd"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"

import CompanyRightSheet from "./CompanyRightSheet"

import Avatar from "../../../_shared/components/Avatar"
import PopConfirm from "../../../_shared/components/PopConfirm"
import Table from "../../../_shared/components/Table"
import usePaginatedRestResource from "../../../_shared/hooks/usePaginatedRestResource"
import { ReactComponent as Delete } from "../../../_shared/assets/icons/delete.svg"

import * as api from "../../../../api"

// ----------------------------------------------------------------------------

function Companies(props) {
	// -------------------------------------
	// Props destructuring
	// -------------------------------------

	const { className } = props

	// -------------------------------------
	// Hooks (e.g. useState, ...)
	// -------------------------------------
	const user = useSelector((state) => (state?.auth.user ? state.auth : null))
	const [searched, setSearched] = useState("")

	const [
		companies,
		,
		{ pagination, count, filters: currentFilters },
		{
			fetchResource: fetchCompanies,
			onChange: handleTableChange,
			onFiltersChange,
		},
	] = usePaginatedRestResource("/companies", user.token, {
		defaultFilters: {
			organization: user.organization,
		},
	})

	const [selectedCompany, setSelectedCompany] = useState()

	// -------------------------------------
	// Memoized values
	// -------------------------------------

	const companySectors = [
		"Commercio",
		"Turismo",
		"Finanza",
		"Sanità",
		"Industria",
		"Edilizia",
		"Artigianato",
		"Agricoltura",
		"Industria mineraria",
	]

	const companyTypes = [
		"Fornitore",
		"Cliente",
		"Partner",
		"Rivenditore",
		"Distributore",
		"Filiale",
	]

	// -------------------------------------
	// Effects
	// -------------------------------------

	// -------------------------------------
	// Component functions
	// -------------------------------------

	function getTableFilters() {
		return [
			{
				key: "businessName",
				type: "text",
				value: currentFilters.businessName,
				onSearch: (value) =>
					onFiltersChange({
						businessName: value,
					}),
			},
			{
				key: "vatNumber",
				type: "text",
				value: currentFilters.vatNumber,
				onSearch: (value) =>
					onFiltersChange({
						vatNumber: value,
					}),
			},
		]
	}

	function handleSearch() {
		onFiltersChange({
			businessName: searched,
			vatNumber: searched,
		})
	}
	function handleChangeSearch(value) {
		if (value.length === 0 && searched.length > value.length) {
			onFiltersChange({
				businessName: value,
				vatNumber: value,
			})
		} else {
			setSearched(value)
		}
	}

	function maxNumber() {
		if (companies.length === 0) return 0
		return Math.max(
			...companies.map((company) => parseInt(company.businessCode))
		)
	}

	function getColumns() {
		return [
			{
				title: "RAGIONE SOCIALE",
				dataIndex: "businessName",
				key: "businessName",
				sorter: (a, b) => a.businessName.localeCompare(b.businessName),
				render: (text, record, index) => (
					<div className="row">
						<Avatar name={text?.charAt?.(0)} />
						<span className="companyLink">
							<Link to={`${record.id}`}>{text}</Link>
						</span>
					</div>
				),
			},
			{
				title: "Partita IVA",
				dataIndex: "vatNumber",
				key: "vatNumber",
			},
			{
				title: "STATO",
				dataIndex: "state",
				key: "state",
			},
			{
				title: "TELEFONO",
				dataIndex: "phoneNumber",
				key: "phoneNumber",
			},
			{
				title: "CONTATTO",
				dataIndex: "contact",
				key: "contact",
				render: (contact) => (
					<div className="row">
						<Avatar
							name={
								contact
									? contact.name.charAt(0) + contact.surname.charAt(0)
									: ""
							}
						/>
						<span>{contact && contact.email}</span>
					</div>
				),
			},
			{
				title: "",
				dataIndex: "delete",
				key: "delete",
				render: (text, record, index) => (
					<PopConfirm
						className={className}
						title="Sei sicuro di voler rimuovere quest'azienda?"
						onConfirm={(e) => deleteCompany(e, record.id)}
						onCancel={(e) => e.stopPropagation()}
						okText="Sì"
						cancelText="No"
						placement="leftTop"
					>
						<div className="delete" onClick={(e) => e.stopPropagation()}>
							<Delete />
						</div>
					</PopConfirm>
				),
			},
		]
	}

	function handleNewCompany() {
		setSelectedCompany({})
	}

	async function deleteCompany(e, companyId) {
		e.stopPropagation()
		try {
			await api.deleteCompany(companyId, user.token)

			fetchCompanies()
		} catch (error) {
			console.log("[ERROR] in delete company: ")
			console.log(error)
		}
	}

	function onCancel() {
		setSelectedCompany(null)
	}

	async function handleSearchContacts(value) {
		try {
			const response = await api.searchContacts(
				value,
				user.organization,
				user.token
			)
			if (response) {
				return response.rows?.map((row) => {
					return { label: row.fullName, id: row.id }
				})
			}
			return null
		} catch (error) {
			console.log("[ERROR] in search contacts: ")
			console.log(error)
		}
	}

	async function onConfirm(values) {
		try {
			if (!values?.businessCode)
				values.businessCode = String(maxNumber() + 1).padStart(4, "0")

			!values?.organization
				? (values.organization = user.organization)
				: (values.organization = values.organization.id)

			if (values?.user) values.user = values.user.id

			values?.id
				? await api.updateCompany(values.id, values, user.token)
				: await api.createCompany(values, user.token)
			fetchCompanies()
			message.success("Operazione eseguita con successo")
			setSelectedCompany(null)
		} catch (error) {
			console.log("[ERROR] in confirm company: ")
			console.log(error)
			message.error("Errore durante l'operazione")
		}
	}

	return (
		<Table
			className={className}
			columns={getColumns()}
			onResourceCreate={() => setSelectedCompany({})}
			dataSource={companies}
			onChange={handleTableChange}
			rowSelection={{ type: "checkbox" }}
			addContact="true"
			handleOnRow={(row) => setSelectedCompany(row)}
			renderTopBarCustomButtons={() => (
				<CompanyRightSheet
					open={!!selectedCompany}
					defaultValues={selectedCompany}
					onConfirm={onConfirm}
					onCancel={onCancel}
					onButtonClick={handleNewCompany}
					handleSearch={handleSearchContacts}
				/>
			)}
			filters={getTableFilters()}
			search
			handleSearchSubmit={handleSearch}
			handleOnChangeSearch={handleChangeSearch}
		/>
	)
}

// ----------------------------------------------------------------------------

const StyledCompanies = styled(Companies)`
	& {
		.row {
			display: flex;
			flex-direction: row;
			align-items: center;

			span {
				margin-left: 8px;
			}
		}
		.app-select {
			min-width: 280px;
		}
		.companyLink {
			text-decoration: underline;
		}
		.form {
			min-width: 280px;
		}
		.add-field {
			display: flex;
			flex-direction: row-reverse;
			justify-content: center;
			margin: auto;
			margin-top: 20px;
			padding: 5px;
			border: none;
			.icon {
				margin: auto;
				margin-right: 10px;
				height: 14px;
			}
		}
	}
`

// ----------------------------------------------------------------------------

export default StyledCompanies
