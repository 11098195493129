import React from "react"
import styled from "styled-components"
import { Select, message } from "antd"
import { useState } from "react"
import { useSelector } from "react-redux"

import UserRightSheet from "./UserRightSheet"

import Avatar from "../../../../../_shared/components/Avatar"
import PopConfirm from "../../../../../_shared/components/PopConfirm"
import Table from "../../../../../_shared/components/Table"
import { ReactComponent as Delete } from "../../../../../_shared/assets/icons/delete.svg"
import usePaginatedRestResource from "../../../../../_shared/hooks/usePaginatedRestResource"

import * as api from "../../../../../../api"

// ----------------------------------------------------------------------------

function Users(props) {
	// -------------------------------------
	// Props destructuring
	// -------------------------------------

	const { className } = props

	// -------------------------------------
	// Hooks (e.g. useState, ...)
	// -------------------------------------

	const user = useSelector((state) => (state?.auth.user ? state.auth : null))
	const [searched, setSearched] = useState("")

	const [
		users,
		,
		{ pagination, count, filters: currentFilters },
		{ fetchResource: fetchUsers, onChange: handleTableChange, onFiltersChange },
	] = usePaginatedRestResource("/users", user.token, {
		defaultFilters: {
			organizations: user.organization,
		},
	})

	const [selectedUser, setSelectedUser] = useState()

	// -------------------------------------
	// Memoized values
	// -------------------------------------

	// -------------------------------------
	// Effects
	// -------------------------------------

	// -------------------------------------
	// Component functions
	// -------------------------------------

	function handleSearch() {
		onFiltersChange({
			email: searched,
			name: searched,
		})
	}
	function handleChangeSearch(value) {
		if (value.length === 0 && searched.length > value.length) {
			onFiltersChange({
				email: value,
				name: value,
			})
		} else {
			setSearched(value)
		}
	}

	function getTableFilters() {
		return [
			{
				key: "email",
				type: "text",
				value: currentFilters.email,
				onSearch: (value) =>
					onFiltersChange({
						email: value,
					}),
			},
		]
	}

	function getColumns() {
		return [
			{
				title: "NOME",
				dataIndex: "fullName",
				key: "fullName",
				sorter: (a, b) => a.fullName.localeCompare(b.fullName),
				render: (text) => {
					if (text) {
						const names = text.split(" ")
						return (
							<div className="row">
								<Avatar
									name={
										names[0].charAt(0) + (names[1] ? names[1].charAt(0) : "")
									}
								/>
								<span>{text}</span>
							</div>
						)
					}
				},
			},
			{
				title: "E-MAIL",
				dataIndex: "email",
				key: "email",
			},
			{
				title: "NUMERO DI TELEFONO",
				dataIndex: "phone",
				key: "phone",
			},
			{
				title: "",
				dataIndex: "delete",
				key: "delete",
				render: (text, record, index) => (
					<PopConfirm
						className={className}
						title="Sei sicuro di voler rimuovere questo utente dall'organizzazione?"
						onConfirm={(e) => deleteUser(e, record)}
						onCancel={(e) => e.stopPropagation()}
						okText="Sì"
						cancelText="No"
						placement="leftTop"
					>
						<div className="delete" onClick={(e) => e.stopPropagation()}>
							<Delete />
						</div>
					</PopConfirm>
				),
			},
		]
	}

	async function deleteUser(e, userRow) {
		e.stopPropagation()
		try {
			const newUserOrganizations = userRow.organizations?.filter(
				(organization) => organization !== user.organization
			)
			await api.updateUser(
				userRow.id,
				{ organizations: [...newUserOrganizations] },
				user.token
			)

			const newOrganizationUsers = users.filter(
				(orgUser) => orgUser.id !== userRow.id
			)
			await api.updateOrganization(
				user.organization,
				{ users: newOrganizationUsers.map((orgUser) => orgUser.id) },
				user.token
			)
			fetchUsers()
		} catch (error) {
			console.log("[ERROR] in delete user: ")
			console.log(error)
		}
	}

	function handleNewUser() {
		setSelectedUser({})
	}

	function onCancel() {
		setSelectedUser(null)
	}

	async function onConfirm(values) {
		let insert = true
		if (values?.email === "") message.error("Email mancante")
		else {
			const newUsers = await api.getUserByEmail(values?.email, user.token)
			let newUser = newUsers.rows[0] ?? {}
			try {
				if (newUsers.count === 0) {
					await api.createInvite(
						{
							email: values?.email,
							organization: user.organization,
							state: "pending",
						},
						user.token
					)
				} else {
					for (let organization of newUser.organizations) {
						if (user.organization === organization && insert) insert = false
					}
				}

				if (insert) {
					if (newUsers.count !== 0) {
						const organization = await api.getOrganization(
							user.organization,
							user.token
						)
						const updateData = {
							organizations: [
								...(newUser.organizations ?? []),
								organization.id,
							],
						}

						await api.updateUser(newUser.id, updateData, user.token)
						await api.updateOrganization(
							user.organization,
							{ users: [...organization.users, newUser.id] },
							user.token
						)
					}
				} else message.info("Utente già presente nell'organizzazione")

				message.success("Operazione eseguita con successo")

				fetchUsers()
				setSelectedUser(null)
			} catch (error) {
				console.log("[ERROR] in confirm user: ")
				console.log(error)
				message.error("Errore durante l'operazione")
			}
		}
	}

	return (
		<Table
			className={className}
			columns={getColumns()}
			onResourceCreate={() => setSelectedUser({})}
			dataSource={users}
			onChange={handleTableChange}
			rowSelection={{ type: "checkbox" }}
			addContact="true"
			handleOnRow={(row) => row}
			renderTopBarCustomButtons={() => (
				<UserRightSheet
					open={!!selectedUser}
					defaultValues={selectedUser}
					onConfirm={onConfirm}
					onCancel={onCancel}
					onButtonClick={handleNewUser}
				/>
			)}
			filters={getTableFilters()}
			search
			handleSearchSubmit={handleSearch}
			handleOnChangeSearch={handleChangeSearch}
		/>
	)
}

// ----------------------------------------------------------------------------

const StyledUsers = styled(Users)`
	& {
		.form {
			min-width: 280px;
		}
		.app-select {
			min-width: 280px;
		}
		.row {
			display: flex;
			flex-direction: row;
			align-items: center;

			span {
				margin-left: 8px;
			}
		}
		.addField {
			display: flex;
			flex-direction: row-reverse;
			justify-content: center;
			margin: auto;
			margin-top: 10px;
			padding: 5px;
			border: none;
			.icon {
				margin: auto;
				margin-right: 10px;
				height: 14px;
			}
		}
	}
`
export default StyledUsers
