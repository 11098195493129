import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { message, Select, Tooltip } from "antd";
import { useSelector } from "react-redux";

import * as api from "../../../../../api";

import useForm from "../../../../_shared/hooks/useForm";
import { ReactComponent as Delete } from "../../../../_shared/assets/icons/delete.svg";
import { ReactComponent as ChatIcon } from "../../../../_shared/assets/icons/chat.svg";
import Task from "../../Task";
import { Link, Routes, Route, useNavigate } from "react-router-dom";
import { Button, Modal } from "antd";
import AppButton from "../../../../_shared/components/AppButton";

import AppInput from "../../../../_shared/components/AppInput";
import Chat from "../../Chats";

// ----------------------------------------------------------------------------

function ChatList(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { className, chats, project } = props;

  // -------------------------------------
  // Hooks (e.g. useState, ...)
  // -------------------------------------
  const user = useSelector((state) => (state?.auth.user ? state.auth : null));

  const [form, setForm] = useState([]);
  const [selectedChat, setSelectedChat] = useState();

  const [getChatName, setGetChatName] = useState(false);
  const [chatName, setChatName] = useState();

  function OpenChat(chat) {
    setSelectedChat(chat);
  }

  async function CreateComment() {
    const body = {
      name: chatName,
      assignedTo: project,
    };
    const response = await api.createChat(body, user.token);

    setForm([...form, response]);
  }

  useEffect(() => {
    console.log("get chat name is : ", getChatName);
  }, getChatName);

  useEffect(() => {
    if (chats && JSON.stringify(form) === "[]") {
      setForm(chats);
    }
  }, []);

  function renderChats() {
    return (
      <div>
        {form?.map((chat) => (
          <Link to={`/chat/${chat.id}`}>
            <div className="ChatGroup" onClick={() => OpenChat(chat)}>
              <ChatIcon className="iconChat"></ChatIcon>
              {console.log("chat name", chat.name)}
              {chat.name}
            </div>
          </Link>
        ))}
      </div>
    );
  }

  function renderHeader() {
    return (
      <div className="information">
        <div className="header-div">
          <p className="projectName">Conversazione progetto</p>

          <AppButton
            onClick={() => {
              setGetChatName(true);
            }}
          >
            Crea una nuova chat
          </AppButton>

          <Modal
            title="Titolo nuova chat"
            visible={getChatName}
            onCancel={() => setGetChatName(false)}
            footer={[
              <Button key="back" onClick={() => setGetChatName(false)}>
                Esci
              </Button>,

              <Button
                key="create"
                onClick={() => {
                  setGetChatName(false);
                  CreateComment();
                }}
              >
                Crea
              </Button>,
            ]}
          >
            <div>
              <AppInput
                value={chatName}
                placeholder="Chat Name"
                onChange={setChatName}
              />
            </div>
          </Modal>
        </div>
      </div>
    );
  }

  // -------------------------------------
  return (
    <div className={className}>
      {renderHeader()}

      {form?.length > 0 && renderChats()}
      {form?.length < 1 && <div>qui NON ci sono chats</div>}
    </div>
  );
}

// ----------------------------------------------------------------------------

const StyledChatList = styled(ChatList)`
  & {
    margin-bottom: 20px;
   
    .ChatGroup {
      
      background-color: rgba(240, 240, 240, 0.8);
      border-radius: 20px;
      box-shadow: 1px 1px #ffa500;
      display: flex;
      flex-direction: row;
      align-items: center;
      
      transition: 0.5s;
      border: 1px;
      font-size : 20px;

      padding : 20px;
      margin: 10px;
      position:relative;
     
      :before {
      content: "";
    
      position: absolute;
      border-right: 10px solid rgba(240, 240, 240, 0.8);
      border-left: 10px solid transparent;
      border-bottom: 10px solid rgba(240, 240, 240, 0.8);
      border-top: 10px solid transparent;
      left: -20px;
      top: 20px;
      
    }
      :hover {
        cursor: pointer;
        // transform: rotateY(10deg);
        box-shadow: 3px 3px #ffa500;
        background-color: rgba(240, 240, 240, 0.8);
        color: black;
    }
    .iconChat {
      height: 30px;
      width: 30px;
      margin: 10px;
    }
  }
`;
// ----------------------------------------------------------------------------

export default StyledChatList;
