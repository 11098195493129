import React, { useEffect, useState} from "react"
import styled from "styled-components"
import { useSelector } from "react-redux"
import { InputNumber } from "antd"

import AppButton from "../../../../../../_shared/components/AppButton"
import RightSheet from "../../../../../../_shared/components/Drawer"
import AppInput from "../../../../../../_shared/components/AppInput"
import useForm from "../../../../../../_shared/hooks/useForm"
import useRestResource from "../../../../../../_shared/hooks/useRestResource"
import FieldLabel from "../../../../../../_shared/components/FieldLabel"


function RatesRightSheet(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className, open, defaultValues, onConfirm, onCancel, onButtonClick} = props
    

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const [form, , setMany, clearForm] = useForm()
    const user = useSelector((state) => (state?.auth.user ? state.auth : null))
    // const [customFields] = useRestResource(
    //     "/custom-fields",
    //     "",
    //     user.token,
    //     {organization: user.organization}
    // )
    
    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    useEffect(() => {
        !open && clearForm()
        // eslint-disable-next-line
    }, [open])

    useEffect(() => {
        defaultValues && setMany(defaultValues)
        // eslint-disable-next-line
    }, [defaultValues])

    // -------------------------------------
    // Component functions
    // -------------------------------------
    
    function update(field) {
            return (value) => {
                setMany({
                    ...form,
                    [field]: value,
                })}
    }

    function handleConfirm() {
        // form?.customFields?.forEach(
        //     (usedCustomField) => checkValueType(usedCustomField)
        // )
        onConfirm && onConfirm(form)
    }

    // function handleNewField(e) {
	// 	e.preventDefault()
	// 	const newCustomFields = [
	// 		...form?.customFields ?? [],
	// 		{ key: Math.floor(Math.random() * 99999), customField: "", value: "" },
	// 	]
	// 	update("customFields")(newCustomFields)
	// }

    // function handleDeleteCustomField(e, key) {
	// 	e.preventDefault()
	// 	const newCustomFields = form?.customFields.filter(
	// 		(customField) => customField.key !== key
	// 	)
	// 	update("customFields")(newCustomFields)
	// }

	// function onChange(customFieldId, value, key) {
	// 	const customField = form?.customFields.find(
	// 		(customField) => customField.key === key
	// 	)
	// 	customField.customField = customFieldId
	// 	customField.value = value
	// }

    // function checkValueType(customField) {
	// 	const originalCustomField = customFields.rows.find(
	// 		(originalField) => originalField.id === customField.customField
	// 	)
	// 	if (originalCustomField.type === "number") {
	// 		const value = parseFloat(customField.value)
	// 		customField.value = null
	// 		customField.value = value
	// 	}
	// 	if (originalCustomField.type === "boolean") {
	// 		const value =
	// 			customField.value !== "" && customField.value !== "false" ? true : false
	// 		customField.value = null
	// 		customField.value = value
	// 	}
	// }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <>
            <AppButton className="add-btn" onClick={onButtonClick}>
                Crea nuova aliquota
            </AppButton>
            <RightSheet
					open={open}
					onCancel={onCancel}
					onConfirm={handleConfirm}
					title={defaultValues?.id  ? "Modifica aliquota" : "Crea aliquota"}
					confirmButtonLabel={defaultValues?.id  ? "Modifica aliquota" : "Crea aliquota"}
					cancelButtonLabel="Annulla"
				>
                <form className="form">
                    <AppInput
                        label="Nome"
                        placeholder="Nome"
                        value={form?.name ?? ""}
                        onChange={update("name")}
                    />
                    <FieldLabel className="percentage">Percentuale:</FieldLabel>
                    <InputNumber
                        value={form?.percentage}
                        min={0}
                        max={100}
                        formatter={value => `${value}%`}
                        parser={value => value.replace('%', '')}
                        onChange={update("percentage")}
                    />
                    {/* {form?.usedCustomFields?.map((customField, index) => (
                        <CustomField
                            key={customField.key}
                            id={customField.key}
                            onChange={onChange}
                            onCancel={(e, key) => handleDeleteCustomField(e,key)}
                            customFields={customFields?.rows}
                            givenCustomField={customField.customField ? customField.customField : null}
                            givenValue={customField.value ? customField.value : null}
                        />
                    ))}
                    <AppButton
                        uxType="link"
                        onClick={handleNewField}
                        className="add-field"
                        icon={<PlusOutlined />}
                    >
                        Aggiungi campo personalizzato
                    </AppButton>  */}
                </form>
            </RightSheet>
        </>
    )
}
// ----------------------------------------------------------------------------

const StyledRatesRightSheet = styled(RatesRightSheet) `
    &{
        .form {
            min-width: 280px;
        }
        .app-select {
            min-width: 280px;
        }
    } 

`
export default StyledRatesRightSheet

