import { PlusOutlined } from "@ant-design/icons";
import { Col, Row, Select } from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { ReactComponent as Plus } from "../../../../_shared/assets/icons/plus.svg";
import AppButton from "../../../../_shared/components/AppButton";
import AppInput from "../../../../_shared/components/AppInput";
import AppSelect from "../../../../_shared/components/AppSelect";
import RightSheet from "../../../../_shared/components/Drawer";
import useForm from "../../../../_shared/hooks/useForm";
import useRestResource from "../../../../_shared/hooks/useRestResource";

import ProductSelection from "../../Deals/ProductSelection";

function ProductRightSheet(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const {
    className,
    open,
    defaultValues,
    cloningValues,
    onConfirm,
    onCancel,
    onButtonClick,
  } = props;

  // -------------------------------------
  // Hooks (e.g. useState, ...)
  // -------------------------------------

  const Option = Select;
  const [form, , setMany, clearForm] = useForm();
  const user = useSelector((state) => (state?.auth.user ? state.auth : null));

  const [customFields] = useRestResource("/custom-fields", "", user.token, {
    organization: user.organization,
  });

  const [taxRates] = useRestResource("/rates", "", user.token, {
    organization: user.organization,
  });

  const [products] = useRestResource("/products", "", user.token, {
    organization: user.organization,
  });

  const [companies] = useRestResource("/companies", "", user.token, {
    organization: user.organization,
  });

  // -------------------------------------
  // Memoized values
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  useEffect(() => {
    !open && clearForm();
    // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    defaultValues && setMany(defaultValues);
    if (cloningValues) {
      cloningValues.id = null;
      cloningValues.code = cloningValues.code + "_copy";
      setMany(cloningValues);
    }
    // eslint-disable-next-line
  }, [defaultValues, cloningValues]);

  useEffect(() => {
    form?.components && update("amount")(calculateAmount());

    // eslint-disable-next-line
  }, [form?.components]);

  // -------------------------------------
  // Component functions
  // -------------------------------------

  function update(field) {
    return (value) => {
      setMany({
        ...form,
        [field]: value,
      });
    };
  }

  function handleConfirm() {
    form?.customFields?.forEach((usedCustomField) =>
      checkValueType(usedCustomField)
    );
    onConfirm && onConfirm(form);
  }

  function handleNewField(e) {
    e.preventDefault();
    const newCustomFields = [
      ...(form?.customFields ?? []),
      {
        key: Math.floor(Math.random() * 99999),
        customField: "",
        value: "",
      },
    ];
    update("customFields")(newCustomFields);
  }

  function handleDeleteCustomField(e, key) {
    e.preventDefault();
    const newCustomFields = form?.customFields.filter(
      (customField) => customField.key !== key
    );
    update("customFields")(newCustomFields);
  }

  // function onChange(customFieldId, value, key) {
  // 	const customField = form?.customFields.find(
  // 		(customField) => customField.key === key
  // 	)
  // 	customField.customField = customFieldId
  // 	customField.value = value
  // }

  function onChange(quantity, price, id, description, discount, key) {
    const productSelection = form?.components?.find(
      (productSelection) => productSelection.key === key
    );
    productSelection.quantity = quantity;
    productSelection.price = price;
    productSelection.product = id;
    productSelection.description = description;
    productSelection.discount = discount;

    update("amount")(calculateAmount());
  }

  function calculateAmount() {
    let newAmount = 0;
    form?.components?.forEach((productSelection) => {
      let prod = productSelection.quantity * productSelection.price;
      newAmount += prod - (prod * productSelection.discount) / 100;
    });
    return newAmount.toFixed(2);
  }

  function getTotal() {
    if (form?.discount && form?.discount > 0) {
      const discountValue = (form?.amount * form?.discount) / 100;
      return form?.amount - discountValue;
    }
    return form?.amount;
  }

  function handleNewProductSelection(e) {
    e.preventDefault();
    const newproductsSelection = [
      ...(form?.components ?? []),
      {
        key: Math.floor(Math.random() * 99999),
        quantity: "",
        price: "",
        product: "",
        description: "",
        discount: 0,
      },
    ];
    update("components")(newproductsSelection);
  }

  function onDeleteProduct(e, id) {
    e.preventDefault();
    const newproductsSelection = form?.components?.filter(
      (productSelection) => productSelection.key !== id
    );
    update("components")(newproductsSelection);
  }

  function checkValueType(customField) {
    const originalCustomField = customFields.find(
      (originalField) => originalField.id === customField.customField
    );
    if (originalCustomField.type === "number") {
      const value = parseFloat(customField.value);
      customField.value = null;
      customField.value = value;
    }
    if (originalCustomField.type === "boolean") {
      const value =
        customField.value !== "" && customField.value !== "false"
          ? true
          : false;
      customField.value = null;
      customField.value = value;
    }
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <>
      <AppButton className="add-btn" onClick={onButtonClick}>
        Crea nuovo prodotto
        <Plus width="14" height="13" style={{ marginLeft: 2 }} />
      </AppButton>
      <RightSheet
        open={open}
        onCancel={onCancel}
        onConfirm={handleConfirm}
        title={defaultValues?.id ? "Modifica prodotto" : "Crea prodotto"}
        confirmButtonLabel={
          defaultValues?.id ? "Modifica prodotto" : "Crea prodotto"
        }
        cancelButtonLabel="Annulla"
        className={`${className}`}
      >
        <form className="form">
          <AppInput
            label="Codice"
            placeholder="Codice"
            value={form?.code ?? ""}
            onChange={update("code")}
          />
          <AppInput
            label="Descrizione"
            placeholder="Descrizione"
            value={form?.description ?? ""}
            onChange={update("description")}
          />
          <AppInput
            label="Descrizione estesa"
            placeholder="Descrizione estesa"
            value={form?.extendedDescription ?? ""}
            onChange={update("extendedDescription")}
          />
          <AppInput
            label="UM"
            placeholder="um"
            value={form?.um ?? ""}
            onChange={update("um")}
          />
          <AppInput
            label="Famiglia"
            placeholder="Famiglia"
            value={form?.family ?? ""}
            onChange={update("family")}
          />
          <AppInput
            label="Descrizone famiglia"
            placeholder="Descrizone famiglia"
            value={form?.familyDescription ?? ""}
            onChange={update("familyDescription")}
          />
          <AppInput
            label="Sottofamiglia"
            placeholder="Sottofamiglia"
            value={form?.subFamily ?? ""}
            onChange={update("subFamily")}
          />
          <AppInput
            label="Descrizone sottofamiglia"
            placeholder="Descrizone sottofamiglia"
            value={form?.subFamilyDescription ?? ""}
            onChange={update("subFamilyDescription")}
          />
          <AppInput
            label="Stato"
            placeholder="Stato"
            value={form?.state ?? ""}
            onChange={update("state")}
          />
          <AppSelect
            label="Aliquota"
            placeholder="Aliquota"
            value={
              taxRates?.rows?.find(
                (taxRate) => taxRate.id === (form?.taxRate?.id ?? form?.taxRate)
              )?.name ?? ""
            }
            onSelect={update("taxRate")}
            className="app-select"
          >
            {taxRates &&
              taxRates?.rows?.map((taxRate, index) => (
                <Option key={taxRate.id} id={index}>
                  {taxRate.name}
                </Option>
              ))}
          </AppSelect>
          <AppInput
            label="Prezzo unitario (€)"
            placeholder=""
            value={
              form?.unitPrice ? form?.unitPrice : form.amount ? form.amount : ""
            }
            onChange={(value) => update("unitPrice")(value.replace(",", "."))}
          />
          <AppSelect
            label="Fornitori"
            placeholder="Fornitori"
            value={form?.suppliers ?? ""}
            onChange={update("suppliers")}
            className="app-select"
            mode="multiple"
            allowClear
          >
            {companies?.rows &&
              companies.rows?.map((company, index) => (
                <Option key={company.id} id={index}>
                  {company.businessName}
                </Option>
              ))}
          </AppSelect>

          <p className="components">Componenti prodotto</p>

          <Row className="products">
            <Col span={11} className="productsPar">
              Prodotti
            </Col>
            <Col span={4} offset={0.5} className="quantityPar">
              Quantità
            </Col>
            <Col span={6} offset={2} className="pricePar">
              Prezzo
            </Col>
          </Row>

          {form?.components?.map((productSelection) => (
            <ProductSelection
              products={products?.rows}
              key={productSelection.key}
              id={productSelection.key}
              onChange={onChange}
              product={productSelection?.product?.id ?? null}
              price={productSelection.price ? productSelection.price : null}
              quantity={
                productSelection.quantity ? productSelection.quantity : null
              }
              description={
                productSelection.description
                  ? productSelection.description
                  : null
              }
              discount={
                productSelection.discount ? productSelection.discount : null
              }
              onCancel={onDeleteProduct}
            />
          ))}

          <p className="total">Subtotale: {form?.amount ?? 0}€</p>

          <AppButton
            uxType="link"
            onClick={handleNewProductSelection}
            className="addField"
            icon={<PlusOutlined />}
          >
            Aggiungi prodotto
          </AppButton>

          <p className="total">Totale: {getTotal()}€</p>

          {/* {form?.customFields?.map((customField, index) => (
                            <CustomField
                                key={customField.key}
                                id={customField.key}
                                onChange={onChange}
                                onCancel={(e, key) => handleDeleteCustomField(e,key)}
                                customFields={customFields?.rows}
                                givenCustomField={
                                    customField.customField ?? null
                                }
                                givenValue={customField.value ?? null}
                            />
                        ))}
                        <AppButton
                            uxType="link"
                            onClick={handleNewField}
                            className="add-field"
                            icon={<PlusOutlined />}
                        >
                            Aggiungi campo personalizzato
                        </AppButton> */}
        </form>
      </RightSheet>
    </>
  );
}
// ----------------------------------------------------------------------------

const StyledProductRightSheet = styled(ProductRightSheet)`
  & {
    .products {
      margin-top: 15px;
    }
    .components {
      margin-top: 20px;
      padding-bottom: 5px;
      font-weight: 400;
      font-size: 12px;
      border-bottom: 1px solid ${({ theme }) => theme.colors.grey200};
    }
  }
`;
export default StyledProductRightSheet;
