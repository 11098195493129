import React, { useState } from "react";
import { message } from "antd";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import * as api from "../../../../api";
import { login } from "../../../../redux/slices/authSlice";

import LogImage from "../../../_shared/components/LoginItems/LogImage";
import AppInput from "../../../_shared/components/AppInput";
import AppButton from "../../../_shared/components/AppButton";
import Checkbox from "../../../_shared/components/FormItems/Checkbox";
import Logo from "../../../_shared/components/LoginItems/Logo";
import Heading from "../../../_shared/components/LoginItems/Heading";
import SubHeading from "../../../_shared/components/LoginItems/SubHeading";
import Row from "../../../_shared/components/LoginItems/Row";
import LogForm from "../../../_shared/components/LoginItems/LogForm";
import FormDiv from "../../../_shared/components/LoginItems/FormDiv";

// ----------------------------------------------------------------------------

function SignIn(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { className } = props;

  // -------------------------------------
  // Hooks (e.g. useState, ...)
  // -------------------------------------

  const [email, setEmail] = useState("newTester@test.com");
  const [password, setPassword] = useState("123456");
  const [remember, setRemember] = useState(false);
  const dispatch = useDispatch();

  // -------------------------------------
  // Component functions
  // -------------------------------------

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      const response = await api.logIn(email, password);
      switch (response.status) {
        case 400:
        case 401:
          message.error("Email o password errati");
          break;
        case 404:
          message.error("Error 404: pagina non trovata");
          break;
        case 500:
          message.error("Errore nel server");
          break;
        default:
          handleLogin(response);
      }
    } catch (error) {}
  }

  function handleLogin(response) {
    const user = {
      fullName: response.user.fullName,
      token: response.token,
      email: response.user.email,
      organization: response.user.organizations[0],
    };
    dispatch(login(user));
  }

  function handleChange() {
    setRemember(!remember);
  }

  return (
    <div className={`${className}`}>
      <FormDiv>
        <Logo className="logo" />
        <LogForm onSubmit={handleSubmit}>
          <Heading>Accedi</Heading>
          <SubHeading>
            Accedi al tuo profilo se vuoi usare tutti i prodotti premium
          </SubHeading>
          <AppInput
            value={email}
            onChange={setEmail}
            label="Email"
            placeholder="Inserisci la tua email"
            required
          ></AppInput>
          <AppInput
            value={password}
            onChange={setPassword}
            label="Password"
            placeholder="Inserisci la tua password"
            type="password"
            required
          ></AppInput>
          <Row className="row-between">
            <Checkbox
              label="Rimani connesso"
              onChange={handleChange}
              checked={remember}
            ></Checkbox>
            <Link to="forgot-password" className="inner link">
              Recupera password
            </Link>
          </Row>
          <AppButton uxType="" htmlType="submit">
            ACCEDI
          </AppButton>

          <div className="outer">
            <p>Non hai un profilo?</p>
            <Link to="sign-up" className="link">
              <AppButton uxType="secondary" htmlType="">
                CREA UN ACCOUNT
              </AppButton>
            </Link>
          </div>
        </LogForm>
      </FormDiv>
      <LogImage page="login" />
    </div>
  );
}

const StyledSignIn = styled(SignIn)`
  & {
    display: flex;
    flex-direction: row;

    .logo {
      svg {
        max-width: 400px;
      }
    }
  }
`;
// ----------------------------------------------------------------------------
export default StyledSignIn;
