import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { PlusOutlined } from "@ant-design/icons";
import { Select } from "antd";

import AppButton from "../../../../_shared/components/AppButton";
import RightSheet from "../../../../_shared/components/Drawer";
import AppInput from "../../../../_shared/components/AppInput";
import useForm from "../../../../_shared/hooks/useForm";
import AppSelect from "../../../../_shared/components/AppSelect";
import CustomField from "../../../../_shared/components/CustomField";
import useRestResource from "../../../../_shared/hooks/useRestResource";
import SearchBox from "../../DashItems/SearchBox";
import Checkbox from "../../../../_shared/components/FormItems/Checkbox";
import SelectAutoComplete from "../../../../_shared/components/SelectAutoComplete";

import { ReactComponent as Plus } from "../../../../_shared/assets/icons/plus.svg";
function CompanyRightSheet(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const {
    className,
    open,
    defaultValues,
    onConfirm,
    onCancel,
    onButtonClick,
    handleSearch,
    header = true,
  } = props;

  // -------------------------------------
  // Hooks (e.g. useState, ...)
  // -------------------------------------

  const [form, setOne, setMany, clearForm] = useForm();

  const user = useSelector((state) => (state?.auth.user ? state.auth : null));
  const [contacts] = useRestResource("/contacts", "", user.token, {
    organization: user.organization,
  });
  const [customFields] = useRestResource("/custom-fields", "", user.token, {
    organization: user.organization,
  });
  const { Option } = Select;
  // -------------------------------------
  // Memoized values
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  useEffect(() => {
    !open && clearForm();
    // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    defaultValues && setMany(defaultValues);

    // eslint-disable-next-line
  }, [defaultValues]);

  // -------------------------------------
  // Component functions
  // -------------------------------------

  function update(field) {
    return (value) => {
      setMany({
        ...form,
        [field]: value,
      });
    };
  }

  function deepUpdate(field, prop) {
    return function (value) {
      const newField = {
        ...form[field],
        [prop]: value,
      };
      setOne(field, newField);
    };
  }

  function handleConfirm() {
    form?.customFields?.forEach((usedCustomField) =>
      checkValueType(usedCustomField)
    );
    onConfirm && onConfirm(form);
  }

  function handleNewField(e) {
    e.preventDefault();
    const newCustomFields = [
      ...(form?.customFields ?? []),
      {
        key: Math.floor(Math.random() * 99999),
        customField: "",
        value: "",
      },
    ];
    update("customFields")(newCustomFields);
  }

  function handleDeleteCustomField(e, key) {
    e.preventDefault();
    const newCustomFields = form?.customFields.filter(
      (customField) => customField.key !== key
    );
    update("customFields")(newCustomFields);
  }

  function onChange(customFieldId, value, key) {
    const customField = form?.customFields.find(
      (customField) => customField.key === key
    );
    customField.customField = customFieldId;
    customField.value = value;
  }

  function checkValueType(customField) {
    const originalCustomField = customFields.rows.find(
      (originalField) => originalField.id === customField.customField
    );
    if (originalCustomField.type === "number") {
      const value = parseFloat(customField.value);
      customField.value = null;
      customField.value = value;
    }
    if (originalCustomField.type === "boolean") {
      const value =
        customField.value !== "" && customField.value !== "false"
          ? true
          : false;
      customField.value = null;
      customField.value = value;
    }
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <>
      {header && (
        <AppButton className="add-btn" onClick={onButtonClick}>
          Crea nuova azienda{" "}
          <Plus width="14" height="13" style={{ marginLeft: 2 }} />
        </AppButton>
      )}
      <RightSheet
        open={open}
        onCancel={onCancel}
        onConfirm={handleConfirm}
        title={defaultValues?.id ? "Modifica azienda" : "Crea azienda"}
        confirmButtonLabel={
          defaultValues?.id ? "Modifica azienda" : "Crea azienda"
        }
        cancelButtonLabel="Annulla"
        className={className}
      >
        <form className="form">
          <AppInput
            label="Ragione sociale"
            placeholder="Nome"
            value={form?.businessName ?? ""}
            onChange={update("businessName")}
          />
          <AppInput
            label="Partita IVA"
            placeholder="IVA"
            value={form?.vatNumber ?? ""}
            onChange={update("vatNumber")}
          />
          <AppInput
            label="Codice fiscale"
            placeholder="Codice"
            value={form?.fiscalCode ?? ""}
            onChange={update("fiscalCode")}
          />
          <SelectAutoComplete
            placeholder="Contatti"
            className="app-select"
            defaultValue={form?.contact?.fullName}
            onSearch={handleSearch}
            label="Contatto"
            onChange={(value) => update("contact")(value)}
          />

          <AppInput
            label="Stato (sede legale)"
            value={form?.registeredOffice?.country ?? ""}
            onChange={deepUpdate("registeredOffice", "country")}
          />
          <Checkbox
            label="EXTRA UE"
            checked={form?.registeredOffice?.extraUe ?? ""}
            onChange={deepUpdate("registeredOffice", "extraUe")}
          />

          <AppInput
            label="Regione (sede legale)"
            value={form?.registeredOffice?.region ?? ""}
            onChange={deepUpdate("registeredOffice", "region")}
          />

          <AppInput
            label="Provincia (sede legale)"
            value={form?.registeredOffice?.province ?? ""}
            onChange={deepUpdate("registeredOffice", "province")}
          />

          <AppInput
            label="Città (sede legale)"
            value={form?.registeredOffice?.city ?? ""}
            onChange={deepUpdate("registeredOffice", "city")}
          />

          <AppInput
            label="Via (sede legale)"
            value={form?.registeredOffice?.street ?? ""}
            onChange={deepUpdate("registeredOffice", "street")}
          />

          <AppInput
            label="Codice Postale (sede legale)"
            value={form?.registeredOffice?.zipCode ?? ""}
            onChange={deepUpdate("registeredOffice", "zipCode")}
          />

          {/* <AppInput
						label="Indirizzo sede legale"
						value={form?.registeredOfficeAddress ?? ""}
						onChange={update("registeredOfficeAddress")}
					/>
					<AppInput
						label="Indirizzo sede operativa"
						value={form?.operativeHeadquarter ?? ""}
						onChange={update("operativeHeadquarter")}
					/> */}

          <AppInput
            label="Telefono"
            value={form?.phoneNumber ?? ""}
            onChange={update("phoneNumber")}
          />
          {/* {form?.customFields?.map((customField, index) => (
                        <CustomField
                            key={customField.key}
                            id={customField.key}
                            onChange={onChange}
                            onCancel={(e, key) =>
                                handleDeleteCustomField(e, key)
                            }
                            customFields={customFields?.rows}
                            givenCustomField={customField.customField ?? null}
                            givenValue={customField.value ?? null}
                        />
                    ))}
                    <AppButton
                        uxType="link"
                        onClick={handleNewField}
                        className="add-field"
                        icon={<PlusOutlined />}
                    >
                        Aggiungi campo personalizzato
                    </AppButton> */}
        </form>
      </RightSheet>
    </>
  );
}
// ----------------------------------------------------------------------------

const StyledCompanyRightSheet = styled(CompanyRightSheet)`
  & {
    .form {
      min-width: 280px;
    }
    .app-select {
      min-width: 280px;
    }
  }
`;
export default StyledCompanyRightSheet;
