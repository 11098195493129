import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { PlusOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Comment,
  Form,
  Input,
  List,
  Select,
  Divider,
} from "antd";

import AppButton from "../../../../../_shared/components/AppButton";
import RightSheet from "../../../../../_shared/components/Drawer";
import AppInput from "../../../../../_shared/components/AppInput";

import AppSelect from "../../../../../_shared/components/AppSelect";
import AppDatePicker from "../../../../../_shared/components/AppDatePicker";

import projectStatus from "../../../../../_shared/constants/projectStatus";
import useRestResource from "../../../../../_shared/hooks/useRestResource";

import useForm from "../../../../../_shared/hooks/useForm";
import moment from "moment";

import * as api from "../../../../../../api";
const { TextArea } = Input;

const CommentList = ({ comments }) => (
  <List
    dataSource={comments}
    header={`${comments.length} ${
      comments.length > 1 ? "Commenti" : "commento"
    }`}
    itemLayout="horizontal"
    renderItem={(props) => <Comment {...props} />}
  />
);

const Editor = ({ onChange, onSubmit, submitting, value }) => {
  return (
    <>
      <Form.Item>
        <TextArea rows={2} onChange={onChange} value={value} />
      </Form.Item>
      <Form.Item>
        <Button loading={submitting} onClick={onSubmit} type="primary">
          Aggiungi commento
        </Button>
      </Form.Item>
    </>
  );
};

function TaskRightSheet(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { className, open, defaultValues, onConfirm, onCancel, team } = props;
  // -------------------------------------
  // Hooks (e.g. useState, ...)
  // -------------------------------------

  const [form, , setMany, clearForm] = useForm();
  const { Option } = Select;
  const { TextArea } = Input;

  const user = useSelector((state) => (state?.auth.user ? state.auth : null));

  // -------------------------------------
  // Memoized values
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  useEffect(() => {
    console.log("open form");
    !open && clearForm();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    async function getComments() {
      const resp = await api.getChat(defaultValues?.chat, user.token);
      setComments(resp.comments);
      setCommentValue("");
      // eslint-disable-next-line
    }
    clearForm();
    if (defaultValues !== {}) {
      defaultValues && setMany(defaultValues);
      getComments();
    }
  }, [defaultValues]);

  // -------------------------------------
  // Component functions
  // -------------------------------------
  function update(field) {
    return (value) => {
      setMany({
        ...form,
        [field]: value,
      });
    };
  }

  function handleConfirm() {
    onConfirm && onConfirm(form);
  }

  const [comments, setComments] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [commentValue, setCommentValue] = useState("");

  const handleSubmit = async () => {
    if (!commentValue) return;
    setSubmitting(true);
    setTimeout(() => {
      setSubmitting(false);
      setCommentValue("");
      setComments([
        ...comments,
        {
          author: user.user,
          content: <p>{commentValue}</p>,
          datetime: moment(moment.now()).fromNow(),
        },
      ]);
    }, 1000);
    const body = {
      chat: form?.chat,
      author: user.user,
      content: commentValue,
      datetime: moment().format("DoMMM  YY H:mma"),
    };
    await api.createComment(body, user.token);
  };

  const handleChange = (e) => {
    setCommentValue(e.target.value);
  };

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <RightSheet
      open={open}
      onCancel={onCancel}
      onConfirm={handleConfirm}
      title={defaultValues?.id ? "Modifica task" : "Aggiungi task"}
      confirmButtonLabel={defaultValues?.id ? "Modifica task" : "Aggiungi task"}
      cancelButtonLabel="Annulla"
      className={`${className}`}
    >
      <form className="form">
        <AppInput
          label="Nome"
          placeholder="Nome"
          value={form?.name ?? ""}
          onChange={update("name")}
        />
        <AppInput
          label="Descrizione"
          placeholder="Descrizione"
          value={form?.description ?? ""}
          onChange={update("description")}
        />
        <AppInput
          label="Stato"
          placeholder="Stato"
          value={form?.state ?? ""}
          onChange={update("state")}
        />

        <AppSelect
          label="Stato attualee"
          placeholder="Stato attuale"
          value={form?.status ?? ""}
          onSelect={update("status")}
          className="app-select"
        >
          {projectStatus &&
            Object.keys(projectStatus).map((value, index) => (
              <Option key={value} id={index}>
                {projectStatus[value]}
              </Option>
            ))}
        </AppSelect>

        <AppDatePicker
          label="Data scadenza"
          value={form?.dueDate ?? null}
          onChange={update("dueDate")}
        />

        <AppInput
          label="Priorità"
          placeholder="Priorità"
          value={form?.priority ?? ""}
          onChange={update("priority")}
        />

        <AppSelect
          label="Assegnato"
          placeholder="Assegna a"
          value={form?.assigned ?? ""}
          onSelect={update("assigned")}
          className="app-select"
        >
          {team &&
            team?.map((user, index) => (
              <Option key={user.id} id={index}>
                {user.name}
              </Option>
            ))}
        </AppSelect>
        <Divider />

        {defaultValues?.id ? (
          <div className="title">
            {comments?.length > 0 && <CommentList comments={comments} />}
            <Comment
              author={user.user}
              content={
                <Editor
                  onChange={handleChange}
                  onSubmit={handleSubmit}
                  submitting={submitting}
                  value={commentValue}
                />
              }
            />
          </div>
        ) : (
          <span />
        )}
      </form>
    </RightSheet>
  );
}
// ----------------------------------------------------------------------------

const StyledTaskRightSheet = styled(TaskRightSheet)`
  & {
    .products {
      margin-top: 15px;
    }
    .components {
      margin-top: 20px;
      padding-bottom: 5px;
      font-weight: 400;
      font-size: 12px;
      border-bottom: 1px solid ${({ theme }) => theme.colors.grey200};
    }
  }
`;
export default StyledTaskRightSheet;
