import React from "react"
import styled from "styled-components"

//------------------------------------------------------------------------------

function _ShadowedCard(props) {
	// -------------------------------------
	// Props destructuring
	// -------------------------------------

	const { className, children, refer} = props

	return <div className={className} ref={refer && refer}>{children}</div>
}

const ShadowedCard = styled(_ShadowedCard)`
	& {
		border-radius: 16px;
		box-shadow: 0px 8px 16px rgba(143, 149, 178, 0.15);
		background-color: ${({ theme }) => theme.colors.light};
	}
`
export default ShadowedCard
