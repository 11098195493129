import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { message, Select, Tooltip } from "antd";
import { useSelector } from "react-redux";

import * as api from "../../../../../api";

import useForm from "../../../../_shared/hooks/useForm";
import { ReactComponent as MilestoneIcon } from "../../../../_shared/assets/icons/milestone.svg";
import { ReactComponent as Edit } from "../../../../_shared/assets/icons/edit.svg";
import Task from "../../Task";
import { Link, Routes, Route, useNavigate } from "react-router-dom";
import { Button, Modal } from "antd";

// ----------------------------------------------------------------------------

function MilestoneCard(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { className, milestone, onDelete, setSelectedTaskList } = props;

  // -------------------------------------
  // Hooks (e.g. useState, ...)
  // -------------------------------------
  const user = useSelector((state) => (state?.auth.user ? state.auth : null));

  const [form, , setMany, clearForm] = useForm();
  const [selectedTask, setSelectedTask] = useState();

  const [visibile, setVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [idToBeDeleted, setIdToBeDeleted] = useState();

  // -------------------------------------
  // Memoized values
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------
  useEffect(() => {
    setMany(milestone);
  }, [milestone]);

  useEffect(() => {
    selectedTask && setMany(selectedTask);
    // eslint-disable-next-line
  }, [selectedTask]);

  useEffect(() => {
    console.log();
    if (selectedTask && JSON.stringify(form) === "{}") {
      setMany(selectedTask);
    }
  }, [form]);

  function handleNewTask() {
    setSelectedTask({});
  }

  async function handleDeleteTask(id) {
    const newTasks = form?.tasks?.filter((task) => task.id !== id);

    setMany({ ...form, tasks: newTasks });

    await api.deleteTask(id, user.token);
  }

  async function onConfirm(values) {
    try {
      !values?.taskList
        ? (values.taskList = milestone.id)
        : (values.taskList = values.taskList.id);

      const body = {
        ...values,
      };

      let response = {};
      if (values?.id) {
        response = await api.updateTask(values.id, body, user.token);
      } else {
        response = await api.createTask(body, user.token);

        form.tasks.push(response);
      }
      setSelectedTask(null);

      message.success("Operazione eseguita con successo");
    } catch (error) {
      console.log("[Error] in confirm taskcard: ", error);

      message.error("Errore durante l'operazione");
    }
  }
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  // -------------------------------------

  return (
    <div className={className}>
      <div className="milestoneList" onClick={() => setVisible(!visibile)}>
        <button
          className="hideButton"
          style={{
            fontWeight: visibile === true ? "bold" : "normal",
            fontSize: "20px",
          }}
        >
          <MilestoneIcon className="icon"></MilestoneIcon>
          {visibile === true ? "^  " : ">  "}
          {`${milestone?.name} `}
          <div
            style={{
              fontStyle: "italic",
              fontSize: "15px",
            }}
          >{`[${form?.tasks?.length} tasks]`}</div>
        </button>
      </div>
    </div>
  );
}

// ----------------------------------------------------------------------------

const StyledMilestoneCard = styled(MilestoneCard)`
  & {
    margin-bottom: 20px;
    .icon {
      height: 35px;
      width: 35px;
    }

    .task {
      border-radius: 12px;
      padding: 10px;
      border-style: solid;
      border-width: 1px;
      border-color: orange;

      :hover {
        cursor: pointer;
        background-color: ${({ theme }) => theme.colors.neutral100};
      }
    }

    .milestoneList {
      background-color: rgba(240, 240, 240, 0.25);
      padding: 10px 5px;
      //height: 250px;
      border-radius: 20px;
      box-shadow: 1px 1px #ffa500;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 45vh;
      transition: 0.5s;
      border: 1px;

      :hover {
        // transform: rotateY(10deg);
        box-shadow: 3px 3px #ffa500;
        background-color: rgba(240, 240, 240, 0.8);
        color: black;
      }
    }
    .form {
      min-width: 280px;
    }

    .hideButton {
      border: none;
      background: none;
      margin-left: 5px;
      margin-top: 5px;
    }
  }
`;
// ----------------------------------------------------------------------------

export default StyledMilestoneCard;
