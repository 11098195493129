import { ReactComponent as HighPriority } from "../assets/icons/highPriority.svg";

import { ReactComponent as MediumPriority } from "../assets/icons/mediumPriority.svg";

import { ReactComponent as LowPriority } from "../assets/icons/lowPriority.svg";

const projectIcons = {
  high: <HighPriority style={{ width: "10px", height: "10px" }} />,

  medium: <MediumPriority style={{ width: "10px", height: "10px" }} />,

  low: <LowPriority style={{ width: "15px", height: "12px" }} />,
};

export default projectIcons;
