import React, { useState, useRef, useEffect } from "react"
import styled from "styled-components"
import { message, Select, Tabs } from "antd"
import { useSelector } from "react-redux"

import * as api from "../../../../api"

import usePaginatedRestResource from "../../../_shared/hooks/usePaginatedRestResource"
// import useForm from "../../../../../_shared/hooks/useForm";
import useForm from "../../../_shared/hooks/useForm"

import { ReactComponent as ArrowBack } from "../../../_shared/assets/icons/arrowback.svg"
import { Link, Routes, Route, useNavigate } from "react-router-dom"

import AppButton from "../../../_shared/components/AppButton"
import AppInput from "../../../_shared/components/AppInput"
import AppDatePicker from "../../../_shared/components/AppDatePicker"
import AppSelect from "../../../_shared/components/AppSelect"
import priorityValues from "../../../_shared/constants/priority"

import { ReactComponent as ModifyIcon } from "../../../_shared/assets/icons/modify.svg"
import FilesUploadComponent from "../../../_shared/components/FilesUpload"

import AppText from "../../../_shared/components/AppText"

import { useParams } from "react-router-dom"

import projectStatus from "../../../_shared/constants/projectStatus"
// ----------------------------------------------------------------------------

function Task(props) {
	// -------------------------------------
	// Props destructuring
	// -------------------------------------

	const { className } = props
	const { TabPane } = Tabs

	const { Option } = Select
	const params = useParams()

	// -------------------------------------
	// Hooks (e.g. useState, ...)
	// -------------------------------------
	const user = useSelector((state) => (state?.auth.user ? state.auth : null))

	const [form, , setMany, clearForm] = useForm()
	const navigate = useNavigate()

	const [selectedTask, setSelectedTask] = useState()
	const [change, setChange] = useState()

	useEffect(() => {
		getTask()
	}, [params.taskId])

	async function getTask() {
		try {
			const response = await api.getTask(params.taskId, user.token)
			setSelectedTask(response)
		} catch (error) {}
	}

	function update(field) {
		return (value) => {
			setMany({
				...form,
				[field]: value,
			})
		}
	}

	useEffect(() => {
		selectedTask && setMany(selectedTask)
		// eslint-disable-next-line
	}, [selectedTask])

	useEffect(() => {
		if (selectedTask === "{}") setMany(selectedTask)
	}, [form])

	async function onConfirm(values) {
		try {
			const body = {
				...form,
			}

			let response = {}
			if (form?.id) {
				response = await api.updateTask(form.id, body, user.token)
			} else {
				response = await api.createTask(body, user.token)
			}
			setSelectedTask(response)
			message.success("Operazione eseguita con successo")
			setChange()
		} catch (error) {
			console.log("[Error] in confirm taskcard: ", error)

			message.error("Errore durante l'operazione")
		}
	}

	function renderValuesChangable() {
		return (
			<div>
				<form className="form">
					<AppInput
						label="Nome"
						placeholder="Nome"
						value={form?.name ?? ""}
						onChange={update("name")}
					/>
					<AppInput
						label="Descrizione"
						placeholder="Descrizione"
						value={form?.description ?? ""}
						onChange={update("description")}
					/>
					<AppSelect
						label="Stato"
						placeholder="Stato"
						value={priorityValues[form?.status] ?? ""}
						onSelect={update("status")}
					>
						{projectStatus &&
							Object.keys(projectStatus).map((value, index) => (
								<Option key={value} id={index}>
									{projectStatus[value]}
								</Option>
							))}
					</AppSelect>

					<AppDatePicker
						label="Data di consegna"
						value={form?.dueDate ?? ""}
						onChange={update("dueDate")}
					/>

					<AppSelect
						label="Priorità"
						placeholder="Priorità"
						value={priorityValues[form?.priority] ?? ""}
						onSelect={update("priority")}
					>
						{priorityValues &&
							Object.keys(priorityValues).map((value, index) => (
								<Option key={value} id={index}>
									{priorityValues[value]}
								</Option>
							))}
					</AppSelect>
				</form>
				<div className="buttons">
					<AppButton uxType="" className="actionBtn" onClick={onConfirm}>
						Salva
					</AppButton>
				</div>
			</div>
		)
	}
	function renderValues() {
		return (
			<div className="informationForm">
				<div className="info">
					<p className="tab-title">Informazioni generali</p>
					<AppText field={"Nome"} value={form?.name ?? ""} />
					<AppText field={"Descrizione"} value={form?.description ?? ""} />
					<AppText field={"Stato"} value={projectStatus[form?.status] ?? ""} />
					<AppText field={"Data di consegna"} value={form?.dueDate ?? ""} />
					<AppText
						field={"Priorità"}
						value={priorityValues[form?.priority] ?? ""}
					/>
				</div>
				<div>
					<ModifyIcon
						className="modifyIcon"
						onClick={() => setChange(true)}
					></ModifyIcon>
				</div>
			</div>
		)
	}

	return (
		<div className={className}>
			<div className="leftSide">
				<div className="title">
					<div className="header">
						<ArrowBack
							className="icon"
							onClick={() => {
								navigate(`/project/${selectedTask?.taskList?.project}`, {
									state: { tab: "2" },
								})
							}}
						></ArrowBack>
						<p className="projectName">{selectedTask?.name ?? ""}</p>
					</div>
				</div>
			</div>
			{change ? renderValuesChangable() : renderValues()}
		</div>
	)

	//
}

// ----------------------------------------------------------------------------

const StyledTask = styled(Task)`
	& {
		width: inherit;
		min-width: 240px;

		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: space-between;
		.leftSide {
			width: 100%;
			padding: 10px 10px;
			padding-bottom: 0px;
		}
		.icon {
			height: 25px;
			width: 25px;
			transition: 0.5s;
			margin-right: 10px;
			:hover {
				transform: rotate(90deg);
			}
		}
		.tab-title {
			font-size: 18px;
		}
		.title {
			padding: 10px 10px;
			height: inherit;
			display: flex;
			flex-direction: column;
			width: 100%;

			.projectName {
				font-size: 25px;
			}
		}
		.information {
			width: 100%;
			padding-top: 10px;
			padding-left: 10px;
			height: 95%;
			overflow: scroll;

			.tab-title {
				font-size: 18px;
			}
			.input-container {
				width: 40%;
				min-width: 240px;
			}
			.task-card {
				width: 60%;
			}
			.app-select {
				width: 100%;
			}
			.header-div {
				width: 100%;
				display: flex;
				flex-direction: row;
				justify-content: space-between;
			}
		}
		.info {
			width: 80vh;
		}
		.header {
			display: flex;
			flex-direction: row;
		}
		.actionFooter {
			height: 50px;
			width: 100%;
			padding: 20px;
			box-sizing: content-box;
			background-color: ${({ theme }) => theme.colors.light};
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-end;
			border-radius: 16px;
			margin-left: -20px;
			z-index: 100;
			.actionBtn {
				margin-right: 20px;
			}
		}
		.addField {
			display: flex;
			flex-direction: row-reverse;
			justify-content: flex-start;
			margin: auto;
			margin-top: 10px;
			padding: 5px;
			border: none;
			.icon {
				margin: auto;
				margin-right: 10px;
				height: 14px;
				width: 30px;
			}
			.center {
				min-width: 0px;
			}
		}
	}
	.center {
		width: 50%;
		min-width: 400px;
		height: inherit;
	}
	.rightSide {
		min-width: 200px;
		width: 25%;
		height: inherit;
		border-left: 1px solid ${({ theme }) => theme.colors.grey200};
	}
	.form {
		min-width: 280px;

		padding: 20px;
	}
	.buttons {
		min-width: 280px;
		padding: 10px;
		display: flex;
		justify-content: flex-end;
	}
	.modifyIcon {
		height: 25px;
		width: 25px;
		justify-content: flex-end;
	}
	.informationForm {
		display: flex;
		flex-direction: row;
		padding: 20px;
	}
`
// ----------------------------------------------------------------------------

export default StyledTask
