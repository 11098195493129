import React from "react"
import styled from "styled-components"
import { message } from "antd"
import { useState } from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"

import ContactRightSheet from "./ContactRightSheet"

import Avatar from "../../../_shared/components/Avatar"
import Table from "../../../_shared/components/Table"
import { ReactComponent as Delete } from "../../../_shared/assets/icons/delete.svg"
import PopConfirm from "../../../_shared/components/PopConfirm"
import usePaginatedRestResource from "../../../_shared/hooks/usePaginatedRestResource"

import * as api from "../../../../api"

// ----------------------------------------------------------------------------

function Contacts(props) {
	// -------------------------------------
	// Props destructuring
	// -------------------------------------

	const { className } = props
	// -------------------------------------
	// Hooks (e.g. useState, ...)
	// -------------------------------------

	const user = useSelector((state) => (state?.auth.user ? state.auth : null))
	const [searched, setSearched] = useState("")

	const [
		contacts,
		,
		{ pagination, count, filters: currentFilters },
		{
			fetchResource: fetchContacts,
			onChange: handleTableChange,
			onFiltersChange,
		},
	] = usePaginatedRestResource("/contacts", user.token, {
		defaultFilters: {
			organization: user.organization,
		},
	})

	const [selectedContact, setSelectedContact] = useState()

	// -------------------------------------
	// Memoized values
	// -------------------------------------

	function getColumns() {
		return [
			{
				title: "NOME",
				dataIndex: "fullName",
				key: "fullName",
				sorter: (a, b) => a.fullName.localeCompare(b.fullName),
				render: (text, record, index) => {
					const names = text.split(" ")
					return (
						<div className="row">
							<Avatar
								name={names[0].charAt(0) + (names[1] ? names[1].charAt(0) : "")}
							/>
							<span className="contactLink">
								<Link to={`${record.id}`}>{text}</Link>
							</span>
						</div>
					)
				},
			},
			{
				title: "AZIENDA",
				dataIndex: "company",
				key: "company",
				render: (text) => (
					<div className="row">
						<Avatar name={text ? text.businessName.charAt(0) : ""} />
						<span>{text ? text.businessName : ""}</span>
					</div>
				),
			},
			{
				title: "E-MAIL",
				dataIndex: "email",
				key: "email",
			},
			{
				title: "N. DI TELEFONO",
				dataIndex: "phone",
				key: "phone",
			},
			
			{
				title: "CONTATTO",
				dataIndex: "owner",
				key: "owner",
				render: (owner) => (
					<div className="row">
						<Avatar
							name={owner ? owner.name.charAt(0) + owner.surname.charAt(0) : ""}
						/>
						<span>{owner && owner.email}</span>
					</div>
				),
			},
			{
				title: "",
				dataIndex: "delete",
				key: "delete",
				render: (text, record, index) => (
					<PopConfirm
						className={className}
						title="Sei sicuro di voler rimuovere questo contatto?"
						onConfirm={(e) => deleteContact(e, record.id)}
						onCancel={(e) => e.stopPropagation()}
						okText="Sì"
						cancelText="No"
						placement="leftTop"
					>
						<div className="delete" onClick={(e) => e.stopPropagation()}>
							<Delete />
						</div>
					</PopConfirm>
				),
			},
		]
	}
	// -------------------------------------
	// Effects
	// -------------------------------------

	// -------------------------------------
	// Component functions
	// -------------------------------------

	function handleSearch() {
		onFiltersChange({
			name: searched,
			email: searched,
		})
	}
	function handleChangeSearch(value) {
		if (value.length === 0 && searched.length > value.length) {
			onFiltersChange({
				name: value,
				email: value,
			})
		} else {
			setSearched(value)
		}
	}

	function getTableFilters() {
		return [
			{
				key: "email",
				type: "text",
				value: currentFilters.email,
				onSearch: (value) =>
					onFiltersChange({
						email: value,
					}),
			},
		]
	}

	function handleNewContact() {
		setSelectedContact({})
	}

	async function deleteContact(e, contactId) {
		e.stopPropagation()
		try {
			await api.deleteContact(contactId, user.token)

			fetchContacts()
		} catch (error) {
			console.log("[ERROR] in delete contact: ")
			console.log(error)
		}
	}

	function onCancel() {
		setSelectedContact(null)
	}

	async function handleSearchUsers(value) {
		try {
			const response = await api.searchUsers(
				value,
				user.organization,
				user.token
			)
			if (response) {
				return response.rows?.map((row) => {
					return { label: row.fullName, id: row.id }
				})
			}
			return null
		} catch (error) {
			console.log("[ERROR] in search users: ")
			console.log(error)
		}
	}

	async function onConfirm(values) {
		try {
			!values?.organization
				? (values.organization = user.organization)
				: (values.organization = values.organization.id)

			if (values?.company) values.company = values.company.id

			values?.id
				? await api.updateContact(values.id, values, user.token)
				: await api.createContact(values, user.token)

			fetchContacts()
			message.success("Operazione eseguita con successo")
			setSelectedContact(null)
		} catch (error) {
			console.log("[ERROR] in confirm contact: ")
			console.log(error)
			message.error("Errore durante l'operazione")
		}
	}

	return (
		<Table
			className={className}
			columns={getColumns()}
			onResourceCreate={() => selectedContact({})}
			dataSource={contacts}
			onChange={handleTableChange}
			rowSelection={{ type: "checkbox" }}
			addContact="true"
			handleOnRow={(row) => setSelectedContact(row)}
			renderTopBarCustomButtons={() => (
				<ContactRightSheet
					open={!!selectedContact}
					defaultValues={selectedContact}
					onConfirm={onConfirm}
					onCancel={onCancel}
					onButtonClick={handleNewContact}
					handleSearch={handleSearchUsers}
				/>
			)}
			filters={getTableFilters()}
			search
			handleSearchSubmit={handleSearch}
			handleOnChangeSearch={handleChangeSearch}
		/>
	)
}

// ----------------------------------------------------------------------------

const StyledContacts = styled(Contacts)`
	& {
		.row {
			display: flex;
			flex-direction: row;
			align-items: center;

			span {
				margin-left: 8px;
			}
		}
		.app-select {
			min-width: 280px;
		}
		.contactLink {
			text-decoration: underline;
		}
		.form {
			min-width: 280px;
		}
		.add-field {
			display: flex;
			flex-direction: row-reverse;
			justify-content: center;
			margin: auto;
			margin-top: 20px;
			padding: 5px;
			border: none;
			.icon {
				margin: auto;
				margin-right: 10px;
				height: 14px;
			}
		}
	}
`
export default StyledContacts
