import React, { useState } from "react"
import styled from "styled-components"
import { Link } from "react-router-dom"
import { message } from "antd"

import Logo from "../../../_shared/components/LoginItems/Logo"
import LogImage from "../../../_shared/components/LoginItems/LogImage"
import AppInput from "../../../_shared/components/AppInput"
import AppButton from "../../../_shared/components/AppButton"
import Heading from "../../../_shared/components/LoginItems/Heading"
import SubHeading from "../../../_shared/components/LoginItems/SubHeading"
import Row from "../../../_shared/components/LoginItems/Row"
import LogForm from "../../../_shared/components/LoginItems/LogForm"
import FormDiv from "../../../_shared/components/LoginItems/FormDiv"
import * as api from "../../../../api"

// ----------------------------------------------------------------------------

function RecoverPassword(props){

    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const [email, setEmail] = useState("")
    // -------------------------------------
    // Component functions
    // -------------------------------------

    async function handleSubmit(e) {
        e.preventDefault()
        message.info("We sent an email to: " + api.recoverPsw(email))
    }

    return (
        <div className={`${className}`}>
            <FormDiv>
                <Logo/>
                <LogForm onSubmit={handleSubmit}>
                    <Heading>Recupera password</Heading>
                    <SubHeading>Inserisci la tua email. Riceverai una email presso questo indirizzo</SubHeading>
                    <AppInput
                        className="input-first"
                        value={email}
                        onChange={setEmail}
                        label="Email"
                        placeholder="Inserisci la tua email"
                        required
                    ></AppInput>
                    <AppButton uxType="" htmlType="submit">
                        INVIA LINK
                    </AppButton>
                    <div className="outer">
                       <p>Non hai un profilo?</p>
                        <Link to="sign-up" className="link">
                            <AppButton uxType="secondary" htmlType="" className="btnn">
                                CREA UN ACCOUNT
                            </AppButton>
                        </Link>
                    </div>
                </LogForm>
            </FormDiv>
            <LogImage page="recover"/>        
        </div> 
    )
}

// ----------------------------------------------------------------------------

const StyledRecoverPassword = styled(RecoverPassword) `
    & {
        width: 100vw;
        height: 100vh;
        display:flex;
        flex-direction:row;
                    
        Form .input-first {
            margin-top: ${({ theme }) => theme.spacing.m}px;
            margin-bottom: ${({ theme }) => theme.spacing.m}px;
        }
    }
`
export default StyledRecoverPassword
