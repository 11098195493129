import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import GiarvisLogo from "../../../assets/giarvis_logo.jpeg";

import { ReactComponent as GiarvisPrimaryLogo } from "../../../assets/giarvis_logo_primary.svg";

function _Logo(props) {
  const { className } = props;

  return (
    <div className={`${className}`}>
      {/* <img src={GiarvisLogo}></img> */}
      <GiarvisPrimaryLogo />
    </div>
  );
}
_Logo.propTypes = {
  className: PropTypes.string.isRequired,
};

_Logo.defaultProps = {};

const Logo = styled(_Logo)`
  & {
    background-color: white;
    width: 35vw;
    max-width: 430px;
    margin-top: 51px;
    img {
      object-fit: contain;
      width: 225px;
      height: 58px;
    }
  }
`;
export default Logo;
