import RoutedApp from "./RoutedApp";
import ThemeProvider from "./ThemeProvider";
import { IntlProvider } from "react-intl";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import ReduxProvider from "./ReduxProvider";

// ----------------------------------------------------------------------------

function App(props) {
  return (
    <ReduxProvider>
      <ThemeProvider>
        <IntlProvider locale="en">
          <Router className={props.className}>
            <Routes>
              <Route path="*" element={<RoutedApp />}></Route>
            </Routes>
          </Router>
        </IntlProvider>
      </ThemeProvider>
    </ReduxProvider>
  );
}

export default App;
