import React, { useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useIntl } from "react-intl"

import closeIcon from "../../assets/icons/close-icn.svg"
import AppButton from "../AppButton"

// ----------------------------------------------------------------------------

const KEYBOARD_ESC_KEY_CODE = 27

// ----------------------------------------------------------------------------

function _RightSheet(props) {
	// -------------------------------------
	// Props destructuring
	// -------------------------------------

	const {
		open,
		children,
		onCancel,
		onConfirm,
		title,
		renderExtraButtons,
		confirmButtonLabel,
		cancelButtonLabel,
		loading,
	} = props

	// -------------------------------------
	// Hooks (e.g. useState, useMemo ...)
	// -------------------------------------

	const { formatMessage } = useIntl()

	// -------------------------------------
	// Effects
	// -------------------------------------

	useEffect(() => {
		startESCListener()

		return function cleanup() {
			stopESCListener()
		}

		// eslint-disable-next-line
	}, [])

	// -------------------------------------
	// Component functions
	// -------------------------------------

	function handleKeyUpEvent(event) {
		if (KEYBOARD_ESC_KEY_CODE === event.keyCode) {
			onCancel && onCancel()
		}
	}

	function startESCListener() {
		window.addEventListener("keyup", handleKeyUpEvent)
	}
	function stopESCListener() {
		window.removeEventListener("keyup", handleKeyUpEvent)
	}

	// -------------------------------------
	// Component local variables
	// -------------------------------------

	if (open) {
		document.body.style.overflow = "hidden"
	} else {
		document.body.style.overflow = ""
	}

	return (
		<div className={`${props.className}${open ? " open" : ""}`}>
			<div className="mask" />

			<div className="sheet">
				<div className="sheetHeader">
					<div className="title">{(title && title) || ""}</div>
					<AppButton uxType="delete"  onClick={onCancel}>
						<img src={closeIcon} alt=""/>
					</AppButton>
				</div>

				{open && <div className="sheet-content">{children}</div>}

				{(onConfirm || onCancel) && (
					<div className="footer">
						{renderExtraButtons && (
							<div className="extra-btns">{renderExtraButtons()}</div>
						)}

						{onCancel && (
							<AppButton
								onClick={onCancel}
								uxType="tertiary"
								className="delete-btn"
							>
								{cancelButtonLabel ||
									formatMessage({
										id: "userInterface.shared.cancel",
									})}
							</AppButton>
						)}
						{onConfirm && (
							<AppButton
								loading={loading}
								className="confirm-btn"
								onClick={onConfirm}
							>
								{confirmButtonLabel ||
									formatMessage({
										id: "userInterface.shared.save",
									})}
							</AppButton>
						)}
					</div>
				)}
			</div>
		</div>
	)
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_RightSheet.propTypes = {
	className: PropTypes.string.isRequired,
	open: PropTypes.bool,
	children: PropTypes.any,
	onConfirm: PropTypes.func,
	onCancel: PropTypes.func,
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	snapPoint: PropTypes.oneOf([40, 50, 80, 100]),
	renderExtraButtons: PropTypes.func,
	loading: PropTypes.bool,
	confirmButtonLabel: PropTypes.string,
	cancelButtonLabel: PropTypes.string,
}

_RightSheet.defaultProps = {
	open: false,
	snapPoint: 40,
	renderExtraButtons: () => {},
}

// ----------------------------------------------------------------------------

const RightSheet = styled(_RightSheet)`
	& {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;

		transform: translateX(100%);
		transition: transform 0.3s ease-in-out;
		overflow-x: hidden;

		z-index: 998;

		::-webkit-scrollbar {
			display: none;
		}

		.sheet {
			position: fixed;
			top: 0;
			right: 0;

			display: flex;
			flex-direction: column;
			justify-content: space-between;
			box-sizing: border-box;

			width: 40vw;
			min-width: 350px;
			height: 100vh;

			background-color: ${({ theme }) => theme.colors.light};
			z-index: 999;

			overflow: auto;

			::-webkit-scrollbar {
				display: none;
			}

			.sheetHeader {
				margin-right: 10px;
				height: 40px;
				min-width: 350px;
				width: inherit;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
				/* border-bottom: 1px solid ${({ theme }) => theme.colors.grey200}; */

				.title {
					width: fit-content;
					font-size: 18px;
					font-weight: 600;
					color: ${(props) => props.theme.colors.textPrimary};
					margin-left: 20px;
				}

				.close-icn {
					padding-right: 1px;
					border: none;
					width: 14px;
					height: 14px;
					background-image: url(${closeIcon});
					background-size: 14px 14px;
					background-repeat: no-repeat;
					background-origin: border-box;
				}
			}

			.sheet-content {
				display: flex;
				flex-direction: column;
				align-items: center;
				height: calc(100% - 100px);
				overflow: auto;
				min-width: 350px;
			}

			.footer {
				height: 60px;
				border-top: 1px solid ${({ theme }) => theme.colors.light};
				/* background-color: ${({ theme }) => theme.colors.grey}; */
				display: flex;
				flex-direction: row;
				justify-content: flex-end;
				align-items: center;
				padding-bottom: 20px;
				padding-right: 2.5vw;
				margin-top: 20px;
				.extra-btns {
					margin-right: 10px;
				}

				.confirm-btn {
					margin-left: 10px;
					width: 120px;
					padding: 0;
				}
				.delete-btn {
					padding: 0 20px;
				}
			}
		}

		.mask {
			position: fixed;
			opacity: 0;
			transition: opacity 0.1s ease 0.3s;
			z-index: 998;
		}
	}

	&.open {
		transform: translateX(0);

		.mask {
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			transform: scale(1);
			opacity: 1;
			background-color: rgba(0, 0, 0, 0.5);
			backdrop-filter: blur(5px);
		}

		/* MOBILE */

		/* @media (max-width: ${(props) => props.theme.screenSizes.mobile}px) {
            .drawer {
                min-width: 100vw;
                width: 100vw;
                max-width: 100vw;
                height: 100vh;
            }
        } */
	}
`
// ----------------------------------------------------------------------------

export default RightSheet
