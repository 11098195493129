import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { ReactComponent as Arrow } from "../../assets/icons/arrow.svg";

import { LoadingOutlined } from "@ant-design/icons";

//------------------------------------------------------------------------------

function _AppButton(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { children, onClick, htmlType, uxType, loading, icon, disabled } =
        props;
    // -------------------------------------
    // Hooks (e.g. useState, useMemo ...)
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function getThemeClassName() {
        switch (uxType) {
            case "primary": {
                return "primary";
            }

            case "secondary": {
                return "secondary";
            }

            case "link": {
                return "link";
            }

            case "delete": {
                return "delete";
            }

            default: {
                return "";
            }
        }
    }

    function isDisabled() {
        return !!disabled ? "disabled" : "";
    }
    // -------------------------------------
    // Component local variables
    // -------------------------------------

    if (uxType === "tertiary") {
        return (
            <button
                className={props.className + " tertiary " + isDisabled()}
                type={htmlType}
                onClick={!loading && !disabled && onClick}
            >
                {loading && <LoadingOutlined className="loader" />}
                <p>{children}</p>
                {/* <Arrow /> */}
            </button>
        );
    }
    return (
        <button
            className={`${
                props.className
            } ${getThemeClassName()} ${isDisabled()}`}
            type={htmlType}
            onClick={!loading && !disabled && onClick}
        >
            {loading && <LoadingOutlined className="loader" />}
            {children}
            {icon && <div className="icon center">{icon}</div>}
        </button>
    );
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_AppButton.propTypes = {
    className: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func,
    type: PropTypes.string,
    uxType: PropTypes.string,
    htmlType: PropTypes.string,
    loading: PropTypes.bool,
    icon: PropTypes.element,
    style: PropTypes.any,
};

_AppButton.defaultProps = {
    onClick: () => {},
    htmlType: "submit",
    style: "default",
};

// ----------------------------------------------------------------------------

const AppButton = styled(_AppButton)`
    & {
        position: relative;

        padding: 12px 24px;
        max-width: 474px;
        width: auto;
        height: ${({ theme }) => theme.spacing.button}px;
        border-radius: 8px;
        background: ${(props) => props.theme.colors.orange800};

        border: none;

        font-weight: 600;
        font-size: 14px;
        line-height: 12px;
        letter-spacing: 0.04em;
        color: ${(props) => props.theme.colors.light} !important;

        appearance: none;
        cursor: pointer;
        outline: none;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        * > {
            z-index: 3;
        }

        .loader {
            margin-right: 5px;
        }

        .icon {
            margin-left: 15px;
        }

        :hover {
            background: ${(props) => props.theme.colors.orangeHover};
        }

        .disabled {
            opacity: 0.3;
        }
    }

    &.primary {
        background-color: ${(props) => props.theme.colors.neutral800};
        color: ${(props) => props.theme.colors.light} !important;
        max-width: unset;
        :hover {
            background: "#302A29";
        }
    }

    &.secondary {
        border: 1px solid ${(props) => props.theme.colors.neutral800};
        background-color: ${(props) => props.theme.colors.light};
        color: ${(props) => props.theme.colors.neutral800} !important;
        max-width: unset;
        :hover {
            background: rgba(48, 42, 41, 0.2);
        }
    }

    &.tertiary {
        border: 1px solid black !important;
        background-color: transparent;
        color: ${(props) => props.theme.colors.grey100} !important;
        border: none;
        font-weight: 700;

        p {
            margin: 0;
           // margin-right: 15px;
            padding: 5px 0px; 
            //border-bottom: 1px solid ${(props) => props.theme.colors.neutral800};
        }

        :hover {
            background-color: transparent;
            background: transparent;
        }

        :hover {
            ::before {
                background-color: ${(props) =>
                    props.theme.colors.hoverTransparency};
            }
        }
    }

    &.success {
        border: 1px solid ${(props) => props.theme.colors.successColor};
        background-color: transparent;
        color: ${(props) => props.theme.colors.successColor} !important;

        :hover {
        }
    }

    &.link {
        /* background-color: transparent; */
        color: transparent;
        color: ${(props) => props.theme.colors.accent01_900} !important;
        font-weight: 400;

        .icon,
        .anticon,
        svg {
            color: ${(props) => props.theme.colors.accent01_900} !important;
        }

        :hover {
            background-color: transparent;
            color: transparent;
            background: transparent;

            color: ${(props) => props.theme.colors.accent01_800} !important;
        }
    }

    &.warning {
        border: 1px solid ${(props) => props.theme.colors.warningDarkColor};
        background-color: transparent;
        color: ${(props) => props.theme.colors.warningDarkColor} !important;

        :hover {
            border-color: ${(props) => props.theme.colors.warningLightColor};
            color: ${(props) =>
                props.theme.colors.warningLightColor} !important;
        }
    }

    &.delete {
        border: 2px solid ${(props) => props.theme.colors.error};
        background-color: transparent;
        color: ${(props) => props.theme.colors.error} !important;
    }
`;
// ----------------------------------------------------------------------------

export default AppButton;
