import React from "react";
import styled from "styled-components";
import { useState } from "react";
import { useSelector } from "react-redux";
import { message, Tag } from "antd";
import { Link } from "react-router-dom";

import { BASE_URL } from "../../../../api";

import { ReactComponent as Download } from "../../../_shared/assets/icons/download.svg";

import Avatar from "../../../_shared/components/Avatar";
import Table from "../../../_shared/components/Table";
import { ReactComponent as Delete } from "../../../_shared/assets/icons/delete.svg";
import PopConfirm from "../../../_shared/components/PopConfirm";
import usePaginatedRestResource from "../../../_shared/hooks/usePaginatedRestResource";

import * as api from "../../../../api";

import DeliveryRightSheet from "./DeliveryRightSheet";

// ----------------------------------------------------------------------------

function DeliveryNotes(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props;

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const user = useSelector((state) => (state?.auth.user ? state.auth : null));
    const [searched, setSearched] = useState("");

    const [
        deliveryNotes,
        ,
        { pagination, count, filters: currentFilters },
        {
            fetchResource: fetchDeliveryNotes,
            onChange: handleTableChange,
            onFiltersChange,
        },
    ] = usePaginatedRestResource("/delivery-notes", user.token, {
        defaultFilters: {
            organization: user.organization,
        },
    });

    const [selectedDeliveryNote, setSelectedDeliveryNote] = useState();

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    function getColumns() {
        return [
            {
                title: "CODICE",
                dataIndex: "code",
                key: "code",
                render: (text, record, index) => (
                    <span className="dealLink">
                        <Link to={`${record.id}`}>{text}</Link>
                    </span>
                ),
            },
            {
                title: "NOME OFFERTA",
                dataIndex: "name",
                key: "name",
                render: (text, record, index) => <span>{text}</span>,
            },
            {
                title: "STATO OFFERTA",
                dataIndex: "status",
                key: "status",
                render: (text) => (
                    <Tag
                        color={
                            text === "Inviata"
                                ? "blue"
                                : text === "Accettata"
                                ? "green"
                                : "red"
                        }
                    >
                        {text}
                    </Tag>
                ),
            },
            {
                title: "TITOLARE DELL' OFFERTA",
                dataIndex: "user",
                key: "user",
                render: (user) => {
                    const names = user?.fullName?.split(" ") ?? "";
                    return (
                        <div className="row">
                            <Avatar
                                name={
                                    names != ""
                                        ? names[0]?.charAt(0) +
                                          (names[1]?.charAt(0) ?? "")
                                        : ""
                                }
                            />
                            <span>{user?.fullName ?? ""}</span>
                        </div>
                    );
                },
            },
            {
                title: "DESTINATARIO",
                dataIndex: "recipient",
                key: "recipient",
                sorter: (a, b) =>
                    a.recipient.businessName.localeCompare(
                        b.recipient.businessName
                    ),
                render: (text) => (
                    <div className="row">
                        <Avatar
                            name={text ? text.businessName?.charAt(0) : ""}
                        />
                        <span>{text ? text.businessName : ""}</span>
                    </div>
                ),
            },
            {
                title: "TOTALE",
                dataIndex: "amount",
                key: "amount",
                sorter: (a, b) => a.amount - b.amount,
                render: (text) => `${text}€`,
            },
            {
                title: "",
                dataIndex: "",
                key: "row-actions",
                render: (text, record, index) => (
                    <div className="row-actions">
                        <a
                            href={`${BASE_URL}/deals/${record.id}/document`}
                            target="__blank"
                            className="delete"
                            onClick={(e) => e.stopPropagation()}
                        >
                            <Download />
                        </a>
                        <PopConfirm
                            className={className}
                            title="Sei sicuro di voler rimuovere questo ddt?"
                            onConfirm={(e) => deleteDeliveryNote(e, record.id)}
                            onCancel={(e) => e.stopPropagation()}
                            okText="Sì"
                            cancelText="No"
                            placement="leftTop"
                        >
                            <div
                                className="delete"
                                onClick={(e) => e.stopPropagation()}
                            >
                                <Delete />
                            </div>
                        </PopConfirm>
                    </div>
                ),
            },
        ];
    }

    // -------------------------------------
    // Component functions
    // -------------------------------------
    function handleSearch() {
        onFiltersChange({
            code: searched,
            name: searched,
        });
    }
    function handleChangeSearch(value) {
        if (value.length === 0 && searched.length > value.length) {
            onFiltersChange({
                code: value,
                name: value,
            });
        } else {
            setSearched(value);
        }
    }

    async function handleSearchRecipient(type, value) {
        try {
            let response;
            if (type === "Company") {
                response = await api.searchCompanies(
                    value,
                    user.organization,
                    user.token
                );
                if (response) {
                    return response.rows?.map((row) => {
                        return { label: row.businessName, id: row.id };
                    });
                }
            } else {
                response = await api.searchContacts(
                    value,
                    user.organization,
                    user.token
                );
                if (response) {
                    return response.rows?.map((row) => {
                        return { label: row.fullName, id: row.id };
                    });
                }
            }

            return null;
        } catch (error) {
            console.log("[ERROR] in search companies/contacts: ");
            console.log(error);
        }
    }

    function getTableFilters() {
        return [
            {
                key: "code",
                type: "text",
                value: currentFilters.code,
                onSearch: (value) =>
                    onFiltersChange({
                        code: value,
                    }),
            },
        ];
    }

    async function deleteDeliveryNote(e, deliveryId) {
        e.stopPropagation();
        try {
            await api.deleteDeliveryNote(deliveryId, user.token);
            fetchDeliveryNotes();
        } catch (error) {
            console.log("[ERROR] in delete ddt: ");
            console.log(error);
        }
    }

    function handleNewDdt() {
        setSelectedDeliveryNote({});
    }

    function onCancel() {
        setSelectedDeliveryNote(null);
    }

    async function onConfirm(values) {
        try {
            !values?.organization
                ? (values.organization = user.organization)
                : (values.organization = values.organization.id);

            if (!values?.owner) values.owner = user.user;

            if (values?.user) values.user = values.user.id;

            if (values?.recipient && typeof values?.recipient !== "string")
                values.recipient = values.recipient.id;

            values?.id
                ? await api.updateDeliveryNotes(values.id, values, user.token)
                : await api.createDeliveryNote(values, user.token);

            fetchDeliveryNotes();
            message.success("Operazione eseguita con successo");
            setSelectedDeliveryNote(null);
        } catch (error) {
            console.log("[ERROR] in confirm ddt: ");
            console.log(error);
            message.error("Errore durante l'operazione");
        }
    }

    async function handleGenerateInvoice(values) {
        try {
            const invoiceObj = {};
            invoiceObj.organization = user.organization;
            invoiceObj.recipientType = values.recipientType;
            invoiceObj.code = values.code;
            if (values?.recipient && typeof values?.recipient !== "string") {
                invoiceObj.recipient = values.recipient.id;
            } else {
                invoiceObj.recipient = values.recipient;
            }

            invoiceObj.taxable = values.amount;

            invoiceObj.rows = values.products?.map((product) => {
                return {
                    description: product?.description,
                    quantity: product?.quantity,
                    unitPrice: product.price,
                    discount: product.discount,
                    key: product?.key,
                };
            });

            await api.createInvoice(user.organization, invoiceObj, user.token);
            message.success("Operazione eseguita con successo");
            setSelectedDeliveryNote(null);
        } catch (error) {
            message.error("ATTENZIONE! Azione non completata, riprovare");
        }
    }

    // -------------------------------------

    return (
        <Table
            className={className}
            columns={getColumns()}
            onResourceCreate={() => selectedDeliveryNote({})}
            dataSource={deliveryNotes}
            onChange={handleTableChange}
            rowSelection={{ type: "checkbox" }}
            addContact="true"
            handleOnRow={(row) => setSelectedDeliveryNote(row)}
            renderTopBarCustomButtons={() => (
                <DeliveryRightSheet
                    open={!!selectedDeliveryNote}
                    defaultValues={selectedDeliveryNote}
                    onConfirm={onConfirm}
                    onCancel={onCancel}
                    onButtonClick={handleNewDdt}
                    generateInvoice={handleGenerateInvoice}
                    handleSearch={handleSearchRecipient}
                />
            )}
            filters={getTableFilters()}
            search
            handleSearchSubmit={handleSearch}
            handleOnChangeSearch={handleChangeSearch}
        />
    );
}

// ----------------------------------------------------------------------------

const StyledDeliveryNotes = styled(DeliveryNotes)`
    & {
        .dealLink {
            text-decoration: underline;
        }
        .row {
            display: flex;
            flex-direction: row;
            align-items: center;

            span {
                margin-left: 8px;
            }
        }
        .products {
            margin-top: 20px;
            font-weight: 300;
            font-size: 14px;
            line-height: 16px;
            color: #252631;
        }

        .total {
            margin-top: 10px;
            display: flex;
            flex-direction: row-reverse;
        }

        .addField {
            display: flex;
            flex-direction: row-reverse;
            justify-content: center;
            margin: auto;
            margin-top: 10px;
            padding: 5px;
            border: none;
            .icon {
                margin: auto;
                margin-right: 10px;
                height: 14px;
            }
        }
        .row-actions {
            display: flex;
            flex-direction: row;
            gap: 10px;
        }
    }
`;
export default StyledDeliveryNotes;
