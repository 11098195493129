import React from "react"
import styled from "styled-components"

function Confirmation (props) {

    const { className, refer } = props

    return (
        <div className={className} ref={refer}>
            <h1>Grazie per aver scelto Giarvis</h1>
            <p>Riceverai un'email di conferma all'indirizzo che hai inserito</p>
        </div>
    )

}

const StyledConfirmation = styled(Confirmation) `
    & {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.02em;
        color: #252631;
    }
`
export default StyledConfirmation