import PropTypes from "prop-types";
import React, { useImperativeHandle, useRef } from "react";
import styled from "styled-components";

import FieldLabel from "../FieldLabel";
import ValidationMessage from "../FormItems/ValidationMessage";

// ----------------------------------------------------------------------------

const _AppInput = React.forwardRef((props, ref) => {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const {
    label,
    className,
    onChange,
    onKeyUp,
    id,
    error,
    state,
    enable,
    ...inputProps
  } = props;

  // -------------------------------------
  // Hooks (e.g. useState, ...)
  // -------------------------------------

  const inputRef = useRef();

  useImperativeHandle(ref, () => ({
    focus: focusThis,
  }));

  // -------------------------------------
  // Memoized values
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  function focusThis() {
    if (!id) {
      throw new Error("Must have id to focus input");
    }
    const elem = document.getElementById(id);
    elem.focus();
  }

  function handleChange(e) {
    onChange && onChange(e.target.value);
  }

  function handleOnKeyUp() {
    onKeyUp && onKeyUp();
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------
  return (
    <div className={`${className}`}>
      {label && <FieldLabel>{label}</FieldLabel>}
      <input
        className={`app-input ${
          (!!state && (state === "wrong" ? "error" : ""),
          !!inputProps?.disabled && " disabled")
        }`}
        id={id}
        {...inputProps}
        ref={inputRef}
        onChange={handleChange}
        onKeyUp={handleOnKeyUp}
      ></input>
      {error && <ValidationMessage>{error}</ValidationMessage>}
    </div>
  );
});

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_AppInput.propTypes = {
  className: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  id: PropTypes.string,
  error: PropTypes.any,
  label: PropTypes.any,
  enable: PropTypes.bool,
};

_AppInput.defaultProps = {};
_AppInput.defaultProps = {};

// ----------------------------------------------------------------------------

const AppInput = styled(_AppInput)`
  & {
    max-width: unset;
    width: 100%;
    margin-top: ${({ theme }) => theme.spacing.s}px;

    .app-input {
      background-color: ${({ theme }) => theme.colors.neutral100};
      border: none;
      border-radius: 8px;
      box-sizing: border-box;
      display: block;
      width: 100%;
      color: ${({ theme }) => theme.colors.inputText};
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.02em;
      outline: none;
      transition: all 0.3s ease;

      /* background-color: transparent; */
      padding: 0px ${({ theme }) => theme.spacing.s}px;
      height: ${({ theme }) => theme.spacing.formItemHeight}px;

      :focus {
        outline: 0;
        border: 1px solid ${({ theme }) => theme.colors.inputBorderFocus};
        background-color: ${({ theme }) => theme.colors.light} !important;
        box-shadow: 1px 1px 2px rgba(237, 186, 141, 0.5);
        border-radius: 4px;
      }

      :hover {
        background-color: ${({ theme }) => theme.colors.neutral200};
      }
    }
    .error {
      border: 1px solid ${({ theme }) => theme.colors.error800};

      :focus {
        outline: 0;
        border-color: ${({ theme }) => theme.colors.error800};
        box-shadow: 0 0 0 4px ${({ theme }) => theme.colors.error200};
      }
    }

    .disabled {
      opacity: 0.4;
    }
  }
`;
// ----------------------------------------------------------------------------

export default AppInput;
