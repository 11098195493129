import { PlusOutlined } from "@ant-design/icons";
import { Col, InputNumber, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { ReactComponent as Plus } from "../../../../_shared/assets/icons/plus.svg";
import AppButton from "../../../../_shared/components/AppButton";
import AppInput from "../../../../_shared/components/AppInput";
import AppSelect from "../../../../_shared/components/AppSelect";
import RightSheet from "../../../../_shared/components/Drawer";
import FieldLabel from "../../../../_shared/components/FieldLabel";
import SelectAutoComplete from "../../../../_shared/components/SelectAutoComplete";
import useForm from "../../../../_shared/hooks/useForm";
import useRestResource from "../../../../_shared/hooks/useRestResource";
import ProductSelection from "../../Deals/ProductSelection";

function OrderRightSheet(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const {
    className,
    open,
    defaultValues,
    onConfirm,
    onCancel,
    onButtonClick,
    generateDDT,
    generateInvoice,
    handleSearch,
  } = props;
  const { Option } = Select;

  // -------------------------------------
  // Hooks (e.g. useState, ...)
  // -------------------------------------

  const [form, , setMany, clearForm] = useForm();
  const user = useSelector((state) => (state?.auth.user ? state.auth : null));
  const [agent, setAgent] = useState(null);
  const [recipientType, setRecipientType] = useState("");

  const [customFields] = useRestResource("/custom-fields", "", user.token, {
    organization: user.organization,
  });
  const [companies] = useRestResource("/companies", "", user.token, {
    organization: user.organization,
  });
  const [contacts] = useRestResource("/contacts", "", user.token, {
    organization: user.organization,
  });
  const [products] = useRestResource("/products", "", user.token, {
    organization: user.organization,
  });

  const [agents] = useRestResource("/agents", "", user.token, {
    organization: user.organization,
  });
  // const [customFields] = useRestResource(
  //     "/custom-fields",
  //     "",
  //     user.token,
  //     {organization: user.organization}
  // )

  // -------------------------------------
  // Memoized values
  // -------------------------------------

  const states = ["Accettato", "Inviato", "Rifiutato"];

  // -------------------------------------
  // Effects
  // -------------------------------------

  useEffect(() => {
    !open && clearForm();
    // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    defaultValues && setMany(defaultValues);

    // eslint-disable-next-line
  }, [defaultValues]);

  useEffect(() => {
    form?.products && update("amount")(calculateAmount());

    // eslint-disable-next-line
  }, [form?.products]);

  // -------------------------------------
  // Component functions
  // -------------------------------------
  function update(field) {
    if (field === "recipient")
    {
      if(recipientType === "Company")
      {

        return (value) => {
          const rec = companies?.rows?.filter((x) => x.id === value)?.[0]
          setMany({
            ...form,
            [field]: rec,
          });
        };
      }
      else{
        return (value) => {
          console.log("contacts : ", contacts)
          const rec = contacts?.rows?.filter((x) => x.id === value)?.[0]
          setMany({
            ...form,
            [field]: rec,
          });
        };
      }
    }
    return (value) => {
      
      setMany({
        ...form,
        [field]: value,
      });
    };
  }

  function handleConfirm() {
    // form?.customFields?.forEach(
    //     (usedCustomField) => checkValueType(usedCustomField)
    // )
    onConfirm && onConfirm(form);
  }

  async function handleGenerateDDT(e) {
    e.preventDefault();
    generateDDT && generateDDT(form);
  }

  async function handleGenerateInvoice(e) {
    e.preventDefault();
    generateInvoice && generateInvoice(form);
  }

  // function handleNewField(e) {
  // 	e.preventDefault()
  // 	const newCustomFields = [
  // 		...form?.customFields ?? [],
  // 		{ key: Math.floor(Math.random() * 99999), customField: "", value: "" },
  // 	]
  // 	update("customFields")(newCustomFields)
  // }

  // function handleDeleteCustomField(e, key) {
  // 	e.preventDefault()
  // 	const newCustomFields = form?.customFields.filter(
  // 		(customField) => customField.key !== key
  // 	)
  // 	update("customFields")(newCustomFields)
  // }

  // function onChange(customFieldId, value, key) {
  // 	const customField = form?.customFields.find(
  // 		(customField) => customField.key === key
  // 	)
  // 	customField.customField = customFieldId
  // 	customField.value = value
  // }

  // function checkValueType(customField) {
  // 	const originalCustomField = customFields.find(
  // 		(originalField) => originalField.id === customField.customField
  // 	)
  // 	if (originalCustomField.type === "number") {
  // 		const value = parseFloat(customField.value)
  // 		customField.value = null
  // 		customField.value = value
  // 	}
  // 	if (originalCustomField.type === "boolean") {
  // 		const value =
  // 			customField.value !== "" && customField.value !== "false" ? true : false
  // 		customField.value = null
  // 		customField.value = value
  // 	}
  // }

  function handleNewProductSelection(e) {
    e.preventDefault();
    const newproductsSelection = [
      ...(form?.products ?? []),
      {
        key: Math.floor(Math.random() * 99999),
        quantity: "",
        price: "",
        product: "",
        description: "",
        discount: 0,
      },
    ];

    update("products")(newproductsSelection);
  }

  function onChange(quantity, price, id, description, discount, key) {
    const productSelection = form?.products?.find(
      (productSelection) => productSelection.key === key
    );
    productSelection.quantity = quantity;
    productSelection.price = price;
    productSelection.product = id;
    productSelection.description = description;
    productSelection.discount = discount;

    update("amount")(calculateAmount());
  }

  function calculateAmount() {
    let newAmount = 0;
    form?.products?.forEach((productSelection) => {
      let prod = productSelection.quantity * productSelection.price;
      newAmount += prod - (prod * productSelection.discount) / 100;
    });
    return newAmount.toFixed(2);
  }

  function onDeleteProduct(e, id) {
    e.preventDefault();
    const newproductsSelection = form?.products?.filter(
      (productSelection) => productSelection.key !== id
    );
    update("products")(newproductsSelection);
  }

  function getTotal() {
    if (form?.discount && form?.discount > 0) {
      const discountValue = (form?.amount * form?.discount) / 100;
      return form?.amount - discountValue;
    }

    return form?.amount;
  }

  function handleSetAgent(value) {
    const agent = agents?.rows?.find((a) => a.id === value);
    setAgent(agent);
    update("commissionPercentage")(agent?.commissionPercentage);
  }

  function calcAgentCommission() {
    if (form?.commissionPercentage) {
      return (getTotal() * form?.commissionPercentage) / 100;
    }
    if (agent?.commissionPercentage) {
      return (getTotal() * agent?.commissionPercentage) / 100;
    }
    return null;
  }
  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <>
      <AppButton className="add-btn" onClick={onButtonClick}>
        Crea nuovo ordine
        <Plus width="14" height="13" style={{ marginLeft: 2 }} />
      </AppButton>
      <RightSheet
        className={`${className}`}
        open={open}
        onCancel={onCancel}
        onConfirm={handleConfirm}
        title={defaultValues?.id ? "Modifica ordine" : "Crea ordine"}
        confirmButtonLabel={
          defaultValues?.id ? "Modifica ordine" : "Crea ordine"
        }
        cancelButtonLabel="Annulla"
      >
        <Row className="converter">
          {defaultValues?.id && (
            <div className="row">
              <AppButton
                uxType="tertiary"
                onClick={handleGenerateDDT}
                className=""
              >
                Genera ddt
              </AppButton>

              <AppButton
                uxType="tertiary"
                onClick={handleGenerateInvoice}
                className="row-btn"
              >
                Genera fattura
              </AppButton>
            </div>
          )}
        </Row>
        <form className="form">
          <AppInput
            label="Nome ordine"
            placeholder="Nome ordine"
            value={form?.name ?? ""}
            onChange={update("name")}
          />
          <AppSelect
            label="Tipo di destinatario"
            placeholder="Tipo"
            value={form?.recipientType ?? ""}
            onSelect={update("recipientType")}
            onChange={(value) => setRecipientType(value)}
          >
            <Option key={"Company"} id={1}>
              Azienda
            </Option>
            <Option key={"Contact"} id={2}>
              Contatto
            </Option>
          </AppSelect>
          <SelectAutoComplete
            label="Destinatario"
            placeholder="Destinatario"
            className="app-select"
            disabled={recipientType === ""}
            defaultValue={
              form?.recipient?.businessName || form?.recipient?.fullName || ""
            }
            onSearch={(value) => handleSearch(recipientType, value)}
            onChange={(value) => update("recipient")(value)}
          />
          <AppSelect
            label="Stato"
            placeholder="Stato"
            value={form?.status ?? ""}
            onSelect={update("status")}
            className="app-select"
          >
            {states &&
              states.map((status, index) => (
                <Option key={status} id={index}>
                  {status}
                </Option>
              ))}
          </AppSelect>
          <AppInput
            label="Note"
            placeholder="Note"
            value={form?.notes ?? ""}
            onChange={update("notes")}
          />
          <Row className="products">
            <Col span={11} className="productsPar">
              Prodotti
            </Col>
            <Col span={4} offset={0.5} className="quantityPar">
              Quantità
            </Col>
            <Col span={6} offset={2} className="pricePar">
              Prezzo
            </Col>
          </Row>

          {form?.products?.map((productSelection) => (
            <ProductSelection
              products={products?.rows}
              key={productSelection.key}
              id={productSelection.key}
              onChange={onChange}
              product={
                productSelection?.product?.id ?? productSelection?.product
              }
              price={productSelection.price ? productSelection.price : null}
              quantity={
                productSelection.quantity ? productSelection.quantity : null
              }
              description={
                productSelection.description
                  ? productSelection.description
                  : null
              }
              discount={
                productSelection.discount ? productSelection.discount : null
              }
              onCancel={onDeleteProduct}
            />
          ))}

          <p className="total">Subtotale: {form?.amount ?? 0}€</p>

          <AppButton
            // uxType="link"
            onClick={handleNewProductSelection}
            className="addField"
            icon={<PlusOutlined />}
          >
            Aggiungi prodotto
          </AppButton>

          <AppInput
            label="Sconto"
            placeholder="Sconto"
            value={form?.discount ?? ""}
            onChange={update("discount")}
          />
          <AppSelect
            label="Agente"
            placeholder="Agente"
            value={form?.agent?.fullName ?? ""}
            onSelect={(value) => {
              handleSetAgent(value);
              update("agent")(value);
            }}
            className="app-select"
          >
            {agents?.rows &&
              agents.rows?.map((agent, index) => (
                <Option key={agent.id} id={index}>
                  {agent.fullName}
                </Option>
              ))}
          </AppSelect>
          <FieldLabel>Percentuale provvigioni</FieldLabel>
          <InputNumber
            value={
              form?.commissionPercentage ?? agent?.commissionPercentage ?? ""
            }
            onChange={update("commissionPercentage")}
            className="inputPerc"
            precision={2}
            step={0.5}
            formatter={(value) => `${value}%`}
            parser={(value) => value?.replace("%", "")}
          />
          <p className="total">Totale: {getTotal()}€</p>
          <p className="total">
            Agente: {calcAgentCommission()?.toFixed(2) ?? ""}€
          </p>
        </form>
      </RightSheet>
    </>
  );
}
// ----------------------------------------------------------------------------

const StyledOrderRightSheet = styled(OrderRightSheet)`
  & {
    .row-btn {
      margin-left: 20px;
    }
  }
`;
export default StyledOrderRightSheet;
