import React, { useEffect } from "react"
import styled from "styled-components"
import { Select, message } from "antd"
import { useState } from "react"
import { useSelector } from "react-redux"

import TeamRightSheet from "./TeamRightSheet"

import Avatar from "../../../../../_shared/components/Avatar"
import PopConfirm from "../../../../../_shared/components/PopConfirm"
import Table from "../../../../../_shared/components/Table"
import { ReactComponent as Delete } from "../../../../../_shared/assets/icons/delete.svg"
import usePaginatedRestResource from "../../../../../_shared/hooks/usePaginatedRestResource"

import * as api from "../../../../../../api"

// ----------------------------------------------------------------------------

function Teams(props) {
	// -------------------------------------
	// Props destructuring
	// -------------------------------------

	const { className } = props

	// -------------------------------------
	// Hooks (e.g. useState, ...)
	// -------------------------------------

	const user = useSelector((state) => (state?.auth.user ? state.auth : null))

	const [
		teams,
		,
		{ paginationT, countT, filtersT },
		{
			fetchResource: fetchTeams,
			onChange: handleTeamsChange,
			onFiltersTeamChange,
		},
	] = usePaginatedRestResource("/teams", user.token, {
		defaultFilters: {
			organizations: user.organization,
		},
	})

	const [
		users,
		,
		{ pagination, count, filters },
		{ fetchResource: fetchUsers, onChange: handleTableChange, onFiltersChange },
	] = usePaginatedRestResource("/users", user.token, {
		defaultFilters: {
			organizations: user.organization,
		},
	})

	const [selectedTeam, setSelectedTeam] = useState()

	// -------------------------------------
	// Memoized values
	// -------------------------------------

	// -------------------------------------
	// Effects
	// -------------------------------------

	// -------------------------------------
	// Component functions
	// -------------------------------------

	function getColumns() {
		return [
			{
				title: "NOME",
				dataIndex: "name",
				key: "name",
				render: (text) => {
					if (text) {
						return <span>{text}</span>
					}
				},
			},
			{
				title: "UTENTI",
				dataIndex: "users",
				key: "users",
				render: (text) => {
					if (text) {
						return (
							<div className="row">
								{text.map((user) => (
									<Avatar
										className="icon"
										name={
											user.fullName.charAt(0) +
											(user.fullName ? user.fullName.charAt(0) : "")
										}
									/>
								))}
							</div>
						)
					}
				},
			},

			{
				title: "",
				dataIndex: "delete",
				key: "delete",
				render: (text, record, index) => (
					<PopConfirm
						className={className}
						title="Sei sicuro di voler rimuovere questo team dall'organizzazione?"
						onConfirm={(e) => deleteTeam(e, record)}
						onCancel={(e) => e.stopPropagation()}
						okText="Sì"
						cancelText="No"
						placement="leftTop"
					>
						<div className="delete" onClick={(e) => e.stopPropagation()}>
							<Delete />
						</div>
					</PopConfirm>
				),
			},
		]
	}

	async function deleteTeam(e, userRow) {
		e.stopPropagation()
		try {
			await api.deleteTeam(userRow.id, user.token)
			fetchTeams()
		} catch (error) {
			console.log("[ERROR] in delete user: ")
			console.log(error)
		}
	}

	function handleNewTeam() {
		setSelectedTeam({})
	}

	function onCancel() {
		setSelectedTeam(null)
	}

	async function onConfirmCreate(values) {
		const newValues = {
			...values,
			users: values.users.map((user) => user.id),
		}

		values?.id
			? await api.updateTeam(values.id, newValues, user.token)
			: await api.createTeam(newValues, user.token)

		setSelectedTeam(null)

		message.success("Operazione conclusa correttamente")
		fetchTeams()
	}

	return (
		<Table
			className={className}
			columns={getColumns()}
			onResourceCreate={() => setSelectedTeam({})}
			dataSource={teams}
			handleOnRow={(row) => setSelectedTeam(row)}
			onChange={handleTableChange}
			renderTopBarCustomButtons={() => (
				<TeamRightSheet
					open={!!selectedTeam}
					allUsers={users}
					defaultValues={selectedTeam}
					onConfirm={onConfirmCreate}
					onCancel={onCancel}
					onButtonClick={handleNewTeam}
				/>
			)}
		/>
	)
}

// ----------------------------------------------------------------------------

const StyledTeams = styled(Teams)`
	& {
		.form {
			min-width: 280px;
		}
		.app-select {
			min-width: 280px;
		}
		.row {
			display: flex;
			flex-direction: row;
			align-items: center;

			span {
				margin-left: 8px;
			}
		}
		.icon {
			margin: 3px;
		}
		.addField {
			display: flex;
			flex-direction: row-reverse;
			justify-content: center;
			margin: auto;
			margin-top: 10px;
			padding: 5px;
			border: none;
			.icon {
				margin: auto;
				margin-right: 10px;
				height: 14px;
			}
		}
	}
`
export default StyledTeams
