import React, { useState } from "react";
import styled from "styled-components";
import ProjectBox from "./ProjectBox";

// ----------------------------------------------------------------------------

function GridProjects(props) {
  const { className, dataSource, completed } = props;

  const [visibile, setVisible] = useState(!completed);
  return (
    <div className={className}>
      <button
        className="hideButton"
        style={{
          fontWeight: visibile === true ? "bold" : "normal",
        }}
        onClick={() => setVisible(!visibile)}
      >
        {visibile === true ? "^ " : "> "}
        {completed === true ? "Completi " : "Attivi "}
        {`(${dataSource.length})`}
      </button>

      <div
        className="container"
        style={{ display: visibile === true ? "grid" : "none" }}
      >
        {dataSource.map((project) => {
          return <ProjectBox className={className} body={project}></ProjectBox>;
        })}
      </div>
    </div>
  );
}

const StyledGridProjects = styled(GridProjects)`
  & {
    .container {
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 10px;
      padding: 20px;
    }
    .hideButton {
      border: none;
      background-color: white;
      margin-left: 10px;
      margin-top: 20px;
      :hover {
        background-color: rgb(180, 180, 180, 0.2);
      }
    }
  }
`;

export default StyledGridProjects;
