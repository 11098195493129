import "../../index.css";

import PropTypes from "prop-types";
import React from "react";
import {
    ThemeProvider as StyledComponentsThemeProvider,
    createGlobalStyle,
    css,
} from "styled-components";
import chroma from "chroma-js";

// ----------------------------------------------------------------------------

export default function ThemeProvider(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { children } = props;

    // -------------------------------------
    // Hooks (e.g. useState, useMemo ...)
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    const theme = {
        spacing: {
            xs: 7,
            s: 20,
            m: 40,
            l: 60,

            formItemHeight: 40,
            button: 40,
            screenPadding: 200,
        },

        typography: {},

        shadow: css`
            box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
        `,

        screenSizes: {
            sm: "576px",
            md: "768px",
        },
        colors: {
            light: "#FFFFFF",
            dark: "#000000",

            primary: "#1d1d1b",
            secondary: "#E5E5E5",
            dashboard: "#FAFBFF",
            button: "#FFAB2B",

            link: "#3F8CFF",
            shade2: "#D8DAE5",
            grey: "#E8ECEF",
            grey100: "#8F95B2",
            grey200: "#E6E8F0",
            darkgrey: "#0E0E0D",

            greySider: "#EFEFEF",

            boxGrey: "#f5f5f5",

            //input
            inputText: "#554D4A",
            inputBorderFocus: "#EF961C",

            //Neutral
            neutral800: "#160601",
            neutral700: "#565656",
            neutral500: "#989898",
            neutral300: "#B6B6B6",
            neutral200: "#D1D1D1",
            neutral100: "#E7E7E7",

            //Orange
            orange800: "#FEA01F",
            orange300: "#FCCE8F",
            orangeHover: "#ECA464",
            orangeIcon: "#E67E22",
            orangeIconHover: "#EDBA8D",
            orangeGradient: "linear-gradient(45deg, #E55F21 0%, #EF961C 100%)",

            //Success
            success800: "#27AE60",
            success300: "#9BDBB6",
            //successGradient: "linear-gradient(45deg, #45BF55 0%, #DAF2DD 100%)",

            //Warning
            warning900: "#FFCC00",
            warning800: "#FFD633",
            warning500: "#FFE580",
            warning200: "#FFF5CC",
            warningGradient:
                "linear-gradient(45deg, #FFCC00 0%, #FFF5CC  100%)",

            //Error,
            error800: "#E74C3C",
            error300: "#FBBAB4",
            //errorGradient: "linear-gradient(45deg, #E53935 0%, #FAD7D7   100%)",

            //Accent_Purple
            accentPurple_800: "#A29BFE",
            accentPurple_300: "#D9D6FB",
            //accent01Gradient: "linear-gradient(45deg, #00ABD8 0%, #CCEEF7 100%)",

            //Accent_Yellow
            accentYellow_800: "#FFC048",
            accentYellow_300: "#FFE5B4",
            //accent02Gradient: "linear-gradient(45deg, #9768D1 0%, #EAE1F6  100%)",

            //Accent_Turquoise
            accentTurquoise_800: "#12CBC4",
            accentTurquoise_300: "#AEF8F5",
            //accent03Gradient: "linear-gradient(45deg, #ED98B30%, #FBEAF0  100%)",

            //Accent_Aqua
            accentAqua_800: "#33D9B2",
            accentAqua_300: "#A8F4E2",
            //accent04Gradient: "linear-gradient(45deg, #00897B0%, #CCE7E5 100%)",

            //Accent_Blue
            accentBlue_800: "#209FFF",
            accentBlue_300: "#82C9FF",
        },
    };

    /* eslint-disable react/prop-types  */
    const GlobalStyle = createGlobalStyle`

        body{
            font-size: 14px;

            a,p {
                color: #1d1d1b;
            }

        }

         .center {
            display: flex;
            align-items: center;
            justify-content: center;
        }
         .flex-center {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .flex {
            display: flex;
        }
        .flex-row {
            flex-direction: row;
        }
        .flex-col {
            flex-direction: column;
        }
        .items-center {
            align-items: center;
        }
        .items-statrt {
            align-items: flex-start;
        }
        .items-statrt {
            align-items: flex-end;
        }
        .justify-center {
            justify-content: center;
        }
        .justify-start {
            justify-content: flex-start;
        }
        .justify-end {
            justify-content: flex-end;
        }
        .justify-between {
            justify-content: space-between;
        }

        .h-screen {
            height: 100vh;
        }
        .h-full {
            height: 100%;
        }
        .w-screen {
            width: 100vw;
        }
        .w-full {
            width: 100%;
        }
        .m-w-unset {
            max-width: unset;
        }

        .overflow-hidden {
            overflow:hidden;
        }
        .overflow-x-hidden {
            overflow-x: hidden;
        }
        .overflow-y-auto {
            overflow-y: auto;
        }

        .text-center {
            text-align: center;
        }
    `;
    /* eslint-enable react/prop-types  */

    return (
        <StyledComponentsThemeProvider theme={theme}>
            <GlobalStyle />
            {children}
        </StyledComponentsThemeProvider>
    );
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

ThemeProvider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
};

ThemeProvider.defaultProps = {};
