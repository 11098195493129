import React from "react"
import styled from "styled-components"
import { Select, message} from "antd"
import { useState } from "react"
import { useSelector } from "react-redux"

import PortRightSheet from "./PortRightSheet"

import PopConfirm from "../../../../../_shared/components/PopConfirm"
import Table from "../../../../../_shared/components/Table"
import { ReactComponent as Delete } from "../../../../../_shared/assets/icons/delete.svg"
import usePaginatedRestResource from "../../../../../_shared/hooks/usePaginatedRestResource"

import * as api from "../../../../../../api"

// ----------------------------------------------------------------------------

function Ports(props) {
	// -------------------------------------
	// Props destructuring
	// -------------------------------------

	const { className } = props
	// -------------------------------------
	// Hooks (e.g. useState, ...)
	// -------------------------------------

	const user = useSelector((state) => (state?.auth.user ? state.auth : null))

    const [
        ports,
        ,
        { pagination, count, filters },
        {
            fetchResource: fetchPorts,
            onChange: handleTableChange,
            onFiltersChange,
        },
    ] = usePaginatedRestResource('/ports', user.token, {
            defaultFilters: {
                organization: user.organization
            },
        })

    const [selectedPort, setSelectedPort] = useState()

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------    
    // Component functions
    // -------------------------------------

	function getColumns(){ 
        return [
            {
                title: "NOME",
                dataIndex: "name",
                key: "name",
            },
            {
                title: "",
                dataIndex: "delete",
                key: "delete",
                render: (text, record, index) => (
                    <PopConfirm
                        className={className}
                        title="Sei sicuro di voler rimuovere questa porto?"
                        onConfirm={(e) => deletePort(e, record.id)}
                        onCancel={(e) => e.stopPropagation()}
                        okText="Sì"
                        cancelText="No"
                        placement="leftTop"
                    >
                        <div className="delete" onClick={(e) => e.stopPropagation()}><Delete/></div>
                    </PopConfirm>
                )
            },
            
        ]
    }

    async function deletePort(e, portId) {
        e.stopPropagation()
        try {
            await api.deletePort(portId, user.token)
            fetchPorts()
        } catch (error) {
            console.log("[ERROR] in delete port: ")
            console.log(error)
        }
    }

	function handleNewPort() {
        setSelectedPort({})
	}

	function onCancel() {
        setSelectedPort(null)
	}

	async function onConfirm(values) {
        if(values?.name === "")
            message.error("Nome mancante")
        else {
            try {
                !values?.organization
                ? values.organization = user.organization
                : values.organization = values.organization.id

            values?.id
                ? await api.updatePort(values.id, values, user.token)
                : await api.createPort(values, user.token)
            
            fetchPorts()
            message.success("Operazione eseguita con successo")
            setSelectedPort(null)
            } catch (error) {
                console.log("[ERROR] in confirm port: ")
                console.log(error)
                message.error("Errore durante l'operazione")
            }
        }
	}

	return (
        <Table
            className={className}
            columns={getColumns()}
            onResourceCreate={() => setSelectedPort({})}
            dataSource={ports}
            onChange={handleTableChange}
            rowSelection={{ type: "checkbox" }}
            addContact="true"
            handleOnRow={(row) => setSelectedPort(row)}
            renderTopBarCustomButtons={() => (
                <PortRightSheet
                    open={!!selectedPort}
                    defaultValues={selectedPort}
                    onConfirm={onConfirm}
                    onCancel={onCancel}
                    onButtonClick={handleNewPort}
                />
            )}
        />
	)
}

// ----------------------------------------------------------------------------

const StyledPorts = styled(Ports)`
	& {
        .form {
            min-width: 280px;
        }
        .app-select {
            min-width: 280px;
        }
		.row {
			display: flex;
			flex-direction: row;
			align-items: center;

			span {
				margin-left: 8px;
			}
		}
		.addField {
			display: flex;
			flex-direction: row-reverse;
			justify-content: center;
			margin: auto;
			margin-top: 10px;
			padding: 5px;
			border: none;
			.icon {
				margin: auto;
				margin-right: 10px;
				height: 14px;
			}
		}
	}
`
export default StyledPorts
