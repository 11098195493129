import { useEffect, useState, useRef } from "react"
import styled from "styled-components"
import { message, Select, Tabs } from "antd"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { PlusOutlined } from "@ant-design/icons"

import AppButton from "../../../../_shared/components/AppButton"
import AppInput from "../../../../_shared/components/AppInput"
import AppSelect from "../../../../_shared/components/AppSelect"
import CustomField from "../../../../_shared/components/CustomField"
import useForm from "../../../../_shared/hooks/useForm"
import useRestResource from "../../../../_shared/hooks/useRestResource"

import * as api from "../../../../../api"
import SelectAutoComplete from "../../../../_shared/components/SelectAutoComplete"

// ----------------------------------------------------------------------------

function ContactData(props) {
	// -------------------------------------
	// Props destructuring
	// -------------------------------------

	const { className } = props
	const { TabPane } = Tabs
	const { Option } = Select

	// -------------------------------------
	// Hooks (e.g. useState, ...)
	// -------------------------------------

	const params = useParams()
	const user = useSelector((state) => (state?.auth.user ? state.auth : null))
	const divRef = useRef()

	const [form, , setMany, clearForm] = useForm()

	const [selectedContact, setSelectedContact] = useState()

	const [users] = useRestResource("/users", "", user.token, {
		organization: user.organization,
	})
	const [companies] = useRestResource("/companies", "", user.token, {
		organization: user.organization,
	})
	const [customFields] = useRestResource("/custom-fields", "", user.token, {
		organization: user.organization,
	})

	// -------------------------------------
	// Memoized form
	// -------------------------------------

	// -------------------------------------
	// Effects
	// -------------------------------------
	useEffect(() => {
		selectedContact && setMany(selectedContact)
		// eslint-disable-next-line
	}, [selectedContact])

	useEffect(() => {
		if (selectedContact && JSON.stringify(form) === "{}")
			setMany(selectedContact)
		// eslint-disable-next-line
	}, [form])

	useEffect(() => {
		getContact()
	}, [params.contactId])

	function update(field) {
		return (value) => {
			setMany({
				...form,
				[field]: value,
			})
		}
	}

	async function getContact() {
		try {
			const response = await api.getContact(params.contactId, user.token)
			if (response) setSelectedContact(response)
		} catch (error) {}
	}

	function handleNewField(e) {
		e.preventDefault()
		const newCustomFields = [
			...(form?.customFields ?? []),
			{ key: Math.floor(Math.random() * 99999), customField: "", value: "" },
		]
		update("customFields")(newCustomFields)
	}

	function handleDeleteCustomField(e, key) {
		e.preventDefault()
		const newCustomFields = form?.customFields.filter(
			(customField) => customField.key !== key
		)
		update("customFields")(newCustomFields)
	}

	function onChange(customFieldId, value, key) {
		const customField = form?.customFields.find(
			(customField) => customField.key === key
		)
		customField.customField = customFieldId
		customField.value = value
	}

	function checkValueType(customField) {
		const originalCustomField = customFields.rows.find(
			(originalField) => originalField.id === customField.customField
		)
		if (originalCustomField.type === "number") {
			const value = parseFloat(customField.value)
			customField.value = null
			customField.value = value
		}
		if (originalCustomField.type === "boolean") {
			const value =
				customField.value !== "" && customField.value !== "false" ? true : false
			customField.value = null
			customField.value = value
		}
	}

	function onCancel() {
		clearForm()
	}

	async function onConfirm() {
		try {
			form?.customFields?.forEach((usedCustomField) =>
				checkValueType(usedCustomField)
			)

			!form?.organization
				? (form.organization = user.organization)
				: (form.organization = form.organization.id)

			await api.updateContact(form.id, form, user.token)

			message.success("Operazione eseguita con successo")
			setSelectedContact(form)
		} catch (error) {
			console.log("[ERROR] in confirm contact: ")
			console.log(error)
			message.error("Errore durante l'operazione")
		}
	}

	function renderGeneralInformation() {
		return (
			<div className="information">
				<p className="tab-title">Informazioni generali</p>
				<div className="input-container">
					<AppInput
						label="Nome"
						placeholder="Nome"
						value={form?.name ?? ""}
						onChange={update("name")}
					/>
					<AppInput
						label="Cognome"
						placeholder="Cognome"
						value={form?.surname ?? ""}
						onChange={update("surname")}
					/>
					<AppInput
						label="Phone"
						placeholder="phone"
						value={form?.phone ?? ""}
						onChange={update("phone")}
					/>
					<AppInput
						label="Email"
						placeholder="Email"
						value={form?.email ?? ""}
						onChange={update("email")}
					/>
					<AppSelect
						label="Proprietario del contatto"
						placeholder="Proprietario"
						value={form?.owner?.fullName ?? ""}
						onSelect={update("owner")}
						className="app-select"
					>
						{users &&
							users.rows?.map((user, index) => (
								<Option key={user.id} id={index}>
									{user.fullName}
								</Option>
							))}
					</AppSelect>
					<AppSelect
						label="Azienda"
						placeholder="Azienda"
						value={form?.company?.businessName ?? ""}
						onSelect={update("company")}
						className="app-select"
					>
						{companies &&
							companies.rows?.map((company, index) => (
								<Option key={company.id} id={index}>
									{company.businessName}
								</Option>
							))}
					</AppSelect>
					{form?.customFields?.map((customField, index) => (
						<CustomField
							key={customField.key}
							id={customField.key}
							onChange={onChange}
							onCancel={(e, key) => handleDeleteCustomField(e, key)}
							customFields={customFields?.rows}
							givenCustomField={
								customField.customField ? customField.customField : null
							}
							givenValue={customField.value ? customField.value : null}
						/>
					))}
					<AppButton
						uxType="link"
						onClick={handleNewField}
						className="addField"
						icon={<PlusOutlined />}
					>
						Aggiungi campo personalizzato
					</AppButton>
				</div>
			</div>
		)
	}

	return (
		<div className={className}>
			<div className="leftSide">
				<div className="title" ref={divRef}>
					<p className="businessName">{form?.fullName ?? ""}</p>
					<Tabs defaultActiveKey="1">
						<TabPane tab="Generale" key="1">
							{renderGeneralInformation()}
						</TabPane>
					</Tabs>
					<div
						className="actionFooter" /*style={{ width: `${width + 20}px` }}*/
					>
						<AppButton
							uxType="tertiary"
							className="actionBtn"
							onClick={onCancel}
						>
							Annulla
						</AppButton>
						<AppButton uxType="" className="actionBtn" onClick={onConfirm}>
							Conferma
						</AppButton>
					</div>
				</div>
			</div>
			{/* <div className="center">

            </div>
            <div className="rightSide">

            </div> */}
		</div>
	)
}

// ----------------------------------------------------------------------------

const StyledContactData = styled(ContactData)`
	& {
		width: inherit;
		min-width: 240px;
		height: 100%;
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		.leftSide {
			min-width: 200px;
			width: 100%;
			height: inherit;
			padding: 10px 10px;
			padding-bottom: 0px;
			//border-right: 1px solid ${({ theme }) => theme.colors.grey200};

			.title {
				padding: 10px 10px;
				height: inherit;
				display: flex;
				flex-direction: column;
				width: 100%;
				//border-bottom: 1px solid ${({ theme }) => theme.colors.grey200};
				.businessName {
					font-size: 20px;
				}
				.ant-tabs {
					height: inherit;
					.ant-tabs-content-holder {
						height: inherit;

						.ant-tabs-content {
							height: inherit;
						}
					}
				}
			}
			.information {
				width: 100%;
				padding-top: 10px;
				padding-left: 10px;
				height: 95%;
				overflow: scroll;
				//border-top: 1px solid ${({ theme }) => theme.colors.grey200};
				//border-bottom: 1px solid ${({ theme }) => theme.colors.grey200};

				.tab-title {
					font-size: 18px;
				}
				.input-container {
					width: 40%;
					min-width: 240px;
				}
				.app-select {
					width: 100%;
				}
			}
			.actionFooter {
				height: 50px;
				width: 100%;
				padding: 20px;
				box-sizing: content-box;
				background-color: ${({ theme }) => theme.colors.light};
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: flex-end;
				border-radius: 16px;
				margin-left: -20px;
				z-index: 100;
				.actionBtn {
					margin-right: 20px;
				}
			}
			.addField {
				display: flex;
				flex-direction: row-reverse;
				justify-content: flex-start;
				margin: auto;
				margin-top: 10px;
				padding: 5px;
				border: none;
				.icon {
					margin: auto;
					margin-right: 10px;
					height: 14px;
					width: 30px;
				}
				.center {
					min-width: 0px;
				}
			}
		}
		.center {
			width: 50%;
			min-width: 400px;
			height: inherit;
		}
		.rightSide {
			min-width: 200px;
			width: 25%;
			height: inherit;
			border-left: 1px solid ${({ theme }) => theme.colors.grey200};
		}
	}
`
export default StyledContactData
