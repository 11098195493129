import { useEffect, useReducer, useRef } from "react";

import * as api from "../../../../api";

//------------------------------------------------------------------------------

export default function usePaginatedRestResource(
    resourceURL,
    token,
    options = {}
) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const {
        pollingSeconds = 0,
        defaultFilters = {},
        defaultSort = {},
    } = options;

    // -------------------------------------
    // Hooks (e.g. useState, useMemo ...)
    // -------------------------------------

    const initialState = {
        resource: [],
        filters: defaultFilters,
        sort: defaultSort,
        status: "idle",
        error: null,
        pagination: {
            pageNumber: 1,
            pageSize: 10,
        },
    };

    const [state, dispatch] = useReducer(reducer, initialState);
    const pollingRef = useRef();

    // -------------------------------------
    // Effects
    // -------------------------------------

    useEffect(() => {
        fetchResource();

        if (pollingSeconds > 0) {
            // clear interval if exists
            pollingRef.current && clearInterval(pollingRef.current);

            const intervalMs = pollingSeconds * 1000;

            pollingRef.current = setInterval(() => {
                fetchResource();
            }, intervalMs);
        }

        return function cleanup() {
            if (pollingSeconds > 0) {
                clearInterval(pollingRef.current);
            }
        };

        //eslint-disable-next-line
    }, [
        state.filters,
        state.sort,
        state?.pagination?.pageSize,
        state?.pagination?.pageNumber,
        pollingSeconds,
         options?.pagination
    ]);

    // -------------------------------------
    // local functions
    // -------------------------------------

    function reducer(state, action) {
        switch (action.type) {
            case "FETCH_PENDING":
                return {
                    ...state,
                    status: "pending",
                };

            case "FETCH_FULFILLED": {
                const resourceCount = action?.payload?.count ?? null;

                const resource = action?.payload?.rows ?? {};

                return {
                    ...state,
                    status: "idle",
                    resource: resource,
                    count: resourceCount,
                };
            }
            case "FETCH_REJECTED":
                return {
                    ...state,
                    status: "error",
                    error: action.payload,
                };

            case "FILTERS_CHANGE": {
                return {
                    ...state,
                    filters: action.payload,
                };
            }

            case "SORT_CHANGE": {
                return {
                    ...state,
                    sort: action.payload,
                };
            }

            case "PAGINATION_CHANGE": {
                return {
                    ...state,
                    pagination: action.payload,
                };
            }

            case "PARAMS_CHANGE": {
                return {
                    ...state,
                    ...action.payload,
                };
            }

            default:
                throw new Error(
                    "Error in usePaginatedRestResource: Unable to recognize action type"
                );
        }
    }

    // -------------------------------------

    async function fetchResource() {
        try {
            dispatch({
                type: "FETCH_PENDING",
            });

            const requestOptions = {
                filters: state?.filters ?? {},
                pageNumber: state?.pagination?.pageNumber,
                pageSize: state?.pagination.pageSize,
                sort: {
                    key: state?.sort?.columnKey,
                    order: state?.sort?.order,
                },
            };

            const apiResponse = await api.getResourceList(
                resourceURL,
                token,
                requestOptions
            );

            dispatch({
                type: "FETCH_FULFILLED",
                payload: apiResponse,
            });
        } catch (error) {
            dispatch({
                type: "FETCH_REJECTED",
                payload: error,
            });
        }
    }

    function handleFiltersChange(newFilters = {}) {
        dispatch({
            type: "FILTERS_CHANGE",
            payload: {
                ...state.filters,
                ...newFilters,
            },
        });
    }

    function handlePaginationChange(newPagination = {}) {
        dispatch({
            type: "PAGINATION_CHANGE",
            payload: {
                ...state.pagination,
                ...newPagination,
            },
        });
    }

    function handleSortChange(newSort = {}) {
        dispatch({
            type: "SORT_CHANGE",
            payload: {
                ...state.sort,
                ...newSort,
            },
        });
    }

    function handleParamsChange(pagination = {}, filters = {}, sorter = {}) {
        dispatch({
            type: "PARAMS_CHANGE",
            payload: {
                pagination: {
                    ...state?.pagination,
                    ...pagination,
                    pageNumber: pagination?.current || pagination?.pageNumber,
                },
                sort: {
                    ...state?.sort,
                    ...sorter,
                },
                filters: {
                    ...state?.filters,
                    ...filters,
                },
            },
        });
    }

    // -------------------------------------
    // returned values
    // -------------------------------------

    const resourceMetaData = {
        pagination: state?.pagination,
        count: state?.count,
        status: state?.status,
        filters: state?.filters,
        error: state?.error,
    };

    const resourceManager = {
        fetchResource: fetchResource,
        onFiltersChange: handleFiltersChange,
        onPaginationChange: handlePaginationChange,
        onSortChange: handleSortChange,
        onChange: handleParamsChange,
    };

    return [state.resource, state.status, resourceMetaData, resourceManager];
}
