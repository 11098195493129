import React, { useState } from "react";
import styled from "styled-components";

function _Avatar(props) {
    const { className, zindex, icon, name, ...divProps } = props;
    return (
        <div className={className} style={{ zIndex: zindex }} {...divProps}>
            {icon ? icon : name}
        </div>
    );
}

const Avatar = styled(_Avatar)`
    & {
        background: ${({ theme }) => theme.colors.orangeGradient};
        width: 48px;
        height: 48px;
        border-radius: 30px;
        padding: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;
export default Avatar;
