import { PlusOutlined } from "@ant-design/icons";
import { Col, message, Row, Select, Tabs } from "antd";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import AppButton from "../../../../_shared/components/AppButton";
import AppInput from "../../../../_shared/components/AppInput";
import useForm from "../../../../_shared/hooks/useForm";
import useRestResource from "../../../../_shared/hooks/useRestResource";

import * as api from "../../../../../api";
import AppSelect from "../../../../_shared/components/AppSelect";
import ProductSelection from "../../Deals/ProductSelection";

// ----------------------------------------------------------------------------

function ProductData(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { className } = props;
  const { TabPane } = Tabs;
  const { Option } = Select;

  // -------------------------------------
  // Hooks (e.g. useState, ...)
  // -------------------------------------

  const params = useParams();
  const user = useSelector((state) => (state?.auth.user ? state.auth : null));
  const divRef = useRef();

  const [form, , setMany, clearForm] = useForm();

  const [selectedProduct, setSelectedProduct] = useState();

  const [customFields] = useRestResource("/custom-fields", "", user.token, {
    organization: user.organization,
  });

  const [taxRates] = useRestResource("/rates", "", user.token, {
    organization: user.organization,
  });

  const [products] = useRestResource("/products", "", user.token, {
    organization: user.organization,
  });
  const [companies] = useRestResource("/companies", "", user.token, {
    organization: user.organization,
  });

  // -------------------------------------
  // Memoized form
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  useEffect(() => {
    selectedProduct && setMany(selectedProduct);
    // eslint-disable-next-line
  }, [selectedProduct]);

  useEffect(() => {
    if (selectedProduct && JSON.stringify(form) === "{}")
      setMany(selectedProduct);
    // eslint-disable-next-line
  }, [form]);

  useEffect(() => {
    getProduct();
  }, [params.productId]);

  useEffect(() => {
    form?.components && update("amount")(calculateAmount());

    // eslint-disable-next-line
  }, [form?.components]);

  // -------------------------------------
  // Component functions
  // -------------------------------------

  function update(field) {
    return (value) => {
      setMany({
        ...form,
        [field]: value,
      });
    };
  }

  async function getProduct() {
    try {
      const response = await api.getProduct(params.productId, user.token);
      if (response) {
        setSelectedProduct(response);
      }
    } catch (error) {}
  }

  function onChange(quantity, price, id, description, discount, key) {
    const productSelection = form?.components?.find(
      (productSelection) => productSelection.key === key
    );
    productSelection.quantity = quantity;
    productSelection.price = price;
    productSelection.product = id;
    productSelection.description = description;
    productSelection.discount = discount;

    update("amount")(calculateAmount());
  }

  function calculateAmount() {
    let newAmount = 0;
    form?.components?.forEach((productSelection) => {
      let prod = productSelection.quantity * productSelection.price;
      newAmount += prod - (prod * productSelection.discount) / 100;
    });
    return newAmount.toFixed(2);
  }

  function getTotal() {
    if (form?.discount && form?.discount > 0) {
      const discountValue = (form?.amount * form?.discount) / 100;
      return form?.amount - discountValue;
    }

    return form?.amount;
  }

  function handleNewProductSelection(e) {
    e.preventDefault();
    const newproductsSelection = [
      ...(form?.components ?? []),
      {
        key: Math.floor(Math.random() * 99999),
        quantity: "",
        price: "",
        product: "",
        description: "",
        discount: 0,
      },
    ];

    update("components")(newproductsSelection);
  }

  function onDeleteProduct(e, id) {
    e.preventDefault();
    const newproductsSelection = form?.components?.filter(
      (productSelection) => productSelection.key !== id
    );
    update("components")(newproductsSelection);
  }

  function handleNewField(e) {
    e.preventDefault();
    const newCustomFields = [
      ...(form?.customFields ?? []),
      {
        key: Math.floor(Math.random() * 99999),
        customField: "",
        value: "",
      },
    ];
    update("customFields")(newCustomFields);
  }

  function handleDeleteCustomField(e, key) {
    e.preventDefault();
    const newCustomFields = form?.customFields.filter(
      (customField) => customField.key !== key
    );
    update("customFields")(newCustomFields);
  }

  // function onChange(customFieldId, value, key) {
  // 	const customField = form?.customFields.find(
  // 		(customField) => customField.key === key
  // 	)
  // 	customField.customField = customFieldId
  // 	customField.value = value
  // }

  function checkValueType(customField) {
    const originalCustomField = customFields.rows.find(
      (originalField) => originalField.id === customField.customField
    );
    if (originalCustomField.type === "number") {
      const value = parseFloat(customField.value);
      customField.value = null;
      customField.value = value;
    }
    if (originalCustomField.type === "boolean") {
      const value =
        customField.value !== "" && customField.value !== "false"
          ? true
          : false;
      customField.value = null;
      customField.value = value;
    }
  }

  function onCancel() {
    clearForm();
  }

  async function onConfirm() {
    try {
      !form?.organization
        ? (form.organization = user.organization)
        : (form.organization = form.organization.id);

      await api.updateProduct(form.id, form, user.token);

      message.success("Operazione eseguita con successo");
      setSelectedProduct(form);
    } catch (error) {
      console.log("[ERROR] in confirm product: ");
      console.log(error);
      message.error("Errore durante l'operazione");
    }
  }

  function renderComponentsTab() {
    return (
      <div className="information">
        <p className="tab-title">Componenti del prodotto</p>
        <div className="input-container">
          <Row className="products">
            <Col span={11} className="productsPar">
              Prodotti
            </Col>
            <Col span={4} offset={0.5} className="quantityPar">
              Quantità
            </Col>
            <Col span={6} offset={2} className="pricePar">
              Prezzo
            </Col>
          </Row>

          {form?.components?.map((productSelection) => (
            <ProductSelection
              products={products?.rows}
              key={productSelection.key}
              id={productSelection.key}
              onChange={onChange}
              product={
                productSelection?.product?.id ?? productSelection?.product
              }
              price={productSelection.price ? productSelection.price : null}
              quantity={
                productSelection.quantity ? productSelection.quantity : null
              }
              description={
                productSelection.description
                  ? productSelection.description
                  : null
              }
              discount={
                productSelection.discount ? productSelection.discount : null
              }
              onCancel={onDeleteProduct}
            />
          ))}

          <p className="total">Subtotale: {form?.amount ?? 0}€</p>

          <AppButton
            uxType="link"
            onClick={handleNewProductSelection}
            className="addField"
            icon={<PlusOutlined />}
          >
            Aggiungi prodotto
          </AppButton>

          <p className="total">Totale: {getTotal()}€</p>
        </div>
      </div>
    );
  }

  function renderGeneralInformation() {
    return (
      <div className="information">
        <p className="tab-title">Informazioni generali</p>
        <div className="input-container">
          <AppInput
            label="Codice"
            placeholder="Codice"
            value={form?.code ?? ""}
            onChange={update("code")}
          />
          <AppInput
            label="Descrizione"
            placeholder="Descrizione"
            value={form?.description ?? ""}
            onChange={update("description")}
          />
          <AppInput
            label="Descrizione estesa"
            placeholder="Descrizione estesa"
            value={form?.extendedDescription ?? ""}
            onChange={update("extendedDescription")}
          />
          <AppInput
            label="UM"
            placeholder="um"
            value={form?.um ?? ""}
            onChange={update("um")}
          />
          <AppInput
            label="Famiglia"
            placeholder="Famiglia"
            value={form?.family ?? ""}
            onChange={update("family")}
          />
          <AppInput
            label="Descrizone famiglia"
            placeholder="Descrizone famiglia"
            value={form?.familyDescription ?? ""}
            onChange={update("familyDescription")}
          />
          <AppInput
            label="Sottofamiglia"
            placeholder="Sottofamiglia"
            value={form?.subFamily ?? ""}
            onChange={update("subFamily")}
          />
          <AppInput
            label="Descrizone sottofamiglia"
            placeholder="Descrizone sottofamiglia"
            value={form?.subFamilyDescription ?? ""}
            onChange={update("subFamilyDescription")}
          />
          <AppInput
            label="Stato"
            placeholder="Stato"
            value={form?.state ?? ""}
            onChange={update("state")}
          />
          <AppInput
            label="Prezzo unitario (€)"
            placeholder=""
            value={
              form?.unitPrice ? form?.unitPrice : form.amount ? form.amount : ""
            }
            onChange={(value) => update("unitPrice")(value.replace(",", "."))}
          />
          <AppSelect
            label="Fornitori"
            placeholder="Fornitori"
            value={form?.suppliers ?? ""}
            onChange={update("suppliers")}
            className="app-select"
            mode="multiple"
            allowClear
          >
            {companies?.rows &&
              companies.rows?.map((company, index) => (
                <Option key={company.id} id={index}>
                  {company.businessName}
                </Option>
              ))}
          </AppSelect>
          <AppSelect
            label="Aliquota"
            placeholder="Aliquota"
            value={
              taxRates?.rows?.find(
                (taxRate) => taxRate.id === (form?.taxRate?.id ?? form?.taxRate)
              )?.name ?? ""
            }
            onSelect={update("taxRate")}
            className="app-select"
          >
            {taxRates &&
              taxRates?.rows?.map((taxRate, index) => (
                <Option key={taxRate.id} id={index}>
                  {taxRate.name}
                </Option>
              ))}
          </AppSelect>
          {/* {form?.customFields?.map((customField, index) => (
                        <CustomField
                            key={customField.key}
                            id={customField.key}
                            onChange={onChange}
                            onCancel={(e, key) => handleDeleteCustomField(e,key)}
                            customFields={customFields?.rows}
                            givenCustomField={
                                customField.customField ? customField.customField : null
                            }
                            givenValue={customField.value ? customField.value : null}
                        />
                    ))}
                    <AppButton
                        uxType="link"
                        onClick={handleNewField}
                        className="addField"
                        icon={<PlusOutlined />}
                    >
                        Aggiungi campo personalizzato
                    </AppButton> */}
        </div>
      </div>
    );
  }

  return (
    <div className={className}>
      <div className="leftSide">
        <div className="title" ref={divRef}>
          <p className="businessName">{form?.code ?? ""}</p>
          <Tabs defaultActiveKey="1">
            <TabPane tab="Generale" key="1">
              {renderGeneralInformation()}
            </TabPane>
            <TabPane tab="Componenti" key="2">
              {renderComponentsTab()}
            </TabPane>
          </Tabs>
          <div className="actionFooter">
            <AppButton
              uxType="tertiary"
              className="actionBtn"
              onClick={onCancel}
            >
              Annulla
            </AppButton>
            <AppButton uxType="" className="actionBtn" onClick={onConfirm}>
              Conferma
            </AppButton>
          </div>
        </div>
      </div>
      {/* <div className="center">

            </div>
            <div className="rightSide">

            </div> */}
    </div>
  );
}

// ----------------------------------------------------------------------------

const StyledProductData = styled(ProductData)`
  & {
    width: inherit;
    min-width: 240px;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    .leftSide {
      min-width: 200px;
      width: 100%;
      height: inherit;
      padding: 10px 10px;
      padding-bottom: 0px;
      //border-right: 1px solid ${({ theme }) => theme.colors.grey200};

      .title {
        padding: 10px 10px;
        height: inherit;
        display: flex;
        flex-direction: column;
        width: 100%;

        .businessName {
          font-size: 20px;
        }
        .ant-tabs {
          height: inherit;
          .ant-tabs-content-holder {
            height: inherit;

            .ant-tabs-content {
              height: inherit;
            }
          }
        }
      }
      .information {
        width: 100%;
        padding-top: 10px;
        padding-left: 10px;
        height: 95%;
        overflow: scroll;

        .tab-title {
          font-size: 18px;
        }
        .input-container {
          width: 40%;
          min-width: 240px;
        }
        .app-select {
          width: 100%;
        }
      }
      .actionFooter {
        height: 50px;
        width: 100%;
        padding: 20px;
        box-sizing: content-box;
        background-color: ${({ theme }) => theme.colors.light};
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        border-radius: 16px;
        margin-left: -20px;
        z-index: 100;
        .actionBtn {
          margin-right: 20px;
        }
      }
      .addField {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-start;
        margin: auto;
        margin-top: 10px;
        padding: 5px;
        border: none;
        .icon {
          margin: auto;
          margin-right: 10px;
          height: 14px;
          width: 30px;
        }
        .center {
          min-width: 0px;
        }
      }
    }
    .center {
      width: 50%;
      min-width: 400px;
      height: inherit;
    }
    .rightSide {
      min-width: 200px;
      width: 25%;
      height: inherit;
      border-left: 1px solid ${({ theme }) => theme.colors.grey200};
    }
  }
`;
export default StyledProductData;
