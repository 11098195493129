import { configureStore, combineReducers } from "@reduxjs/toolkit"
import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"

import authSliceReducer from "../slices/authSlice"

// ----------------------------------------------------------------------------

const reducers = combineReducers({
	auth: authSliceReducer,
})

const persistConfig = {
	key: "giarvis",
	storage,
}

const persistedReducer = persistReducer(persistConfig, reducers)

const store = configureStore({
	reducer: persistedReducer,
})

const persistor = persistStore(store)

// ----------------------------------------------------------------------------

export { persistor }
export default store
