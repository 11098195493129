import React from "react";
import styled from "styled-components";
import { message } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import AgentRightSheet from "./AgentRightSheet";

import Avatar from "../../../_shared/components/Avatar";
import Table from "../../../_shared/components/Table";
import { ReactComponent as Delete } from "../../../_shared/assets/icons/delete.svg";
import PopConfirm from "../../../_shared/components/PopConfirm";
import usePaginatedRestResource from "../../../_shared/hooks/usePaginatedRestResource";

import * as api from "../../../../api";

// ----------------------------------------------------------------------------

function Agents(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props;
    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const user = useSelector((state) => (state?.auth.user ? state.auth : null));
    const [searched, setSearched] = useState("");

    const [
        agents,
        ,
        { pagination, count, filters: currentFilters },
        {
            fetchResource: fetchAgents,
            onChange: handleTableChange,
            onFiltersChange,
        },
    ] = usePaginatedRestResource("/agents", user.token, {
        defaultFilters: {
            organization: user.organization,
        },
    });

    const [selectedAgent, setSelectedAgent] = useState();

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    function getColumns() {
        return [
            {
                title: "NOME",
                dataIndex: "fullName",
                key: "fullName",
                sorter: (a, b) => a.fullName.localeCompare(b.fullName),
                render: (text, record, index) => {
                    const names = text.split(" ");
                    return (
                        <div className="row">
                            <Avatar
                                name={
                                    names[0].charAt(0) +
                                    (names[1] ? names[1].charAt(0) : "")
                                }
                            />
                            <span className="agentLink">
                                <Link to={`${record.id}`}>{text}</Link>
                            </span>
                        </div>
                    );
                },
            },
            {
                title: "E-MAIL",
                dataIndex: "email",
                key: "email",
            },
            {
                title: "NUMERO DI TELEFONO",
                dataIndex: "phone",
                key: "phone",
            },
            {
                title: "AZIENDA",
                dataIndex: "company",
                key: "company",
                render: (text) => (
                    <div className="row">
                        {text && (
                            <Avatar
                                name={text ? text.businessName.charAt(0) : ""}
                            />
                        )}
                        <span>{text ? text.businessName : ""}</span>
                    </div>
                ),
            },
            {
                title: "PROVVIGIONI",
                dataIndex: "commissionPercentage",
                key: "commissionPercentage",
                render: (text) => `${text}%`,
            },
            {
                title: "",
                dataIndex: "delete",
                key: "delete",
                render: (text, record, index) => (
                    <PopConfirm
                        className={className}
                        title="Sei sicuro di voler rimuovere questo agente?"
                        onConfirm={(e) => handleDeleteAgent(e, record.id)}
                        onCancel={(e) => e.stopPropagation()}
                        okText="Sì"
                        cancelText="No"
                        placement="leftTop"
                    >
                        <div
                            className="delete"
                            onClick={(e) => e.stopPropagation()}
                        >
                            <Delete />
                        </div>
                    </PopConfirm>
                ),
            },
        ];
    }
    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function handleSearch() {
        onFiltersChange({
            name: searched,
            email: searched,
        });
    }
    function handleChangeSearch(value) {
        if (value.length === 0 && searched.length > value.length) {
            onFiltersChange({
                name: value,
                email: value,
            });
        } else {
            setSearched(value);
        }
    }

    function getTableFilters() {
        return [
            {
                key: "email",
                type: "text",
                value: currentFilters.email,
                onSearch: (value) =>
                    onFiltersChange({
                        email: value,
                    }),
            },
        ];
    }

    function handleNewAgent() {
        setSelectedAgent({});
    }

    async function handleDeleteAgent(e, agentId) {
        e.stopPropagation();
        try {
            await api.deleteAgent(agentId, user.token);

            fetchAgents();
        } catch (error) {
            console.log("[ERROR] in delete agent: ");
            console.log(error);
        }
    }

    function onCancel() {
        setSelectedAgent(null);
    }

    async function onConfirm(values) {
        console.log(values);
        try {
            !values?.organization
                ? (values.organization = user.organization)
                : (values.organization = values.organization.id);

            if (values?.company) values.company = values.company.id;
            if (!!values?.order?.id) {
                values.order = values?.order?.id;
            }
            values?.id
                ? await api.updateAgent(values.id, values, user.token)
                : await api.createAgent(values, user.token);

            fetchAgents();
            message.success("Operazione eseguita con successo");
            setSelectedAgent(null);
        } catch (error) {
            console.log("[ERROR] in confirm agent: ");
            console.log(error);
            message.error("Errore durante l'operazione");
        }
    }

    return (
        <Table
            className={className}
            columns={getColumns()}
            onResourceCreate={() => selectedAgent({})}
            dataSource={agents}
            onChange={handleTableChange}
            rowSelection={{ type: "checkbox" }}
            addAgent="true"
            handleOnRow={(row) => setSelectedAgent(row)}
            renderTopBarCustomButtons={() => (
                <AgentRightSheet
                    open={!!selectedAgent}
                    defaultValues={selectedAgent}
                    onConfirm={onConfirm}
                    onCancel={onCancel}
                    onButtonClick={handleNewAgent}
                />
            )}
            filters={getTableFilters()}
            search
            handleSearchSubmit={handleSearch}
            handleOnChangeSearch={handleChangeSearch}
        />
    );
}

// ----------------------------------------------------------------------------

const StyledAgents = styled(Agents)`
    & {
        .row {
            display: flex;
            flex-direction: row;
            align-items: center;

            span {
                margin-left: 8px;
            }
        }
        .app-select {
            min-width: 280px;
        }
        .agentLink {
            text-decoration: underline;
        }
        .form {
            min-width: 280px;
        }
        .add-field {
            display: flex;
            flex-direction: row-reverse;
            justify-content: center;
            margin: auto;
            margin-top: 20px;
            padding: 5px;
            border: none;
            .icon {
                margin: auto;
                margin-right: 10px;
                height: 14px;
            }
        }
    }
`;
export default StyledAgents;
