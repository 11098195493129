import React, { useRef } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Popconfirm as AntdPopconfirm } from "antd"

//------------------------------------------------------------------------------

function Popconfirm(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { children, ...popconfirmProps } = props

    // -------------------------------------
    // Hooks (e.g. useState, useMemo ...)
    // -------------------------------------

    const containerRef = useRef()

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={props.className} ref={containerRef}>
            <AntdPopconfirm
                {...popconfirmProps}
                getPopupContainer={(ref) => (containerRef.current = ref)}
            >
                {children}
            </AntdPopconfirm>
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

Popconfirm.propTypes = {
    className: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
}

Popconfirm.defaultProps = {}

// ----------------------------------------------------------------------------

const StyledPopconfirm = styled(Popconfirm)`
    & {
        .ant-btn-primary {
            border-color: ${(props) => props.theme.colors.error};
            background-color: ${(props) => props.theme.colors.error};
        }
    }
`
// ----------------------------------------------------------------------------

export default StyledPopconfirm
