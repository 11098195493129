import React from "react"
import PropTypes from "prop-types"
import styled, { useTheme } from "styled-components"
import { CalendarOutlined, SearchOutlined } from "@ant-design/icons"

// ----------------------------------------------------------------------------

const ICON_TYPE_MAP = {
    calendar: CalendarOutlined,
    search: SearchOutlined,
}

// ----------------------------------------------------------------------------

function FilterIcon(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { filtered, type, ...restProps } = props

    // -------------------------------------
    // Hooks (e.g. useState, useMemo ...)
    // -------------------------------------

    const theme = useTheme()

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    const IconComponent = ICON_TYPE_MAP[type]

    if (IconComponent) {
        return (
            <IconComponent
                {...restProps}
                style={{
                    color: filtered ? theme?.colors?.accentColor : "#aaa",
                }}
            />
        )
    }
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

FilterIcon.propTypes = {
    className: PropTypes.string.isRequired,
}

FilterIcon.defaultProps = {}

// ----------------------------------------------------------------------------

const StyledFilterIcon = styled(FilterIcon)`
    & {
    }
`
// ----------------------------------------------------------------------------

export default StyledFilterIcon
