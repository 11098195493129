import { message, Select } from "antd";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import InvoiceRightSheet from "./InvoiceRightSheet";

import { ReactComponent as Delete } from "../../../_shared/assets/icons/delete.svg";
import Avatar from "../../../_shared/components/Avatar";
import PopConfirm from "../../../_shared/components/PopConfirm";
import Table from "../../../_shared/components/Table";
import usePaginatedRestResource from "../../../_shared/hooks/usePaginatedRestResource";

import { Link } from "react-router-dom";
import * as api from "../../../../api";

// ----------------------------------------------------------------------------

function Invoices(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { className } = props;
  const { Option } = Select;
  // -------------------------------------
  // Hooks (e.g. useState, ...)
  // -------------------------------------
  const user = useSelector((state) => (state?.auth.user ? state.auth : null));
  const navigate = useNavigate();
  const [
    invoices,
    ,
    { pagination, count, filters },
    {
      fetchResource: fetchInvoices,
      onChange: handleTableChange,
      onFiltersChange,
    },
  ] = usePaginatedRestResource("/invoices", user.token, {
    defaultFilters: {
      organization: user.organization,
    },
  });

  const [selectedInvoice, setSelectedInvoice] = useState();

  // -------------------------------------
  // Memoized values
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  function getColumns() {
    return [
      {
        title: "NUMERO FATTURA",
        dataIndex: "code",
        key: "code",
        render: (text, record, index) => (
          <span className="invoiceLink">
            <Link to={`${record.id}`}>{text}</Link>
          </span>
        ),
      },
      {
        title: "DESTINATARIO",
        dataIndex: "recipient",
        key: "recipient",
        render: (text) => (
          <div className="row">
            <Avatar
              name={text?.businessName?.charAt(0) ?? text?.name?.charAt(0)}
            />
            <span> {text?.businessName ?? text?.name}</span>
          </div>
        ),
      },
      {
        title: "DATA",
        dataIndex: "date",
        key: "date",
        render: (text) => <span>{dayjs(text).format("DD/MM/YYYY")}</span>,
      },
      {
        title: "",
        dataIndex: "delete",
        key: "delete",
        render: (text, record, index) => (
          <PopConfirm
            className={className}
            title="Sei sicuro di voler rimuovere questo ordine?"
            onConfirm={(e) => deleteInvoice(e, record.id)}
            onCancel={(e) => e.stopPropagation()}
            okText="Sì"
            cancelText="No"
            placement="leftTop"
          >
            <div className="delete" onClick={(e) => e.stopPropagation()}>
              <Delete />
            </div>
          </PopConfirm>
        ),
      },
    ];
  }

  function onCancel() {
    setSelectedInvoice(null);
  }

  async function deleteInvoice(e, invoiceId) {
    e.stopPropagation();
    try {
      await api.deleteInvoice(invoiceId, user.token);

      fetchInvoices();
    } catch (error) {
      console.log("[ERROR] in delete invoice: ");
      console.log(error);
    }
  }

  async function onConfirm(values) {
    try {
      if (!values?.number)
        values.number = String(maxNumber() + 1).padStart(4, "0");

      !values?.organization
        ? (values.organization = user.organization)
        : (values.organization = values.organization.id);

      if (values?.user) values.user = values.user.id;

      values?.id
        ? await api.updateInvoice(values.id, values, user.token)
        : await api.createInvoice(user.organization, values, user.token);

      fetchInvoices();
      message.success("Operazione eseguita con successo");
      setSelectedInvoice(null);
    } catch (error) {
      console.log("[ERROR] in confirm invoice: ");
      console.log(error);
      message.error("Errore durante l'operazione");
    }
  }

  function maxNumber() {
    if (invoices.length === 0) return 0;
    return Math.max(...invoices.map((invoice) => parseInt(invoice.number)));
  }

  return (
    <Table
      className={className}
      columns={getColumns()}
      onResourceCreate={() => setSelectedInvoice({})}
      dataSource={invoices}
      rowSelection={{ type: "checkbox" }}
      addContact="true"
      handleOnRow={(row) => navigate(`${row.id}`)}
      renderTopBarCustomButtons={() => (
        <InvoiceRightSheet
          open={!!selectedInvoice}
          defaultValues={selectedInvoice}
          onConfirm={onConfirm}
          onCancel={onCancel}
        />
      )}
    />
  );
}

// ----------------------------------------------------------------------------

const StyledInvoices = styled(Invoices)`
  & {
    .row {
      display: flex;
      flex-direction: row;
      align-items: center;

      span {
        margin-left: 8px;
      }
    }
    .invoiceLink {
      text-decoration: underline;
    }
    .add-field {
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
      margin: auto;
      margin-top: 10px;
      padding: 5px;
      border: none;
      .icon {
        margin: auto;
        margin-right: 10px;
        height: 14px;
      }
    }
  }
`;
export default StyledInvoices;
