import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { PlusOutlined } from "@ant-design/icons";
import { InputNumber, Select } from "antd";

import AppButton from "../../../../_shared/components/AppButton";
import RightSheet from "../../../../_shared/components/Drawer";
import AppInput from "../../../../_shared/components/AppInput";
import useForm from "../../../../_shared/hooks/useForm";
import AppSelect from "../../../../_shared/components/AppSelect";
import CustomField from "../../../../_shared/components/CustomField";
import useRestResource from "../../../../_shared/hooks/useRestResource";
import FieldLabel from "../../../../_shared/components/FieldLabel";
import { ReactComponent as Plus } from "../../../../_shared/assets/icons/plus.svg";

function AgentRightSheet(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { className, open, defaultValues, onConfirm, onCancel, onButtonClick } =
    props;

  // -------------------------------------
  // Hooks (e.g. useState, ...)
  // -------------------------------------

  const [form, , setMany, clearForm] = useForm();
  const user = useSelector((state) => (state?.auth.user ? state.auth : null));
  const [select, setSelect] = useState("order");
  // const [customFields] = useRestResource(
  //     "/custom-fields",
  //     "",
  //     user.token,
  //     {organization: user.organization}
  // )

  const [orders] = useRestResource("/orders", "", user.token, {
    organization: user.organization,
  });
  const [deals] = useRestResource("/deals", "", user.token, {
    organization: user.organization,
  });
  const { Option } = Select;

  // -------------------------------------
  // Memoized values
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  useEffect(() => {
    !open && clearForm();
    // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    defaultValues && setMany(defaultValues);
    if (!!defaultValues?.order) {
      setSelect("order");
    }
    if (!!defaultValues?.deal) {
      setSelect("deal");
    }
    // eslint-disable-next-line
  }, [defaultValues]);

  // -------------------------------------
  // Component functions
  // -------------------------------------
  function update(field) {
    return (value) => {
      setMany({
        ...form,
        [field]: value,
      });
    };
  }

  function handleConfirm() {
    // form?.customFields?.forEach((usedCustomField) =>
    //     checkValueType(usedCustomField)
    // );
    if (select === "order") {
      form.deal = null;
    }

    if (select === "deal") {
      form.order = null;
    }
    onConfirm && onConfirm(form);
  }

  function calculateCommission() {
    if (!!form?.commissionPercentage) {
      let id, orderAmount;
      if (select === "order") {
        if (!!form?.order?.id) {
          id = form?.order?.id;
        } else {
          id = form?.order;
        }
        orderAmount = orders?.rows?.find((order) => order.id === id)?.amount;
      }
      if (select === "deal") {
        if (!!form?.deal?.id) {
          id = form?.deal?.id;
        } else {
          id = form?.deal;
        }
        orderAmount = deals?.rows?.find((deal) => deal.id === id)?.amount;
      }
      console.log(orderAmount);
      return ((orderAmount * form?.commissionPercentage) / 100).toFixed(2);
    }
    return "";
  }

  // function handleNewField(e) {
  // 	e.preventDefault()
  // 	const newCustomFields = [
  // 		...form?.customFields ?? [],
  // 		{ key: Math.floor(Math.random() * 99999), customField: "", value: "" },
  // 	]
  // 	update("customFields")(newCustomFields)
  // }

  // function handleDeleteCustomField(e, key) {
  // 	e.preventDefault()
  // 	const newCustomFields = form?.customFields.filter(
  // 		(customField) => customField.key !== key
  // 	)
  // 	update("customFields")(newCustomFields)
  // }

  // function onChange(customFieldId, value, key) {
  // 	const customField = form?.customFields.find(
  // 		(customField) => customField.key === key
  // 	)
  // 	customField.customField = customFieldId
  // 	customField.value = value
  // }

  // function checkValueType(customField) {
  //     const originalCustomField = customFields.find(
  //         (originalField) => originalField.id === customField.customField
  //     );
  //     if (originalCustomField.type === "number") {
  //         const value = parseFloat(customField.value);
  //         customField.value = null;
  //         customField.value = value;
  //     }
  //     if (originalCustomField.type === "boolean") {
  //         const value =
  //             customField.value !== "" && customField.value !== "false"
  //                 ? true
  //                 : false;
  //         customField.value = null;
  //         customField.value = value;
  //     }
  // }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <>
      <AppButton className="add-btn" onClick={onButtonClick}>
        Crea nuovo agente
        <Plus width="14" height="13" style={{ marginLeft: 2 }} />
      </AppButton>
      <RightSheet
        open={open}
        onCancel={onCancel}
        onConfirm={handleConfirm}
        title={defaultValues?.id ? "Modifica agente" : "Crea agente"}
        confirmButtonLabel={
          defaultValues?.id ? "Modifica agente" : "Crea agente"
        }
        cancelButtonLabel="Annulla"
        className={`${className}`}
      >
        <form className="form">
          <AppInput
            label="E-mail"
            placeholder="E-mail"
            value={form?.email ?? ""}
            onChange={update("email")}
          />
          <AppInput
            label="Nome"
            placeholder="Nome"
            value={form?.name ?? ""}
            onChange={update("name")}
          />
          <AppInput
            label="Cognome"
            placeholder="Cognome"
            value={form?.surname ?? ""}
            onChange={update("surname")}
          />
          <AppInput
            label="Numero di telefono"
            placeholder="Numero"
            value={form?.phone ?? ""}
            onChange={update("phone")}
          />
          <br />
          <FieldLabel>Percentuale provvigioni</FieldLabel>
          <InputNumber
            value={form?.commissionPercentage ?? ""}
            onChange={update("commissionPercentage")}
            className="inputPerc"
            precision={2}
            step={0.5}
            formatter={(value) => `${value}%`}
            parser={(value) => value?.replace("%", "")}
          />

          {/* {form?.customFields?.map((customField, index) => (
                        <CustomField
                            key={customField.key}
                            id={customField.key}
                            onChange={onChange}
                            onCancel={(e, key) => handleDeleteCustomField(e,key)}
                            customFields={customFields?.rows}
                            givenCustomField={
                                customField.customField ?? null
                            }
                            givenValue={customField.value ?? null}
                        />
                    ))} */}
          {/* <AppButton
                        uxType="link"
                        onClick={handleNewField}
                        className="add-field"
                        icon={<PlusOutlined />}
                    >
                        Aggiungi campo personalizzato
                    </AppButton> */}
        </form>
      </RightSheet>
    </>
  );
}
// ----------------------------------------------------------------------------

const StyledAgentRightSheet = styled(AgentRightSheet)`
  & {
    .inputPerc {
      width: inherit;
    }
  }
`;
export default StyledAgentRightSheet;
