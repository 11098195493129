import React from "react";
import styled from "styled-components";
import { Select } from "antd";
import FieldLabel from "../FieldLabel";

function _AppText(props) {
  const { className, field, value } = props;

  return (
    <div className={className}>
      <div className="field">{field}</div>
      {value && <div className="value">{value}</div>}
    </div>
  );
}

const AppText = styled(_AppText)`
  & {
    margin-top: 20px;
    width: inherit;

    .field {
      width: 100%;
      padding: 5px;
      font-size: 20px;
      border-radius: 10px;
      background-image: linear-gradient(
        to left,
        rgba(240, 240, 240, 0),
        rgba(265, 140, 0, 0.8)
      );
    }
    .value {
      padding: 10px;
      min-width: inherit;
      font-size: 15px;
      width: inherit;
      height: 43px;
    }
  }
`;
export default AppText;
