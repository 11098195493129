import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import LoginBackground from "../../../assets/background_images/bg_logIn.png"
import RecoverBackground from "../../../assets/background_images/bg_recover.png"
import SignUpBackground from "../../../assets/background_images/bg_signUp.png"

function _LogImage (props) {

    const { className, page} = props


    function renderImage(){
        switch(page){
            case 'login': return <img src={LoginBackground}></img>
            case 'signUp': return <img src={SignUpBackground}></img>
            case 'recover': return <img src={RecoverBackground}></img>
            default: return <img src={LoginBackground}></img> 
        }
    }
    return (
            <div className={`${className}`}>
                {renderImage()}
            </div>
    )
}
_LogImage.propTypes = {
    className: PropTypes.string.isRequired
}

_LogImage.defaultProps = {}

const LogImage = styled(_LogImage) `
    & {
        height:100vh;
        img{
            height:100vh;
            width: 50vw;
            object-position: right;
            object-fit: cover;
        }
    }
`
export default LogImage