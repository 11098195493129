import React, { useState, useRef, useEffect } from "react"
import styled from "styled-components"
import { useDispatch, useSelector } from "react-redux"
import { Routes, Route, Outlet, Link } from "react-router-dom"
import { Dropdown, Menu } from "antd"

import { logout } from "../../../redux/slices/authSlice"
import * as api from "../../../api"

// import { ReactComponent as Bag } from "../../../_shared/assets/icons/bag.svg"
// import { ReactComponent as Chart } from "../../../_shared/assets/icons/chart.svg"
// import { ReactComponent as Users } from "../../../_shared/assets/icons/users.svg"
import { ReactComponent as ProjectsIcon } from "../../_shared/assets/icons/projects.svg"
import { ReactComponent as Document } from "../../_shared/assets/icons/document.svg"
// import { ReactComponent as Paper } from "../../../_shared/assets/icons/paper.svg"
// import { ReactComponent as Calendar } from "../../../_shared/assets/icons/calendar.svg"

import Notifications from "../../_shared/components/Notifications"
import ShadowedCard from "../../_shared/components/ShadowedCard"

import { ReactComponent as Wallet } from "../../_shared/assets/icons/wallet.svg"
import { ReactComponent as Dash } from "../../_shared/assets/icons/dash.svg"
import { ReactComponent as Exit } from "../../_shared/assets/icons/exit.svg"
import UserCard from "../../_shared/components/UserCard"

import Sider from "./DashItems/Sider"
import SearchList from "./DashItems/SearchList"
import SearchBox from "./DashItems/SearchBox"
import Contacts from "./Contacts"
import Companies from "./Companies"
import Deals from "./Deals"
import Products from "./Products"
import Invoices from "./Invoices"
import Orders from "./Orders"
import DeliveryNotes from "./DeliveryNotes"
import OrganizationSettings from "./OrganizationSettings"
import CompanyData from "./Companies/companyData"
import ContactData from "./Contacts/ContactData"
import DealData from "./Deals/DealData"
import ProductData from "./Products/ProductData"
import OrderData from "./Orders/OrderData"
import DeliveryData from "./DeliveryNotes/DeliveryData"
import Projects from "./Projects"
import Agents from "./Agents"
import AgentData from "./Agents/AgentData"

import Tickets from "./Tickets"

import ProjectData from "./Projects/ProjectData"
import Task from "./Task"

import Chat from "./Chats"

// ----------------------------------------------------------------------------

function LoggedApp(props) {
	// -------------------------------------
	// Props destructuring
	// -------------------------------------

	const { className } = props
	const [notification, setNotification] = useState(false)
	const [searchResults, setSearchResults] = useState(null)
	const [drop, setDrop] = useState(false)
	const box = useRef(null)
	const searchRef = useRef(null)
	const bell = useRef(null)

	const user = useSelector((state) => (state?.auth.user ? state.auth : null))

	const dispatch = useDispatch()
	const userName = {
		name: user.user.split(" ")[0],
		surname: user.user.split(" ")[1],
	}

	useEffect(() => {
		// Function for click event
		function handleOutsideClick(event) {
			if (
				box.current &&
				!box.current.contains(event.target) &&
				bell.current &&
				!bell.current.contains(event.target)
			) {
				setNotification(false)
			}
			if (searchRef.current && !searchRef.current.contains(event.target))
				setSearchResults(null)
		}

		// Adding click event listener
		document.addEventListener("click", handleOutsideClick)
		return () => document.removeEventListener("click", handleOutsideClick)
	}, [box, bell])

	const elems = [
		{ name: "CRM", tag: <Dash className="icon" /> },
		// {name: "Fatture",tag: <Wallet className="icon"/>},
		// {name:"E-commerce",tag:<Bag className="icon"/>},
		// {name:"Pages",tag:<Chart className="icon"/>},
		// {name:"Users",tag:<Users className="icon"/>},
		// {name:"Invoices",tag:<Wallet className="icon"/>},
		{ name: "Progetti", tag: <Document className="icon" /> },
		// {name:"Tasks",tag:<Paper className="icon"/>},
		// {name:"Calendar",tag:<Calendar className="icon"/>}
	]

	const activities = [
		{
			user: "Name Surname",
			action: "Comment",
			time: "2h",
			target: "UI Prototype",
			key: 0,
		},
		{
			user: "Name1 Surname1",
			action: "Comment",
			time: "11h",
			target: "Figma Project",
			key: 1,
		},
		{
			user: "Name2 Surname2",
			action: "Share",
			time: "3h",
			target: "UI Prototype",
			key: 2,
		},
		{
			user: "Name3 Surname3",
			action: "Message",
			time: "1h",
			target: "",
			key: 3,
		},
		{
			user: "Name4 Surname4",
			action: "Comment",
			time: "10h",
			target: "UX Design",
			key: 4,
		},
		{
			user: "Name5 Surname5",
			action: "Comment",
			time: "3h",
			target: "UI Prototype",
			key: 5,
		},
	]

	function handleNotificationClick(e) {
		e.preventDefault()
		setNotification(!notification)
	}

	function handleLogoutClick() {
		dispatch(logout())
	}

	async function handleSearchSubmit(search) {
		try {
			const response = await api.search(user.organization, search, user.token)
			setSearchResults(response)
		} catch (error) {
			console.log("[Error] in search: ", error)
		}
	}

	function handleOnChangeSearch(search) {
		if (search.length < 4) setSearchResults(null)
	}

	const logOut = (
		<Menu className="menu">
			{/* <Menu.Item className="item" key="1">
				<Link to="user-settings"> Profilo utente </Link>
			</Menu.Item> */}
			<Menu.Item className="item" key="2">
				<Link to="organizzazione"> Impostazioni organizzazione </Link>
			</Menu.Item>

			<Menu.Item className="item" key="3" onClick={handleLogoutClick}>
				Logout <Exit />
			</Menu.Item>
		</Menu>
	)

	return (
		<div className={`${className}`}>
			<div className="outerSide">
				<Sider routes={elems} />
				<Dropdown
					overlay={logOut}
					visible={drop}
					placement="top"
					getPopupContainer={(node) => node.parentElement}
					trigger="click"
					onVisibleChange={(visible) => setDrop(visible)}
					destroyPopupOnHide
				>
					<div className="sideFoot">
						<UserCard user={userName} />
					</div>
				</Dropdown>
			</div>

			<main className="box">
				<div className="header">
					{/* <SearchBox
						handleNotificationClick={handleNotificationClick}
						num={activities.length}
						refer={bell}
						handleSearchSubmit={handleSearchSubmit}
						handleOnChangeSearch={handleOnChangeSearch}
					/> */}
					{searchResults && (
						<SearchList data={searchResults} refer={searchRef} />
					)}
					{notification && (
						<Notifications activities={activities} refer={box} />
					)}
				</div>
				<ShadowedCard className="outlet">
					<Outlet />
					<Routes>
						<Route exaxt path="/" element={<Contacts />} />
						<Route path="/:contactId" element={<ContactData />} />
						<Route path="aziende" element={<Companies />} />
						<Route path="agenti" element={<Agents />} />
						<Route path="agenti/:agentId" element={<AgentData />} />
						<Route path="offerte" element={<Deals />} />
						<Route path="offerte/:dealId" element={<DealData />} />
						<Route path="prodotti" element={<Products />} />
						<Route path="prodotti/:productId" element={<ProductData />} />
						<Route path="ordini" element={<Orders />} />
						<Route path="ordini/:orderId" element={<OrderData />} />
						<Route path="ddt" element={<DeliveryNotes />} />
						<Route path="ddt/:ddtId" element={<DeliveryData />} />
						<Route path="nuova-fattura" element={<Invoices />} />
						<Route path="aziende/:companyId" element={<CompanyData />} />
						<Route path="organizzazione" element={<OrganizationSettings />} />
						<Route path="user-settings" element={<Companies />} />
						<Route path="i-tuoi-progetti" element={<Projects />} />
						<Route path="i-tuoi-ticket" element={<Tickets />} />

						<Route path="project/:projectId" element={<ProjectData />} />
						<Route path="task/:taskId" element={<Task />} />
						<Route path="chat/:chatId" element={<Chat />} />
					</Routes>
				</ShadowedCard>
			</main>
		</div>
	)
}

// ----------------------------------------------------------------------------

const StyledLoggedApp = styled(LoggedApp)`
	& {
		width: 100vw;
		height: 100vh;
		display: flex;
		flex-direction: row;
		overflow-y: auto;
		.icon {
			margin-top: 6px;
		}
		.box {
			width: calc(100% - 230px);
			height: 100%;
			display: flex;
			flex-direction: column;
			background-color: ${({ theme }) => theme.colors.boxGrey};
			padding: 0 40px;
			position: absolute;
			left: 230px;
			overflow: auto;
			.header {
				min-width: 400px;
				height: 80px;
				border: none;
			}
			.outlet {
				margin-bottom: 20px;
				height: 90%;
				min-width: 240px;
				overflow: scroll;
			}
		}

		.outerSide {
			width: 230px;
			height: 100vh;
			display: flex;
			flex-direction: column;
			border-right: 1px solid ${({ theme }) => theme.colors.grey200};
			position: fixed;

			.ant-dropdown {
				display: flex;
				justify-content: center;
				.menu {
					width: 200px;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: space-between;

					.item {
						width: inherit;

						span {
							display: flex;
							flex-direction: row;
							justify-content: space-between;
							align-items: center;
						}
					}
				}
			}
			.overlay {
				width: 100px;
			}

			.sideFoot {
				height: 9vh;
				min-height: 50px;
				width: 228px;
				background-color: white;
				display: flex;
				flex-direction: column;
				align-items: center;
			}
		}
	}
`
export default StyledLoggedApp
