import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import AppButton from "../../../../../../_shared/components/AppButton";
import RightSheet from "../../../../../../_shared/components/Drawer";
import AppInput from "../../../../../../_shared/components/AppInput";
import useForm from "../../../../../../_shared/hooks/useForm";
import useRestResource from "../../../../../../_shared/hooks/useRestResource";

function UserRightSheet(props) {
  // -------------------------------------
  // Props destructuringa
  // -------------------------------------

  const {
    className,
    open,
    openCreate,
    defaultValues,
    onConfirmInvite,

    onConfirmCreate,
    onCancel,
    onButtonClickInvite,
    onButtonClickCreate,
  } = props;

  // -------------------------------------
  // Hooks (e.g. useState, ...)
  // -------------------------------------

  const [form, , setMany, clearForm] = useForm();
  const user = useSelector((state) => (state?.auth.user ? state.auth : null));

  // -------------------------------------
  // Memoized values
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  useEffect(() => {
    !open && clearForm();
  }, [open]);

  useEffect(() => {
    defaultValues && setMany(defaultValues);
  }, [defaultValues]);

  // -------------------------------------
  // Component functions
  // -------------------------------------

  function update(field) {
    return (value) => {
      setMany({
        ...form,
        [field]: value,
      });
    };
  }

  function handleConfirm() {
    onConfirmInvite && onConfirmInvite(form);
  }

  function handleConfirmCreate() {
    onConfirmCreate && onConfirmCreate(form);
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <div className={className}>
      <div className="inRow">
        <AppButton className="add-btn" onClick={onButtonClickInvite}>
          Invita utente
        </AppButton>
        <RightSheet
          open={open}
          onCancel={onCancel}
          onConfirm={handleConfirm}
          title={"Invita utente"}
          confirmButtonLabel={"Invita utente"}
          cancelButtonLabel="Annulla"
          className={`${className}`}
        >
          <form className="form">
            <AppInput
              label="E-mail"
              placeholder="E-mail"
              value={form?.email ?? ""}
              onChange={update("email")}
            />
          </form>
        </RightSheet>

        <AppButton className="add-btn" onClick={onButtonClickCreate}>
          Crea utente
        </AppButton>
        <RightSheet
          open={openCreate}
          onCancel={onCancel}
          onConfirm={handleConfirmCreate}
          title={"Crea utente"}
          confirmButtonLabel={"Crea utente"}
          cancelButtonLabel="Annulla"
          className={`${className}`}
        >
          <form className="form">
            <AppInput
              label="Nome"
              placeholder="Nome"
              value={form?.name ?? ""}
              onChange={update("name")}
            />
            <AppInput
              label="Cognome"
              placeholder="Cognome"
              value={form?.surname ?? ""}
              onChange={update("surname")}
            />
            <AppInput
              label="E-mail"
              placeholder="E-mail"
              value={form?.email ?? ""}
              onChange={update("email")}
            />
          </form>
        </RightSheet>
      </div>
    </div>
  );
}
// ----------------------------------------------------------------------------

const StyledUserRightSheet = styled(UserRightSheet)`
  & {
    .form {
      min-width: 280px;
    }
    .app-select {
      min-width: 280px;
    }
    .inRow {
      display: flex;
      flex-direction: row;
    }
  }
`;
export default StyledUserRightSheet;
