import React, { useState, useEffect, Component } from "react";
import styled from "styled-components";
import { message, Tooltip, Progress, Popconfirm, Avatar } from "antd";
import { useSelector } from "react-redux";

import * as api from "../../../../../api";

import useForm from "../../../../_shared/hooks/useForm";
import { ReactComponent as Delete } from "../../../../_shared/assets/icons/delete.svg";
import { ReactComponent as Edit } from "../../../../_shared/assets/icons/edit.svg";
import { ReactComponent as TaskListIcon } from "../../../../_shared/assets/icons/tasklist.svg";

import priorityIcons from "../../../../_shared/constants/priorityIcons";

import TaskRightSheet from "./TaskRightSheet";
import { Switch } from "antd";
import projectStatus from "../../../../_shared/constants/projectStatus";
import useRestResource from "../../../../_shared/hooks/useRestResource";

// ----------------------------------------------------------------------------

function TaskListCard(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { className, taskList, onDelete, setSelectedTaskList, project } = props;

  // -------------------------------------
  // Hooks (e.g. useState, ...)
  // -------------------------------------
  const user = useSelector((state) => (state?.auth.user ? state.auth : null));

  const [form, , setMany, clearForm] = useForm();
  const [selectedTask, setSelectedTask] = useState();

  const [visibile, setVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [idToBeDeleted, setIdToBeDeleted] = useState();

  const [ticketsDone, setTicketsDone] = useState();
  const [filteredTicketsDone, setFilteredTicketsDone] = useState();

  const [ticketsTodo, setTicketsTodo] = useState();
  const [filteredTicketsTodo, setFilteredTicketsTodo] = useState();

  const [showDoneTickets, setShowDoneTickets] = useState();
  const [dragView, setDragView] = useState(false);

  const [users] = useRestResource("/users", "", user.token, {
    organizations: user.organization,
  });

  // -------------------------------------
  // Memoized values
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  const onChange = (checked) => {
    setShowDoneTickets(checked);
  };

  useEffect(() => {
    selectedTask && setMany(selectedTask);

    // eslint-disable-next-line
  }, [selectedTask]);

  useEffect(() => {
    if (selectedTask && JSON.stringify(form) === "{}") {
      setMany(selectedTask);
    }

    setTicketsTodo(
      form?.tasks?.filter(
        (task) => projectStatus[task.status] !== projectStatus.completed
      )
    );

    setTicketsDone(
      form?.tasks?.filter(
        (task) => projectStatus[task.status] === projectStatus.completed
      )
    );
  }, [form]);

  useEffect(() => {
    setMany(taskList);
  }, []);

  function handleNewTask(e) {
    e.stopPropagation();
    setSelectedTask({});
  }

  function handleModifyTaskList(e) {
    e.stopPropagation();
    setSelectedTaskList({});
  }

  async function handleDeleteTask(id) {
    const newTasks = form?.tasks?.filter((task) => task.id !== id);

    setMany({ ...form, tasks: newTasks });

    await api.deleteTask(id, user.token);
  }

  function getAvatarName(id) {
    const user = project?.team?.filter((user) => user.id === id);

    return user !== []
      ? user[0]?.name?.charAt(0)?.toUpperCase() +
          user[0]?.surname?.charAt(0)?.toUpperCase()
      : "";
  }

  async function onConfirm(values) {
    try {
      !values?.taskList
        ? (values.taskList = taskList.id)
        : (values.taskList = values.taskList.id);

      const body = {
        ...values,
      };

      let response = {};
      if (values?.id) {
        response = await api.updateTask(values.id, body, user.token);

        const newTasks = form?.tasks?.filter((task) => task.id !== values.id);

        const taskToModify = form?.tasks?.find((task) => task.id === values.id);
        const index = form.tasks.indexOf(taskToModify);

        newTasks.splice(index, 0, response);
        setMany({ ...form, tasks: newTasks });
      } else {
        response = await api.createTask(body, user.token);

        form.tasks.push(response);
        setTicketsTodo(
          form?.tasks?.filter(
            (task) => projectStatus[task.status] !== projectStatus.completed
          )
        );

        setTicketsDone(
          form?.tasks?.filter(
            (task) => projectStatus[task.status] === projectStatus.completed
          )
        );
      }
      setSelectedTask(null);

      message.success("Operazione eseguita con successo");
    } catch (error) {
      console.log("[Error] in confirm taskcard: ", error);

      message.error("Errore durante l'operazione");
    }
  }
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  // -------------------------------------
  function renderNormalView() {
    return (
      <div className={className}>
        <div className="taskList" onClick={() => setVisible(!visibile)}>
          <button
            className="hideButton"
            style={{
              fontWeight: visibile === true ? "bold" : "normal",
              fontSize: "20px",
            }}
          >
            <TaskListIcon className="icon"></TaskListIcon>

            {`${taskList?.name} `}
            <div
              style={{
                fontStyle: "italic",
                fontSize: "15px",
              }}
            >{`[${ticketsTodo?.length} tasks aperti]`}</div>
            <div
              style={{
                fontStyle: "italic",
                fontSize: "15px",
              }}
            >{`[${ticketsDone?.length} tasks chiusi]`}</div>
          </button>

          <div className="statistics">
            <div className="circleStyle">
              <div>Stato</div>
              <Progress
                type="circle"
                percent={Math.round(
                  (ticketsDone?.length / form?.tasks?.length) * 100
                )}
                width={60}
              />
            </div>
          </div>

          <div className="utilities">
            <Tooltip title="Aggiungi nuovo task">
              <div className="newtask" onClick={(e) => handleNewTask(e)}>
                +
              </div>
            </Tooltip>
            <Tooltip title="Modifica task list ">
              <div className="delete" onClick={(e) => handleModifyTaskList(e)}>
                <Edit />
              </div>
            </Tooltip>
            <Tooltip title="Elimina task list ">
              <div
                className="delete"
                onClick={(e) => onDelete(e, taskList?.id)}
              >
                <Delete />
              </div>
            </Tooltip>
          </div>
        </div>

        {visibile && (
          <div className="tasks">
            <ul className="container">
              {ticketsTodo?.map((task) => (
                <li>
                  <div>
                    <div className={"taskRow"}>
                      <div
                        className={
                          projectStatus[task.status] === projectStatus.completed
                            ? "taskCompleted"
                            : "task"
                        }
                        onClick={() => setSelectedTask(task)}
                      >
                        {" "}
                        {priorityIcons[task.priority]}
                        {task?.assigned
                          ? users && (
                              <Avatar>{getAvatarName(task.assigned)}</Avatar>
                            )
                          : ""}
                        {task.name} - {task.description}
                      </div>

                      <div>
                        <Popconfirm
                          placement="topLeft"
                          title={`Elimina il task -> ${task.name}`}
                          onConfirm={() => handleDeleteTask(task.id)}
                          onCancel={() => handleCancel()}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Delete
                            //type="primary"
                            onClick={() => {
                              setIsModalOpen(true);
                              setIdToBeDeleted(task.id);
                            }}
                            className="delete"
                          />
                        </Popconfirm>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
            <ul className="container">
              <Switch
                onChange={onChange}
                checkedChildren="Nascondi"
                unCheckedChildren="Mostra tickets chiusi"
              />{" "}
              {showDoneTickets === true &&
                ticketsDone?.map((task) => (
                  <li>
                    <div>
                      <div className={"taskRow"}>
                        <div
                          className="taskCompleted"
                          onClick={() => setSelectedTask(task)}
                        >
                          {priorityIcons[task.priority]}
                          {task.name} - {task.description}
                        </div>
                        <div>
                          <Popconfirm
                            placement="topLeft"
                            title={`Elimina il task -> ${task.name}`}
                            onConfirm={() => handleDeleteTask(task.id)}
                            onCancel={() => handleCancel()}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Delete
                              //type="primary"
                              onClick={() => {
                                setIsModalOpen(true);
                                setIdToBeDeleted(task.id);
                              }}
                              className="delete"
                            />
                          </Popconfirm>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
            </ul>
          </div>
        )}

        <TaskRightSheet
          open={!!selectedTask}
          onCancel={() => setSelectedTask(null)}
          defaultValues={selectedTask}
          onConfirm={onConfirm}
          team={project.team}
        />
      </div>
    );
  }

  return renderNormalView();
}
// ----------------------------------------------------------------------------

const StyledTaskListCard = styled(TaskListCard)`
  & {
    margin-bottom: 20px;
    .container {
      max-height: 270px;
      width: 400px;
      list-style-type: none;
      overflow-y: scroll;
    }
    .icon {
      height: 15px;
      width: 15px;
      // margin: 10px;
    }
    .tasks {
      padding: 10px;
      display: flex;
      flex-direction: row;
    }
    .completed {
      background-color: ${({ theme }) => theme.colors.success200};
    }

    .circleStyle {
      text-align: center;
    }
    .task {
      border-radius: 110px;
      padding: 2px;
      border-bottom: 1px solid orange;

      //font-size: 11px;
      width: 250px;
      padding-left: 10px;

      :hover {
        cursor: pointer;
        background-color: ${({ theme }) => theme.colors.neutral100};
      }
    }
    .taskCompleted {
      border-radius: 110px;
      padding: 5px;
      border-bottom: 1px solid orange;

      width: 250px;
      opacity: 0.3;
      //padding: 10px;

      :hover {
        cursor: pointer;
        background-color: ${({ theme }) => theme.colors.neutral100};
      }
    }
    .taskRow {
      display: flex;
      flex-direction: row;
      margin-top: 5px;
    }
    .taskList {
      background-color: rgba(240, 240, 240, 0.25);
      padding: 10px 5px;
      //height: 250px;
      border-radius: 20px;
      box-shadow: 1px 1px #ffa500;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      transition: 0.5s;
      border: 1px;

      :hover {
        cursor: pointer;
        // transform: rotateY(10deg);
        box-shadow: 3px 3px #ffa500;
        background-color: rgba(240, 240, 240, 0.8);
        color: black;
      }
      .newtask {
        width: 25px;
        height: 25px;
        background-color: ${({ theme }) => theme.colors.orange300};
        text-align: center;
        border-radius: 99px;

        :hover {
          cursor: pointer;
          background: ${({ theme }) => theme.colors.orangeGradient};
        }
      }
    }
    .form {
      min-width: 280px;
    }
    .utilities {
      display: flex;
      flex-direction: row;
      // background-color: red;
      padding: 10px;
      width: 30%;
      justify-content: space-around;
      //flex: 6%;
    }
    .statistics {
      width: 30%;
      // background-color: yellow;
      display: flex;
      justify-content: flex-start;
    }

    .delete {
      :hover {
        cursor: pointer;
      }
    }
    .hideButton {
      border: none;
      background: none;
      //background-color: green;
      width: 40%;
      text-align: left;
    }
  }
`;
// ----------------------------------------------------------------------------

export default StyledTaskListCard;
