import React, { useState, useEffect} from "react"
import styled from "styled-components"
import { Select } from "antd"

import AppButton from "../AppButton"
import AppSelect from "../AppSelect"
import AppInput from "../AppInput"

// ----------------------------------------------------------------------------

function CustomField (props) {

    // -------------------------------------
	// Props destructuring
	// -------------------------------------
   
    const { className, onCancel, id, onChange, customFields, givenCustomField, givenValue} = props
    const { Option } = Select

    // -------------------------------------
	// Hooks (e.g. useState, ...)
	// -------------------------------------
    const [ customField, setCustomField ] = useState(givenCustomField ? givenCustomField : "")
    const [ value, setValue ] = useState(givenValue ? givenValue : "")

    useEffect(() => {
        onChange(customField, value, id)
    })
    
    return (
        <div className={className}>
            <AppSelect
                customField="Seleziona campo personalizzato"
                placeholder="Campo"
                value={customField}
                onSelect={setCustomField}
                className="app-select-custom"
            >
            {customFields &&
                customFields.map((customField, index) => (
                    <Option key={customField.id} id={index}>
                        {customField.name}
                    </Option>
                ))}
            </AppSelect>
            <AppInput value={value} placeholder="value" onChange={setValue}/>
            <AppButton uxType="delete" className="deleteCustom" onClick={(e) => onCancel(e,id)}>{"Elimina"}</AppButton>
        </div>
    )
}

// ----------------------------------------------------------------------------

const StyledCustomField = styled(CustomField) `
    & {
        margin-top: 15px;
        padding:0px 15px;
        height: fit-content;
        border-radius: 5px;
        border: 3px solid ${({ theme }) => theme.colors.grey200};
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        :hover {
            border-left: 3px solid ${({ theme }) => theme.colors.button}
        }
        .customField {
            margin-top: 0px;
            .app-input {
                height: 25px;
                border: none;
                padding-left: 0;
                background-color: #f0f2f5;

                :hover {
                    background-color: ${({ theme }) => theme.colors.grey200};
                }
            }
        }
        .deleteCustom {
            margin-top: 10px;
            margin-bottom: 13px;
            padding:0 5px;
            height: 20px;
            min-width: 65px;
            width: fit-content
        }
        .app-select-custom {
            min-width: unset;
            width: 100%
        }

    }
`
export default StyledCustomField