import React from "react";
import { useDrag } from "react-dnd";
import styled from "styled-components";
import { ReactComponent as TaskListIcon } from "../../../../../_shared/assets/icons/tasklist.svg";
import projectStatus from "../../../../../_shared/constants/projectStatus";
import { Progress, Tooltip } from "antd";

function TaskListDD({ className, taskList }) {
  const [{ isDragging, opacity }, drag] = useDrag(() => ({
    type: "TaskListDD",
    item: { item: taskList, class: "TL" },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.1 : 1,
    }),
  }));
  return (
    <div className={className} ref={drag} style={{ opacity }}>
      <button className="taskList">
        <TaskListIcon className="icon"></TaskListIcon>
        <div className="name">
          {`${taskList.name} (${taskList.tasks.length}) `}
        </div>
        <Tooltip
          title={`Ticket totali : ${taskList.tasks.length} \n Completati : ${
            taskList.tasks.filter(
              (task) => projectStatus[task.status] === projectStatus.completed
            ).length
          }`}
        >
          <Progress
            className="iconStatus"
            type="circle"
            percent={Math.round(
              (taskList.tasks.filter(
                (task) => projectStatus[task.status] === projectStatus.completed
              ).length /
                taskList.tasks.length) *
                100
            )}
            width={30}
          />
        </Tooltip>
      </button>
    </div>
  );
}

const StyledTaskListDD = styled(TaskListDD)`
  & {
    .taskList {
      margin: 5px;
      background-color: rgba(240, 240, 240, 0.8);
      padding: 10px 20px;

      border-radius: 20px;
      box-shadow: 1px 1px #ffa500;
      display: flex;
      justify-content: flex-start;

      align-items: center;
      width: 90%;
      transition: 0.5s;
      border: 1px;

      :hover {
        cursor: pointer;

        box-shadow: 3px 3px #ffa500;
        background-color: rgba(240, 240, 240, 0.8);
        color: black;
      }
      .name {
        flex-grow: 4;

        text-align: left;
      }
      .iconStatus {
      }
    }
  }
`;

export default StyledTaskListDD;
