import React from "react";
import styled from "styled-components";
import { Select } from "antd";
import FieldLabel from "../FieldLabel";

function _AppMultiSelect(props) {
  const {
    className,
    label,
    placeholder,
    value,
    onChange,
    defaultValue,
    children,
  } = props;
  console.log(props);
  return (
    <div className={className}>
      {label && <FieldLabel>{label}</FieldLabel>}
      <Select
        mode="multiple"
        className="AppSelect"
        placeholder={placeholder && placeholder}
        defaultValue={defaultValue}
        value={value !== "" ? value : undefined}
        onChange={onChange}
      >
        {children}
      </Select>
    </div>
  );
}

const AppMultiSelect = styled(_AppMultiSelect)`
  & {
    margin-top: 20px;
    width: inherit;

    .AppSelect {
      min-width: inherit;
      width: inherit;
      min-height: 43px;

      .ant-select-selector {
        height: inherit;
        background-color: ${({ theme }) => theme.colors.light};
        border: 1px solid ${({ theme }) => theme.colors.grey};
        border-radius: 4px;
        //padding: 0px ${({ theme }) => theme.spacing.s}px;
        padding-top: 5px;
        .ant-select-selection-search-input {
          //padding: 0px ${({ theme }) => theme.spacing.xs}px;
          padding-top: 5px;
        }
      }
    }
  }
`;
export default AppMultiSelect;
