import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

function _Row (props) {

    const { className, children, uxType} = props

    function getThemeClassName() {
        switch (uxType) {
            case "footer":
                    return "footer"
            default:
                    return ""
        }
    }

    return (
            <div className={`${className} ${getThemeClassName()}`}>
                { children }
            </div>
    )
}
_Row.propTypes = {
    className: PropTypes.string.isRequired,
    children: PropTypes.node
}

_Row.defaultProps = {}

const Row = styled(_Row) `
    & {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: ${({ theme }) => theme.spacing.s}px;
        margin-top: ${({ theme }) => theme.spacing.s}px;

        .inner {
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.02em;
            color: ${({ theme }) => theme.spacing.dark};
            text-decoration: none;
        }
        span.outer {
            font-size: 14px;
            font-weight: normal;
            line-height: 21px;
            color:#98A9BC; 
            text-align: center;
            display: block;
        }
    }

    &.footer {
        width: 23vw;
        min-width:295px;
        margin-top:10vh;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        span .link {
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: #252631;
            text-decoration: none;
        }
    }
`
export default Row