import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"

import AppInput from "../../../../_shared/components/AppInput"
import AppButton from "../../../../_shared/components/AppButton"
import Heading from "../../../../_shared/components/LoginItems/Heading"
import SubHeading from "../../../../_shared/components/LoginItems/SubHeading"
import Row from "../../../../_shared/components/LoginItems/Row"

function OrganizationJoin (props) {

    const { className, refer, org } = props
    
    return (
        <div className={className} ref={refer}>
            
            <Heading>
                Accedi all'azienda
            </Heading>
            <SubHeading>
                Il tuo profilo è stato creato, ma l'azienda da te inserita esiste già. Richiedi l'accesso all'azienda per poter accedere al servizio.
            </SubHeading>
            
            <strong>AZIENDA: {org} </strong>
            
            <Row></Row>
        </div>
    )

}

const StyledOrganizationJoin = styled(OrganizationJoin) `
    & {
        
        .continueBtn {
            margin-top: ${({ theme }) => theme.spacing.s}px
        }
        
    }

`
export default StyledOrganizationJoin