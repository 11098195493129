import React, { useState } from "react";
import styled from "styled-components";
import Moment from "moment";

// ----------------------------------------------------------------------------

function ProjectModify(props) {
  const { className, project } = props;
  console.log("cio");
  //<div className={className}>stai modificando il progetto {project}</div>
}

// ----------------------------------------------------------------------------

const StyledProjectModify = styled(ProjectModify)`
  & {
    .boxProject {
      background-color: rgba(240, 240, 240, 0.35);
      // padding: 10px 5px;
      height: 180px;
      border-radius: 20px;
      box-shadow: 2px 2px #e9e9e9;
      width: 100%;
    }
    .title {
      font-size: 30px;
      padding: 5px;
    }
    .description {
      font-size: 14px;
      padding-left: 10px;
    }
    .customer {
      font-size: 12px;
      padding-left: 10px;
    }
    .lastUpdate {
      display: table-cell;
      font-size: 12px;

      padding-left: 1px;
    }
    .state {
      display: table-cell;
      font-size: 12px;
      text-align: right;
      padding-left: 10px;
    }
    .footer {
      display: table;
      //width: 100%; /*Optional*/
      table-layout: fixed; /*Optional*/
      border-spacing: 10px; /*Optional*/
    }
  }
`;
export default StyledProjectModify;
