import { Select } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import * as api from "../../../../api";
import FieldLabel from "../FieldLabel";
function SelectAutoComplete(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const {
        className,
        placeholder,
        onSearch,
        onChange,
        label,
        defaultValue,
        ...selectProps
    } = props;

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------
    const [data, setData] = useState([]);
    const [value, setValue] = useState();
    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    useEffect(() => {
        defaultValue && setValue(defaultValue);
    }, [defaultValue]);

    // -------------------------------------
    // Component functions
    // -------------------------------------

    async function handleSearch(newValue) {
        if (newValue.length > 0 && !!onSearch) {
            const response = await onSearch(newValue);
            setData(response);
        } else {
            setData([]);
        }
    }

    function handleChange(newValue) {
        setValue(newValue);
        onChange && onChange(newValue);
    }
    // -------------------------------------
    // Component local variables
    // -------------------------------------

    const { Option } = Select;

    // -------------------------------------

    return (
        <div className={className}>
            {label && <FieldLabel>{label}</FieldLabel>}
            <Select
                showSearch
                value={value}
                placeholder={placeholder}
                className="appSelect"
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={handleSearch}
                onChange={handleChange}
                notFoundContent={null}
                {...selectProps}
            >
                {data?.map((item) => (
                    <Option key={item.id}>{item.label}</Option>
                ))}
            </Select>
        </div>
    );
}
// ----------------------------------------------------------------------------

const StyledSelectAutoComplete = styled(SelectAutoComplete)`
    & {
        margin-top: 20px;
        width: inherit;

        .appSelect {
            min-width: inherit;
            width: inherit;
            height: 43px;

            .ant-select-selector {
                height: inherit;
                background-color: ${({ theme }) => theme.colors.light};
                border: 1px solid ${({ theme }) => theme.colors.grey};
                border-radius: 4px;
                padding: 0px ${({ theme }) => theme.spacing.s}px;
                padding-top: 5px;
                .ant-select-selection-search-input {
                    padding: 0px ${({ theme }) => theme.spacing.xs}px;
                    padding-top: 5px;
                }
            }
        }
    }
`;
export default StyledSelectAutoComplete;
