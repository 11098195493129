import React, { useEffect, useState } from "react"
import styled from "styled-components"
import TaskListDD from "./TaskListDD"
import MilestoneDD from "./MilestoneDD"
import TaskDD from "./TaskDD"
import AppInput from "../../../../_shared/components/AppInput"

import { ReactComponent as TaskIcon } from "../../../../_shared/assets/icons/task.svg"
import { ReactComponent as TaskList } from "../../../../_shared/assets/icons/tasklist.svg"

function DragDrop(props) {
	const { className, tasks, taskLists, milestones, setSelectedMilestone } =
		props

	const [taskFilter, setTaskFilter] = useState("")
	const [filteredTasks, setFilteredTasks] = useState()

	const [taskListFilter, setTaskListFilter] = useState("")
	const [filteredTaskLists, setFilteredTaskLists] = useState()

	useEffect(() => {
		const newTasks = taskLists.filter((taskList) =>
			taskList.name.toLowerCase().includes(taskListFilter)
		)

		setFilteredTaskLists(newTasks)

		// eslint-disable-next-line
	}, [taskListFilter])

	useEffect(() => {
		const newTasks = tasks.filter((task) =>
			task?.name?.toLowerCase().includes(taskFilter)
		)
		setFilteredTasks(newTasks)

		// eslint-disable-next-line
	}, [taskFilter])

	return (
		<div className={className}>
			<div className="containerRowsMilestones">
				<div>
					{milestones?.map((milestone) => (
						<MilestoneDD
							milestone={milestone}
							setSelectedMilestone={setSelectedMilestone}
						/>
					))}
				</div>
				<div>
					<div className="titleTasks">
						<TaskList className="icon"></TaskList>
						Task Lists ({taskLists.length}
					</div>

					<AppInput
						className="filterMilestone"
						value={taskListFilter}
						onChange={(e) => setTaskListFilter(e)}
						placeholder="Filtro Task List"
					></AppInput>
					<ul className="container">
						{filteredTaskLists?.map((taskList, index) => (
							<li>
								<TaskListDD taskList={taskList} />
							</li>
						))}
					</ul>
					<div className="titleTasks">
						<TaskIcon className="icon"></TaskIcon>
						Tasks ({tasks.length})
					</div>
					<AppInput
						className="filterMilestone"
						value={taskFilter}
						onChange={(e) => setTaskFilter(e)}
						placeholder="Filtro Task"
					></AppInput>
					<ul className="container">
						{filteredTasks?.map((task) => (
							<li>
								<TaskDD task={task} />{" "}
							</li>
						))}
					</ul>
				</div>
			</div>
		</div>
	)
}

const StyledDragDrop = styled(DragDrop)`
	& {
		.containerRowsMilestones {
			display: flex;
			justify-content: space-around;
		}
		.icon {
			height: 25px;
			width: 25px;
		}
		.titleTasks {
			font-size: 25px;
			padding-left: 20px;
			display: flex;
			flex-direction: row;
		}
		.container {
			height: 270px;
			width: 400px;
			list-style-type: none;
			overflow-y: scroll;
		}
		.filterMilestone {
			width: 80%;
			padding-left: 80px;
			padding-bottom: 20px;
			margin-top: 0;
		}
	}
`

export default StyledDragDrop
