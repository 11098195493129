import { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { message, Select, Tabs } from "antd";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";

import AppButton from "../../../../_shared/components/AppButton";
import AppInput from "../../../../_shared/components/AppInput";
import AppSelect from "../../../../_shared/components/AppSelect";
import CustomField from "../../../../_shared/components/CustomField";
import useForm from "../../../../_shared/hooks/useForm";
import useRestResource from "../../../../_shared/hooks/useRestResource";

import * as api from "../../../../../api";
import Checkbox from "../../../../_shared/components/FormItems/Checkbox";
import SelectAutoComplete from "../../../../_shared/components/SelectAutoComplete";

// ----------------------------------------------------------------------------

function CompanyData(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props;
    const { TabPane } = Tabs;
    const { Option } = Select;

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const params = useParams();
    const divRef = useRef();
    const user = useSelector((state) => (state?.auth.user ? state.auth : null));
    const [form, setOne, setMany, clearForm] = useForm();

    const [region, setRegion] = useState("");
    const [province, setProvince] = useState("");
    const [city, setCity] = useState("");
    const [street, setStreet] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [extraUe, setExtraUe] = useState(false);
    const [state, setState] = useState("");

    const [selectedCompany, setSelectedCompany] = useState();

    const [contacts] = useRestResource("/contacts", "", user.token, {
        organization: user.organization,
    });

    const [rates] = useRestResource("/rates", "", user.token, {
        organization: user.organization,
    });

    const [ports] = useRestResource("/ports", "", user.token, {
        organization: user.organization,
    });

    const [customFields] = useRestResource("/custom-fields", "", user.token, {
        organization: user.organization,
    });

    // -------------------------------------
    // Memoized data
    // -------------------------------------

    const paymentOptions = ["Bonifico", "Carta di credito", "Assegno"];
    const companySectors = [
        "Commercio",
        "Turismo",
        "Finanza",
        "Sanità",
        "Industria",
        "Edilizia",
        "Artigianato",
        "Agricoltura",
        "Industria mineraria",
    ];

    const companyTypes = [
        "Fornitore",
        "Cliente",
        "Partner",
        "Rivenditore",
        "Distributore",
        "Costruttore",
        "Filiale",
    ];

    // -------------------------------------
    // Effects
    // -------------------------------------

    useEffect(() => {
        selectedCompany && setMany(selectedCompany);
        setRegion(selectedCompany?.registeredOffice?.region ?? "");
        setProvince(selectedCompany?.registeredOffice?.province ?? "");
        setCity(selectedCompany?.registeredOffice?.city ?? "");
        setStreet(selectedCompany?.registeredOffice?.street ?? "");
        setZipCode(selectedCompany?.registeredOffice?.zipCode ?? "");

        // eslint-disable-next-line
    }, [selectedCompany]);

    useEffect(() => {
        if (selectedCompany && JSON.stringify(form) === "{}")
            setMany(selectedCompany);
        // eslint-disable-next-line
    }, [form]);

    useEffect(() => {
        getCompany();
    }, [params.companyId]);

    // -------------------------------------
    // Component functions
    // -------------------------------------

    async function getCompany() {
        try {
            const response = await api.getCompany(params.companyId, user.token);
            if (response) setSelectedCompany(response);
        } catch (error) {
            console.log("[ERROR] in getCompany");
        }
    }

    function update(field) {
        return (value) => {
            setMany({
                ...form,
                [field]: value,
            });
        };
    }

    function deepUpdate(field, prop) {
        return function (value) {
            const newField = {
                ...form[field],
                [prop]: value,
            };
            setOne(field, newField);
        };
    }

    async function handleSearchContacts(value) {
        try {
            const response = await api.searchContacts(
                value,
                user.organization,
                user.token
            );
            if (response) {
                return response.rows?.map((row) => {
                    return { label: row.fullName, id: row.id };
                });
            }
            return null;
        } catch (error) {
            console.log("[ERROR] in search contacts: ");
            console.log(error);
        }
    }

    async function handleSearchUsers(value) {
        try {
            const response = await api.searchUsers(
                value,
                user.organization,
                user.token
            );
            if (response) {
                return response.rows?.map((row) => {
                    return { label: row.fullName, id: row.id };
                });
            }
            return null;
        } catch (error) {
            console.log("[ERROR] in search users: ");
            console.log(error);
        }
    }

    function handleNewField(e) {
        e.preventDefault();
        const newCustomFields = [
            ...(form?.customFields ?? []),
            {
                key: Math.floor(Math.random() * 99999),
                customField: "",
                value: "",
            },
        ];
        update("customFields")(newCustomFields);
    }

    function handleDeleteCustomField(e, key) {
        e.preventDefault();
        const newCustomFields = form?.customFields.filter(
            (customField) => customField.key !== key
        );
        update("customFields")(newCustomFields);
    }

    function onChange(customFieldId, value, key) {
        const customField = form?.customFields.find(
            (customField) => customField.key === key
        );
        customField.customField = customFieldId;
        customField.value = value;
    }

    function checkValueType(customField) {
        const originalCustomField = customFields.rows.find(
            (originalField) => originalField.id === customField.customField
        );
        if (originalCustomField.type === "number") {
            const value = parseFloat(customField.value);
            customField.value = null;
            customField.value = value;
        }
        if (originalCustomField.type === "boolean") {
            const value =
                customField.value !== "" && customField.value !== "false"
                    ? true
                    : false;
            customField.value = null;
            customField.value = value;
        }
    }

    function onCancel() {
        clearForm();
    }

    async function onConfirm() {
        try {
            form?.customFields?.forEach((usedCustomField) =>
                checkValueType(usedCustomField)
            );

            !form?.organization
                ? (form.organization = user.organization)
                : (form.organization = form.organization.id);

            if (form?.user) form.user = form.user.id;

            await api.updateCompany(form.id, form, user.token);

            message.success("Operazione eseguita con successo");
            setSelectedCompany(form);
        } catch (error) {
            console.log("[ERROR] in confirm company: ");
            console.log(error);
            message.error("Errore durante l'operazione");
        }
    }

    function renderGeneralInformation() {
        return (
            <div className="information">
                <p className="tab-title">Informazioni generali</p>
                <div className="input-container">
                    <AppInput
                        label="Nome dominio società"
                        placeholder="Nome dominio"
                        value={form?.businessDomainName ?? ""}
                        onChange={update("businessDomainName")}
                    />
                    <AppInput
                        label="Ragione sociale"
                        placeholder="Nome"
                        value={form?.businessName ?? ""}
                        onChange={update("businessName")}
                    />
                    <AppInput
                        label="Codice fiscale"
                        placeholder="Codice"
                        value={form?.fiscalCode ?? ""}
                        onChange={update("fiscalCode")}
                    />
                    <AppInput
                        label="Partita iva"
                        placeholder="IVA"
                        value={form?.vatNumber ?? ""}
                        onChange={update("vatNumber")}
                    />
                    {/* <AppSelect
                        label="Proprietario"
                        placeholder="Proprietario"
                        value={form?.owner?.fullName ?? ""}
                        onSelect={update("owner")}
                        className="app-select"
                    >
                        {contacts &&
                            contacts.rows?.map((contact, index) => (
                                <Option key={contact.id} id={index}>
                                    {contact.fullName}
                                </Option>
                            ))}
                    </AppSelect> */}
                    <SelectAutoComplete
                        label="Proprietario"
                        placeholder="Proprietario"
                        className="app-select"
                        defaultValue={form?.owner?.fullName ?? ""}
                        onSearch={handleSearchUsers}
                        onChange={(value) => update("owner")(value)}
                    />
                    <AppSelect
                        label="Tipologia"
                        placeholder="Tipologia"
                        value={form?.type ?? ""}
                        onSelect={update("type")}
                        className="app-select"
                    >
                        {companyTypes.map((type, index) => (
                            <Option key={type} id={index}>
                                {type}
                            </Option>
                        ))}
                    </AppSelect>
                    <SelectAutoComplete
                        placeholder="Contatti"
                        className="app-select"
                        defaultValue={form?.contact?.fullName ?? ""}
                        onSearch={handleSearchContacts}
                        label="Contatto"
                        onChange={(value) => update("contact")(value)}
                    />
                    <AppInput
                        label="Settore"
                        placeholder="Settore"
                        value={form?.sector ?? ""}
                        onChange={update("sector")}
                    />
                    <AppInput
                        label="Numero di dipendenti"
                        placeholder="Numero di dipendenti"
                        value={form?.employeesNumber ?? ""}
                        onChange={update("employeesNumber")}
                    />

                    {form?.customFields?.map((customField, index) => (
                        <CustomField
                            key={customField.key}
                            id={customField.key}
                            onChange={onChange}
                            onCancel={(e, key) =>
                                handleDeleteCustomField(e, key)
                            }
                            customFields={customFields?.rows}
                            givenCustomField={
                                customField.customField
                                    ? customField.customField
                                    : null
                            }
                            givenValue={
                                customField.value ? customField.value : null
                            }
                        />
                    ))}
                    <AppButton
                        uxType="link"
                        onClick={handleNewField}
                        className="addField"
                        icon={<PlusOutlined />}
                    >
                        Aggiungi campo personalizzato
                    </AppButton>
                </div>
            </div>
        );
    }

    function renderShipping() {
        return (
            <div className="information">
                <p className="tab-title">Informazioni sulla spedizione</p>
                <div className="input-container">
                    <AppInput
                        label="Codice postale"
                        placeholder="Codice postale"
                        value={form?.postalCode ?? ""}
                        onChange={update("postalCode")}
                    />
                    <AppSelect
                        label="Porto"
                        placeholder="Porto"
                        value={form?.port?.name ?? ""}
                        onSelect={update("port")}
                        className="app-select"
                    >
                        {ports.rows?.map((port, index) => (
                            <Option key={port.id} id={index}>
                                {port.name}
                            </Option>
                        ))}
                    </AppSelect>
                    <AppInput
                        label="Corriere"
                        placeholder="Corriere"
                        value={form?.courierService ?? ""}
                        onChange={update("courierService")}
                    />
                    <AppInput
                        label="Regione"
                        value={region}
                        onChange={setRegion}
                    />

                    <AppInput
                        label="Provincia"
                        value={province}
                        onChange={setProvince}
                    />

                    <AppInput label="Città" value={city} onChange={setCity} />

                    <AppInput label="Via" value={street} onChange={setStreet} />

                    <AppInput
                        label="Codice postale"
                        value={zipCode}
                        onChange={setZipCode}
                    />
                    <AppInput
                        label="Stato"
                        placeholder="Stato"
                        value={form?.state ?? ""}
                        onChange={update("state")}
                    />
                </div>
            </div>
        );
    }

    function renderLocations() {
        return (
            <div className="information">
                <p className="tab-title">Sede Legale</p>
                <div className="input-container">
                    <AppInput
                        label="Stato"
                        value={form?.registeredOffice?.country ?? ""}
                        onChange={deepUpdate("registeredOffice", "country")}
                    />
                    <Checkbox
                        label="EXTRA UE"
                        checked={form?.registeredOffice?.extraUe ?? ""}
                        onChange={deepUpdate("registeredOffice", "extraUe")}
                    />
                    <AppInput
                        label="Regione"
                        value={form?.registeredOffice?.region ?? ""}
                        onChange={deepUpdate("registeredOffice", "region")}
                    />

                    <AppInput
                        label="Provincia"
                        value={form?.registeredOffice?.province ?? ""}
                        onChange={deepUpdate("registeredOffice", "province")}
                    />

                    <AppInput
                        label="Città"
                        value={form?.registeredOffice?.city ?? ""}
                        onChange={deepUpdate("registeredOffice", "city")}
                    />

                    <AppInput
                        label="Via"
                        value={form?.registeredOffice?.street ?? ""}
                        onChange={deepUpdate("registeredOffice", "street")}
                    />

                    <AppInput
                        label="Codice postale"
                        value={form?.registeredOffice?.zipCode ?? ""}
                        onChange={deepUpdate("registeredOffice", "zipCode")}
                    />

                    <br />

                    <p className="tab-title">Sede Operativa</p>

                    <AppInput
                        label="Stato"
                        value={form?.operativeHeadquarter?.country ?? ""}
                        onChange={deepUpdate("operativeHeadquarter", "country")}
                    />
                    <Checkbox
                        label="EXTRA UE"
                        checked={form?.operativeHeadquarter?.extraUe ?? ""}
                        onChange={deepUpdate("operativeHeadquarter", "extraUe")}
                    />
                    <AppInput
                        label="Regione"
                        value={form?.operativeHeadquarter?.region ?? ""}
                        onChange={deepUpdate("operativeHeadquarter", "region")}
                    />

                    <AppInput
                        label="Provincia"
                        value={form?.operativeHeadquarter?.province ?? ""}
                        onChange={deepUpdate(
                            "operativeHeadquarter",
                            "province"
                        )}
                    />

                    <AppInput
                        label="Città"
                        value={form?.operativeHeadquarter?.city ?? ""}
                        onChange={deepUpdate("operativeHeadquarter", "city")}
                    />

                    <AppInput
                        label="Via"
                        value={form?.operativeHeadquarter?.street ?? ""}
                        onChange={deepUpdate("operativeHeadquarter", "street")}
                    />

                    <AppInput
                        label="Codice postale"
                        value={form?.operativeHeadquarter?.zipCode ?? ""}
                        onChange={deepUpdate("operativeHeadquarter", "zipCode")}
                    />
                </div>
            </div>
        );
    }

    function renderPayment() {
        return (
            <div className="information">
                <p className="tab-title">Informazioni sui pagamenti</p>
                <div className="input-container">
                    <AppSelect
                        label="Aliquota IVA"
                        placeholder="IVA"
                        value={form?.rate?.name ?? ""}
                        onSelect={update("rate")}
                        className="app-select"
                    >
                        {rates?.rows?.map((option, index) => (
                            <Option key={option.id} id={index}>
                                {option.name}
                            </Option>
                        ))}
                    </AppSelect>
                    <AppSelect
                        label="Metodi di pagamento"
                        placeholder="Pagamento"
                        mode="multiple"
                        allowClear
                        value={form?.paymentMethods ?? ""}
                        onChange={update("paymentMethods")}
                        className="app-select"
                    >
                        {paymentOptions.map((option, index) => (
                            <Option key={option} id={index}>
                                {option}
                            </Option>
                        ))}
                    </AppSelect>
                    <AppInput
                        label="ABI"
                        placeholder="ABI"
                        value={form?.abi ?? ""}
                        onChange={update("abi")}
                    />
                    <AppInput
                        label="CAB"
                        placeholder="CAB"
                        value={form?.cab ?? ""}
                        onChange={update("cab")}
                    />
                    <AppInput
                        label="IBAN"
                        placeholder="IBAN"
                        value={form?.iban ?? ""}
                        onChange={update("iban")}
                    />
                    <AppInput
                        label="Codice SDI"
                        placeholder="SDI"
                        value={form?.sdi ?? ""}
                        onChange={update("sdi")}
                    />
                    <AppInput
                        label="Calcolo provvigioni"
                        placeholder="Provvigioni"
                        value={form?.commissionCalculation ?? ""}
                        onChange={update("commissionCalculation")}
                    />
                </div>
            </div>
        );
    }

    function renderContacts() {
        return (
            <div className="information">
                <p className="tab-title">Contatti</p>
                <div className="input-container">
                    <AppInput
                        label="Agente di riferimento"
                        placeholder="Nome"
                        value={form?.referralAgent ?? ""}
                        onChange={update("referralAgent")}
                    />
                    <AppInput
                        label="Numero di telefono"
                        placeholder="Telefono"
                        value={form?.phoneNumber ?? ""}
                        onChange={update("phoneNumber")}
                    />
                    <AppInput
                        label="Numero di cellulare"
                        placeholder="Cellulare"
                        value={form?.mobileNumber ?? ""}
                        onChange={update("mobileNumber")}
                    />
                    <AppInput
                        label="Email"
                        placeholder="Email"
                        value={form?.email ?? ""}
                        onChange={update("email")}
                    />
                    <AppInput
                        label="PEC"
                        placeholder="PEC"
                        value={form?.pec}
                        onChange={update("pec")}
                    />
                    <AppInput
                        label="LinkedIn"
                        placeholder="LinkedIn"
                        value={form?.linkedInPage ?? ""}
                        onChange={update("linkedInPage")}
                    />
                </div>
            </div>
        );
    }

    return (
        <div className={className}>
            <div className="leftSide">
                <div className="title" ref={divRef}>
                    <p className="businessName">{form?.businessName}</p>
                    <Tabs defaultActiveKey="1">
                        <TabPane tab="Generale" key="1">
                            {renderGeneralInformation()}
                        </TabPane>
                        <TabPane tab="Spedizioni" key="2">
                            {renderShipping()}
                        </TabPane>
                        <TabPane tab="Sedi" key="3">
                            {renderLocations()}
                        </TabPane>
                        <TabPane tab="Pagamenti" key="4">
                            {renderPayment()}
                        </TabPane>
                        <TabPane tab="Contatti" key="5">
                            {renderContacts()}
                        </TabPane>
                        <TabPane tab="Storico azienda" key="6"></TabPane>
                    </Tabs>
                    <div className="actionFooter">
                        <AppButton
                            uxType="tertiary"
                            className="actionBtn"
                            onClick={onCancel}
                        >
                            Annulla
                        </AppButton>
                        <AppButton
                            uxType=""
                            className="actionBtn"
                            onClick={onConfirm}
                        >
                            Conferma
                        </AppButton>
                    </div>
                </div>
            </div>
            {/* <div className="center">

            </div>
            <div className="rightSide">

            </div> */}
        </div>
    );
}

// ----------------------------------------------------------------------------

const StyledCompanyData = styled(CompanyData)`
    & {
        width: inherit;
        display: flex;
        height: 100%;
        flex-direction: row;
        align-items: flex-start;
        .leftSide {
            min-width: 200px;
            width: 100%;
            height: inherit;
            padding: 10px 10px;
            padding-bottom: 0px;
            //border-right: 1px solid ${({ theme }) => theme.colors.grey200};

            .title {
                padding: 10px 10px;
                margin-bottom: 30px;
                width: 100%;
                height: inherit;
                display: flex;
                flex-direction: column;
                .businessName {
                    font-size: 20px;
                }
                .ant-tabs {
                    height: inherit;
                    .ant-tabs-content-holder {
                        height: inherit;

                        .ant-tabs-content {
                            height: inherit;
                        }
                    }
                }
            }
            .information {
                width: 100%;
                min-width: 240px;
                padding: 10px 10px;
                height: 95%;
                overflow: scroll;

                .tab-title {
                    font-size: 18px;
                }
                .app-select {
                    width: 100%;
                }
                .input-container {
                    width: 40%;
                    min-width: 240px;
                }
                .addField {
                    display: flex;
                    flex-direction: row-reverse;
                    justify-content: flex-start;
                    margin: auto;
                    margin-top: 10px;
                    padding: 5px;
                    border: none;
                    .icon {
                        margin: auto;
                        margin-right: 10px;
                        height: 14px;
                        width: 30px;
                    }
                    .center {
                        min-width: 0px;
                    }
                }
            }
            .actionFooter {
                height: 50px;
                width: 100%;
                padding: 20px;
                box-sizing: content-box;
                background-color: ${({ theme }) => theme.colors.light};
                bottom: 10px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-end;
                border-radius: 16px;
                margin-left: -20px;
                z-index: 100;
                .actionBtn {
                    margin-right: 20px;
                }
            }
        }
        .center {
            width: 50%;
            min-width: 400px;
            height: inherit;
        }
        .rightSide {
            min-width: 200px;
            width: 25%;
            height: inherit;
            border-left: 1px solid ${({ theme }) => theme.colors.grey200};
        }
    }
`;
export default StyledCompanyData;
