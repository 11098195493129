import * as api from "../../../../api"

// ----------------------------------------------------------------------------

export default [
	{
		command: "create admin user",
		inputs: [
			{
				key: "email",
				hint: "Inserisci l'email:",
				sort: 1,
			},
			{
				key: "password",
				hint: "Inserisci la password:",
				sort: 2,
			},
			{
				key: "name",
				hint: "Inserisci il nome:",
				sort: 3,
			},
			{
				key: "surname",
				hint: "Inserisci il cognome:",
				sort: 4,
			},
		],
		execute: api.createAdminUser,
	},
]
