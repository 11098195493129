import React from "react";
import { useDroppable } from "@dnd-kit/core";
import {
  SortableContext,
  horizontalListSortingStrategy,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { message, Tooltip, Progress, Popconfirm, Avatar } from "antd";

import SortableItem, { Item } from "./sortable_item";
import styled from "styled-components";

const containerStyle = {
  padding: "10px 10px",
  borderRadius: 10,
  minHeight: 50,
  minWidth: "250px",
  background: "rgb(220,220,220, 25%)",
  // border: "2px solid red",
};

export const Container = React.forwardRef((props, ref) => {
  const { children, row } = props;

  return (
    <div
      ref={ref}
      style={{
        ...containerStyle,
        flexDirection: row ? "row" : "column",
      }}
    >
      {children}
    </div>
  );
});

function SortableContainer(props) {
  const {
    getItems,
    id,
    obj,
    className,
    handleModifyTaskList,
    handleNewTask,
    handleModifyTask,
  } = props;

  const items = getItems(id);
  const itemIds = items.map((item) => item.id);

  const { isOver, setNodeRef } = useDroppable({
    id,
  });

  if (isOver) {
    console.log("is over", id);
  }

  return (
    <div className={className}>
      <SortableItem
        id={id}
        handlePosition="right"
        handleModifyTaskList={handleModifyTaskList}
      >
        <Container ref={setNodeRef}>
          <SortableContext
            items={itemIds}
            strategy={verticalListSortingStrategy}
          >
            <div className="container">
              <div className="title">{obj?.name.toUpperCase()}</div>
              <Tooltip title="Aggiungi nuovo task">
                <div className="newtask" onClick={(e) => handleNewTask(e)}>
                  +
                </div>
              </Tooltip>
            </div>
            {items.map((item) => {
              let child = (
                <Item
                  id={item.id}
                  obj={item.item}
                  handleModifyTask={handleModifyTask}
                />
              );

              if (item.container) {
                return (
                  <SortableContainer
                    key={item.id}
                    id={item.id}
                    getItems={getItems}
                    handlePosition="top"
                  />
                );
              }

              return (
                <SortableItem key={item.id} id={item.id}>
                  {child}
                </SortableItem>
              );
            })}
          </SortableContext>
        </Container>
      </SortableItem>
    </div>
  );
}

const StyledSortableContainer = styled(SortableContainer)`
  & {
    .title {
      font-size: 15px;
      padding: 10px;
    }
    .container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
    .newtask {
      width: 25px;
      height: 25px;
      background-color: ${({ theme }) => theme.colors.orange300};
      text-align: center;
      border-radius: 99px;

      :hover {
        cursor: pointer;
        background: ${({ theme }) => theme.colors.orangeGradient};
      }
    }
  }
`;

export default StyledSortableContainer;
