import React, { useState, useRef, useEffect} from "react"
import styled from "styled-components"
import { Link } from "react-router-dom"
import { message , Steps} from "antd"
import { CSSTransition, SwitchTransition} from 'react-transition-group';

import UserData from "./UserData"
import CompanyData from "./CompanyData"
import Confirmation from "./Confirmation"
import OrganizationJoin from "./OrganizationJoin"

import Logo from "../../../_shared/components/LoginItems/Logo"
import LogImage from "../../../_shared/components/LoginItems/LogImage"
import Row from "../../../_shared/components/LoginItems/Row"
import LogForm from "../../../_shared/components/LoginItems/LogForm"
import useForm from "../../../_shared/hooks/useForm"
import FormDiv from "../../../_shared/components/LoginItems/FormDiv"
import AppButton from "../../../_shared/components/AppButton"

import * as api from "../../../../api"

// ----------------------------------------------------------------------------

function SignUp(props) {
	// -------------------------------------
	// Props destructuring
	// -------------------------------------

	const { className } = props

	// -------------------------------------
	// Hooks (e.g. useState, ...)
	// -------------------------------------
	// const [fullName, setFullName] = useState("")
    const { Step } = Steps
    const [open, setOpen] = useState(false)

    const [form, , setMany, clearForm] = useForm()

    const [ step, setStep ] = useState(0)
    const [ existingOrg, setExistingOrg] = useState("")
    const nodeRef0 = useRef(null)
    const nodeRef1 = useRef(null)
    const nodeRef2 = useRef(null)
    const nodeRef3 = useRef(null)
    const nodeRef4 = useRef(null)
	
    // -------------------------------------
    // Effects
    // -------------------------------------

    useEffect (() => {
        if(!open)
            setOpen(!open)
    }) 

    // -------------------------------------
	// Component functions
	// -------------------------------------

    function update(field) {
        return (value) => {
            setMany({
                ...form,
                [field]: value,
            })}
}

	async function handleSubmit() {
		if (!form?.terms) message.error("Devi accettare le condizioni")
		else {
			try {
				const userResponse = await api.signUp(form?.email, form?.password, form?.name, form?.surname)
                if(userResponse.status === 409)
                    message.error("Email già in uso")
                else {
                    if (userResponse.status === 401)
                        message.error("Dati inseriti errati")
                    else {

                        const findOrgResponse = await api.getOrganizations(form?.vatNumber, userResponse.token)
                        
                        if(findOrgResponse.count !== 0){
                            setExistingOrg(findOrgResponse.rows[0].businessName)
                            setStep(4)
                        }
                        else {
                            const businessBody = {
                                businessName: form?.businessName,
                                vatNumber: form?.vatNumber
                            }

                            const orgResponse = await api.createOrganization(businessBody, userResponse.token)
                            if (orgResponse.status === 401) message.error("Dati inseriti errati")
                            else {
                                setStep(3)
                                const updateBody= {
                                    organizations: orgResponse.id
                                }
                                const updateResponse = await api.updateUser(userResponse.user.id, updateBody, userResponse.token)
                            }
                        }
                    }
                }
			} catch (error) {}
		}
	}
    
    function nextStep (e) {
        e.preventDefault()
        if(step === 2) handleSubmit()
        else setStep(step + 1)
        setOpen(!open)
    }

    function prevStep () {
        setStep(step - 1)
    }

    function renderStepPage () {
        switch (step) {
            case 0: return <UserData update={update} values={form} refer={nodeRef0} step={step}/>
            case 1: return <UserData update={update} values={form} refer={nodeRef1} step={step}/>
            case 2: return <CompanyData update={update} values={form} refer={nodeRef2}/>
            case 3: return <Confirmation refer={nodeRef3}/>
            case 4: return <OrganizationJoin refer={nodeRef4} org={existingOrg}/>
            default: return
        }
    }

    function getNodeRef () {
        switch (step) {
            case 0: return nodeRef0
            case 1: return nodeRef1
            case 2: return nodeRef2
            case 3: return nodeRef3
            case 4: return nodeRef4
            default: return
        }
    }

	return (
		<div className={`${className}`}>
			<FormDiv>
				<Logo />
				<LogForm onSubmit={nextStep} className="logForm">
                    <SwitchTransition mode="out-in">
                        <CSSTransition
                            timeout={400} 
                            classNames="step"
                            nodeRef={getNodeRef()}
                            unmountOnExit
                            key={step}
                        >   
                            {renderStepPage()}
                        </CSSTransition>
                    </SwitchTransition>

					<div className="outer">
                       <p>Hai già un profilo?</p>
                        <Link to="sign-up" className="link">
                            <AppButton uxType="secondary" htmlType="" className="btnn">
                                ACCEDI
                            </AppButton>
                        </Link>
                    </div>
				</LogForm>
                
                {step < 4 &&
                <Row className="steps">
                    <Steps progressDot current={ step }>
                        <Step />
                        <Step />
                        <Step />
                        <Step />
                    </Steps>
                </Row>}
			</FormDiv>
            <LogImage page="signUp"/>
		</div>
	)
}

const StyledSignUp = styled(SignUp)`
	& {
        display: flex;
        flex-direction: row;

        .steps {
            margin: 0;
            width: 23vw;
            min-width:295px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-top: 40px;
            .ant-steps {
                width: fit-content;
                margin-right:90px;
                .ant-steps-item{
                    width: 50px;
                    
                }
            }
        }
        .logForm {

            .step-enter, .step-appear{
                opacity: 0;
                transform:translateX(38%);
                }
            .step-enter-active, .step-appear-active {
                opacity: 1;
                transform:translateX(0);
                transition: opacity 400ms, transform 400ms;
            }
            .step-exit {
                opacity: 1;
                transform:translateX(0%);
            }
            .step-exit-active {
                opacity: 0;
                transform:translateX(-100%);
                transition: opacity 400ms, transform 400ms;
            }
        }
        
	}
`
// ----------------------------------------------------------------------------
export default StyledSignUp
