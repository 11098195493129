import React, { useState, useEffect} from "react"
import styled from "styled-components"
import { Route, Routes} from "react-router-dom"

import LogImage from "../../_shared/components/LoginItems/LogImage"

import CommandLine from "./CommandLine"
import StyledSignIn from "./SignIn"
import StyledSignUp from "./SignUp"
import RecoverPassword from "./RecoverPassword"
import InvitedLogin from "./InvitedLogin"

// ----------------------------------------------------------------------------

function _AuthPage(props) {
	// -------------------------------------
	// Props destructuring
	// -------------------------------------

	const { className } = props
	// -------------------------------------
	// Hooks (e.g. useState, ...)
	// -------------------------------------

	// -------------------------------------
	// Component functions
	// -------------------------------------
    
	return (
		<div className={`${className}`}>
			<Routes>
                <Route path="/invites/:inviteId" element={<InvitedLogin />} />
				<Route path="/*" element={<StyledSignIn />} />
                <Route path="/sign-up" element={<StyledSignUp />} />
                <Route path="/forgot-password" element={<RecoverPassword/>} />
			</Routes>

			<CommandLine />
		</div>
	)
}

const AuthPage = styled(_AuthPage)`
	& {
		width: 100vw;
		height: 100vh;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: row;
        .outer{
            margin-top: 30px;
            width: 100%;
            text-align: center;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.02em;
            color: #252631;
        }

        .row-between{
            margin: 25px 0;
        }
	}
`
// ----------------------------------------------------------------------------
export default AuthPage
