import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"

import AppInput from "../../../../_shared/components/AppInput"
import AppButton from "../../../../_shared/components/AppButton"
import Heading from "../../../../_shared/components/LoginItems/Heading"
import SubHeading from "../../../../_shared/components/LoginItems/SubHeading"
import Row from "../../../../_shared/components/LoginItems/Row"

function UserData (props) {

    const { className, update, values, refer, step} = props

    function personalData () {
        return (
            <>
                <AppInput
                    value={values?.name ?? ""}
                    onChange={update("name")}
                    label="Nome"
                    placeholder="Inserisci il tuo nome"
                    required
                ></AppInput>
                <AppInput
                    value={values?.surname ?? ""}
                    onChange={update("surname")}
                    label="Cognome"
                    placeholder="Inserisci il tuo cognome"
                    required
                ></AppInput>
            </>
        )
    }

    function accountData () {
        return (
            <>
                <AppInput
                    value={values?.email ?? ""}
                    onChange={update("email")}
                    label="Email"
                    placeholder="Inserisci la tua email"
                    required
                ></AppInput>
                <AppInput
                    value={values?.password ?? ""}
                    onChange={update("password")}
                    label="Password"
                    placeholder="Inserisci la tua password"
                    type="password"
                    required
                ></AppInput>
            </>
        )
    }
    return (
        <div className={className} ref={refer} >
            
            <Heading>
                Crea profilo
            </Heading>
            <SubHeading>
                Accedi al tuo profilo se vuoi usare tutti i prodotti premium 
            </SubHeading>
            {step ? personalData() : accountData()}
            <Row></Row>
            <AppButton className="continueBtn" uxType="" htmlType="submit">
                CONTINUA
            </AppButton>
        </div>
    )

}

const StyledUserData = styled(UserData) `
    & {
        
        .continueBtn {
            margin-top: ${({ theme }) => theme.spacing.s}px
        }
        
    }

`
export default StyledUserData