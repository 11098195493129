import React from "react";
import { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { Avatar, Tooltip, Modal, Button, Badge } from "antd";
import FieldLabel from "../FieldLabel";
import { AntDesignOutlined, ApiFilled, UserOutlined } from "@ant-design/icons";

function _AppUserSelect(props) {
  const {
    className,
    label,
    users,
    allUsers,
    setNewUser,
    canBeModified,
    setUserToBeRemoved,
  } = props;
  const [openModal, setOpenModal] = useState(false);
  return (
    <div className={className}>
      {label && <FieldLabel>{label}</FieldLabel>}
      <div className="row">
        {users &&
          users.map((user) => (
            <Tooltip
              title={
                (canBeModified ? "Rimuovi\n" : "") +
                user?.name?.toUpperCase() +
                " " +
                user?.surname?.toUpperCase()
              }
            >
              <Avatar
                className="iconAvatar"
                onClick={() => canBeModified && setUserToBeRemoved(user)}
                style={canBeModified && { cursor: "pointer" }}
              >
                {user?.name?.charAt(0)?.toUpperCase() +
                  user?.surname?.charAt(0)?.toUpperCase()}
              </Avatar>
            </Tooltip>
          ))}
        {canBeModified && (
          <Tooltip title="Aggiungi utente">
            <Avatar className="iconAvatar" onClick={() => setOpenModal(true)}>
              +
            </Avatar>
          </Tooltip>
        )}
      </div>

      <Modal
        title="Aggiungi utente"
        visible={openModal}
        footer={[
          <Button key="back" onClick={() => setOpenModal(false)}>
            Esci
          </Button>,
        ]}
        onCancel={() => setOpenModal(false)}
      >
        {allUsers &&
          allUsers?.rows?.map((user) => (
            <Tooltip
              title={user.name.toUpperCase() + " " + user.surname.toUpperCase()}
            >
              <Avatar
                className="iconAvatar"
                onClick={() => {
                  setNewUser(user);
                  setOpenModal(false);
                }}
                style={{ cursor: "pointer", margin: "5px" }}
              >
                {user.name.charAt(0).toUpperCase() +
                  user.surname.charAt(0).toUpperCase()}
              </Avatar>
            </Tooltip>
          ))}
      </Modal>
    </div>
  );
}

const AppUserSelect = styled(_AppUserSelect)`
  & {
    margin-top: 20px;
    .row {
      display: flex;
      flex-direction: row;
    }
    .iconAvatar {
      margin: 5px;
    }
    .addIcon {
      margin: 5px;
      background-color: rgba(100, 100, 100, 0.4);
      height: 32px;
      width: 32px;
      border-radius: 100px;
      text-align: center;
    }

    .AppSelect {
      min-width: inherit;
      width: inherit;
      height: 43px;

      .ant-select-selector {
        height: inherit;
        background-color: ${({ theme }) => theme.colors.light};
        border: 1px solid ${({ theme }) => theme.colors.grey};
        border-radius: 4px;
        padding: 0px ${({ theme }) => theme.spacing.s}px;
        padding-top: 5px;
        .ant-select-selection-search-input {
          padding: 0px ${({ theme }) => theme.spacing.xs}px;
          padding-top: 5px;
        }
      }
    }
  }
`;
export default AppUserSelect;
