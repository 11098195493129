import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { message, Progress, Tooltip } from "antd";
import { useSelector } from "react-redux";

import * as api from "../../../../../../api";

import { useDrop } from "react-dnd";
import useForm from "../../../../../_shared/hooks/useForm";
import { ReactComponent as MilestoneIcon } from "../../../../../_shared/assets/icons/milestone.svg";
import { isArray, isObjectLike } from "lodash";

import { ReactComponent as Delete } from "../../../../../_shared/assets/icons/delete.svg";
import { ReactComponent as Edit } from "../../../../../_shared/assets/icons/edit.svg";

import projectStatus from "../../../../../_shared/constants/projectStatus";
import moment from "moment";
import "moment/locale/it";

function MilestoneDD(props) {
  const { className, milestone, onDelete, setSelectedMilestone } = props;

  // -------------------------------------
  // Hooks (e.g. useState, ...)
  // -------------------------------------
  const user = useSelector((state) => (state?.auth.user ? state.auth : null));

  const [form, , setMany, clearForm] = useForm();
  const [tasks, setTasks] = useState();

  const [visibile, setVisible] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [idToBeDeleted, setIdToBeDeleted] = useState();

  const [selectedTask, setSelectedTask] = useState();
  moment.locale("it");

  useEffect(() => {
    setMany(milestone);
    setTasks(milestone.tasks);
  }, [milestone]);

  function CheckTaskExists(taskToBeInserted) {
    if (tasks?.filter((e) => e.id === taskToBeInserted.id).length === 0) {
      return taskToBeInserted;
    }
    return [];
  }

  function CheckTaskListsExists(taskToBeInserted) {
    let newArray = [];

    taskToBeInserted.forEach((element) => {
      if (tasks?.filter((e) => e.id === element.id).length === 0) {
        newArray = [...newArray, element];
      }
    });
    return newArray;
  }

  const handleDrop = async (item) => {
    const taskToBeInsertedAll =
      item.class === "Task" ? item?.item : item?.item?.tasks;

    let taskToBeInserted;
    if (tasks.length > 0) {
      taskToBeInserted =
        item.class === "Task"
          ? CheckTaskExists(taskToBeInsertedAll)
          : CheckTaskListsExists(taskToBeInsertedAll);
    } else {
      taskToBeInserted = taskToBeInsertedAll;
    }

    if (taskToBeInserted.length === 0) {
      console.log("no new task to be insert");
      return;
    }
    const old = tasks?.map((task) => task?.id);

    const updateData = {
      tasks:
        item.class === "Task"
          ? [...old, taskToBeInserted]
          : [...old, ...taskToBeInserted],
    };

    const response = await api.updateMilestone(
      milestone.id,
      updateData,
      user.token
    );

    setTasks(
      item.class === "Task"
        ? [...tasks, taskToBeInserted]
        : [...tasks, ...taskToBeInserted]
    );
  };

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ["TaskListDD", "TaskDD"],
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),

    drop: (item, monitor) => handleDrop(item),
  });

  return (
    <div className={className} ref={drop}>
      <div className="milestoneList" onClick={() => setVisible(!visibile)}>
        <button
          className="hideButton"
          style={{
            fontWeight: visibile === true ? "bold" : "normal",
            fontSize: "20px",
          }}
        >
          <MilestoneIcon className="icon"></MilestoneIcon>
          {visibile === true ? "^  " : ">  "}
          {`${form?.name} `}
          {isOver && canDrop && <div>Rilascia qui</div>}
          <div
            style={{
              fontStyle: "italic",
              fontSize: "15px",
            }}
          >{`[${tasks?.length ?? 0} tasks]`}</div>
        </button>

        <div className="statistics">
          <div style={{ width: 200, alignSelf: "center", margin: "30px" }}>
            <Tooltip
              style={{ whiteSpace: "pre-line" }}
              title={`Tasks : ${tasks?.length}  completati : ${
                tasks?.filter(
                  (task) =>
                    projectStatus[task.status] === projectStatus.completed
                ).length
              }          
              Mancanti : ${
                tasks?.filter(
                  (task) =>
                    projectStatus[task.status] !== projectStatus.completed
                ).length
              }`}
            >
              <Progress
                percent={Math.round(
                  (tasks?.filter(
                    (task) =>
                      projectStatus[task.status] === projectStatus.completed
                  ).length /
                    tasks?.length) *
                    100
                )}
                size="small"
              />
            </Tooltip>
          </div>

          <div style={{ width: 200, alignSelf: "center", margin: "30px" }}>
            <Tooltip
              title={
                milestone.deadLine
                  ? moment(milestone?.deadLine).format("DD MMMM YYYY")
                  : ""
              }
            >
              Scadenza : {moment(milestone?.deadLine).fromNow()}
            </Tooltip>
          </div>
        </div>
        <div className="utilities">
          <Tooltip title="Modifica milestone ">
            <div
              className="delete"
              onClick={() => setSelectedMilestone(milestone)}
            >
              <Edit />
            </div>
          </Tooltip>
          <Tooltip title="Elimina milestone ">
            <div className="delete" onClick={(e) => onDelete(e, milestone?.id)}>
              <Delete />
            </div>
          </Tooltip>
        </div>
      </div>
      {visibile && (
        <ul className="container">
          <div className="tasksRow">
            {tasks?.map((task) => (
              <div className={"taskRow"}>
                <div className="task">
                  {task.name} - {task.description}
                </div>
              </div>
            ))}
          </div>
        </ul>
      )}
    </div>
  );
}

const StyledMilestoneDD = styled(MilestoneDD)`
  & {
    margin-bottom: 20px;
    .icon {
      height: 35px;
      width: 35px;
    }
    .statistics {
      width: 40%;
      // background-color: yellow;
      display: flex;
      justify-content: space-between;
    }
    .container {
      max-height: 270px;
      width: 400px;
      list-style-type: none;
      overflow-y: scroll;
    }
    .task {
      border-radius: 12px;
      padding: 10px;
      border-style: solid;
      border-width: 1px;
      border-color: orange;

      :hover {
        cursor: pointer;
        background-color: ${({ theme }) => theme.colors.neutral100};
      }
    }
    .utilities {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-right: 5px;
      min-width: 70px;
    }
    .task {
      border-radius: 110px;
      padding: 2px;
      border-bottom: 1px solid orange;

      //font-size: 11px;
      width: 250px;
      padding-left: 10px;

      :hover {
        cursor: pointer;
        background-color: ${({ theme }) => theme.colors.neutral100};
      }
    }
    .taskCompleted {
      border-radius: 110px;
      padding: 5px;
      border-bottom: 1px solid orange;

      width: 250px;
      opacity: 0.3;

      :hover {
        cursor: pointer;
        background-color: ${({ theme }) => theme.colors.neutral100};
      }
    }
    .taskRow {
      display: flex;
      flex-direction: row;
      margin-top: 5px;
    }

    .milestoneList {
      background-color: rgba(240, 240, 240, 0.25);
      padding: 10px 5px;
      border-radius: 20px;
      box-shadow: 1px 1px #ffa500;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 70vh;
      transition: 0.5s;
      border: 1px;

      :hover {
        box-shadow: 3px 3px #ffa500;
        background-color: rgba(240, 240, 240, 0.8);
        color: black;
      }
    }
    .form {
      min-width: 280px;
    }

    .hideButton {
      border: none;
      background: none;
      margin-left: 5px;
      margin-top: 5px;
      width: 30%;
      text-align: left;
    }
  }
`;

export default StyledMilestoneDD;
