import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

function _FormDiv (props) {

    const { className , children} = props

    return (
            <div className={`${className}`} >
                { children }
            </div>
    )
}
_FormDiv.propTypes = {
    className: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired
}

_FormDiv.defaultProps = {}

const FormDiv = styled(_FormDiv) `
    & {
        width:50vw;
        height:100vh;
        background-color: ${(props) => props.theme.colors.light};
        display:flex;
        flex-direction: column;
        align-items:center;
        justify-content: space-around
    }
`
export default FormDiv