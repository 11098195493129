import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import AppButton from "../../../../../../_shared/components/AppButton";
import RightSheet from "../../../../../../_shared/components/Drawer";
import AppInput from "../../../../../../_shared/components/AppInput";
import AppMultiSelect from "../../../../../../_shared/components/AppMultiSelect";

import useForm from "../../../../../../_shared/hooks/useForm";
import useRestResource from "../../../../../../_shared/hooks/useRestResource";
import { Select, message } from "antd";

function TeamRightSheet(props) {
  // -------------------------------------
  // Props destructuringa
  // -------------------------------------

  const {
    className,
    open,
    defaultValues,
    onConfirm,
    onCancel,
    allUsers,
    onButtonClick,
  } = props;
  // -------------------------------------
  // Hooks (e.g. useState, ...)
  // -------------------------------------

  const [form, , setMany, clearForm] = useForm();
  const user = useSelector((state) => (state?.auth.user ? state.auth : null));
  const { Option } = Select;

  // -------------------------------------
  // Memoized values
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  useEffect(() => {
    !open && clearForm();
  }, [open]);

  useEffect(() => {
    defaultValues && setMany(defaultValues);
  }, [defaultValues]);

  // -------------------------------------
  // Component functions
  // -------------------------------------

  function update(field) {
    return (value) => {
      setMany({
        ...form,
        [field]: value,
      });
    };
  }

  function handleConfirm() {
    onConfirm && onConfirm(form);
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  console.log("form?.users: ", form?.users);
  return (
    <div className={className}>
      <div className="inRow">
        <AppButton className="add-btn" onClick={onButtonClick}>
          Crea nuovo team
        </AppButton>
        <RightSheet
          open={open}
          onCancel={onCancel}
          onConfirm={handleConfirm}
          title={defaultValues?.id ? "Modifica team" : "Crea team"}
          confirmButtonLabel={defaultValues?.id ? "Modifica team" : "Crea team"}
          cancelButtonLabel="Annulla"
          className={`${className}`}
        >
          <form className="form">
            <AppInput
              label="Nome"
              placeholder="Nome"
              value={form?.name ?? ""}
              onChange={update("name")}
            />

            <AppMultiSelect
              label="Utenti del team"
              mode="multiple"
              size={"default"}
              placeholder="Seleziona gli utenti del team"
              onChange={(usersIds) => {
                const newUsers = usersIds?.map((userId) =>
                  allUsers?.find((user) => user?.id === userId)
                );

                update("users")(newUsers);
              }}
              style={{
                marginTop: "20px",
                width: "100%",
              }}
              value={form?.users?.map?.((user) => user.id) ?? []}
            >
              {allUsers &&
                allUsers?.map((user, index) => (
                  <Option key={user.id}>{user.fullName}</Option>
                ))}
            </AppMultiSelect>
          </form>
        </RightSheet>
      </div>
    </div>
  );
}
// ----------------------------------------------------------------------------

const StyledTeamRightSheet = styled(TeamRightSheet)`
  & {
    .form {
      min-width: 280px;
    }
    .app-select {
      min-width: 280px;
    }
    .inRow {
      display: flex;
      flex-direction: row;
    }
  }
`;
export default StyledTeamRightSheet;
