import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import { ReactComponent as CheckIcon } from "../../../assets/icons/check.svg"

// ----------------------------------------------------------------------------

function _Checkbox(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className, checked, label, onChange } = props

    // -------------------------------------
    // Hooks (e.g. useState, useMemo ...)
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function handleStateChange(e) {
        e.stopPropagation()
        onChange && onChange(!checked)
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={`${className}`}>
            <div onClick={handleStateChange} className="check-input">
                <div className="check-icon">
                    <CheckIcon />
                </div>
            </div>
            {label && <label className="check-label">{label}</label>}
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_Checkbox.propTypes = {
    className: PropTypes.string.isRequired,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    activeColor: PropTypes.string,
    inactiveColor: PropTypes.string,
}

_Checkbox.defaultProps = {}

// ----------------------------------------------------------------------------

const Checkbox = styled(_Checkbox)`
    & {
        display: flex;
        align-items: center;
        flex-direction: row;
        font-size: 11px;
        font-weight: 500;
        text-align: center;
        .check-input {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 16px;
            height: 16px;
            border-radius: 4px;
            border: 1px solid ${({ theme }) => theme.colors.neutral300};
            cursor: pointer;
            transition: all 200ms ease;
            background-color: ${({
                checked,
                theme,
                activeColor,
                inactiveColor,
            }) =>
                checked
                    ? activeColor ?? theme.colors.neutral300
                    : inactiveColor ?? theme.colors.light};

            .check-icon {
                transition: all 200ms ease;
                opacity: ${({ checked }) => (checked ? 1 : 0)};
                svg {
                    width: 11px;
                    height: 8px
                }
            }
        }
        .check-label {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.02em;
            color: ${({ theme }) => theme.spacing.dark};
            display: block;
            margin-left: 10px;
        }
    }
`
// ----------------------------------------------------------------------------

export default Checkbox
