import { PlusOutlined } from "@ant-design/icons";
import { Select } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { ReactComponent as Plus } from "../../../../_shared/assets/icons/plus.svg";
import AppButton from "../../../../_shared/components/AppButton";
import AppInput from "../../../../_shared/components/AppInput";
import AppSelect from "../../../../_shared/components/AppSelect";
import CustomField from "../../../../_shared/components/CustomField";
import RightSheet from "../../../../_shared/components/Drawer";
import useForm from "../../../../_shared/hooks/useForm";
import useRestResource from "../../../../_shared/hooks/useRestResource";

function InvoiceRightSheet(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { className, open, defaultValues, onConfirm, onCancel, onButtonClick } =
    props;

  // -------------------------------------
  // Hooks (e.g. useState, ...)
  // -------------------------------------

  const [form, , setMany, clearForm] = useForm();
  const user = useSelector((state) => (state?.auth.user ? state.auth : null));
  const [recipients, setRecipients] = useState();
  const [contacts] = useRestResource("/contacts", "", user.token, {
    organization: user.organization,
  });
  const [companies] = useRestResource("/companies", "", user.token, {
    organization: user.organization,
  });
  const [customFields] = useRestResource("/custom-fields", "", user.token, {
    organization: user.organization,
  });
  const { Option } = Select;

  // -------------------------------------
  // Memoized values
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  useEffect(() => {
    if (form?.recipientType === "Company") setRecipients(companies);
    else setRecipients(contacts);
  });

  useEffect(() => {
    !open && clearForm();
    // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    defaultValues && setMany(defaultValues);
    // eslint-disable-next-line
  }, [defaultValues]);

  // -------------------------------------
  // Component functions
  // -------------------------------------

  function update(field) {
    return (value) => {
      setMany({
        ...form,
        [field]: value,
      });
    };
  }

  function handleConfirm() {
    form?.customFields?.forEach((usedCustomField) =>
      checkValueType(usedCustomField)
    );
    onConfirm && onConfirm(form);
  }

  function handleNewField(e) {
    e.preventDefault();
    const newCustomFields = [
      ...(form?.customFields ?? []),
      {
        key: Math.floor(Math.random() * 99999),
        customField: "",
        value: "",
      },
    ];
    update("customFields")(newCustomFields);
  }

  function handleDeleteCustomField(e, key) {
    e.preventDefault();
    const newCustomFields = form?.customFields.filter(
      (customField) => customField.key !== key
    );
    update("customFields")(newCustomFields);
  }

  function onChange(customFieldId, value, key) {
    const customField = form?.customFields.find(
      (customField) => customField.key === key
    );
    customField.customField = customFieldId;
    customField.value = value;
  }

  function checkValueType(customField) {
    const originalCustomField = customFields.rows.find(
      (originalField) => originalField.id === customField.customField
    );
    if (originalCustomField.type === "number") {
      const value = parseFloat(customField.value);
      customField.value = null;
      customField.value = value;
    }
    if (originalCustomField.type === "boolean") {
      const value =
        customField.value !== "" && customField.value !== "false"
          ? true
          : false;
      customField.value = null;
      customField.value = value;
    }
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <>
      <Link to="nuova" className="link">
        <AppButton className="add-btn">
          Crea nuova fattura{" "}
          <Plus width="14" height="13" style={{ marginLeft: 2 }} />
        </AppButton>
      </Link>

      <RightSheet
        open={open}
        onCancel={onCancel}
        onConfirm={handleConfirm}
        title={defaultValues?.id ? "Modifica fattura" : "Crea fattura"}
        confirmButtonLabel={
          defaultValues?.id ? "Modifica fattura" : "Crea fattura"
        }
        cancelButtonLabel="Annulla"
      >
        <form className="form">
          <AppSelect
            label="Tipo di destinatario"
            placeholder="Tipo"
            value={form?.recipientType ?? ""}
            onSelect={update("recipientType")}
            onChange={() => setRecipients([])}
          >
            <Option key={"Company"} id={1}>
              Azienda
            </Option>
            <Option key={"Contact"} id={2}>
              Contatto
            </Option>
          </AppSelect>

          <AppSelect
            label="Destinatario"
            placeholder="Destinatario"
            value={form?.recipient?.businessName ?? form?.recipient?.name}
            onSelect={update("recipient")}
          >
            {recipients &&
              recipients.rows?.map((recipient, index) => (
                <Option key={recipient.id} id={index}>
                  {recipient.businessName ?? recipient.name}
                </Option>
              ))}
          </AppSelect>
          <AppInput
            label="Data"
            placeholder="Data"
            value={form?.date ?? ""}
            onChange={update("date")}
          />
          <AppInput
            label="Note"
            placeholder="Note"
            value={form?.note ?? ""}
            onChange={update("note")}
          />

          {form?.usedCustomFields?.map((customField, index) => (
            <CustomField
              key={customField.key}
              id={customField.key}
              onChange={onChange}
              onCancel={(e, key) => handleDeleteCustomField(e, key)}
              customFields={customFields?.rows}
              givenCustomField={
                customField.customField ? customField.customField : null
              }
              givenValue={customField.value ? customField.value : null}
            />
          ))}
          <AppButton
            uxType="link"
            onClick={handleNewField}
            className="add-field"
            icon={<PlusOutlined />}
          >
            Aggiungi campo personalizzato
          </AppButton>
        </form>
      </RightSheet>
    </>
  );
}
// ----------------------------------------------------------------------------

const StyledInvoiceRightSheet = styled(InvoiceRightSheet)`
  & {
    .form {
      min-width: 280px;
    }
    .app-select {
      min-width: 280px;
    }
  }
`;
export default StyledInvoiceRightSheet;
