import React, { useState } from "react";
import { HiDuplicate } from "react-icons/hi";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";

import ProductRightSheet from "./ProductRightSheet";

import { ReactComponent as Delete } from "../../../_shared/assets/icons/delete.svg";
import PopConfirm from "../../../_shared/components/PopConfirm";
import Table from "../../../_shared/components/Table";
import usePaginatedRestResource from "../../../_shared/hooks/usePaginatedRestResource";

import { message } from "antd";
import * as api from "../../../../api";

// ----------------------------------------------------------------------------

function Products(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { className } = props;

  // -------------------------------------
  // Hooks (e.g. useState, ...)
  // -------------------------------------

  const user = useSelector((state) => (state?.auth.user ? state.auth : null));
  const [searched, setSearched] = useState("");

  const [
    products,
    ,
    { pagination, count, filters: currentFilters },
    {
      fetchResource: fetchProducts,
      onChange: handleTableChange,
      onFiltersChange,
    },
  ] = usePaginatedRestResource("/products", user.token, {
    defaultFilters: {
      organization: user.organization,
    },
  });

  const [selectedProduct, setSelectedProduct] = useState();
  const [cloneProduct, setCloneProduct] = useState();

  // -------------------------------------
  // Memoized values
  // -------------------------------------

  function getColumns() {
    return [
      {
        title: "CODICE ARTICOLO",
        dataIndex: "code",
        key: "code",
        render: (text, record, index) => (
          <span className="productLink">
            <Link to={`${record.id}`}>{text}</Link>
          </span>
        ),
      },
      {
        title: "DESCRIZIONE",
        dataIndex: "description",
        key: "description",
      },
      {
        title: "DESCRIZIONE ESTESA",
        dataIndex: "extendedDescription",
        key: "extendedDescription",
      },
      {
        title: "UM",
        dataIndex: "um",
        key: "um",
      },
      {
        title: "FAM.",
        dataIndex: "family",
        key: "family",
      },
      {
        title: "DESCRIZIONE FAMIGLIA",
        dataIndex: "familyDescription",
        key: "familyDescription",
      },
      {
        title: "S/FAM",
        dataIndex: "subFamily",
        key: "subFamily",
      },
      {
        title: "DESCRIZIONE SOTTOFAMIGLIA",
        dataIndex: "subFamilyDescription",
        key: "subFamilyDescription",
      },
      {
        title: "STATO ARTICOLO",
        dataIndex: "state",
        key: "state",
      },
      {
        title: "PREZZO UNITARIO",
        dataIndex: "unitPrice",
        key: "unitPrice",
        sorter: (a, b) => a.unitPrice - b.unitPrice,
        render: (text) => `${text}€`,
      },
      {
        title: "",
        dataIndex: "clone",
        key: "clone",
        render: (text, record, index) => (
          <div
            className="duplicate"
            onClick={(e) => handleCloneProduct(e, record)}
          >
            <HiDuplicate />
          </div>
        ),
      },
      {
        title: "",
        dataIndex: "delete",
        key: "delete",
        render: (text, record, index) => (
          <PopConfirm
            className={className}
            title="Sei sicuro di voler rimuovere questo prodotto?"
            onConfirm={(e) => deleteProduct(e, record.id)}
            onCancel={(e) => e.stopPropagation()}
            okText="Sì"
            cancelText="No"
            placement="leftTop"
          >
            <div className="delete" onClick={(e) => e.stopPropagation()}>
              <Delete />
            </div>
          </PopConfirm>
        ),
      },
    ];
  }

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  function handleSearch() {
    onFiltersChange({
      code: searched,
      description: searched,
    });
  }
  function handleChangeSearch(value) {
    if (value.length === 0 && searched.length > value.length) {
      onFiltersChange({
        code: value,
        description: value,
      });
    } else {
      setSearched(value);
    }
  }

  function getTableFilters() {
    return [
      {
        key: "code",
        type: "text",
        value: currentFilters.code,
        onSearch: (value) =>
          onFiltersChange({
            code: value,
          }),
      },
    ];
  }

  async function deleteProduct(e, productId) {
    e.stopPropagation();
    try {
      await api.deleteProduct(productId, user.token);

      fetchProducts();
    } catch (error) {
      console.log("[ERROR] in delete product: ");
      console.log(error);
    }
  }

  function handleCloneProduct(e, product) {
    e.stopPropagation();
    setCloneProduct(product);
  }

  function handleNewProduct() {
    setSelectedProduct({});
  }

  function onCancel() {
    setSelectedProduct(null);
    setCloneProduct(null);
  }

  async function onConfirm(values) {
    try {
      !values?.organization
        ? (values.organization = user.organization)
        : (values.organization = values.organization.id);

      !values?.unitPrice &&
        values?.amount &&
        (values.unitPrice = values.amount);

      const body = {
        ...values,
        taxRate: values?.taxRate?.id ?? values?.taxRate,
      };

      let response = {};
      if (values?.id) {
        response = await api.updateProduct(values.id, body, user.token);
      } else {
        response = await api.createProduct(user.organization, body, user.token);
      }

      fetchProducts();
      setSelectedProduct(null);
      setCloneProduct(null);
      message.success("Operazione eseguita con successo");
    } catch (error) {
      console.log("[Error] in confirm product: ", error);
      if (error.statusCode === 409) {
        message.error("Prodotto già esistente");
      } else {
        message.error("Errore durante l'operazione");
      }
    }
  }

  console.log("pagination: ", pagination);

  return (
    <Table
      className={className}
      columns={getColumns()}
      onResourceCreate={() => selectedProduct({})}
      dataSource={products}
      pagination={{ ...(pagination ?? {}), total: count }}
      onChange={handleTableChange}
      rowSelection={{ type: "checkbox" }}
      addContact="true"
      handleOnRow={(row) => setSelectedProduct(row)}
      renderTopBarCustomButtons={() => (
        <ProductRightSheet
          open={!!selectedProduct || cloneProduct}
          defaultValues={selectedProduct}
          cloningValues={cloneProduct}
          onConfirm={onConfirm}
          onCancel={onCancel}
          onButtonClick={handleNewProduct}
        />
      )}
      filters={getTableFilters()}
      search
      handleSearchSubmit={handleSearch}
      handleOnChangeSearch={handleChangeSearch}
    />
  );
}

// ----------------------------------------------------------------------------

const StyledProducts = styled(Products)`
  & {
    .productLink {
      text-decoration: underline;
    }
    .form {
      min-width: 280px;
    }
    .row {
      display: flex;
      flex-direction: row;
      align-items: center;

      span {
        margin-left: 8px;
      }
    }
    .app-select {
      min-width: 280px;
    }
    .addField {
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
      margin: auto;
      margin-top: 10px;
      padding: 5px;
      border: none;
      .icon {
        margin: auto;
        margin-right: 10px;
        height: 14px;
      }
    }

    .duplicate {
      width: 35px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 24px;
      font-size: 24px;
      svg path {
        fill: ${({ theme }) => theme.colors.grey100};
      }
      :hover {
        background-color: ${({ theme }) => theme.colors.grey200};
      }
    }
  }
`;
export default StyledProducts;
