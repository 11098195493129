import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ----------------------------------------------------------------------------

const initialState = {
    token: null,
    user: null,
    email: null,
    organization: null,
};

// ----------------------------------------------------------------------------
// Slice
// ----------------------------------------------------------------------------

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        login: (state, action) => {
            state.user = action.payload.fullName;
            state.token = action.payload.token;
            state.email = action.payload.email;
            state.organization = action.payload.organization;
        },
        logout: () => {
            return initialState;
        },
    },
});

const { login, logout } = authSlice.actions;

export { logout, login };

export default authSlice.reducer;
