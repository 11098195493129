/* global process */
import dayjs from "dayjs"
import URLJoin from "url-join"
import urljoin from "url-join"
// ----------------------------------------------------------------------------

const DEBUG_API = process.env.NODE_ENV === "development"

const DEFAULT_API_PORT = "3001"
export const BASE_URL = getBaseURL()
const ACCESS_TOKEN = "aYL4PbpTk6vGEtaRnYJmgBWx4zrgDepf"

// ----------------------------------------------------------------------------

/* eslint-disable */
export function getBaseURL() {
	if (process.env.NODE_ENV === "development") {
		return "http://localhost:3001"
	} else {
		return `https://giarvis.no-gravity.it/api`
	}
}
// ----------------------------------------------------------------------------

export class APINotOkError extends Error {
	constructor(statusCode, serverResponse) {
		const message =
			(serverResponse && serverResponse.message) ||
			`HTTP status code: ${statusCode}`
		super(message)
		this.name = "APINotOkError"
		this.statusCode = statusCode
		this.serverMessage = serverResponse && serverResponse.state
		this.errorKey = serverResponse && serverResponse.key
	}
}

// ----------------------------------------------------------------------------
// Auth
// ----------------------------------------------------------------------------

export function signUp(email, password, name, surname) {
	const headers = {}
	const body = {
		email,
		password,
		name,
		surname,
		access_token: ACCESS_TOKEN,
	}

	return executePOSTRequest(URLJoin(BASE_URL, "/users"), null, body, headers)
}

export function logIn(email, password) {
	const base64 = require("js-base64").Base64
	var encoded = base64.encode(`${email}:${password}`)
	const headers = {
		Authorization: `Basic ${encoded}`,
	}

	const body = { access_token: ACCESS_TOKEN }

	return executePOSTRequest(URLJoin(BASE_URL, "/auth"), null, body, headers)
}

// ----------------------------------------------------------------------------
// Users
// ----------------------------------------------------------------------------

export function createAdminUser({ email, password, name, surname }) {
	const body = {
		email,
		password,
		name,
		surname,
		role: "admin",
	}

	return signUp(body)
}

export function getUser(userId, token) {
	let relativeUrl = `users/${userId}`
	const url = URLJoin(BASE_URL, relativeUrl)

	return executeGETRequest(url, token)
}

export function getUserByEmail(email, token) {
	let relativeUrl = `users?email=${email}`
	const url = URLJoin(BASE_URL, relativeUrl)

	return executeGETRequest(url, token)
}

export function getUsers(organization, token) {
	let relativeUrl = `users?organizations=${organization}`
	const url = URLJoin(BASE_URL, relativeUrl)

	return executeGETRequest(url, token)
}

export function searchUsers(query, organization, token) {
	let relativeUrl = `users/search?organizations=${organization}?search=${query}`
	const url = URLJoin(BASE_URL, relativeUrl)

	return executeGETRequest(url, token)
}

export function updateUser(userId, updateData, token) {
	let relativeUrl = `users/${userId}`
	const url = URLJoin(BASE_URL, relativeUrl)

	const body = {
		...updateData,
	}

	return executePUTRequest(url, token, body)
}

export function recoverPsw(email) {
	//return executePOSTRequest(URLJoin(BASE_URL, "/users"), null, body, headers)
	return email
}

// ----------------------------------------------------------------------------
// Invites
// ----------------------------------------------------------------------------

export function createInvite(inviteBody, token) {
	const body = {
		...inviteBody,
	}

	return executePOSTRequest(URLJoin(BASE_URL, "/invites"), token, body).then(
		(response) => {
			return response
		}
	)
}

export function getInvites(organization, token) {
	let relativeUrl = `invites?organization=${organization}`
	const url = URLJoin(BASE_URL, relativeUrl)

	return executeGETRequest(url, token)
}

export function getInvite(inviteId) {
	let relativeUrl = `invites/${inviteId}`
	const url = URLJoin(BASE_URL, relativeUrl)

	return executeGETRequest(url)
}

export function updateInvite(inviteId, updateData) {
	let relativeUrl = `invites/${inviteId}`
	const url = URLJoin(BASE_URL, relativeUrl)
	const body = {
		...updateData,
	}

	return executePUTRequest(url, null, body)
}

export function deleteInvite(inviteId) {
	let relativeUrl = `invites/${inviteId}`
	const url = URLJoin(BASE_URL, relativeUrl)
	return executeDELETERequest(url)
}

// ----------------------------------------------------------------------------
// Contacts
// ----------------------------------------------------------------------------

export function createContact(contactBody, token) {
	const body = {
		...contactBody,
	}

	return executePOSTRequest(URLJoin(BASE_URL, "/contacts"), token, body).then(
		(response) => {
			return response
		}
	)
}

export function getContacts(organization, token) {
	let relativeUrl = `contacts?organization=${organization}`
	const url = URLJoin(BASE_URL, relativeUrl)

	return executeGETRequest(url, token)
}

export function getContact(contactId, token) {
	let relativeUrl = `contacts/${contactId}`
	const url = URLJoin(BASE_URL, relativeUrl)

	return executeGETRequest(url, token)
}

export function updateContact(contactId, updateData, token) {
	let relativeUrl = `contacts/${contactId}`
	const url = URLJoin(BASE_URL, relativeUrl)
	const body = {
		...updateData,
	}
	return executePUTRequest(url, token, body)
}

export function searchContacts(query, organization, token) {
	let relativeUrl = `contacts/search?organization=${organization}?search=${query}`
	const url = URLJoin(BASE_URL, relativeUrl)

	return executeGETRequest(url, token)
}

export function deleteContact(contactId, token) {
	let relativeUrl = `contacts/${contactId}`
	const url = URLJoin(BASE_URL, relativeUrl)
	return executeDELETERequest(url, token)
}

// ----------------------------------------------------------------------------
// Agents
// ----------------------------------------------------------------------------

export function createAgent(agentBody, token) {
	const body = {
		...agentBody,
	}

	return executePOSTRequest(URLJoin(BASE_URL, "/agents"), token, body).then(
		(response) => {
			return response
		}
	)
}

export function getAgents(organization, token) {
	let relativeUrl = `agents?organization=${organization}`
	const url = URLJoin(BASE_URL, relativeUrl)

	return executeGETRequest(url, token)
}

export function getAgent(agentId, token) {
	let relativeUrl = `agents/${agentId}`
	const url = URLJoin(BASE_URL, relativeUrl)

	return executeGETRequest(url, token)
}

export function updateAgent(agentId, updateData, token) {
	let relativeUrl = `agents/${agentId}`
	const url = URLJoin(BASE_URL, relativeUrl)
	const body = {
		...updateData,
	}
	return executePUTRequest(url, token, body)
}

export function deleteAgent(agentId, token) {
	let relativeUrl = `agents/${agentId}`
	const url = URLJoin(BASE_URL, relativeUrl)
	return executeDELETERequest(url, token)
}

// ----------------------------------------------------------------------------
// Companies
// ----------------------------------------------------------------------------
export function createCompany(businessBody, token) {
	const body = {
		...businessBody,
	}
	return executePOSTRequest(URLJoin(BASE_URL, "/companies"), token, body).then(
		(response) => {
			return response
		}
	)
}

export function getCompanies(organization, token) {
	let relativeUrl = `companies?organization=${organization}`
	const url = URLJoin(BASE_URL, relativeUrl)

	return executeGETRequest(url, token)
}

export function getCompany(companyId, token) {
	let relativeUrl = `companies/${companyId}`
	const url = URLJoin(BASE_URL, relativeUrl)

	return executeGETRequest(url, token)
}

export function updateCompany(companyId, updateData, token) {
	let relativeUrl = `companies/${companyId}`
	const url = URLJoin(BASE_URL, relativeUrl)
	const body = {
		...updateData,
	}
	return executePUTRequest(url, token, body)
}
export function deleteCompany(companyId, token) {
	let relativeUrl = `companies/${companyId}`
	const url = URLJoin(BASE_URL, relativeUrl)
	return executeDELETERequest(url, token)
}

export function searchCompanies(query, organization, token) {
	let relativeUrl = `companies/search?organization=${organization}?search=${query}`
	const url = URLJoin(BASE_URL, relativeUrl)

	return executeGETRequest(url, token)
}

// ----------------------------------------------------------------------------
// Organizations
// ----------------------------------------------------------------------------
export function createOrganization(businessBody, token) {
	const body = {
		...businessBody,
	}
	return executePOSTRequest(URLJoin(BASE_URL, "/organizations"), token, body)
}
export function getOrganization(orgId, token) {
	let relativeUrl = `organizations/${orgId}`
	const url = URLJoin(BASE_URL, relativeUrl)

	return executeGETRequest(url, token)
}

export function getOrganizations(vatNumber, token) {
	let relativeUrl = `organizations?vatNumber=${vatNumber}`
	const url = URLJoin(BASE_URL, relativeUrl)

	return executeGETRequest(url, token)
}

export function updateOrganization(orgId, updateData, token) {
	let relativeUrl = `organizations/${orgId}`
	const url = URLJoin(BASE_URL, relativeUrl)
	const body = {
		...updateData,
	}

	return executePUTRequest(url, token, body)
}

export function deleteOrganization(orgId, token) {
	let relativeUrl = `organization/${orgId}`
	const url = URLJoin(BASE_URL, relativeUrl)
	return executeDELETERequest(url, token)
}

// ----------------------------------------------------------------------------
// Deals
// ----------------------------------------------------------------------------
export function createDeal(dealBody, token) {
	const body = {
		...dealBody,
	}

	return executePOSTRequest(URLJoin(BASE_URL, "/deals"), token, body).then(
		(response) => {
			return response
		}
	)
}

export function getDeals(organization, token) {
	let relativeUrl = `deals?organization=${organization}`
	const url = URLJoin(BASE_URL, relativeUrl)

	return executeGETRequest(url, token)
}

export function getDeal(dealId, token) {
	let relativeUrl = `deals/${dealId}`
	const url = URLJoin(BASE_URL, relativeUrl)

	return executeGETRequest(url, token)
}

export function updateDeal(dealId, updateData, token) {
	let relativeUrl = `deals/${dealId}`
	const url = URLJoin(BASE_URL, relativeUrl)
	const body = {
		...updateData,
	}

	return executePUTRequest(url, token, body)
}

export function deleteDeal(dealId, token) {
	let relativeUrl = `deals/${dealId}`
	const url = URLJoin(BASE_URL, relativeUrl)
	return executeDELETERequest(url, token)
}

// ----------------------------------------------------------------------------
// Rate
// ----------------------------------------------------------------------------
export function createRate(dealBody, token) {
	const body = {
		...dealBody,
	}

	return executePOSTRequest(URLJoin(BASE_URL, "/rates"), token, body).then(
		(response) => {
			return response
		}
	)
}

export function getRates(organization, token) {
	let relativeUrl = `rates?organization=${organization}`
	const url = URLJoin(BASE_URL, relativeUrl)

	return executeGETRequest(url, token)
}

export function updateRate(rateId, updateData, token) {
	let relativeUrl = `rates/${rateId}`
	const url = URLJoin(BASE_URL, relativeUrl)
	const body = {
		...updateData,
	}

	return executePUTRequest(url, token, body)
}

export function deleteRate(rateId, token) {
	let relativeUrl = `rates/${rateId}`
	const url = URLJoin(BASE_URL, relativeUrl)
	return executeDELETERequest(url, token)
}

// ----------------------------------------------------------------------------
// Port
// ----------------------------------------------------------------------------
export function createPort(port, token) {
	const body = {
		...port,
	}

	return executePOSTRequest(URLJoin(BASE_URL, "/ports"), token, body).then(
		(response) => {
			return response
		}
	)
}

export function getPorts(organization, token) {
	let relativeUrl = `ports?organization=${organization}`
	const url = URLJoin(BASE_URL, relativeUrl)

	return executeGETRequest(url, token)
}

export function updatePort(portId, updateData, token) {
	let relativeUrl = `ports/${portId}`
	const url = URLJoin(BASE_URL, relativeUrl)
	const body = {
		...updateData,
	}

	return executePUTRequest(url, token, body)
}

export function deletePort(portId, token) {
	let relativeUrl = `ports/${portId}`
	const url = URLJoin(BASE_URL, relativeUrl)
	return executeDELETERequest(url, token)
}

// ----------------------------------------------------------------------------
// Product
// ----------------------------------------------------------------------------
export async function createProduct(organization, product, token) {
	const body = {
		organization,
		...product,
	}

	return executePOSTRequest(URLJoin(BASE_URL, "/products"), token, body)
}

export async function getProducts(organization, token) {
	let relativeUrl = `products?organization=${organization}`
	const url = URLJoin(BASE_URL, relativeUrl)

	return executeGETRequest(url, token)
}

export async function getProduct(productId, token) {
	let relativeUrl = `products/${productId}`
	const url = URLJoin(BASE_URL, relativeUrl)

	return executeGETRequest(url, token)
}

export async function updateProduct(productId, updateData, token) {
	let relativeUrl = `products/${productId}`
	const url = URLJoin(BASE_URL, relativeUrl)
	const body = {
		...updateData,
	}

	return executePUTRequest(url, token, body)
}

export async function deleteProduct(productId, token) {
	let relativeUrl = `products/${productId}`
	const url = URLJoin(BASE_URL, relativeUrl)
	return executeDELETERequest(url, token)
}

// ----------------------------------------------------------------------------
// Invoice
// ----------------------------------------------------------------------------
export function createInvoice(organization, invoice, token) {
	const body = {
		organization,
		...invoice,
	}

	return executePOSTRequest(URLJoin(BASE_URL, "/invoices"), token, body).then(
		(response) => {
			return response
		}
	)
}

export function getInvoices(organization, token) {
	let relativeUrl = `invoices?organization=${organization}`
	const url = URLJoin(BASE_URL, relativeUrl)

	return executeGETRequest(url, token)
}

export function getInvoice(invoiceId, token) {
	let relativeUrl = `invoices/${invoiceId}`
	const url = URLJoin(BASE_URL, relativeUrl)

	return executeGETRequest(url, token)
}

export function updateInvoice(invoiceId, updateData, token) {
	let relativeUrl = `invoices/${invoiceId}`
	const url = URLJoin(BASE_URL, relativeUrl)
	const body = {
		...updateData,
	}

	return executePUTRequest(url, token, body)
}

export function deleteInvoice(invoiceId, token) {
	let relativeUrl = `invoices/${invoiceId}`
	const url = URLJoin(BASE_URL, relativeUrl)
	return executeDELETERequest(url, token)
}

// ----------------------------------------------------------------------------
// Order
// ----------------------------------------------------------------------------
export function createOrder(order, token) {
	const body = {
		...order,
	}

	return executePOSTRequest(URLJoin(BASE_URL, "/orders"), token, body).then(
		(response) => {
			return response
		}
	)
}

export function getOrders(organization, token) {
	let relativeUrl = `orders?organization=${organization}`
	const url = URLJoin(BASE_URL, relativeUrl)

	return executeGETRequest(url, token)
}

export function getOrder(orderID, token) {
	let relativeUrl = `orders/${orderID}`
	const url = URLJoin(BASE_URL, relativeUrl)

	return executeGETRequest(url, token)
}
export function updateOrder(orderId, updateData, token) {
	let relativeUrl = `orders/${orderId}`
	const url = URLJoin(BASE_URL, relativeUrl)
	const body = {
		...updateData,
	}

	return executePUTRequest(url, token, body)
}

export function deleteOrder(orderId, token) {
	let relativeUrl = `orders/${orderId}`
	const url = URLJoin(BASE_URL, relativeUrl)
	return executeDELETERequest(url, token)
}

// ----------------------------------------------------------------------------
// DeliveryNote
// ----------------------------------------------------------------------------
export function createDeliveryNote(deliveryNote, token) {
	const body = {
		...deliveryNote,
	}

	return executePOSTRequest(URLJoin(BASE_URL, "/delivery-notes"), token, body)
}

export function getDeliveryNotes(organization, token) {
	let relativeUrl = `delivery-notes?organization=${organization}`
	const url = URLJoin(BASE_URL, relativeUrl)

	return executeGETRequest(url, token)
}

export function getDeliveryNote(ddtId, token) {
	let relativeUrl = `delivery-notes/${ddtId}`
	const url = URLJoin(BASE_URL, relativeUrl)

	return executeGETRequest(url, token)
}

export function updateDeliveryNotes(orderId, updateData, token) {
	let relativeUrl = `delivery-notes/${orderId}`
	const url = URLJoin(BASE_URL, relativeUrl)
	const body = {
		...updateData,
	}

	return executePUTRequest(url, token, body)
}

export function deleteDeliveryNote(orderId, token) {
	let relativeUrl = `delivery-notes/${orderId}`
	const url = URLJoin(BASE_URL, relativeUrl)
	return executeDELETERequest(url, token)
}

// ----------------------------------------------------------------------------
// Projects
// ----------------------------------------------------------------------------
export function createProject(project, token) {
	const body = {
		...project,
	}

	return executePOSTRequest(URLJoin(BASE_URL, "/projects"), token, body)
}

export function getProjects(organization, token) {
	let relativeUrl = `projects?organization=${organization}`
	const url = URLJoin(BASE_URL, relativeUrl)

	return executeGETRequest(url, token)
}

export function getProject(projectId, token) {
	let relativeUrl = `projects/${projectId}`
	const url = URLJoin(BASE_URL, relativeUrl)

	return executeGETRequest(url, token)
}

export function updateProject(projectId, updateData, token) {
	let relativeUrl = `projects/${projectId}`
	const url = URLJoin(BASE_URL, relativeUrl)
	const body = {
		...updateData,
	}
	console.log(body)
	return executePUTRequest(url, token, body)
}

export function deleteProject(projectId, token) {
	let relativeUrl = `projects/${projectId}`
	const url = URLJoin(BASE_URL, relativeUrl)
	return executeDELETERequest(url, token)
}

// ----------------------------------------------------------------------------
// TaskLists
// ----------------------------------------------------------------------------
export function createTaskList(taskList, token) {
	const body = {
		...taskList,
	}
	console.log("api create task list")
	return executePOSTRequest(URLJoin(BASE_URL, "/tasklists"), token, body)
}

export function getTaskLists(organization, token) {
	let relativeUrl = `tasklists?organization=${organization}`
	const url = URLJoin(BASE_URL, relativeUrl)

	return executeGETRequest(url, token)
}

export function getTaskList(taskListId, token) {
	let relativeUrl = `tasklists/${taskListId}`
	const url = URLJoin(BASE_URL, relativeUrl)

	return executeGETRequest(url, token)
}

export function updateTaskList(taskListId, updateData, token) {
	let relativeUrl = `tasklists/${taskListId}`
	const url = URLJoin(BASE_URL, relativeUrl)
	const body = {
		...updateData,
	}

	return executePUTRequest(url, token, body)
}

export function deleteTaskList(taskListId, token) {
	let relativeUrl = `task-lists/${taskListId}`
	const url = URLJoin(BASE_URL, relativeUrl)
	return executeDELETERequest(url, token)
}

// ----------------------------------------------------------------------------
// Tasks
// ----------------------------------------------------------------------------
export function createTask(task, token) {
	const body = {
		...task,
	}

	return executePOSTRequest(URLJoin(BASE_URL, "/tasks"), token, body)
}

export function getTasks(organization, token) {
	let relativeUrl = `tasks?organization=${organization}`
	const url = URLJoin(BASE_URL, relativeUrl)

	return executeGETRequest(url, token)
}

export function getTask(taskId, token) {
	let relativeUrl = `tasks/${taskId}`
	const url = URLJoin(BASE_URL, relativeUrl)

	return executeGETRequest(url, token)
}

export function updateTask(taskId, updateData, token) {
	let relativeUrl = `tasks/${taskId}`
	const url = URLJoin(BASE_URL, relativeUrl)
	const body = {
		...updateData,
	}

	return executePUTRequest(url, token, body)
}

export function deleteTask(taskId, token) {
	let relativeUrl = `tasks/${taskId}`
	const url = URLJoin(BASE_URL, relativeUrl)
	return executeDELETERequest(url, token)
}

// ----------------------------------------------------------------------------
// Chats
// ----------------------------------------------------------------------------
export function createChat(chat, token) {
	const body = {
		...chat,
	}

	return executePOSTRequest(URLJoin(BASE_URL, "/chats"), token, body)
}

export function getChats(organization, assigned, token) {
	let relativeUrl = `chats?organization=${organization}&assignedTo=${assigned}`
	const url = URLJoin(BASE_URL, relativeUrl)

	return executeGETRequest(url, token)
}

export function getChat(chatId, token) {
	let relativeUrl = `chats/${chatId}`
	const url = URLJoin(BASE_URL, relativeUrl)

	return executeGETRequest(url, token)
}

export function updateChat(chatId, updateData, token) {
	let relativeUrl = `chats/${chatId}`
	const url = URLJoin(BASE_URL, relativeUrl)
	const body = {
		...updateData,
	}

	return executePUTRequest(url, token, body)
}

export function deleteChat(chatId, token) {
	let relativeUrl = `chats/${chatId}`
	const url = URLJoin(BASE_URL, relativeUrl)
	return executeDELETERequest(url, token)
}

// ----------------------------------------------------------------------------
// Comments
// ----------------------------------------------------------------------------
export function createComment(chat, token) {
	const body = {
		...chat,
	}

	return executePOSTRequest(URLJoin(BASE_URL, "/comments"), token, body)
}

export function getComments(organization, token) {
	let relativeUrl = `comments?organization=${organization}`
	const url = URLJoin(BASE_URL, relativeUrl)

	return executeGETRequest(url, token)
}

export function getComment(commentId, token) {
	let relativeUrl = `comments/${commentId}`
	const url = URLJoin(BASE_URL, relativeUrl)

	return executeGETRequest(url, token)
}

export function updateComment(commentId, updateData, token) {
	let relativeUrl = `comments/${commentId}`
	const url = URLJoin(BASE_URL, relativeUrl)
	const body = {
		...updateData,
	}

	return executePUTRequest(url, token, body)
}

export function deleteComment(commentId, token) {
	let relativeUrl = `comments/${commentId}`
	const url = URLJoin(BASE_URL, relativeUrl)
	return executeDELETERequest(url, token)
}

// ----------------------------------------------------------------------------
// Milestones
// ----------------------------------------------------------------------------
export function createMilestone(milestone, token) {
	const body = {
		...milestone,
	}

	return executePOSTRequest(URLJoin(BASE_URL, "/milestones"), token, body)
}

export function getMilestones(organization, token) {
	let relativeUrl = `milestones?organization=${organization}`
	const url = URLJoin(BASE_URL, relativeUrl)

	return executeGETRequest(url, token)
}

export function getMilestone(milestonesId, token) {
	let relativeUrl = `milestones/${milestonesId}`
	const url = URLJoin(BASE_URL, relativeUrl)

	return executeGETRequest(url, token)
}

export function updateMilestone(milestonesId, updateData, token) {
	let relativeUrl = `milestones/${milestonesId}`
	const url = URLJoin(BASE_URL, relativeUrl)
	const body = {
		...updateData,
	}
	console.log(body)
	return executePUTRequest(url, token, body)
}

export function deleteMilestone(milestonesId, token) {
	let relativeUrl = `milestones/${milestonesId}`
	const url = URLJoin(BASE_URL, relativeUrl)
	return executeDELETERequest(url, token)
}

// ----------------------------------------------------------------------------
// Teams
// ----------------------------------------------------------------------------
export function createTeam(team, token) {
	const body = {
		...team,
	}

	return executePOSTRequest(URLJoin(BASE_URL, "/teams"), token, body)
}

export function getTeams(organization, token) {
	let relativeUrl = `teams?organization=${organization}`
	const url = URLJoin(BASE_URL, relativeUrl)

	return executeGETRequest(url, token)
}

export function getTeam(teamId, token) {
	let relativeUrl = `teams/${teamId}`
	const url = URLJoin(BASE_URL, relativeUrl)

	return executeGETRequest(url, token)
}

export function updateTeam(teamId, updateData, token) {
	let relativeUrl = `teams/${teamId}`
	const url = URLJoin(BASE_URL, relativeUrl)
	const body = {
		...updateData,
	}
	console.log(body)
	return executePUTRequest(url, token, body)
}

export function deleteTeam(teamId, token) {
	let relativeUrl = `teams/${teamId}`
	const url = URLJoin(BASE_URL, relativeUrl)
	return executeDELETERequest(url, token)
}

// ----------------------------------------------------------------------------
// CustomFields
// ----------------------------------------------------------------------------
export function createCustomField(customFieldBody, token) {
	const body = {
		...customFieldBody,
	}

	return executePOSTRequest(URLJoin(BASE_URL, "/custom-fields"), token, body)
}

export function getCustomFields(organization, token) {
	let relativeUrl = `custom-fields?organization=${organization}`
	const url = URLJoin(BASE_URL, relativeUrl)

	return executeGETRequest(url, token)
}

export function updateCustomField(customFieldId, updateData, token) {
	let relativeUrl = `custom-fields/${customFieldId}`
	const url = URLJoin(BASE_URL, relativeUrl)
	const body = {
		...updateData,
	}

	return executePUTRequest(url, token, body)
}

export function deleteCustomField(customFieldId, token) {
	let relativeUrl = `custom-fields/${customFieldId}`
	const url = URLJoin(BASE_URL, relativeUrl)
	return executeDELETERequest(url, token)
}

// ----------------------------------------------------------------------------
// Search
// ----------------------------------------------------------------------------
export function search(organization, search, token) {
	let relativeUrl = `search?organization=${organization}?search=${search}`
	const url = URLJoin(BASE_URL, relativeUrl)

	return executeGETRequest(url, token)
}

// ----------------------------------------------------------------------------
// REST Resources
// ----------------------------------------------------------------------------
export function getResourceList(resourceURI, token, options) {
	const { pageNumber, pageSize = 10, sort, filters } = options
	const url = URLJoin(BASE_URL, resourceURI)

	const filterParams = {}
	for (let filterKey in filters) {
		if (filters[filterKey]?.length) {
			if (Array.isArray(filters[filterKey])) {
				filterParams[filterKey] = filters[filterKey].join(",")
			} else {
				filterParams[filterKey] = filters[filterKey]
			}
		}
	}

	const params = {
		...filterParams,
		 limit: pageSize,
	}

	if (pageNumber) {
		params.page = pageNumber
	}

	if (sort) {
		const sortOperator = sort.order === "descend" ? "-" : "+"
		params.sort = `${sortOperator}${sort.key}`
	}

	const urlWithQuery = urlWithQueryParams(url, params)
	return executeGETRequest(urlWithQuery, token, { isList: true }).then(
		(response) => {
			return response
		}
	)
}

export function getResource(resourceURI, token) {
	const url = URLJoin(BASE_URL, resourceURI)
	return executeGETRequest(url, token, { isList: false })
}

// ----------------------------------------------------------------------------
// Basic Helpers
// ----------------------------------------------------------------------------

export function executePOSTRequest(url, token, body, headers) {
	const newHeaders = headers || {}
	if (token) {
		newHeaders["Authorization"] = `Bearer ${token}`
	}

	return executeRequest(url, "post", body, newHeaders)
}

export function executeGETRequest(url, token, options) {
	const headers = {
		Authorization: `Bearer ${token}`,
	}
	return executeRequest(url, "get", null, headers, options)
}

export function executePUTRequest(url, token, body, headers) {
	const newHeaders = headers || {}
	if (token) {
		newHeaders["Authorization"] = `Bearer ${token}`
	}
	return executeRequest(url, "put", body, newHeaders)
}
export function executeDELETERequest(url, token) {
	const headers = {
		Authorization: `Bearer ${token}`,
	}
	return executeRequest(url, "delete", null, headers)
}
// ----------------------------------------------------------------------------

function executeRequest(givenURL, method, givenBody, givenHeaders, options) {
	let url = normalizeURL(givenURL)
	const headers = {
		...givenHeaders,
		Accept: "application/json",
	}
	let body
	if (givenBody) {
		if (headers["Content-Type"]) {
			body = givenBody
		} else {
			headers["Content-Type"] = "application/json"
			body = JSON.stringify(givenBody)
			if (DEBUG_API) {
				// eslint-disable-next-line
				console.debug(`[API/REQUEST/BODY]`, url, givenBody)
			}
		}
	}
	const requestData = {
		method,
		headers,
		body,
	}
	if (DEBUG_API) {
		// eslint-disable-next-line
		console.debug(`[API/REQUEST]`, url, requestData)
	}

	return fetch(url, requestData).then((response) => {
		if (DEBUG_API) {
			// eslint-disable-next-line
			console.debug(`[API/RESPONSE]`, url, response)
		}

		return response.text().then(function (text) {
			let responseBody
			try {
				responseBody = JSON.parse(text)
			} catch (e) {
				responseBody = text
			}

			if (DEBUG_API) {
				// eslint-disable-next-line
				console.debug(`[API/RESPONSE/BODY]`, responseBody)
			}

			if (response.ok) {
				return responseBody
			} else {
				throw new APINotOkError(response.status, responseBody, url)
			}
			//}
		})
	})
}

// ----------------------------------------------------------------------------

function normalizeURL(givenURL) {
	let isPathAbsolute =
		givenURL.indexOf("http://") === 0 || givenURL.indexOf("https://") === 0
	if (isPathAbsolute) {
		return givenURL
	} else {
		return URLJoin(BASE_URL, givenURL.replace(/^\.\//, ""))
	}
}

function urlWithQueryParams(url, params) {
	const urlParams = Object.entries(params)
		.map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
		.join("&")
	return `${url}?${urlParams}`
}
