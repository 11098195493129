import { React, useRef, useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import SearchBox from "../../../../RoutedApp/LoggedApp/DashItems/SearchBox"

// ----------------------------------------------------------------------------

function TableTopBar(props) {
	// -------------------------------------
	// Props destructuring
	// -------------------------------------

	const {
		showNewResourceForm,
		selectionCount,
		deleteResources,
		children,
		totalResources,
		visibleResources,
		pagination,
		newResourceButtonDisabled,
		search,
		addContact,
		renderTopBarCustomButtons,
        handleSearchSubmit,
        handleOnChangeSearch
	} = props

	const inputRef = useRef()

	// -------------------------------------
	// Hooks (e.g. useState, useMemo ...)
	// -------------------------------------

	// -------------------------------------
	// Effects
	// -------------------------------------

	// -------------------------------------
	// Component functions
	// -------------------------------------

	// -------------------------------------
	// Component local variables
	// -------------------------------------

	let currentItemShowed = visibleResources

	// if (pagination && pagination.pageSize && visibleResources) {
	//     let currentPage = pagination.current || 1
	//     if (pagination) {
	//         let startItems = (currentPage - 1) * pagination.pageSize
	//         if (startItems === 0) {
	//             startItems = 1
	//         }
	//         let endItems =
	//             (currentPage - 1) * pagination.pageSize + visibleResources
	//         currentItemShowed = startItems + "-" + endItems + "  "
	//     }
	// }

	return (
		<div className={props.className}>
			<div className="left-section">
				{search ? (
					<SearchBox 
                        showNotification={false} 
                        handleSearchSubmit={handleSearchSubmit}
                        handleOnChangeSearch={handleOnChangeSearch}
                        className="searchBox" 
                    />
				) : (
					""
				)}
			</div>
			<div className="right-section">{renderTopBarCustomButtons()}</div>
		</div>
	)
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

TableTopBar.propTypes = {
	className: PropTypes.string.isRequired,
	showNewResourceForm: PropTypes.func,
	selectionCount: PropTypes.number, //.isRequired,
	deleteResources: PropTypes.func,
	children: PropTypes.any,
	totalResources: PropTypes.number,
	list: PropTypes.array, //.isRequired,
	pagination: PropTypes.any,
	visibleResources: PropTypes.number,
}

TableTopBar.defaultProps = {}

// ----------------------------------------------------------------------------

const StyledTableTopBar = styled(TableTopBar)`
	& {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		flex-direction: row;
		align-items: center;
		height: 90px;

		.left-section {
			display: flex;
			flex-direction: row;
			align-items: center;

			.searchBox {
				position: unset;
				margin-left: 30px;

				.icon {
					top: -40px;
					svg {
						stroke: ${({ theme }) => theme.colors.shade2};
						opacity: 0.3;
					}
				}

				form .input {
					width: 325px;

					.app-input {
						background-color: unset;
						border: 1px solid ${({ theme }) => theme.colors.shade2};
					}
				}
			}
		}

		.center-section {
			flex: 1;
			display: flex;
			flex-direction: row;
			align-items: center;
		}

		.right-section {
			display: flex;
			flex-direction: row;
			align-items: center;

			.add-btn {
				padding: 0 15px;
				margin-right: ${({ theme }) => theme.spacing.s}px;
			}

			> .count-tag {
				cursor: auto;
				background: transparent;
				color: #8d9bad;
				border: 1px solid #b1bcc9;
			}
		}

		.form {
			width: 25vw;
		}
	}
`
// ----------------------------------------------------------------------------

export default StyledTableTopBar
