import { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import {
  message,
  Select,
  Tabs,
  Row,
  Col,
  Modal,
  Button,
  Popover,
  Radio,
} from "antd";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import AppSelect from "../../../../_shared/components/AppSelect";

import * as api from "../../../../../api";

import useForm from "../../../../_shared/hooks/useForm";
import AppButton from "../../../../_shared/components/AppButton";
import AppInput from "../../../../_shared/components/AppInput";
import AppText from "../../../../_shared/components/AppText";

import useRestResource from "../../../../_shared/hooks/useRestResource";
import {
  Link,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { ReactComponent as ArrowBack } from "../../../../../App/_shared/assets/icons/arrowback.svg";

import { ReactComponent as ModifyIcon } from "../../../../../App/_shared/assets/icons/modify.svg";
import { ReactComponent as ViewSelect } from "../../../../../App/_shared/assets/icons/viewSelect.svg";

import projectStatus from "../../../../_shared/constants/projectStatus";
import AppDataPicker from "../../../../_shared/components/AppDatePicker";

import AppUserSelect from "../../../../_shared/components/AppUserSelect";

import TaskListCreation from "../TaskListCreation";
import TaskListCard from "../TaskListCard";
import Milestones from "../../Milestones";
import ChatList from "../../Chats/ChatList";

import DraggableView from "../DraggableView";

// ----------------------------------------------------------------------------

function ProjectData(props) {
  const navigate = useNavigate();
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { className } = props;
  const { TabPane } = Tabs;

  const { Option } = Select;
  const location = useLocation();

  // -------------------------------------
  // Hooks (e.g. useState, ...)
  // -------------------------------------
  const [form, , setMany, clearForm] = useForm();
  const params = useParams();
  const user = useSelector((state) => (state?.auth.user ? state.auth : null));
  const divRef = useRef();
  const [selectedTaskList, setSelectedTaskList] = useState();

  const [selectedProject, setSelectedProject] = useState();
  const [change, setChange] = useState();

  const [newUser, setNewUser] = useState();
  const [userToBeRemoved, setUserToBeRemoved] = useState();

  const [draggableView, setDraggableView] = useState(2);

  const [companies] = useRestResource("/companies", "", user.token, {
    organization: user.organization,
  });

  const [users] = useRestResource("/users", "", user.token, {
    organization: user.organization,
  });
  // -------------------------------------
  // Memoized form
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------
  useEffect(() => {
    getProject();
  }, [params.projectId]);

  useEffect(() => {
    onAddUser(newUser);
  }, [newUser]);

  useEffect(() => {
    getProject();
  }, [draggableView]);

  useEffect(() => {
    removeUser(userToBeRemoved);
  }, [userToBeRemoved]);

  useEffect(() => {
    selectedProject && setMany(selectedProject);
    // eslint-disable-next-line
  }, [selectedProject]);

  useEffect(() => {
    if (selectedProject && JSON.stringify(form) === "{}")
      setMany(selectedProject);
    // eslint-disable-next-line
  }, [form]);

  // -------------------------------------
  // Component functions
  // -------------------------------------

  async function getProject() {
    console.log("getProject called");
    try {
      const response = await api.getProject(params.projectId, user.token);
      if (response) {
        setSelectedProject(response);
      }
    } catch (error) {}
  }

  function update(field) {
    return (value) => {
      setMany({
        ...form,
        [field]: value,
      });
    };
  }

  function handleNewTaskList(e) {
    setSelectedTaskList({});
    getProject();
  }

  async function onAddUser(newUser) {
    // const old = form.item.map((user) => user)
    const old = form?.team?.map((olduser) => olduser);
    const updateData = {
      team: [...(old ?? []), newUser?.id],
    };
    await api.updateProject(selectedProject?.id, updateData, user.token);

    getProject();
  }

  async function removeUser(userToRemove) {
    // const old = form.item.map((user) => user)
    const newData = form?.team?.filter((user) => user.id !== userToRemove.id);
    const updateData = {
      team: newData,
    };
    await api.updateProject(selectedProject?.id, updateData, user.token);

    getProject();
  }

  async function onConfirm() {
    try {
      !form?.organization
        ? (form.organization = user.organization)
        : (form.organization = form.organization.id);

      await api.updateProject(form.id, form, user.token);
      message.success("Operazione eseguita con successo");
      setSelectedProject(form);
      setChange();
      getProject();
    } catch (error) {
      console.log("[ERROR] in confirm project: ");
      console.log(error);
      message.error("Errore durante l'operazione");
    }
  }

  async function handleConfirmTaskList(values) {
    try {
      !values?.project
        ? (values.project = selectedProject?.id)
        : (values.project = values.project.id);

      const body = {
        ...values,
      };
      let response = {};
      if (values?.id) {
        response = await api.updateTaskList(values.id, body, user.token);
      } else {
        response = await api.createTaskList(body, user.token);
        const old = selectedProject?.taskLists?.map((taskList) => taskList?.id);
        const updateData = {
          taskLists: [...old, response?.id],
        };
        const resp = await api.updateProject(
          selectedProject?.id,
          updateData,
          user.token
        );
        console.log(resp);
      }
      getProject();
      setSelectedTaskList(null);
      message.success("Operazione eseguita con successo");
    } catch (error) {
      console.log("[Error] in confirm taskList: ", error);

      message.error("Errore durante l'operazione");
    }
  }

  async function handleDeleteTaskList(e, id) {
    e.preventDefault();
    const newTaskLists = form?.taskLists?.filter(
      (taskList) => taskList.key !== id
    );
    update("taskLists")(newTaskLists);
    await api.deleteTaskList(id, user.token);

    getProject();
  }

  function renderGeneralInformation() {
    return (
      <div className="information">
        <p className="tab-title">Informazioni generali</p>
        <div className="input-container">
          <AppInput
            label="Nome"
            placeholder="Nome"
            value={form?.name ?? ""}
            onChange={update("name")}
          />
          <AppInput
            label="Descrizione"
            placeholder="Descrizione"
            value={form?.description ?? ""}
            onChange={update("description")}
          />
          <AppSelect
            label="Cliente"
            placeholder="Cliente"
            value={form?.customer?.businessName ?? ""}
            onSelect={update("customer")}
            className="app-select"
          >
            {companies &&
              companies.rows?.map((company, index) => (
                <Option key={company.id} id={index}>
                  {company.businessName}
                </Option>
              ))}
          </AppSelect>
          <AppSelect
            label="Stato attuale"
            placeholder="Stato attuale"
            value={form?.status ?? ""}
            onSelect={update("status")}
            className="app-select"
          >
            {projectStatus &&
              Object.keys(projectStatus).map((value, index) => (
                <Option key={value} id={index}>
                  {projectStatus[value]}
                </Option>
              ))}
          </AppSelect>
          <AppDataPicker
            label="Data consegna progetto"
            value={form?.deadLine ?? null}
            onChange={update("deadLine")}
          />

          <AppUserSelect
            label="Utenti nel progetto"
            placeholder="Utenti"
            users={form?.team ?? ""}
            allUsers={users}
            setNewUser={setNewUser}
            setUserToBeRemoved={setUserToBeRemoved}
            canBeModified={true}
            className="app-select"
          ></AppUserSelect>
        </div>
      </div>
    );
  }

  // const onChange = (e) => {
  //   setDraggableView(e.target.value);
  // };

  function renderContentSortFilter() {
    // return (
    //   <div style={{ marginLeft: "80px" }}>
    //     <Radio.Group onChange={onChange} value={draggableView}>
    //       <Radio value={1}>Default</Radio>
    //       <Radio value={2}>Draggable view</Radio>
    //     </Radio.Group>
    //   </div>
    // );
  }

  function renderTasksTab() {
    return (
      <div className="information">
        <div className="header-div">
          <div className="projectName">
            Task Lists progetto
            {renderContentSortFilter()}
          </div>
          <AppButton onClick={handleNewTaskList}>
            Crea nuova task list
          </AppButton>
          {
            <TaskListCreation
              open={!!selectedTaskList}
              onCancel={() => setSelectedTaskList(null)}
              onConfirm={handleConfirmTaskList}
              defaultValues={selectedTaskList}
            />
          }
        </div>
        {draggableView === 1 &&
          form?.taskLists?.map((taskList) => (
            <TaskListCard
              taskList={taskList}
              className="task-card"
              onDelete={handleDeleteTaskList}
              setSelectedTaskList={() => setSelectedTaskList(selectedTaskList)}
              project={selectedProject}
            />
          ))}{" "}
        {draggableView === 2 && (
          <DraggableView
            taskLists={form?.taskLists}
            className="drag-view"
            onDelete={handleDeleteTaskList}
            setSelectedTaskList={setSelectedTaskList}
            project={selectedProject}
            updateProject={getProject}
          />
        )}
      </div>
    );
  }
  function renderChat() {
    return <ChatList chats={form.chats} project={form.id}></ChatList>;
  }

  function renderInformation() {
    return (
      <div className="informationForm">
        <div className="info">
          <p className="tab-title">Informazioni generali</p>
          <AppText field={"Nome"} value={form?.name ?? ""} />
          <AppText field={"Descrizione"} value={form?.description ?? ""} />
          <AppText
            field={"Cliente"}
            value={form?.customer?.businessName ?? ""}
          />
          <AppText field={"Data di consegna"} value={form?.deadLine ?? ""} />
          <AppText field={"Stato"} value={projectStatus[form?.status] ?? ""} />
          <AppText field={"Progetto in condivisione con "} />
          <AppUserSelect users={form?.team} />
        </div>
        <div>
          <ModifyIcon
            className="modifyIcon"
            onClick={() => setChange(true)}
          ></ModifyIcon>
        </div>
      </div>
    );
  }

  function renderMilestones() {
    return <Milestones project={selectedProject} getProject={getProject} />;
  }

  function renderInformationModify() {
    return (
      <div>
        {renderGeneralInformation()}
        <div className="actionFooter">
          <AppButton uxType="" className="actionBtn" onClick={onConfirm}>
            Salva
          </AppButton>
        </div>
      </div>
    );
  }
  // -------------------------------------

  return (
    <div className={className}>
      <div className="leftSide">
        <div className="title" ref={divRef}>
          <div className="header">
            <ArrowBack
              className="icon"
              onClick={() => {
                navigate("/i-tuoi-progetti");
              }}
            ></ArrowBack>
            <p className="projectName">{form?.name ?? ""}</p>
          </div>

          <Tabs defaultActiveKey={location?.state?.tab ?? "1"}>
            <TabPane tab="Generale" key="1">
              {change ? renderInformationModify() : renderInformation()}
            </TabPane>
            <TabPane tab="Task Lists" key="2">
              {renderTasksTab()}
            </TabPane>
            <TabPane tab="Chat" key="3">
              {renderChat()}
            </TabPane>
            <TabPane tab="Milestones" key="4">
              {renderMilestones()}
            </TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  );
}

// ----------------------------------------------------------------------------

const StyledProjectData = styled(ProjectData)`
  & {
    width: inherit;
    min-width: 240px;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    .leftSide {
      min-width: 200px;
      width: 100%;
      height: inherit;
      padding: 10px 10px;
      padding-bottom: 0px;
    }
    //border-right: 1px solid ${({ theme }) => theme.colors.grey200};
    .icon {
      height: 25px;
      width: 25px;
      transition: 0.5s;
      margin-right: 10px;
    }
    .title {
      padding: 10px 10px;
      height: inherit;
      display: flex;
      flex-direction: column;
      width: 100%;
      //border-bottom: 1px solid ${({ theme }) => theme.colors.grey200};
      .projectName {
        font-size: 25px;
        display: flex;
        align-items: center;
      }
      .ant-tabs {
        height: inherit;
        .ant-tabs-content-holder {
          height: inherit;

          .ant-tabs-content {
            height: inherit;
          }
        }
      }
    }
    .tab-title {
      font-size: 18px;
    }
    .information {
      width: 100%;
      padding-top: 10px;
      padding-left: 10px;
      height: 95%;
      overflow: scroll;
      //border-top: 1px solid ${({ theme }) => theme.colors.grey200};
      //border-bottom: 1px solid ${({ theme }) => theme.colors.grey200};

      .input-container {
        width: 40%;
        min-width: 240px;
      }
      .task-card {
        width: 60%;
      }
      .app-select {
        width: 100%;
      }
      .header-div {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }
    .header {
      display: flex;
      flex-direction: row;
    }
    .actionFooter {
      height: 50px;
      width: 100%;
      padding: 20px;
      box-sizing: content-box;
      background-color: ${({ theme }) => theme.colors.light};
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      border-radius: 16px;
      margin-left: -20px;
      z-index: 100;
      .actionBtn {
        margin-right: 20px;
      }
    }
    .addField {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-start;
      margin: auto;
      margin-top: 10px;
      padding: 5px;
      border: none;
      .icon {
        margin: auto;
        margin-right: 10px;
        height: 14px;
        width: 30px;
      }
      .center {
        min-width: 0px;
      }
    }
  }
  .center {
    width: 50%;
    min-width: 400px;
    height: inherit;
  }
  .rightSide {
    min-width: 200px;
    width: 25%;
    height: inherit;
    border-left: 1px solid ${({ theme }) => theme.colors.grey200};
  }
  .form {
    min-width: 280px;
  }
  .modifyIcon {
    height: 25px;
    width: 25px;
    justify-content: flex-end;
  }
  .informationForm {
    display: flex;
    flex-direction: row;
    padding: 20px;
  }
  .info {
    width: 80%;
  }
`;
// ----------------------------------------------------------------------------
export default StyledProjectData;
