import { DatePicker } from "antd";
import PropTypes from "prop-types";
import React, { useImperativeHandle, useRef } from "react";
import styled from "styled-components";
import moment from "moment";

import FieldLabel from "../FieldLabel";
import ValidationMessage from "../FormItems/ValidationMessage";

// ----------------------------------------------------------------------------

const AppDatePicker = React.forwardRef((props, ref) => {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const {
        label,
        className,
        value,
        onChange,
        id,
        error,
        state,
        ...inputProps
    } = props;

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const inputRef = useRef();

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function handleChange(momentDate) {
        const isoDate = momentDate.toISOString();

        onChange?.(isoDate);
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------
    return (
        <div className={`${className}`}>
            {label && <FieldLabel>{label}</FieldLabel>}
            <DatePicker
                style={{ width: "50%" }}
                size="large"
                format={["DD/MM/YYYY"]}
                onChange={handleChange}
                value={(value && moment(value)) || null}
                placeholder={inputProps?.placeholder ?? "Seleziona data"}
            />
        </div>
    );
});

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

AppDatePicker.propTypes = {
    className: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    id: PropTypes.string,
    error: PropTypes.any,
    label: PropTypes.any,
};

AppDatePicker.defaultProps = {};

// ----------------------------------------------------------------------------

const StyledAppDatePicker = styled(AppDatePicker)`
    & {
        max-width: unset;
        width: 100%;
        margin-top: ${({ theme }) => theme.spacing.s}px;

        .app-input {
            background-color: ${({ theme }) => theme.colors.light};
            border: 1px solid ${({ theme }) => theme.colors.grey};
            border-radius: 8px;
            box-sizing: border-box;
            display: block;
            width: 100%;

            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: 0.02em;

            /* border: none; */
            outline: none;
            /* background-color: transparent; */
            padding: 0px ${({ theme }) => theme.spacing.s}px;
            height: ${({ theme }) => theme.spacing.formItemHeight}px;

            :focus {
                outline: 0;
                border-color: ${({ theme }) => theme.colors.neutral900};
                box-shadow: 0 0 0 4px ${({ theme }) => theme.colors.neutral100};
            }
        }
        .error {
            border: 1px solid ${({ theme }) => theme.colors.error900};

            :focus {
                outline: 0;
                border-color: ${({ theme }) => theme.colors.error900};
                box-shadow: 0 0 0 4px ${({ theme }) => theme.colors.error200};
            }
        }
    }
`;
// ----------------------------------------------------------------------------

export default StyledAppDatePicker;
