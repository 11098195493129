import React from "react";
import { useDrag } from "react-dnd";
import styled from "styled-components";
import { ReactComponent as TaskIcon } from "../../../../../_shared/assets/icons/task.svg";

import { ReactComponent as CompletedIcon } from "../../../../../_shared/assets/icons/completedIcon.svg";
import { ReactComponent as UncompleteTask } from "../../../../../_shared/assets/icons/uncompleteTask.svg";

import projectStatus from "../../../../../_shared/constants/projectStatus";

function TaskDD({ className, task }) {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "TaskDD",
    item: { item: task, class: "Task" },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));
  return (
    <div className={className} ref={drag}>
      <button className="taskList">
        {projectStatus[task.status] === projectStatus.completed ? (
          <CompletedIcon className="icon"></CompletedIcon>
        ) : (
          <UncompleteTask className="icon"></UncompleteTask>
        )}

        {`${task.name} `}
      </button>
    </div>
  );
}

const StyledTaskDD = styled(TaskDD)`
  & {
    .icon {
      height: 25px;
      width: 25px;
    }
    .taskList {
      margin: 5px;
      background-color: rgba(240, 240, 240, 0.8);
      padding: 10px 20px;

      border-radius: 20px;
      box-shadow: 1px 1px #ffa500;
      display: flex;
      justify-content: flex-start;

      align-items: center;
      width: 90%;
      transition: 0.5s;
      border: 1px;

      :hover {
        cursor: pointer;
        // transform: rotateY(10deg);
        box-shadow: 3px 3px #ffa500;
        background-color: rgba(240, 240, 240, 0.8);
        color: black;
      }
    }
  }
`;

export default StyledTaskDD;
