import React from "react";
import styled from "styled-components";

import AppInput from "../../../../_shared/components/AppInput";
import AppButton from "../../../../_shared/components/AppButton";
import Checkbox from "../../../../_shared/components/FormItems/Checkbox";
import Row from "../../../../_shared/components/LoginItems/Row";
import Heading from "../../../../_shared/components/LoginItems/Heading";
import SubHeading from "../../../../_shared/components/LoginItems/SubHeading";

function CompanyData(props) {
  const { className, update, values, refer } = props;

  return (
    <div className={className} ref={refer}>
      <Heading>Dati aziendali</Heading>
      <SubHeading>
        Accedi al tuo profilo se vuoi usare tutti i prodotti premium
      </SubHeading>
      <AppInput
        value={values?.businessName ?? ""}
        onChange={update("businessName")}
        label="Nome azienda"
        placeholder="Nome azienda"
        required
      ></AppInput>
      <AppInput
        value={values?.vatNumber ?? ""}
        onChange={update("vatNumber")}
        label="Partita iva"
        placeholder="Inserisci partita iva"
        required
      ></AppInput>
      <Row>
        <Checkbox
          label={
            <span>
              I agree with <a className="term-link">terms & conditions</a>
            </span>
          }
          onChange={update("terms")}
          checked={values?.terms ?? false}
        ></Checkbox>
      </Row>
      <AppButton uxType="" htmlType="submit">
        CONTINUA
      </AppButton>
    </div>
  );
}

const StyledCompanyData = styled(CompanyData)`
  & {
    .term-link {
      text-decoration: underline;
      font-weight: 700;
    }
  }
`;
export default StyledCompanyData;
