import React, { useState } from "react";
import styled from "styled-components";
import Logo from "../../../../_shared/components/LoginItems/Logo";
import { Collapse } from "antd";
import { NavLink } from "react-router-dom";

// ----------------------------------------------------------------------------

function _SiderDropdown(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------
  const { className, routes } = props;

  const { Panel } = Collapse;
  const [activePanel, setActivePanel] = useState(
    localStorage.getItem("activePanel")
      ? parseInt(localStorage.getItem("activePanel"))
      : 1
  );
  const menu = [
    ["Contatti", "Agenti", "Aziende", "Offerte", "Prodotti", "Ordini", "DDT"],
    ["Attivi", "Completati"],
    ["Attive"],
    ["I tuoi progetti", "I tuoi ticket"],
  ];

  function renderIcon(id) {
    const comp = routes[id].tag;
    return comp;
  }

  function renderDrop(id) {
    return (
      <div className="dropped">
        <div className="innerDrop">
          {menu[id].map((el, index) => (
            <NavLink
              key={index}
              to={
                el.toLowerCase() === "contatti"
                  ? "/"
                  : `${el.toLowerCase().replace(/\s+/g, "-")}`
              }
              className={({ isActive }) => (isActive ? "selected" : "")}
            >
              {el}
            </NavLink>
          ))}
        </div>
      </div>
    );
  }

  function renderPanel(name, id) {
    // if (id !== 3 && id !== 7) {
    return (
      <Panel
        header={<div className="name">{name}</div>}
        className={`drop ${activePanel === id + 1 ? "activated" : ""}`}
        key={id + 1}
        extra={renderIcon(id)}
      >
        {renderDrop(id)}
      </Panel>
    );
    // }
    // return (
    // 	<Panel
    // 		header={<div className="name">{name}</div>}
    // 		className={`drop disabled${id === 0 ? "selected" : ""}`}
    // 		key={id + 1}
    // 		extra={renderIcon(id)}
    // 		showArrow={false}
    // 		collapsible="disabled"
    // 	></Panel>
    // )
  }

  function handleChange(key) {
    localStorage.setItem("activePanel", parseInt(key));
    setActivePanel(parseInt(key));
  }

  return (
    <div className={`${className}`}>
      <div className="sider">
        <Logo className="logo" />
        <Collapse
          accordion
          ghost
          expandIconPosition="right"
          className="collapse"
          defaultActiveKey={activePanel}
          activeKey={activePanel}
          onChange={handleChange}
        >
          {routes.map((el, index) => renderPanel(el.name, index))}
        </Collapse>
      </div>
    </div>
  );
}

// ----------------------------------------------------------------------------

const SiderDropdown = styled(_SiderDropdown)`
  & {
    .sider {
      width: 260px;
      height: 91vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      .logo {
        width: 228px;
        display: flex;
        background-color: inherit;
        margin-top: ${({ theme }) => theme.spacing.s}px;
        svg {
          width: 160px;
          height: 60px;
        }
      }
      .collapse {
        margin-top: ${({ theme }) => theme.spacing.s}px;
        width: 228px;
      }
      .drop {
        margin-top: ${({ theme }) => theme.spacing.xs}px;
      }
      .drop .ant-collapse-header {
        width: 200px;
        height: 40px;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        line-height: 18px;
        border-radius: 12px;
        color: ${({ theme }) => theme.colors.neutral800};

        .ant-collapse-extra {
          margin-right: 10px;
          margin-left: 0px;
        }

        .name {
          width: 90px;
        }

        &:hover {
          cursor: pointer;

          svg path,
          circle {
            stroke: ${({ theme }) => theme.colors.orangeIconHover};
          }
          color: ${({ theme }) => theme.colors.orangeIconHover};
        }
      }

      .ant-collapse-content > .ant-collapse-content-box {
        padding: 12px 0px;
        padding-left: 4px;
      }
      .activated {
        svg path,
        circle {
          stroke: ${({ theme }) => theme.colors.orangeIcon};
        }
        .ant-collapse-header {
          border: 1px solid ${({ theme }) => theme.colors.accent200};
          background-color: ${({ theme }) => theme.colors.accent200};
          color: ${({ theme }) => theme.colors.orangeIcon};
          font-weight: 500;
        }
      }

      .dropped {
        font-size: 12px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        .innerDrop {
          display: flex;
          flex-direction: column;
          align-items: baseline;
          width: 100%;

          .selected {
            color: ${({ theme }) => theme.colors.neutral800};
            background-color: ${({ theme }) => theme.colors.neutral200};
          }
        }

        a {
          margin-bottom: 10px;
          height: 36px;
          color: ${({ theme }) => theme.colors.neutral700};
          width: 100%;
          padding: 7px;
          padding-top: 9px;
          font-weight: 500;
          border-radius: 8px;

          :hover {
            color: ${({ theme }) => theme.colors.darkgrey};
          }
        }
      }
    }
  }
`;
export default SiderDropdown;
