import React, { useState, useRef, useEffect, useReducer } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";

import * as api from "../../../../api";
import usePaginatedRestResource from "../../../_shared/hooks/usePaginatedRestResource";
import PopConfirm from "../../../_shared/components/PopConfirm";
import GridProjects from "../../../_shared/components/GridProjects";
import AppButton from "../../../_shared/components/AppButton";
// import projectStatus from "../../../_shared/constants/projectStatus";
// import ProjectRightSheet from "./ProjectRightSheet";

import { ReactComponent as Delete } from "../../../_shared/assets/icons/delete.svg";

// ----------------------------------------------------------------------------

function Tickets(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { className, completed } = props;

  // -------------------------------------
  // Hooks (e.g. useState, ...)
  // -------------------------------------

  const [selectedProject, setSelectedProject] = useState();
  const [tasks, setTasks] = useState([]);

  const user = useSelector((state) => (state?.auth.user ? state.auth : null));
  const [
    projects,
    ,
    { pagination, count, filters },
    {
      fetchResource: fetchProjects,
      onChange: handleTableChange,
      onFiltersChange: FilterChanged,
    },
  ] = usePaginatedRestResource("/projects", user.token, {
    defaultFilters: {
      organization: user.organization,
    },
  });

  useEffect(() => {
    console.log("projects :", projects);
    getAllTickets(projects);
  }, [projects]);

  function handleNewProject() {
    setSelectedProject({});
  }

  function onCancel() {
    setSelectedProject(null);
  }

  async function deleteProject(e, projectId) {
    try {
      await api.deleteProject(projectId, user.token);

      fetchProjects();
    } catch (error) {
      console.log("[ERROR] in delete project: ");
      console.log(error);
    }
  }

  function CreateNewProject() {}

  async function onConfirm(values) {
    try {
      !values?.organization
        ? (values.organization = user.organization)
        : (values.organization = values.organization.id);
      const body = {
        ...values,
      };

      let response = {};
      if (values?.id) {
        response = await api.updateProject(values.id, body, user.token);
      } else {
        response = await api.createProject(body, user.token);
      }
      fetchProjects();
      setSelectedProject(null);
      message.success("Operazione eseguita con successo");
    } catch (error) {
      console.log("[Error] in confirm project: ", error);

      message.error("Errore durante l'operazione");
    }
  }
  function getAllTickets(projects) {
    let allTasks = [];
    projects.forEach((prj) => {
      prj.taskLists.forEach((element) => {
        if (element?.tasks !== []) {
          allTasks = [...allTasks, ...element?.tasks];
        }
      });
    });

    setTasks(allTasks);
  }
  function getTicketProject(project) {
    let allTasks = [];

    project.taskLists.forEach((element) => {
      if (element?.tasks !== []) {
        allTasks = [...allTasks, ...element?.tasks.map((t) => t.name)];
      }
    });
    return allTasks;
  }
  // -------------------------------------

  return (
    <div className={className}>
      <div className="header">
        <div className="titleProj">Tickets a te assegnati </div>
      </div>
      i tuoi ticket sono :{tasks.length}
      <div>
        {projects.map((proj) => (
          <div>
            Project : {proj.name} ({getTicketProject(proj).length})
          </div>
        ))}
      </div>
      {/* <ProjectRightSheet
        open={!!selectedProject}
        defaultValues={selectedProject}
        onConfirm={onConfirm}
        onCancel={onCancel}
        onButtonClick={handleNewProject}
      />
      <GridProjects
        dataSource={projects.filter(
          (project) => project.status !== Object.keys(projectStatus)[2]
        )}
        completed={false}
      ></GridProjects>
      <GridProjects
        dataSource={projects.filter(
          (project) => project.status === Object.keys(projectStatus)[2]
        )}
        completed={true}
      ></GridProjects> */}
    </div>

    //  <ProjectBox className={className} body={getBody()}></ProjectBox>

    // <Table
    //   className={className}
    //   columns={getColumns()}
    //   onResourceCreate={() => setSelectedProject({})}
    //   dataSource={projects}
    //   pagination={{ ...(pagination ?? {}), total: count }}
    //   onChange={handleTableChange}
    //   rowSelection={{ type: "checkbox" }}
    //   addContact="true"
    //   handleOnRow={(row) => setSelectedProject(row)}
    //   renderTopBarCustomButtons={() => (
    //
    //   )}
    // />
  );
}

// ----------------------------------------------------------------------------

const StyledTickets = styled(Tickets)`
  & {
    .form {
      min-width: 280px;
    }
    .titleProj {
      font-family: "Lato", sans-serif;
      font-size: 35px;
      font-weight: 300;
      line-height: 58px;
      padding-left: 40px;
      padding-top: 20px;
    }
    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .button {
      padding: 20px;
    }
  }
`;
// ----------------------------------------------------------------------------

export default StyledTickets;
