import React from "react"
import styled from "styled-components"
import Avatar from "../../Avatar"
import PropTypes from "prop-types"

// ----------------------------------------------------------------------------
function _Activity (props) {

    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className, data, Icon} = props

    function renderAction() {
        switch (data.action){
            case 'Message': return 'Sent you a message'
            case 'Comment': return ('Commented on ')
            case 'Share': return ('Shared the project ')
        }
    }

    function renderTarget() {
        if(data.target !== '')
            return (<a>{data.target}</a>)
    }

    return (
        <div className={className}>
            <Avatar className="photo"/>
            {Icon ? <Icon className="icon"/> : ""}
            <div className="user">
                <span className="firstRow">{data.user}</span>
                <span className="secondRow">{renderAction()}{renderTarget()}</span>
            </div>
            <div className="date">
                <span>{data.time}</span>
            </div>
        </div>
    )
}

const Activity = styled(_Activity) `
    & {
       display: flex;
       padding: 0 15px;
       flex-direction: row;
       align-items: center;
       justify-content: space-between;
       width: 295px;
       height: 70px;
       
       border-radius: 16px;  

        .icon{
            position:relative;
            right:19px;
            bottom:14px;
        }
       .photo {
           width: 40px;
           height: 40px;
       }

       .user {
            font-weight: 700;
            width:190px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .firstRow {
                font-size: 14px;
                line-height: 20px;
            }
            .secondRow {
                font-size: 11px;
                line-height: 18px;
                color: ${({ theme }) => theme.colors.grey100};
            }
       }

       .date {
           font-weight: 400;
           display: flex;
           justify-content: flex-end;
           height: 40px;
           width: 20px;
           color: ${({ theme }) => theme.colors.grey100};
       }

       :hover {
           cursor: pointer;
           background-color: #E7FAFF;
       }
    }
`
export default Activity