import { Select, Tabs } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import Port from "./Tabs/Ports";
import Rate from "./Tabs/Rates";
import Team from "./Tabs/Teams";
import User from "./Tabs/Users";

import CustomField from "./Tabs/CustomFields";

import useRestResource from "../../../_shared/hooks/useRestResource";

// ----------------------------------------------------------------------------

function OrganizationSettings(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { className } = props;
  const { TabPane } = Tabs;
  const { Option } = Select;

  // -------------------------------------
  // Hooks (e.g. useState, ...)
  // -------------------------------------
  const user = useSelector((state) => (state?.auth.user ? state.auth : null));

  const [organization] = useRestResource(
    "/organizations",
    user.organization,
    user.token
  );

  const [tabKey, setTabKey] = useState("1");

  // -------------------------------------
  // Memoized data
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  return (
    <div className={className}>
      <p className="businessName">{organization?.businessName}</p>
      <Tabs
        className={className}
        onChange={setTabKey}
        activeKey={tabKey}
        destroyInactiveTabPane
      >
        <TabPane tab="Utenti" key="1">
          <User />
        </TabPane>
        <TabPane tab="Teams" key="2">
          <Team />
        </TabPane>
        <TabPane tab="Aliquote" key="3">
          <Rate />
        </TabPane>
        <TabPane tab="Porti" key="4">
          <Port />
        </TabPane>
        <TabPane tab="Campi personalizzati" key="5">
          <CustomField />
        </TabPane>
      </Tabs>
    </div>
  );
}

// ----------------------------------------------------------------------------

const StyledOrganizationSettings = styled(OrganizationSettings)`
  & {
    .businessName {
      padding-top: 10px;
      padding-left: 10px;
      font-size: 20px;
    }

    .row {
      display: flex;
      flex-direction: row;
      align-items: center;

      span {
        margin-left: 8px;
      }
    }
    .ant-tabs-nav {
      margin-left: 10px;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: ${({ theme }) => theme.colors.orange800};
    }
    .ant-tabs-ink-bar {
      background: ${({ theme }) => theme.colors.orange800};
    }
    .app-select {
      width: 100%;
    }
  }
`;
export default StyledOrganizationSettings;
