import { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { PlusOutlined } from "@ant-design/icons";
import { message, Select, Tabs, Row, Col, InputNumber } from "antd";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import ProductSelection from "../../Deals/ProductSelection";

import AppButton from "../../../../_shared/components/AppButton";
import AppInput from "../../../../_shared/components/AppInput";
import AppSelect from "../../../../_shared/components/AppSelect";
import CustomField from "../../../../_shared/components/CustomField";
import useForm from "../../../../_shared/hooks/useForm";
import useRestResource from "../../../../_shared/hooks/useRestResource";

import * as api from "../../../../../api";
import FieldLabel from "../../../../_shared/components/FieldLabel";
import SelectAutoComplete from "../../../../_shared/components/SelectAutoComplete";

// ----------------------------------------------------------------------------

function OrderData(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className, newDDT } = props;
    const { TabPane } = Tabs;
    const { Option } = Select;

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const params = useParams();
    const user = useSelector((state) => (state?.auth.user ? state.auth : null));
    const divRef = useRef();
    const [agent, setAgent] = useState(null);
    const [recipientType, setRecipientType] = useState("");

    const [form, , setMany, clearForm] = useForm();

    const [selectedOrder, setSelectedOrder] = useState();

    const [users] = useRestResource("/users", "", user.token, {
        organizations: user.organization,
    });
    const [companies] = useRestResource("/companies", "", user.token, {
        organization: user.organization,
    });
    const [products] = useRestResource("/products", "", user.token, {
        organization: user.organization,
    });
    const [customFields] = useRestResource("/custom-fields", "", user.token, {
        organization: user.organization,
    });

    const [agents] = useRestResource("/agents", "", user.token, {
        organization: user.organization,
    });

    // -------------------------------------
    // Effects
    // -------------------------------------
    useEffect(() => {
        selectedOrder && setMany(selectedOrder);
        selectedOrder && setRecipientType(selectedOrder.recipientType);
        // eslint-disable-next-line
    }, [selectedOrder]);

    useEffect(() => {
        if (selectedOrder && JSON.stringify(form) === "{}")
            setMany(selectedOrder);
        // eslint-disable-next-line
    }, [form]);

    useEffect(() => {
        getOrder();
    }, [params.orderId]);

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function update(field) {
        return (value) => {
            setMany({
                ...form,
                [field]: value,
            });
        };
    }

    function handleNewField(e) {
        e.preventDefault();
        const newCustomFields = [
            ...(form?.customFields ?? []),
            {
                key: Math.floor(Math.random() * 99999),
                customField: "",
                value: "",
            },
        ];
        update("customFields")(newCustomFields);
    }

    function handleDeleteCustomField(e, key) {
        e.preventDefault();
        const newCustomFields = form?.customFields.filter(
            (customField) => customField.key !== key
        );
        update("customFields")(newCustomFields);
    }

    function onChangeCustomField(customFieldId, value, key) {
        const customField = form?.customFields.find(
            (customField) => customField.key === key
        );
        customField.customField = customFieldId;
        customField.value = value;
    }

    function checkValueType(customField) {
        const originalCustomField = customFields.rows.find(
            (originalField) => originalField.id === customField.customField
        );
        if (originalCustomField.type === "number") {
            const value = parseFloat(customField.value);
            customField.value = null;
            customField.value = value;
        }
        if (originalCustomField.type === "boolean") {
            const value =
                customField.value !== "" && customField.value !== "false"
                    ? true
                    : false;
            customField.value = null;
            customField.value = value;
        }
    }

    async function getOrder() {
        try {
            const response = await api.getOrder(params.orderId, user.token);
            if (response) setSelectedOrder(response);
        } catch (error) {}
    }

    function onChange(quantity, price, id, description, discount, key) {
        const productSelection = form?.products?.find(
            (productSelection) => productSelection.key === key
        );
        productSelection.quantity = quantity;
        productSelection.price = price;
        productSelection.product = id;
        productSelection.description = description;
        productSelection.discount = discount;
        update("amount")(calculateAmount());
    }

    function calculateAmount() {
        let newAmount = 0;
        form?.products?.forEach((productSelection) => {
            let prod = productSelection.quantity * productSelection.price;
            newAmount += prod - (prod * productSelection.discount) / 100;
        });
        return newAmount.toFixed(2);
    }

    function getTotal() {
        if (form?.discount && form?.discount > 0) {
            const discountValue = (form?.amount * form?.discount) / 100;
            return form?.amount - discountValue;
        }

        return form?.amount;
    }

    function handleSetAgent(value) {
        const agent = agents?.rows?.find((a) => a.id === value);
        setAgent(agent);
        update("commissionPercentage")(agent?.commissionPercentage);
    }

    function calcAgentCommission() {
        if (form?.commissionPercentage) {
            return (calculateAmount() * form?.commissionPercentage) / 100;
        }
        if (agent?.commissionPercentage) {
            return (calculateAmount() * agent?.commissionPercentage) / 100;
        }
        return null;
    }

    async function handleNewDDT(e) {
        e.preventDefault();
        newDDT && newDDT(form);
    }

    async function handleSearchRecipient(value) {
        try {
            let response;
            if (recipientType === "Company") {
                response = await api.searchCompanies(
                    value,
                    user.organization,
                    user.token
                );
                if (response) {
                    return response.rows?.map((row) => {
                        return { label: row.businessName, id: row.id };
                    });
                }
            } else {
                response = await api.searchContacts(
                    value,
                    user.organization,
                    user.token
                );
                if (response) {
                    return response.rows?.map((row) => {
                        return { label: row.fullName, id: row.id };
                    });
                }
            }

            return null;
        } catch (error) {
            console.log("[ERROR] in search companies/contacts: ");
            console.log(error);
        }
    }

    async function handleGenerateInvoice() {
        try {
            const invoiceObj = {};
            invoiceObj.organization = user.organization;
            invoiceObj.recipientType = form.recipientType;
            invoiceObj.code = form.code;
            if (form?.recipient && typeof form?.recipient !== "string") {
                invoiceObj.recipient = form.recipient.id;
            } else {
                invoiceObj.recipient = form.recipient;
            }

            invoiceObj.taxable = form.amount;

            invoiceObj.rows = form.products?.map((product) => {
                return {
                    description: product?.description,
                    quantity: product?.quantity,
                    unitPrice: product.price,
                    discount: product.discount,
                    key: product?.key,
                };
            });

            await api.createInvoice(user.organization, invoiceObj, user.token);
            message.success("Operazione eseguita con successo");
        } catch (error) {
            message.error("ATTENZIONE! Azione non completata, riprovare");
        }
    }

    async function handleSearchUsers(value) {
        try {
            const response = await api.searchUsers(
                value,
                user.organization,
                user.token
            );
            if (response) {
                return response.rows?.map((row) => {
                    return { label: row.fullName, id: row.id };
                });
            }
            return null;
        } catch (error) {
            console.log("[ERROR] in search users: ");
            console.log(error);
        }
    }

    async function onConfirm() {
        try {
            !form?.organization
                ? (form.organization = user.organization)
                : (form.organization = form.organization.id);

            if (form?.user) form.user = form.user.id;

            if (typeof form?.recipient !== "string")
                form.recipient = form.recipient.id;

            await api.updateOrder(form.id, form, user.token);

            message.success("Operazione eseguita con successo");
            setSelectedOrder(form);
        } catch (error) {
            console.log("[ERROR] in confirm order: ");
            console.log(error);
            message.error("Errore durante l'operazione");
        }
    }

    function onCancel() {
        clearForm();
    }

    function handleNewProductSelection(e) {
        e.preventDefault();
        const newproductsSelection = [
            ...(form?.products ?? []),
            {
                key: Math.floor(Math.random() * 99999),
                quantity: "",
                price: "",
                product: "",
                description: "",
                discount: 0,
            },
        ];

        update("products")(newproductsSelection);
    }

    function onDeleteProduct(e, id) {
        e.preventDefault();
        const newproductsSelection = form?.products?.filter(
            (productSelection) => productSelection.key !== id
        );
        update("products")(newproductsSelection);
    }

    function renderGeneralInformation() {
        return (
            <div className="information">
                <p className="tab-title">Informazioni generali</p>
                <div className="input-container">
                    <AppInput
                        label="Nome"
                        placeholder="Nome"
                        value={form?.name ?? ""}
                        onChange={update("name")}
                    />
                    <AppInput
                        label="Status"
                        placeholder="Status"
                        value={form?.status ?? ""}
                        onChange={update("status")}
                    />
                    <AppSelect
                        className="app-select"
                        label="Tipo di destinatario"
                        placeholder="Tipo"
                        value={form?.recipientType ?? ""}
                        onSelect={update("recipientType")}
                        onChange={(value) => setRecipientType(value)}
                    >
                        <Option key={"Company"} id={1}>
                            Azienda
                        </Option>
                        <Option key={"Contact"} id={2}>
                            Contatto
                        </Option>
                    </AppSelect>
                    <SelectAutoComplete
                        label="Destinatario"
                        placeholder="Destinatario"
                        className="app-select"
                        disabled={recipientType === ""}
                        defaultValue={
                            form?.recipient?.businessName ||
                            form?.recipient?.fullName ||
                            ""
                        }
                        onSearch={handleSearchRecipient}
                        onChange={(value) => update("recipient")(value)}
                    />
                    <SelectAutoComplete
                        label="Titolare dell'offerta"
                        placeholder="Utente"
                        className="app-select"
                        defaultValue={form?.owner ?? ""}
                        onSearch={handleSearchUsers}
                        onChange={(value) => update("owner")(value)}
                    />
                    {/* {form?.customFields?.map((customField, index) => (
                        <CustomField
                            key={customField.key}
                            id={customField.key}
                            onChange={onChangeCustomField}
                            onCancel={(e, key) => handleDeleteCustomField(e,key)}
                            customFields={customFields?.rows}
                            givenCustomField={
                                customField.customField ? customField.customField : null
                            }
                            givenValue={customField.value ? customField.value : null}
                        />
                    ))}
                    <AppButton
                        uxType="link"
                        onClick={handleNewField}
                        className="addField"
                        icon={<PlusOutlined />}
                    >
                        Aggiungi campo personalizzato
                    </AppButton> */}
                </div>
            </div>
        );
    }

    function renderProducts() {
        return (
            <div className="information">
                <p className="tab-title">Prodotti dell'ordine</p>
                <div className="input-container">
                    <Row className="products">
                        <Col span={11} className="productsPar">
                            Prodotti
                        </Col>
                        <Col span={4} offset={0.5} className="quantityPar">
                            Quantità
                        </Col>
                        <Col span={6} offset={2} className="pricePar">
                            Prezzo
                        </Col>
                    </Row>

                    {form?.products?.map((productSelection) => (
                        <ProductSelection
                            products={products?.rows}
                            key={productSelection.key}
                            id={productSelection.key}
                            onChange={onChange}
                            product={
                                productSelection.product
                                    ? productSelection.product.id
                                    : null
                            }
                            price={
                                productSelection.price
                                    ? productSelection.price
                                    : null
                            }
                            quantity={
                                productSelection.quantity
                                    ? productSelection.quantity
                                    : null
                            }
                            description={
                                productSelection.description
                                    ? productSelection.description
                                    : null
                            }
                            discount={
                                productSelection.discount
                                    ? productSelection.discount
                                    : null
                            }
                            onCancel={onDeleteProduct}
                        />
                    ))}
                    <AppInput
                        label="Sconto"
                        placeholder="Sconto"
                        value={form?.discount ?? ""}
                        onChange={update("discount")}
                    />
                    <p className="total">Subtotale: {form?.amount ?? 0}€</p>
                    <AppSelect
                        label="Agente"
                        placeholder="Agente"
                        value={form?.agent?.fullName ?? ""}
                        onSelect={(value) => {
                            handleSetAgent(value);
                            update("agent")(value);
                        }}
                        className="app-select"
                    >
                        {agents?.rows &&
                            agents.rows?.map((agent, index) => (
                                <Option key={agent.id} id={index}>
                                    {agent.fullName}
                                </Option>
                            ))}
                    </AppSelect>
                    <FieldLabel>Percentuale provvigioni</FieldLabel>
                    <InputNumber
                        value={
                            form?.commissionPercentage ??
                            agent?.commissionPercentage ??
                            ""
                        }
                        onChange={update("commissionPercentage")}
                        className="inputPerc"
                        precision={2}
                        step={0.5}
                        formatter={(value) => `${value}%`}
                        parser={(value) => value?.replace("%", "")}
                    />
                    <p className="total">Totale: {getTotal()}€</p>
                    <p className="total">
                        Agente: {calcAgentCommission()?.toFixed(2) ?? ""}€
                    </p>

                    <AppButton
                        uxType="link"
                        onClick={handleNewProductSelection}
                        className="addField"
                        icon={<PlusOutlined />}
                    >
                        Aggiungi prodotto
                    </AppButton>
                </div>
            </div>
        );
    }

    return (
        <div className={className}>
            <div className="leftSide">
                <div className="title" ref={divRef}>
                    <Row className="title-row">
                        <p className="businessName">{form?.name}</p>
                        <Row>
                            <AppButton
                                uxType="tertiary"
                                onClick={handleNewDDT}
                                className=""
                            >
                                Converti in ddt
                            </AppButton>
                            <AppButton
                                uxType="tertiary"
                                onClick={handleGenerateInvoice}
                                className="row-btn"
                            >
                                Genera fattura
                            </AppButton>
                        </Row>
                    </Row>

                    <Tabs defaultActiveKey="1">
                        <TabPane tab="Generale" key="1">
                            {renderGeneralInformation()}
                        </TabPane>
                        <TabPane tab="Prodotti" key="2">
                            {renderProducts()}
                        </TabPane>
                    </Tabs>
                    <div className="actionFooter">
                        <AppButton
                            uxType="tertiary"
                            className="actionBtn"
                            onClick={onCancel}
                        >
                            Annulla
                        </AppButton>
                        <AppButton
                            uxType=""
                            className="actionBtn"
                            onClick={onConfirm}
                        >
                            Conferma
                        </AppButton>
                    </div>
                </div>
            </div>
            {/* <div className="center">

            </div>
            <div className="rightSide">

            </div> */}
        </div>
    );
}

// ----------------------------------------------------------------------------

const StyledOrderData = styled(OrderData)`
    & {
        width: inherit;
        min-width: 240px;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        .leftSide {
            min-width: 200px;
            width: 100%;
            height: inherit;
            padding: 10px 10px;
            padding-bottom: 0px;
            //border-right: 1px solid ${({ theme }) => theme.colors.grey200};

            .title {
                padding: 10px 10px;
                height: inherit;
                display: flex;
                flex-direction: column;
                width: 100%;

                .businessName {
                    font-size: 20px;
                }

                .ant-tabs {
                    height: inherit;
                    .ant-tabs-content-holder {
                        height: inherit;

                        .ant-tabs-content {
                            height: inherit;
                        }
                    }
                }

                .title-row {
                    width: inherit;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                }
            }
            .information {
                width: 100%;
                padding-top: 10px;
                padding-left: 10px;
                height: 95%;
                overflow: scroll;

                .tab-title {
                    font-size: 18px;
                }
                .app-select {
                    width: 100%;
                }
                .input-container {
                    width: 40%;
                    min-width: 240px;
                }
                .row {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    span {
                        margin-left: 8px;
                    }
                }
                .products {
                    margin-top: 20px;
                    font-weight: 300;
                    font-size: 14px;
                    line-height: 16px;
                    color: #252631;
                }

                .total {
                    margin-top: 10px;
                    display: flex;
                    flex-direction: row-reverse;
                }
                .addField {
                    display: flex;
                    flex-direction: row-reverse;
                    justify-content: flex-start;
                    margin: auto;
                    margin-top: 10px;
                    padding: 5px;
                    border: none;
                    .icon {
                        margin: auto;
                        margin-right: 10px;
                        height: 14px;
                        width: 30px;
                    }
                    .center {
                        min-width: 0px;
                    }
                }
            }
            .actionFooter {
                height: 50px;
                width: 100%;
                padding: 20px;
                box-sizing: content-box;
                background-color: ${({ theme }) => theme.colors.light};
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-end;
                border-radius: 16px;
                margin-left: -20px;
                z-index: 100;
                .actionBtn {
                    margin-right: 20px;
                }
            }
        }
        .center {
            width: 50%;
            min-width: 400px;
            height: inherit;
        }
        .rightSide {
            min-width: 200px;
            width: 25%;
            height: inherit;
            border-left: 1px solid ${({ theme }) => theme.colors.grey200};
        }
        .row-btn {
            margin-left: 20px;
        }
    }
`;
export default StyledOrderData;
