import React, { useEffect } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { PlusOutlined } from "@ant-design/icons";
import { message, Select, Row, Col } from "antd";

import AppButton from "../../../../_shared/components/AppButton";
import RightSheet from "../../../../_shared/components/Drawer";
import AppInput from "../../../../_shared/components/AppInput";
import useForm from "../../../../_shared/hooks/useForm";

function TaskListCreation(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { className, open, defaultValues, onConfirm, onCancel, onButtonClick } =
    props;

  // -------------------------------------
  // Hooks (e.g. useState, ...)
  // -------------------------------------

  const Option = Select;
  const [form, , setMany, clearForm] = useForm();
  const user = useSelector((state) => (state?.auth.user ? state.auth : null));

  // -------------------------------------
  // Memoized values
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  useEffect(() => {
    !open && clearForm();
    // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    defaultValues && setMany(defaultValues);

    // eslint-disable-next-line
  }, [defaultValues]);

  // -------------------------------------
  // Component functions
  // -------------------------------------
  function update(field) {
    return (value) => {
      setMany({
        ...form,
        [field]: value,
      });
    };
  }

  function handleConfirm() {
    // form?.customFields?.forEach((usedCustomField) =>
    // 	checkValueType(usedCustomField)
    // )
    onConfirm && onConfirm(form);
  }

  // function handleNewField(e) {
  // 	e.preventDefault()
  // 	const newCustomFields = [
  // 		...(form?.customFields ?? []),
  // 		{ key: Math.floor(Math.random() * 99999), customField: "", value: "" },
  // 	]
  // 	update("customFields")(newCustomFields)
  // }

  // function handleDeleteCustomField(e, key) {
  // 	e.preventDefault()
  // 	const newCustomFields = form?.customFields.filter(
  // 		(customField) => customField.key !== key
  // 	)
  // 	update("customFields")(newCustomFields)
  // }

  // function onChange(customFieldId, value, key) {
  // 	const customField = form?.customFields.find(
  // 		(customField) => customField.key === key
  // 	)
  // 	customField.customField = customFieldId
  // 	customField.value = value
  // }

  // function checkValueType(customField) {
  // 	const originalCustomField = customFields.find(
  // 		(originalField) => originalField.id === customField.customField
  // 	)
  // 	if (originalCustomField.type === "number") {
  // 		const value = parseFloat(customField.value)
  // 		customField.value = null
  // 		customField.value = value
  // 	}
  // 	if (originalCustomField.type === "boolean") {
  // 		const value =
  // 			customField.value !== "" && customField.value !== "false" ? true : false
  // 		customField.value = null
  // 		customField.value = value
  // 	}
  // }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <RightSheet
      open={open}
      onCancel={onCancel}
      onConfirm={handleConfirm}
      title={defaultValues?.id ? "Modifica lista" : "Crea lista task"}
      confirmButtonLabel={
        defaultValues?.id ? "Modifica lista" : "Crea lista task"
      }
      cancelButtonLabel="Annulla"
      className={`${className}`}
    >
      <form className="form">
        <AppInput
          label="Nome"
          placeholder="Nome"
          value={form?.name ?? ""}
          onChange={update("name")}
        />
        <AppInput
          label="Descrizione"
          placeholder="Descrizione"
          value={form?.description ?? ""}
          onChange={update("description")}
        />
        <AppInput
          label="Stato"
          placeholder="Stato"
          value={form?.state ?? ""}
          onChange={update("state")}
        />
        <AppInput
          label="Data di consegna"
          placeholder="Data"
          value={form?.dueDate ?? ""}
          onChange={update("dueDate")}
        />
        <AppInput
          label="Priorità"
          placeholder="Priorità"
          value={form?.priority ?? ""}
          onChange={update("priority")}
        />
      </form>
    </RightSheet>
  );
}
// ----------------------------------------------------------------------------

const StyledTaskListCreation = styled(TaskListCreation)`
  & {
    .products {
      margin-top: 15px;
    }
    .components {
      margin-top: 20px;
      padding-bottom: 5px;
      font-weight: 400;
      font-size: 12px;
      border-bottom: 1px solid ${({ theme }) => theme.colors.grey200};
    }
  }
`;
export default StyledTaskListCreation;
