import React from "react"
import PropTypes from "prop-types"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"

import store, { persistor } from "../../redux/store"

// ----------------------------------------------------------------------------

export default function ReduxProvider(props) {
	// -------------------------------------
	// Props destructuring
	// -------------------------------------

	const { children } = props

	// -------------------------------------
	// Hooks (e.g. useState, useMemo ...)
	// -------------------------------------

	// -------------------------------------
	// Effects
	// -------------------------------------

	// -------------------------------------
	// Component functions
	// -------------------------------------

	// -------------------------------------
	// Component local variables
	// -------------------------------------

	return (
		<Provider store={store}>
			<PersistGate persistor={persistor} loading={null}>
				{children}
			</PersistGate>
		</Provider>
	)
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

ReduxProvider.propTypes = {
	children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
}

ReduxProvider.defaultProps = {}
