import { Progress} from "antd"
import React from "react"
import styled from "styled-components"

function _ProgressBar (props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------
    const{ className, percent} = props

    return (
        <Progress percent={percent} className={className}/>
    )
}

const ProgressBar = styled(_ProgressBar)`
    & {
        .ant-progress-text {
            color: ${({ theme }) => theme.colors.grey100};
        }
    }
`

// ----------------------------------------------------------------------------

export default ProgressBar