import { Checkbox, Table, Tag } from "antd";
import PropTypes from "prop-types";
import React, { useReducer } from "react";
import styled from "styled-components";
import { ReactComponent as Delete } from "../../../_shared/assets/icons/delete.svg";
import { ReactComponent as Sets } from "../../../_shared/assets/icons/dots.svg";
import { ReactComponent as Edit } from "../../../_shared/assets/icons/edit.svg";
import Avatar from "../Avatar";
import FilterIcon from "./FilterIcon";
import ProgressBar from "./ProgressBar";
import TableDateRangeFilterDropdown from "./TableDateRangeFilterDropdown";
import TableTextFilterDropdown from "./TableTextFilterDropdown";
import TableTopBar from "./TableTopBar";
//------------------------------------------------------------------------------

function AppTable(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const {
    className,
    dataSource,
    columns,
    filters,
    pagination,
    renderTopBarCustomButtons,
    rowKey,
    showNewResourceForm,
    newResourceButtonDisabled,
    handleOnRow,
    handleOnChangeSearch,
    handleSearchSubmit,
    ...tableProps
  } = props;

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  const [filtersState, dispatch] = useReducer(
    filtersReducer,
    getInitialFilterStatus()
  );

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  function filtersReducer(state, action) {
    switch (action.type) {
      case "FILTER_VISIBILITY_CHANGE": {
        const filterKey = action?.payload?.filterKey;
        return {
          ...state,
          [filterKey]: {
            ...(state[filterKey] ?? {}),
            visible: action?.payload?.visible,
          },
        };
      }
    }
  }

  function getInitialFilterStatus() {
    let initialFilterStatus = {};
    filters?.forEach((filter) => {
      if (filter?.key) {
        initialFilterStatus = {
          ...initialFilterStatus,
          [filter?.key]: {
            value: null,
            visible: false,
          },
        };
      }
    });

    return initialFilterStatus;
  }

  function handleFilterVisibilityChange(key, visible) {
    dispatch({
      type: "FILTER_VISIBILITY_CHANGE",
      payload: {
        filterKey: key,
        visible,
      },
    });
  }

  function renderFilterIcon(key, type) {
    // antd default filtered boolean value not working
    const filter = filters?.find((filter) => filter?.key === key);

    let isFiltered = filter?.value ? true : false;

    switch (type) {
      case "date-range": {
        isFiltered = filter?.value?.filter((f) => f)?.length > 0 ? true : false;

        return <FilterIcon type={"calendar"} filtered={isFiltered} />;
      }
      case "text": {
        return <FilterIcon type={"search"} filtered={isFiltered} />;
      }
    }
  }

  function handleFilterChange(key) {
    return function (value) {
      const filter = filters?.find((filter) => filter?.key === key);

      if (filter) {
        const { onSearch } = filter;

        onSearch && onSearch(value);

        dispatch({
          type: "FILTER_VISIBILITY_CHANGE",
          payload: {
            filterKey: key,
            visible: false,
          },
        });
      }
    };
  }

  function handleDateRangeChange(key) {
    return function (value) {
      if (!value) {
        // clear filter case
        handleFilterChange(key)(value);
      }
    };
  }

  function renderFilterDropdown(key, type) {
    switch (type) {
      case "date-range": {
        return (
          <TableDateRangeFilterDropdown
            key="eventHistory"
            id="eventHistoryDateRangeContainer"
            visible={filtersState[key]?.visible}
            onOk={handleFilterChange(key)}
            onChange={handleDateRangeChange(key)}
          />
        );
      }
      case "text": {
        return (
          <TableTextFilterDropdown
            visible={filtersState[key]?.visible}
            onSearch={handleFilterChange(key)}
          />
        );
      }
    }
  }

  function getColumnsWithFilters() {
    return columns?.map((column) => {
      const columnFilter = filters?.find(
        (filter) => filter?.key === column?.key
      );

      if (!columnFilter) {
        return column;
      } else {
        return {
          ...column,
          filterIcon: (filtered) =>
            renderFilterIcon(columnFilter?.key, columnFilter?.type),
          filterDropdown: () =>
            renderFilterDropdown(columnFilter?.key, columnFilter?.type),
          filterDropdownVisible: filtersState[columnFilter?.key]?.visible,
          onFilterDropdownVisibleChange: (visible) =>
            handleFilterVisibilityChange(columnFilter?.key, visible),
        };
      }
    });
  }

  function setRowKey(record) {
    if (rowKey) {
      return rowKey(record);
    } else {
      return record?.key ?? null;
    }
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  const tableColumns = getColumnsWithFilters();

  console.log("pagination table: ", pagination);

  return (
    <div className={className}>
      {!tableProps.noTableBar && (
        <TableTopBar
          className="table-top-bar"
          visibleResources={dataSource?.length}
          totalResources={pagination?.total ?? 0}
          pagination={pagination}
          showNewResourceForm={showNewResourceForm}
          newResourceButtonDisabled={newResourceButtonDisabled}
          renderTopBarCustomButtons={renderTopBarCustomButtons}
          handleSearchSubmit={handleSearchSubmit}
          handleOnChangeSearch={handleOnChangeSearch}
          {...tableProps}
        ></TableTopBar>
      )}
      <Table
        className="table"
        dataSource={dataSource}
        rowKey={setRowKey}
        scroll={{ x: true }}
        columns={tableColumns}
        // pagination={{  }}
        pagination={pagination}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              handleOnRow(record);
            },
          };
        }}
        {...tableProps}
      />
    </div>
  );
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

AppTable.propTypes = {
  className: PropTypes.string.isRequired,
  dataSource: PropTypes.array,
  columns: PropTypes.array,
  filters: PropTypes.array,
  pagination: PropTypes.object,
  renderTopBarCustomButtons: PropTypes.func,
  rowKey: PropTypes.func,
  showNewResourceForm: PropTypes.bool,
  newResourceButtonDisabled: PropTypes.bool,
};

AppTable.defaultProps = {
  filters: [],
  dataSource: [
    {
      key: "1",
      Title: "Branding Guidlines",
      Deadline: "18 April 2021",
      Progress: "20",
      Lead: "m",
      Member: [1, 2],
      Status: "Active",
    },
    {
      key: "2",
      Title: "bbbb",
      Deadline: "18 May 2021",
      Progress: "40",
      Lead: "m",
      Member: [1, 2, 3],
      Status: "Pending",
    },
    {
      key: "3",
      Title: "bbbb",
      Deadline: "18 June 2021",
      Progress: "60",
      Lead: "m",
      Member: [1, 2, 3, 4],
      Status: "Closed",
    },
    {
      key: "4",
      Title: "bbbb",
      Deadline: "18 August 2021",
      Progress: "90",
      Lead: "m",
      Member: [1, 2],
      Status: "New",
    },
    {
      key: "5",
      Title: "bbbb",
      Deadline: "18 August 2021",
      Progress: "90",
      Lead: "m",
      Member: [1, 2],
      Status: "New",
    },
    {
      key: "6",
      Title: "bbbb",
      Deadline: "18 August 2021",
      Progress: "90",
      Lead: "m",
      Member: [1, 2],
      Status: "New",
    },
    {
      key: "7",
      Title: "bbbb",
      Deadline: "18 August 2021",
      Progress: "90",
      Lead: "m",
      Member: [1, 2],
      Status: "New",
    },
    {
      key: "8",
      Title: "bbbb",
      Deadline: "18 August 2021",
      Progress: "90",
      Lead: "m",
      Member: [1, 2],
      Status: "New",
    },
  ],
  columns: [
    {
      title: "Title",
      dataIndex: "Title",
      key: "Title",
      render: (text) => {
        return (
          <div className="title">
            <Checkbox className="checkbox" />
            <div className="innerTitle">
              <span>{text}</span>
              <span className="date">zzzz</span>
            </div>
          </div>
        );
      },
    },
    {
      title: "Deadline",
      dataIndex: "Deadline",
      key: "Deadline",
    },
    {
      title: "Progress",
      dataIndex: "Progress",
      key: "Progress",
      render: (perc) => {
        return <ProgressBar percent={perc} />;
      },
    },
    {
      title: "Lead",
      dataIndex: "Lead",
      key: "Lead",
      render: (mem) => <Avatar className="avatar" />,
    },
    {
      title: "Member",
      dataIndex: "Member",
      key: "Member",
      render: (member) => (
        <div className="member">
          {member.map((mem, index) => {
            if (index <= 3)
              return (
                <Avatar
                  className={`avatar ${index === 0 ? "" : "memAvatar"}`}
                  key={index}
                  zindex={member.length - index - 1}
                />
              );
          })}
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
      render: (tag) => {
        let color;
        let textColor;
        switch (tag) {
          case "Active":
            color = "rgba(108, 93, 211, 0.1)";
            textColor = "#9D90FA";
            break;

          case "Pending":
            color = "#FFF3DC";
            textColor = "#FF9A7B";
            break;

          case "Closed":
            color = "#FFEBF6";
            textColor = "#FF754C";
            break;

          case "New":
            color = "#CDF4FF";
            textColor = "#3F8CFF";
            break;

          default: {
          }
        }
        return (
          <Tag color={color} style={{ color: textColor }} className="tag">
            {tag}
          </Tag>
        );
      },
    },
    {
      title: "",
      dataIndex: "utilities",
      key: "utilities",
      render: () => (
        <div className="utilities">
          <Edit />
          <Delete />
          <Sets />
        </div>
      ),
    },
  ],
};

// ----------------------------------------------------------------------------

const StyledAppTable = styled(AppTable)`
  & {
    display: flex;
    flex-direction: column;
    font-weight: 700;
    line-height: 20px;
    .avatar {
      border: 1px solid ${({ theme }) => theme.colors.light};
      filter: drop-shadow(0px 8px 16px rgba(108, 93, 211, 0.2));
    }
    .member {
      display: flex;
      flex-direction: row;

      .memAvatar {
        margin-left: -18px;
      }
    }
    .delete {
      width: 35px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 24px;
      svg path {
        fill: ${({ theme }) => theme.colors.grey100};
      }
      :hover {
        background-color: ${({ theme }) => theme.colors.grey200};
      }
    }
    .tag {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border-radius: 24px;
      padding: 6px 12px;
      margin: 0;
      width: 88px;
      height: 32px;
    }
    .utilities {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-right: 5px;
      min-width: 70px;
    }

    .ant-checkbox-wrapper {
      margin-left: 8px;
    }
    .title {
      display: flex;
      flex-direction: row;
      align-items: center;

      .ant-checkbox-input {
        border-radius: 4px;
        border: 1.5px solid ${({ theme }) => theme.colors.shade2};
      }
      .innerTitle {
        display: flex;
        flex-direction: column;
        margin-left: 15px;

        .date {
          color: ${({ theme }) => theme.colors.grey100};
          font-size: 12px;
        }
      }
    }

    .table {
      border-width: 1px;
      border-radius: 8px;
      border: 1px solid #f6f4f2;

      .ant-table-ping-left:not(.ant-table-has-fix-left)
        .ant-table-container::before,
      .ant-table-ping-left:not(.ant-table-has-fix-left)
        .ant-table-container::after {
        box-shadow: none;
      }

      .ant-table-container table > thead > tr:first-child th:first-child {
        border-top-left-radius: 8px;
      }
      .ant-table-container table > thead > tr:first-child th:last-child {
        border-top-right-radius: 8px;
       
      }

      .ant-table-tbody {
        tr,
        td {
          border-bottom: 1px solid #f6f4f2;
          //white-space: nowrap;
        }

        .ant-table-cell-row-hover {
          background-color: rgba(108, 93, 211, 0.05);
        }
      }

      .ant-table-content,
      .ant-table,
      .ant-spin-container,
      .ant-spin-nested-loading {
        border-radius: 8px;
      }
      .ant-table-thead > tr {
        background-color: ${({ theme }) => theme.colors.greySider};
      }

      .ant-table-thead > tr > th {
        color: ${({ theme }) => theme.colors.grey100};
        background-color: ${({ theme }) => theme.colors.greySider};
        line-height: 20px;
        border-bottom: none;
      }
      .ant-table-thead
        > tr
        > th:not(:last-child):not(.ant-table-selection-column):not(
          .ant-table-row-expand-icon-cell
        ):not([colspan])::before {
        width: 0px;
        height: 0px;
      }
      .ant-table-row {
        cursor: pointer;

        .device-id {
          font-size: 10px;
        }
      }
      .ant-pagination {
        margin-right: ${({ theme }) => theme.spacing.s}px;
      }
      .ant-pagination-item-active {
        border-color: ${(props) => props.theme.colors.primary};

        a {
          color: ${(props) => props.theme.colors.primary};
        }
      }

      .ant-pagination-item {
        :hover,
        :focus {
          border-color: ${(props) => props.theme.colors.primary};
          a {
            color: ${(props) => props.theme.colors.primary};
          }
        }
      }

      .ant-pagination-item.ant-pagination-item-1.ant-pagination-item-active {
        background-color: transparent;
        border-color: ${(props) => props.theme.colors.primary};

        a {
          color: ${(props) => props.theme.colors.primary};
        }
      }

      .ant-table-placeholder {
        background-color: transparent;
      }
    }
  }
`;
// ----------------------------------------------------------------------------

export default StyledAppTable;
