import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as UpIcon } from "../../assets/icons/up.svg";
import { ReactComponent as DownIcon } from "../../assets/icons/down.svg";
import { ReactComponent as ThreeDots } from "../../assets/icons/threeDots.svg";
import Avatar from "../Avatar";

// ----------------------------------------------------------------------------

function UserCard(props) {
  const { className, user, handleDropdown } = props;
  const iconName = user?.name?.charAt?.(0) + user?.surname?.charAt?.(0);
  return (
    <div className={`${className}`}>
      <Avatar className="avatar" name={iconName} />
      <div className="username">
        <span>{user.name}</span>
        <span className="job"></span>
      </div>
      {/* <div className="arrows">
				<UpIcon />
				<DownIcon />
			</div> */}
      <ThreeDots width={6} height={20} />
    </div>
  );
}

// ----------------------------------------------------------------------------

const StyledUserCard = styled(UserCard)`
  & {
    width: inherit;
    height: inherit;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    padding-right: 40px;
    .avatar {
      width: 40px;
      height: 40px;
    }

    .username {
      width: 100px;
      display: flex;
      flex-direction: column;
      font-size: 14px;
      line-height: 20px;
      color: #081735;

      .job {
        color: ${({ theme }) => theme.colors.grey100};
      }
    }

    .arrows {
      height: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
    }
    &:hover {
      cursor: pointer;
    }
  }
`;
export default StyledUserCard;
