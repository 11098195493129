import React, { useEffect, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import { useSelector } from "react-redux";

import * as api from "../../../../api";

import { ReactComponent as ArrowBack } from "../../../_shared/assets/icons/arrowback.svg";
import { Avatar, Button, Comment, Form, Input, List } from "antd";
import { concat } from "lodash";
import AppButton from "../../../_shared/components/AppButton";

import { useParams, useNavigate } from "react-router-dom";

const { TextArea } = Input;

const CommentList = ({ comments }) => (
  <List
    dataSource={comments}
    header={`${comments.length} ${
      comments.length > 1 ? "Commenti" : "commento"
    }`}
    itemLayout="horizontal"
    renderItem={(props) => <Comment {...props} />}
  />
);

const Editor = ({ onChange, onSubmit, submitting, value }) => (
  <>
    <Form.Item>
      <TextArea rows={2} onChange={onChange} value={value} />
    </Form.Item>
    <Form.Item>
      <Button
        htmlType="submit"
        loading={submitting}
        onClick={onSubmit}
        type="primary"
      >
        Aggiungi commento
      </Button>
    </Form.Item>
  </>
);

function Chat(props) {
  const { className, handleClose } = props;

  const [currentChat, setCurrentChat] = useState();

  const [comments, setComments] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [value, setValue] = useState("");
  const params = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    getChat();
  }, [params.chatId]);

  async function getChat() {
    try {
      const response = await api.getChat(params.chatId, user.token);
      setCurrentChat(response);
    } catch (error) {}
  }

  useEffect(() => {
    if (currentChat && comments?.length === 0) {
      setComments(currentChat?.comments);
    }
  }, [currentChat]);

  const user = useSelector((state) => (state?.auth.user ? state.auth : null));

  const handleSubmit = async () => {
    if (!value) return;
    setSubmitting(true);
    setTimeout(() => {
      setSubmitting(false);
      setValue("");
      setComments([
        ...comments,
        {
          author: user.user,
          content: <p>{value}</p>,
          datetime: moment(moment.now()).fromNow(),
        },
      ]);
    }, 1000);

    const body = {
      chat: currentChat.id,
      author: user.user,
      content: value,
      datetime: moment().format("DoMMM  YY H:mma"),
    };
    await api.createComment(body, user.token);
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <div className={className}>
      <div className="header">
        <ArrowBack
          className="icon"
          onClick={() => {
            navigate(-1, {
              state: { tab: "3" },
            });
          }}
        ></ArrowBack>
        <div className="title"> Chat {currentChat?.name}</div>
        {comments?.length > 0 && <CommentList comments={comments} />}
        <Comment
          author={user.user}
          content={
            <Editor
              onChange={handleChange}
              onSubmit={handleSubmit}
              submitting={submitting}
              value={value}
            />
          }
        />
      </div>
    </div>
  );
}
const StyledChat = styled(Chat)`
  & {
    width: inherit;
    min-width: 240px;
    padding: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: space-between;
    .leftSide {
      width: 100%;
      padding: 10px 10px;
      padding-bottom: 0px;
    }
    .icon {
      height: 25px;
      width: 25px;
      transition: 0.5s;
      margin-right: 10px;
      :hover {
        transform: rotate(90deg);
      }
    }
    .title {
      font-size: 25px;
    }
  }
`;
export default StyledChat;
