import React, { useState } from "react";
import styled from "styled-components";
import { Select } from "antd";
import FieldLabel from "../FieldLabel";
import { ReactComponent as ArrowIcon } from "../../assets/icons/down.svg";

function _AppSelect(props) {
    const {
        className,
        label,
        placeholder,
        value,
        onSelect,
        defaultValue,
        children,
        ...selectProps
    } = props;

    const [dropped, setDropped] = useState(false);
    const [focused, setFocused] = useState(false);

    return (
        <div className={className}>
            {label && <FieldLabel>{label}</FieldLabel>}
            <Select
                className={"appSelect " + (!!focused && "focused-select")}
                bordered={false}
                showSearch
                placeholder={placeholder && placeholder}
                optionFilterProp="children"
                defaultValue={defaultValue && defaultValue.email}
                filterOption={(input, option) =>
                    option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                }
                filterSort={(optionA, optionB) =>
                    optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                }
                value={value !== "" ? value : undefined}
                onSelect={onSelect}
                suffixIcon={
                    <ArrowIcon
                        className={`select-icon ${!!dropped && "dropped"}`}
                    />
                }
                onDropdownVisibleChange={() => setDropped(!dropped)}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                dropdownStyle={{
                    boxShadow: "none",
                    filter: "drop-shadow(1px 1px 2px rgba(237, 186, 141, 0.5))",
                }}
                {...selectProps}
            >
                {children}
            </Select>
        </div>
    );
}

const AppSelect = styled(_AppSelect)`
    & {
        margin-top: 20px;
        width: inherit;

        .appSelect {
            min-width: inherit;
            width: inherit;
            height: ${({ theme }) => theme.spacing.formItemHeight}px;
            background-color: ${({ theme }) => theme.colors.neutral100};
            border-radius: 4px;

            .ant-select-selector {
                height: inherit;
                background-color: ${({ theme }) => theme.colors.neutral100};
                border-radius: 4px;
                padding: 0px ${({ theme }) => theme.spacing.s}px;
                padding-top: 5px;

                .ant-select-selection-search-input {
                    padding: 0px ${({ theme }) => theme.spacing.xs}px;
                    padding-top: 5px;
                }

                .ant-select-selection-placeholder {
                    color: ${({ theme }) => theme.colors.inputText};
                    font-size: 12px;
                    font-weight: 400;
                }

                .ant-select-arrow .anticon svg {
                    color: ${({ theme }) => theme.colors.neutral800};
                }
            }

            :hover {
                background-color: ${({ theme }) => theme.colors.neutral200};
            }
        }
        .focused-select {
            background-color: ${({ theme }) => theme.colors.light};
        }

        .dropdown {
            background-color: ${({ theme }) => theme.colors.light};
        }

        .ant-select-focused {
            border: 1px solid ${({ theme }) => theme.colors.inputBorderFocus};
        }
        .select-icon {
            transition: all 0.3s ease;
        }

        .dropped {
            transform: rotate(-180deg);
        }
    }
`;
export default AppSelect;
