import {React} from "react"
import styled from "styled-components"
import Activity from "./Activity"
import AppButton from "../AppButton"
import { ReactComponent as Img } from "../../assets/icons/icon.svg"
// ----------------------------------------------------------------------------

function _Notifications (props) {

    // -------------------------------------
    // Props destructuring
    // -------------------------------------
    const { className, activities, refer} = props
    
    

    return (
        <div className={className} ref={refer}>
            <div className="head">
                Recent Notifications
            </div>
            { activities.map ((act, index) => {
                if(index <= 4)
                    return <Activity key={index} data={act} Icon={Img}/>
            })}
            <AppButton className="btn"><span>See all activity</span></AppButton>
        </div>
    )
}

const Notifications = styled(_Notifications) `
    & {
        position: absolute;
        top: 70px;
        right:50px;
        width: 310px;
        height: fit-content;
        background-color: ${({ theme }) => theme.colors.light};
        box-shadow: 0px 8px 16px rgba(143, 149, 178, 0.15);
        border-radius: 24px;   
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 100;
        
        .head {
            font-size: 17px;
            line-height: 24px;
            font-weight: 700;
            width: inherit;
            display: flex;
            align-items: center;
            margin-left:40px;
            margin-top:10px;
            height: 50px;
            border-radius: 24px; 
        }

        .btn {
            border: none;
            background-color:unset;
            margin-bottom: 15px;
            margin-top: 10px;
            span {
                color: ${({ theme }) => theme.colors.button};
                font-weight: 700;
            }
        }
    }
`
export default Notifications