import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Input, Button } from "antd"
import { FormattedMessage } from "react-intl"

// ----------------------------------------------------------------------------

function TableTextFilterDropdown(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { onSearch } = props

    // -------------------------------------
    // Hooks (e.g. useState, useMemo ...)
    // -------------------------------------

    const [value, setValue] = useState(null)

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function handleSearch(event) {
        onSearch && onSearch(value)
    }

    function handleInputChange(event) {
        setValue(event?.target?.value)
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={props.className}>
            <Input
                placeholder="Search"
                value={value}
                onChange={handleInputChange}
                onPressEnter={handleSearch}
            />
            <Button type="primary" onClick={handleSearch}>
                <FormattedMessage
                    id={"userInterface.shared.search"}
                    defaultMessage={"Search"}
                />
            </Button>
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

TableTextFilterDropdown.propTypes = {
    className: PropTypes.string.isRequired,
    onSearch: PropTypes.func.isRequired,
}

TableTextFilterDropdown.defaultProps = {}

// ----------------------------------------------------------------------------

const StyledTableTextFilterDropdown = styled(TableTextFilterDropdown)`
    & {
        padding: 8px;
        border-radius: 6px;
        background: #fff;
        box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);

        input {
            width: 130px;
            margin-right: 8px;
        }

        .highlight {
            color: #f50;
        }
    }
`
// ----------------------------------------------------------------------------

export default StyledTableTextFilterDropdown
