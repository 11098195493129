import React from "react";
import styled from "styled-components";
import { Select, Tag, message } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { BASE_URL } from "../../../../api";

import DealRightSheet from "./DealRightSheet";

import Avatar from "../../../_shared/components/Avatar";
import Table from "../../../_shared/components/Table";
import { ReactComponent as Delete } from "../../../_shared/assets/icons/delete.svg";
import { ReactComponent as Download } from "../../../_shared/assets/icons/download.svg";
import usePaginatedRestResource from "../../../_shared/hooks/usePaginatedRestResource";

import PopConfirm from "../../../_shared/components/PopConfirm";

import * as api from "../../../../api";

// ----------------------------------------------------------------------------

function Deals(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props;
    const { Option } = Select;

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------
    const user = useSelector((state) => (state?.auth.user ? state.auth : null));
    const [searched, setSearched] = useState("");

    const [
        deals,
        ,
        { pagination, count, filters: currentFilters },
        {
            fetchResource: fetchDeals,
            onChange: handleTableChange,
            onFiltersChange,
        },
    ] = usePaginatedRestResource("/deals", user.token, {
        defaultFilters: {
            organization: user.organization,
        },
    });

    const [selectedDeal, setSelectedDeal] = useState();

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    function getColumns() {
        return [
            {
                title: "CODICE",
                dataIndex: "code",
                key: "code",
                render: (text, record, index) => (
                    <span className="dealLink">
                        <Link to={`${record.id}`}>{text}</Link>
                    </span>
                ),
            },
            {
                title: "NOME OFFERTA",
                dataIndex: "name",
                key: "name",
                sorter: (a, b) => a.name.localeCompare(b.name),
                render: (text, record, index) => (
                    <span className="dealLink">
                        <Link to={`${record.id}`}>{text}</Link>
                    </span>
                ),
            },
            {
                title: "STATO OFFERTA",
                dataIndex: "status",
                key: "status",
                render: (text) => (
                    <Tag
                        color={
                            text === "Inviata"
                                ? "blue"
                                : text === "Accettata"
                                ? "green"
                                : "red"
                        }
                    >
                        {text}
                    </Tag>
                ),
            },
            {
                title: "TITOLARE DELL' OFFERTA",
                dataIndex: "user",
                key: "user",
                render: (user) => {
                    const names = user?.fullName?.split(" ") ?? "";
                    return (
                        <div className="row">
                            <Avatar
                                name={
                                    names != ""
                                        ? names[0].charAt(0) +
                                          (names[1].charAt(0) ?? "")
                                        : ""
                                }
                            />
                            <span>{user?.fullName ?? ""}</span>
                        </div>
                    );
                },
            },
            {
                title: "CLIENTE",
                dataIndex: "recipient",
                key: "recipient",
                sorter: (a, b) =>
                    a.recipient.businessName
                        ? a.recipient.businessName.localeCompare(
                              b.recipient.businessName ?? b.recipient.fullName
                          )
                        : a.recipient.fullName.localeCompare(
                              b.recipient.businessName ?? b.recipient.fullName
                          ),
                render: (text) => (
                    <div className="row">
                        <Avatar
                            name={
                                text
                                    ? text?.businessName?.charAt(0) ??
                                      text?.fullName?.charAt(0)
                                    : ""
                            }
                        />
                        <span>
                            {text ? text?.businessName ?? text?.fullName : ""}
                        </span>
                    </div>
                ),
            },
            {
                title: "TOTALE",
                dataIndex: "amount",
                key: "amount",
                sorter: (a, b) => a.amount - b.amount,
                render: (text) => `${text}€`,
            },
            {
                title: "",
                dataIndex: "",
                key: "row-actions",
                render: (text, record, index) => (
                    <div className="row-actions">
                        <a
                            href={`${BASE_URL}/deals/${record.id}/document`}
                            target="__blank"
                            className="delete"
                            onClick={(e) => e.stopPropagation()}
                        >
                            <Download />
                        </a>
                        <PopConfirm
                            className={className}
                            title="Sei sicuro di voler rimuovere questa offerta?"
                            onConfirm={(e) => deleteDeal(e, record.id)}
                            onCancel={(e) => e.stopPropagation()}
                            okText="Sì"
                            cancelText="No"
                            placement="leftTop"
                        >
                            <div
                                className="delete"
                                onClick={(e) => e.stopPropagation()}
                            >
                                <Delete />
                            </div>
                        </PopConfirm>
                    </div>
                ),
            },
        ];
    }
    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function getTableFilters() {
        return [
            {
                key: "code",
                type: "text",
                value: currentFilters.code,
                onSearch: (value) =>
                    onFiltersChange({
                        code: value,
                    }),
            },
            {
                key: "name",
                type: "text",
                value: currentFilters.name,
                onSearch: (value) =>
                    onFiltersChange({
                        name: value,
                    }),
            },
        ];
    }

    async function handleDownloadDocument() {}

    async function deleteDeal(e, dealId) {
        e.stopPropagation();
        try {
            await api.deleteDeal(dealId, user.token);

            fetchDeals();
        } catch (error) {
            console.log("[ERROR] in delete deal: ");
            console.log(error);
        }
    }

    function handleNewDeal() {
        setSelectedDeal({});
    }

    function onCancel() {
        setSelectedDeal(null);
    }

    function handleSearch() {
        onFiltersChange({
            name: searched,
            code: searched,
        });
    }
    function handleChangeSearch(value) {
        if (value.length === 0 && searched.length > value.length) {
            onFiltersChange({
                name: value,
                code: value,
            });
        } else {
            setSearched(value);
        }
    }

    async function handleNewOrder(values) {
        try {
            !values?.organization
                ? (values.organization = user.organization)
                : (values.organization = values?.organization?.id);

            if (values?.user) values.user = values?.user?.id;

            if (values?.recipient && typeof values?.recipient !== "string")
                values.recipient = values.recipient.id;

            await api.createOrder(values, user.token);
            await api.updateDeal(
                values?.id,
                { status: "Accettata" },
                user.token
            );

            fetchDeals();
            message.success("Operazione eseguita con successo");
            setSelectedDeal(null);
        } catch (error) {
            message.error("ATTENZIONE! Azione non completata, riprovare");
        }
    }

    async function handleSearchRecipient(type, value) {
        try {
            let response;
            if (type === "Company") {
                response = await api.searchCompanies(
                    value,
                    user.organization,
                    user.token
                );
                if (response) {
                    return response.rows?.map((row) => {
                        return { label: row.businessName, id: row.id };
                    });
                }
            } else {
                response = await api.searchContacts(
                    value,
                    user.organization,
                    user.token
                );
                if (response) {
                    return response.rows?.map((row) => {
                        return { label: row.fullName, id: row.id };
                    });
                }
            }

            return null;
        } catch (error) {
            console.log("[ERROR] in search companies/contacts: ");
            console.log(error);
        }
    }

    async function handleGenerateInvoice(values) {
        try {
            const invoiceObj = {};
            invoiceObj.organization = user.organization;
            invoiceObj.recipientType = values.recipientType;
            invoiceObj.code = values.code;
            if (values?.recipient && typeof values?.recipient !== "string") {
                invoiceObj.recipient = values.recipient.id;
            } else {
                invoiceObj.recipient = values.recipient;
            }

            invoiceObj.taxable = values.amount;

            invoiceObj.rows = values.products?.map((product) => {
                return {
                    description: product?.description,
                    quantity: product?.quantity,
                    unitPrice: product.price,
                    discount: product.discount,
                    key: product?.key,
                };
            });

            await api.createInvoice(user.organization, invoiceObj, user.token);
            message.success("Operazione eseguita con successo");
            setSelectedDeal(null);
        } catch (error) {
            message.error("ATTENZIONE! Azione non completata, riprovare");
        }
    }

    async function onConfirm(values) {
        try {
            console.log("on Confirm: ", values);
            !values?.organization
                ? (values.organization = user.organization)
                : (values.organization = values?.organization?.id);

            if (values?.user) values.user = values?.user?.id;
            if (values?.agent?.id) values.agent = values?.agent?.id;
            if (values?.recipient && typeof values?.recipient !== "string")
                values.recipient = values.recipient.id;

            // const org = await api.getOrganizations(values?.recipient, user.token)
            // if(org.count === 0){
            //     let orgUser
            //     const company = await api.getCompany(values?.recipient, user.token)

            //     if(company.contact){
            //         orgUser = await api.signUp(
            //             company.contact.email,
            //             102938,
            //             company.contact.name,
            //             company.contact.surname
            //         )
            //     }else if (company.email !==""){
            //             orgUser = await api.signUp(
            //                 company.email,
            //                 102938,
            //                 "User",
            //                 "User"
            //             )
            //         }
            //         else if (company.owner){
            //                 orgUser = await api.signUp(
            //                     company.email,
            //                     102938,
            //                     company.owner.name,
            //                     company.owner.surname
            //                 )
            //             }

            //     const orgBody = {
            //         businessName: company.businessName,
            //         businessDomainName: company.businessDomainName,
            //         vatNumber: company.vatNumber,
            //         user: orgUser.id
            //     }

            //     const org = await api.createOrganization(orgBody, user.token)
            //     await api.updateUser(orgUser.user.id, {organizations: org.id}, user.token)
            //     await api.createInvite(
            //         { email: orgUser.user.email, organization: org.id, state: "pending" },
            //         user.token
            //     )
            // }

            values?.id
                ? await api.updateDeal(values.id, values, user.token)
                : await api.createDeal(values, user.token);

            fetchDeals();
            message.success("Operazione eseguita con successo");
            setSelectedDeal(null);
        } catch (error) {
            console.log("[ERROR] in confirm deal: ");
            console.log(error);
            message.error("Errore durante l'operazione");
        }
    }

    return (
        <Table
            className={className}
            columns={getColumns()}
            onResourceCreate={() => selectedDeal({})}
            dataSource={deals}
            onChange={handleTableChange}
            rowSelection={{ type: "checkbox" }}
            addContact="true"
            handleOnRow={(row) => setSelectedDeal(row)}
            renderTopBarCustomButtons={() => (
                <DealRightSheet
                    open={!!selectedDeal}
                    defaultValues={selectedDeal}
                    onConfirm={onConfirm}
                    onCancel={onCancel}
                    onButtonClick={handleNewDeal}
                    newOrder={handleNewOrder}
                    generateInvoice={handleGenerateInvoice}
                    handleSearch={handleSearchRecipient}
                />
            )}
            filters={getTableFilters()}
            search
            handleSearchSubmit={handleSearch}
            handleOnChangeSearch={handleChangeSearch}
        />
    );
}

// ----------------------------------------------------------------------------

const StyledDeals = styled(Deals)`
    & {
        .dealLink {
            text-decoration: underline;
        }
        .form {
            width: 100%;
            padding: 0px 23px;
            min-width: 280px;
            overflow: scroll;
        }
        .app-select {
            min-width: 280px;
        }
        .converter-btn {
            width: 88%;
        }
        .row {
            display: flex;
            flex-direction: row;
            align-items: center;

            span {
                margin-left: 8px;
            }
        }

        .row-actions {
            display: flex;
            flex-direction: row;
            gap: 10px;
        }

        .products {
            margin-top: 20px;
            font-weight: 300;
            font-size: 14px;
            line-height: 16px;
            color: #252631;
        }

        .total {
            margin-top: 10px;
            display: flex;
            flex-direction: row-reverse;
        }

        .addField {
            display: flex;
            flex-direction: row-reverse;
            justify-content: center;
            margin: auto;
            margin-top: 10px;
            padding: 5px;
            border: none;
            .icon {
                margin: auto;
                margin-right: 10px;
                height: 14px;
            }
        }
    }
`;
export default StyledDeals;
