import React from "react"
import styled from "styled-components"
import { Select, message} from "antd"
import { useState } from "react"
import { useSelector } from "react-redux"

import RateRightSheet from "./RateRightSheet"

import PopConfirm from "../../../../../_shared/components/PopConfirm"
import Table from "../../../../../_shared/components/Table"
import { ReactComponent as Delete } from "../../../../../_shared/assets/icons/delete.svg"
import usePaginatedRestResource from "../../../../../_shared/hooks/usePaginatedRestResource"

import * as api from "../../../../../../api"

// ----------------------------------------------------------------------------

function Rates(props) {
	// -------------------------------------
	// Props destructuring
	// -------------------------------------

	const { className } = props
	const { Option } = Select
	// -------------------------------------
	// Hooks (e.g. useState, ...)
	// -------------------------------------

	const user = useSelector((state) => (state?.auth.user ? state.auth : null))

    const [
        rates,
        ,
        { pagination, count, filters },
        {
            fetchResource: fetchRates,
            onChange: handleTableChange,
            onFiltersChange,
        },
    ] = usePaginatedRestResource('/rates', user.token, {
            defaultFilters: {
                organization: user.organization
            },
        })

    const [selectedRate, setSelectedRate] = useState()

	// -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------    
    // Component functions
    // -------------------------------------

	function getColumns() {
        return [
            {
                title: "NOME",
                dataIndex: "name",
                key: "name",
            },
            {
                title: "Percentuale",
                dataIndex: "percentage",
                key: "percentage",
            },
            {
                title: "",
                dataIndex: "delete",
                key: "delete",
                render: (text, record, index) => (
                    <PopConfirm
                        className={className}
                        title="Sei sicuro di voler rimuovere questa aliquota?"
                        onConfirm={(e) => deleteRate(e, record.id)}
                        onCancel={(e) => e.stopPropagation()}
                        okText="Sì"
                        cancelText="No"
                        placement="leftTop"
                    >
                        <div className="delete" onClick={(e) => e.stopPropagation()}><Delete/></div>
                    </PopConfirm>
                )
            },
            
        ]
    }

    async function deleteRate(e, rateId) {
        e.stopPropagation()
        try {
            await api.deleteRate(rateId, user.token)
            fetchRates()
        } catch (error) {
            console.log("[ERROR] in delete rate: ")
            console.log(error)
        }
    }

	function handleNewRate() {
        setSelectedRate({})
	}

	function onCancel() {
        setSelectedRate(null)
	}

	async function onConfirm(values) {
        if(values?.name === "")
            message.error("Nome mancante")
        else {
            try {
                !values?.organization
                    ? values.organization = user.organization
                    : values.organization = values.organization.id

                values?.id
                    ? await api.updateRate(values.id, values, user.token)
                    : await api.createRate(values, user.token)
                
                fetchRates()
                message.success("Operazione eseguita con successo")
                setSelectedRate(null)
                } catch (error) {
                    console.log("[ERROR] in confirm rate: ")
                    console.log(error)
                    message.error("Errore durante l'operazione")
                }
        }
	}

	return (
		<Table
            className={className}
            columns={getColumns()}
            onResourceCreate={() => setSelectedRate({})}
            dataSource={rates}
            rowSelection={{ type: "checkbox" }}
            addContact="true"
            handleOnRow={(row) => setSelectedRate(row)}
            renderTopBarCustomButtons={() => (
                <RateRightSheet
                    open={!!selectedRate}
                    defaultValues={selectedRate}
                    onConfirm={onConfirm}
                    onCancel={onCancel}
                    onButtonClick={handleNewRate}
                />
            )}
        />
	)
}

// ----------------------------------------------------------------------------

const StyledRates = styled(Rates)`
	& {
        .form {
            min-width: 280px;

            .percentage{
                margin-top: 20px
            }
        }
        .app-select {
            min-width: 280px;
        }
		.row {
			display: flex;
			flex-direction: row;
			align-items: center;

			span {
				margin-left: 8px;
			}
		}
		.addField {
			display: flex;
			flex-direction: row-reverse;
			justify-content: center;
			margin: auto;
			margin-top: 10px;
			padding: 5px;
			border: none;
			.icon {
				margin: auto;
				margin-right: 10px;
				height: 14px;
			}
		}
	}
`
export default StyledRates
