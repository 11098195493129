import React, { useEffect } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { PlusOutlined } from "@ant-design/icons";
import { message, Select, Row, Col } from "antd";

import AppButton from "../../../../_shared/components/AppButton";
import RightSheet from "../../../../_shared/components/Drawer";
import AppInput from "../../../../_shared/components/AppInput";
import useForm from "../../../../_shared/hooks/useForm";
import AppDatePicker from "../../../../_shared/components/AppDatePicker";

function MilestoneCreation(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { className, open, defaultValues, onConfirm, onCancel } = props;

  // -------------------------------------
  // Hooks (e.g. useState, ...)
  // -------------------------------------

  const Option = Select;
  const [form, , setMany, clearForm] = useForm();
  const user = useSelector((state) => (state?.auth.user ? state.auth : null));

  // -------------------------------------
  // Memoized values
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  useEffect(() => {
    !open && clearForm();
    // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    defaultValues && setMany(defaultValues);

    // eslint-disable-next-line
  }, [defaultValues]);

  // -------------------------------------
  // Component functions
  // -------------------------------------
  function update(field) {
    return (value) => {
      setMany({
        ...form,
        [field]: value,
      });
    };
  }

  function handleConfirm() {
    onConfirm && onConfirm(form);
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <RightSheet
      open={open}
      onCancel={onCancel}
      onConfirm={handleConfirm}
      title={defaultValues?.id ? "Modifica Milestone" : "Crea Milestone"}
      confirmButtonLabel={
        defaultValues?.id ? "Modifica Milestone" : "Crea Milestone"
      }
      cancelButtonLabel="Annulla"
      className={`${className}`}
    >
      <form className="form">
        <AppInput
          label="Nome"
          placeholder="Nome"
          value={form?.name ?? ""}
          onChange={update("name")}
        />
        <AppInput
          label="Descrizione"
          placeholder="Descrizione"
          value={form?.description ?? ""}
          onChange={update("description")}
        />

        <AppDatePicker
          label="Scadenza"
          placeholder="Data"
          value={form?.deadLine ?? ""}
          onChange={update("deadLine")}
        />
      </form>
    </RightSheet>
  );
}
// ----------------------------------------------------------------------------

const StyledMilestoneCreation = styled(MilestoneCreation)`
  & {
    .products {
      margin-top: 15px;
    }
    .components {
      margin-top: 20px;
      padding-bottom: 5px;
      font-weight: 400;
      font-size: 12px;
      border-bottom: 1px solid ${({ theme }) => theme.colors.grey200};
    }
  }
`;
export default StyledMilestoneCreation;
