import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

function _SubHeading (props) {

    const { className, children} = props

    return (
        <span className={`${className}`}>{ children }</span>
    )
}
_SubHeading.propTypes = {
    className: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired
}

_SubHeading.defaultProps = {}

const SubHeading = styled(_SubHeading) `
    & {
        display: inline-block;
        margin-bottom: ${({ theme }) => theme.spacing.xs}px;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.02em;
        color: #252631;
    }
`
export default SubHeading