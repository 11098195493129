import React from "react";
import styled from "styled-components";
import { useState } from "react";
import { useSelector } from "react-redux";
import { message, Select, Tag } from "antd";

import OrderRightSheet from "./OrderRightSheet";

import Avatar from "../../../_shared/components/Avatar";
import Table from "../../../_shared/components/Table";
import PopConfirm from "../../../_shared/components/PopConfirm";
import { ReactComponent as Delete } from "../../../_shared/assets/icons/delete.svg";
import usePaginatedRestResource from "../../../_shared/hooks/usePaginatedRestResource";

import * as api from "../../../../api";
import { Link } from "react-router-dom";
import useRestResource from "../../../_shared/hooks/useRestResource";

// ----------------------------------------------------------------------------

function Orders(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props;
    const { Option } = Select;

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------
    const user = useSelector((state) => (state?.auth.user ? state.auth : null));
    const [searched, setSearched] = useState("");

    const [
        orders,
        ,
        { pagination, count, filters: currentFilters },
        {
            fetchResource: fetchOrders,
            onChange: handleTableChange,
            onFiltersChange,
        },
    ] = usePaginatedRestResource("/orders", user.token, {
        defaultFilters: {
            organization: user.organization,
        },
    });


    const [contacts] = useRestResource("/contacts", "", user.token, {
        organization: user.organization,
      });
    const [selectedOrder, setSelectedOrder] = useState();
    // -------------------------------------
    // Memoized values
    // -------------------------------------

    function getColumns() {
        return [
            {
                title: "CODICE",
                dataIndex: "code",
                key: "code",
                render: (text, record, index) => (
                    <span className="dealLink">
                        <Link to={`${record.id}`}>{text}</Link>
                    </span>
                ),
            },
            {
                title: "NOME ORDINE",
                dataIndex: "name",
                key: "name",
            },
            {
                title: "STATO ORDINE",
                dataIndex: "status",
                key: "status",
                render: (text) => (
                    <Tag
                        color={
                            text === "Inviata"
                                ? "blue"
                                : text === "Accettata"
                                ? "green"
                                : "red"
                        }
                    >
                        {text}
                    </Tag>
                ),
            },
            {
                title: "TITOLARE DELL' OFFERTA",
                dataIndex: "user",
                key: "user",
                render: (user) => {
                    const names = user?.fullName?.split(" ") ?? "";
                    return (
                        <div className="row">
                            <Avatar
                                name={
                                    names != ""
                                        ? names[0].charAt(0) +
                                          (names[1].charAt(0) ?? "")
                                        : ""
                                }
                            />
                            <span>{user?.fullName ?? ""}</span>
                        </div>
                    );
                },
            },
            {
                title: "CLIENTE",
                dataIndex: "recipient",
                key: "recipient",
                sorter: (a, b) =>
                    a.recipient.businessName
                        ? a.recipient.businessName.localeCompare(
                              b.recipient.businessName ?? b.recipient.fullName
                          )
                        : a.recipient.fullName.localeCompare(
                              b.recipient.businessName ?? b.recipient.fullName
                          ),
                render: (text) => (
                    <div className="row">
                        <Avatar
                            name={
                                text
                                    ? text?.businessName?.charAt(0) ??
                                      text?.fullName?.charAt(0)
                                    : ""
                            }
                        />
                        <span>
                            {text ? text?.businessName ?? text?.fullName : ""}
                        </span>
                    </div>
                ),
            },
            {
                title: "TOTALE",
                dataIndex: "amount",
                key: "amount",
                sorter: (a, b) => a.amount - b.amount,
                render: (text) => `${text}€`,
            },
            {
                title: "",
                dataIndex: "delete",
                key: "delete",
                render: (text, record, index) => (
                    <PopConfirm
                        className={className}
                        title="Sei sicuro di voler rimuovere questo ordine?"
                        onConfirm={(e) => deleteOrder(e, record.id)}
                        onCancel={(e) => e.stopPropagation()}
                        okText="Sì"
                        cancelText="No"
                        placement="leftTop"
                    >
                        <div
                            className="delete"
                            onClick={(e) => e.stopPropagation()}
                        >
                            <Delete />
                        </div>
                    </PopConfirm>
                ),
            },
        ];
    }
    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function handleSearch() {
        onFiltersChange({
            code: searched,
            name: searched,
        });
    }
    function handleChangeSearch(value) {
        if (value.length === 0 && searched.length > value.length) {
            onFiltersChange({
                code: value,
                name: value,
            });
        } else {
            setSearched(value);
        }
    }

    function getTableFilters() {
        return [
            {
                key: "code",
                type: "text",
                value: currentFilters.code,
                onSearch: (value) =>
                    onFiltersChange({
                        code: value,
                    }),
            },
        ];
    }

    async function deleteOrder(e, orderId) {
        e.stopPropagation();
        const order = orders.find((order) => order.id === orderId);
        if (order.owner === user.email) {
            try {
                await api.deleteOrder(orderId, user.token);

                fetchOrders();
            } catch (error) {
                console.log("[ERROR] in delete order: ");
                console.log(error);
            }
        } else {
            message.error(
                "Ordine cancellabile solo dall'utente che l'ha creato"
            );
        }
    }

    async function handleSearchRecipient(type, value) {
        try {
            let response;
            if (type === "Company") {
                response = await api.searchCompanies(
                    value,
                    user.organization,
                    user.token
                );
                if (response) {
                    return response.rows?.map((row) => {
                        return { label: row.businessName, id: row.id };
                    });
                }
            } else {
                response = await api.searchContacts(
                    value,
                    user.organization,
                    user.token
                );
                if (response) {
                    return response.rows?.map((row) => {
                        return { label: row.fullName, id: row.id };
                    });
                }
            }

            return null;
        } catch (error) {
            console.log("[ERROR] in search companies/contacts: ");
            console.log(error);
        }
    }

    function handleNewOrder() {
        setSelectedOrder({});
    }

    function onCancel() {
        setSelectedOrder(null);
    }

    async function onConfirm(values) {
        try {
            let vatNumber;
            !values?.organization
                ? (values.organization = user.organization)
                : (values.organization = values.organization.id);

            if (!values?.owner) values.owner = user.user;

            if (values?.agent?.id) values.agent = values?.agent?.id;

            if (values?.user) values.user = values.user.id;

            if (values?.recipient && typeof values?.recipient !== "string") {
                vatNumber = values.recipient.vatNumber;
                values.recipient = values.recipient.id;
            }

             const org = await api.getOrganizations(vatNumber, user.token);
           
            if (org.count === 0) {
                let orgUser;
                let company;
                if(values.recipientType === "Contact")
                {
                    company = contacts?.rows?.find((x) => x.id === values?.recipient)
                                      
                    }
                    else
                    {
                    

                    company = await api.getCompany(
                        values?.recipient,
                        user.token
                        );
                    
            

                if ( company?.contact && company?.contact) {
                    orgUser = await api.getUserByEmail(
                        company.contact.email,
                        user.token
                    );

                    if (!user?.email)
                        orgUser = await api.signUp(
                            company.contact.email,
                            102938,
                            company.contact.name,
                            company.contact.surname
                        );
                } else if (company?.email && company?.email !== "") {
                  
                    orgUser = await api.signUp(
                        company.email,
                        102938,
                        "User",
                        "User"
                    );
                } else if (company.owner) {
                    orgUser = await api.signUp(
                        company.email,
                        102938,
                        company.owner.name,
                        company.owner.surname
                    );
                }
               
                  const orgBody = {
                      businessName: company.businessName,
                      businessDomainName: company.businessDomainName,
                      vatNumber: company.vatNumber,
                      user: orgUser?.id,
                  };

                  const org = await api.createOrganization(orgBody, user.token);
                  await api.updateUser(
                      orgUser.user.id ?? orgUser.id,
                      { organizations: org.id },
                      user.token
                  );
                  await api.createInvite(
                      {
                          email: orgUser?.user?.email,
                          organization: org?.id,
                          state: "pending",
                      },
                      user.token
                  );
                }
            }
            values?.id
                ? await api.updateOrder(values.id, values, user.token)
                : await api.createOrder(values, user.token);

            fetchOrders();
            message.success("Operazione eseguita con successo");
            setSelectedOrder(null);
        } catch (error) {
            console.log("[ERROR] in confirm order: ");
            console.log(error);
            message.error("Errore durante l'operazione");
        }
    }

    async function handleNewDDT(values) {
        try {
            !values?.organization
                ? (values.organization = user.organization)
                : (values.organization = values?.organization?.id);

            if (values?.user) values.user = values?.user?.id;

            if (values?.recipient && typeof values?.recipient !== "string")
                values.recipient = values.recipient.id;

            await api.createDeliveryNote(values, user.token);
            await api.updateOrder(
                values.id,
                { status: "Accettato" },
                user.token
            );
            fetchOrders();
            message.success("Operazione eseguita con successo");
            setSelectedOrder(null);
        } catch (error) {
            message.error("ATTENZIONE! Azione non completata, riprovare");
        }
    }

    async function handleGenerateInvoice(values) {
        try {
            const invoiceObj = {};
            invoiceObj.organization = user.organization;
            invoiceObj.recipientType = values.recipientType;
            invoiceObj.code = values.code;
            if (values?.recipient && typeof values?.recipient !== "string") {
                invoiceObj.recipient = values.recipient.id;
            } else {
                invoiceObj.recipient = values.recipient;
            }

            invoiceObj.taxable = values.amount;

            invoiceObj.rows = values.products?.map((product) => {
                return {
                    description: product?.description,
                    quantity: product?.quantity,
                    unitPrice: product.price,
                    discount: product.discount,
                    key: product?.key,
                };
            });

            await api.createInvoice(user.organization, invoiceObj, user.token);
            message.success("Operazione eseguita con successo");
            setSelectedOrder(null);
        } catch (error) {
            message.error("ATTENZIONE! Azione non completata, riprovare");
        }
    }

    return (
        <Table
            className={className}
            columns={getColumns()}
            onResourceCreate={() => selectedOrder({})}
            dataSource={orders}
            onChange={handleTableChange}
            rowSelection={{ type: "checkbox" }}
            addContact="true"
            handleOnRow={(row) => setSelectedOrder(row)}
            renderTopBarCustomButtons={() => (
                <OrderRightSheet
                    open={!!selectedOrder}
                    defaultValues={selectedOrder}
                    onConfirm={onConfirm}
                    onCancel={onCancel}
                    onButtonClick={handleNewOrder}
                    generateDDT={handleNewDDT}
                    generateInvoice={handleGenerateInvoice}
                    handleSearch={handleSearchRecipient}
                />
            )}
            filters={getTableFilters()}
            search
            handleSearchSubmit={handleSearch}
            handleOnChangeSearch={handleChangeSearch}
        />
    );
}

// ----------------------------------------------------------------------------

const StyledOrders = styled(Orders)`
    & {
        .dealLink {
            text-decoration: underline;
        }
        .row {
            display: flex;
            flex-direction: row;
            align-items: center;

            span {
                margin-left: 8px;
            }
        }
        .form {
            width: 100%;
            padding: 0px 23px;
            min-width: 280px;
            overflow: scroll;
        }
        .app-select {
            min-width: 280px;
        }
        .converter {
            width: 88%;
        }
        .products {
            margin-top: 20px;
            font-weight: 300;
            font-size: 14px;
            line-height: 16px;
            color: #252631;
        }

        .total {
            margin-top: 10px;
            display: flex;
            flex-direction: row-reverse;
        }

        .addField {
            display: flex;
            flex-direction: row-reverse;
            justify-content: center;
            margin: auto;
            margin-top: 10px;
            padding: 5px;
            border: none;
            .icon {
                margin: auto;
                margin-right: 10px;
                height: 14px;
            }
        }
    }
`;
export default StyledOrders;
