import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

function _LogForm (props) {

    const { className , onSubmit, children} = props

    return (
            <form 
                className={`${className}`}
                onSubmit= {onSubmit}
            >
                { children }
            </form>
    )
}
_LogForm.propTypes = {
    className: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired
}

_LogForm.defaultProps = {}

const LogForm = styled(_LogForm) `
    & {
        min-height: 440px;
        width: 35vw;
        min-width:295px;
        max-width:450px;
        background-color: ${(props) => props.theme.colors.light};
        border-radius: 20px;
        padding: 30px 20px;
        margin:0px auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
`
export default LogForm