import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { message, Select } from "antd";
import { useSelector } from "react-redux";

import useForm from "../../../../_shared/hooks/useForm";
import AppButton from "../../../../_shared/components/AppButton";
import AppInput from "../../../../_shared/components/AppInput";
import RightSheet from "../../../../_shared/components/Drawer";
import useRestResource from "../../../../_shared/hooks/useRestResource";
import AppSelect from "../../../../_shared/components/AppSelect";
import AppDataPicker from "../../../../_shared/components/AppDatePicker";
import { configConsumerProps } from "antd/lib/config-provider";
import projectStatus from "../../../../_shared/constants/projectStatus";

// ----------------------------------------------------------------------------

function ProjectRightSheet(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { className, open, defaultValues, onConfirm, onCancel, onButtonClick } =
    props;

  const { Option } = Select;

  // -------------------------------------
  // Hooks (e.g. useState, ...)
  // -------------------------------------

  const [form, , setMany, clearForm] = useForm();
  const user = useSelector((state) => (state?.auth.user ? state.auth : null));

  const [users] = useRestResource("/users", "", user.token, {
    organizations: user.organization,
  });

  const [companies] = useRestResource("/companies", "", user.token, {
    organization: user.organization,
  });

  // -------------------------------------
  // Memoized values
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  useEffect(() => {
    !open && clearForm();
    // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    defaultValues && setMany(defaultValues);
    // eslint-disable-next-line
  }, [defaultValues]);
  // -------------------------------------
  // Component functions
  // -------------------------------------

  function update(field) {
    return (value) => {
      setMany({
        ...form,
        [field]: value,
      });
    };
  }

  function handleConfirm() {
    onConfirm && onConfirm(form);
  }

  // -------------------------------------

  return (
    <RightSheet
      open={open}
      onCancel={onCancel}
      onConfirm={handleConfirm}
      title={defaultValues?.id ? "Modifica progetto" : "Crea progetto"}
      confirmButtonLabel={
        defaultValues?.id ? "Modifica progetto" : "Crea progetto"
      }
      cancelButtonLabel="Annulla"
      className={`${className}`}
    >
      <form className="form">
        <AppInput
          label="Nome"
          placeholder="Nome"
          value={form?.name ?? ""}
          onChange={update("name")}
        />
        <AppInput
          label="Descrizione"
          placeholder="Descrizione"
          value={form?.description ?? ""}
          onChange={update("description")}
        />

        <AppSelect
          label="Cliente"
          placeholder="Cliente"
          value={form?.customer?.businessName ?? ""}
          onSelect={update("customer")}
          className="app-select"
        >
          {companies &&
            companies.rows?.map((company, index) => (
              <Option key={company.id} id={index}>
                {company.businessName}
              </Option>
            ))}
        </AppSelect>

        <AppSelect
          label="Stato attuale"
          placeholder="Stato attuale"
          value={form?.status ?? ""}
          onSelect={update("status")}
          className="app-select"
        >
          {projectStatus &&
            Object.entries(projectStatus).map((status, index) => (
              <Option key={status[0]} id={index}>
                {status[1]}
              </Option>
            ))}
        </AppSelect>

        <AppDataPicker
          label="Data consegna progetto"
          value={form?.deadLine ?? null}
          onChange={update("deadLine")}
        />
        {/* {form?.customFields?.map((customField, index) => (
                            <CustomField
                                key={customField.key}
                                id={customField.key}
                                onChange={onChange}
                                onCancel={(e, key) => handleDeleteCustomField(e,key)}
                                customFields={customFields?.rows}
                                givenCustomField={
                                    customField.customField ?? null
                                }
                                givenValue={customField.value ?? null}
                            />
                        ))}
                        <AppButton
                            uxType="link"
                            onClick={handleNewField}
                            className="add-field"
                            icon={<PlusOutlined />}
                        >
                            Aggiungi campo personalizzato
                        </AppButton> */}
      </form>
    </RightSheet>
  );
}

// ----------------------------------------------------------------------------

const StyledProjectRightSheet = styled(ProjectRightSheet)`
  & {
  }
`;
// ----------------------------------------------------------------------------

export default StyledProjectRightSheet;
