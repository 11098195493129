import React, { useEffect, useState } from "react";
import styled from "styled-components";

import {
  DndContext,
  DragOverlay,
  closestCorners,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  MouseSensor,
  TouchSensor,
} from "@dnd-kit/core";
import {
  arrayMove,
  sortableKeyboardCoordinates,
  SortableContext,
  horizontalListSortingStrategy,
} from "@dnd-kit/sortable";

import { message, Tooltip, Progress, Popconfirm, Avatar } from "antd";

import SortableContainer, { Container } from "./container";
import * as api from "../../../../../api";

import { useSelector } from "react-redux";

import SortableItem, { Item } from "./sortable_item";
import useForm from "../../../../_shared/hooks/useForm";
import TaskRightSheet from "../TaskListCard/TaskRightSheet";

function DraggableView(props) {
  const {
    className,

    onDelete,
    setSelectedTaskList,
    project,
    updateProject,
  } = props;

  const user = useSelector((state) => (state?.auth.user ? state.auth : null));

  const [data, setData] = useState({
    items: [],
  });
  const [activeId, setActiveId] = useState();

  const [form, , setMany, clearForm] = useForm();

  const [selectedTask, setSelectedTask] = useState();
  const [currentTaskList, setCurrentTaskList] = useState();

  async function onConfirm(values) {
    try {
      const body = {
        ...values,
        taskList: currentTaskList,
      };

      let response = {};
      if (values?.id) {
        response = await api.updateTask(values.id, body, user.token);
      } else {
        response = await api.createTask(body, user.token);
      }
      updateProject();
      setSelectedTask(null);

      message.success("Operazione eseguita con successo");
    } catch (error) {
      console.log("[Error] in confirm taskcard: ", error);

      message.error("Errore durante l'operazione");
    }
  }

  function handleNewTask(e, obj) {
    e.stopPropagation();
    setCurrentTaskList(obj);
    setSelectedTask({});
  }

  async function handleDeleteTask(id) {
    const newTasks = form?.tasks?.filter((task) => task.id !== id);

    setMany({ ...form, tasks: newTasks });

    await api.deleteTask(id, user.token);
  }

  useEffect(() => {
    if (data?.save === true) saveData();
  }, [data]);

  useEffect(() => {
    setData({ items: [] });

    project?.taskLists.forEach((tl) => {
      setData((prev) => ({
        items: [
          ...prev.items,
          {
            id: tl._id,
            container: true,
            item: tl,
          },
        ],
      }));
    });

    project?.taskLists.forEach((tl) => {
      tl?.tasks?.forEach((task) => {
        setData((prev) => ({
          items: [
            ...prev.items,
            {
              id: task._id,
              parent: tl._id,
              item: task,
            },
          ],
        }));
      });
    });

    setMany(project);
  }, [project]);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 200,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  return (
    <div className={className}>
      <DndContext
        sensors={sensors}
        onDragStart={handleDragStart}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
        strategy={closestCorners}
      >
        <SortableContext
          id="root"
          items={getItemIds()}
          strategy={horizontalListSortingStrategy}
        >
          <div className="wrapperStyle">
            {getItems().map((item) => {
              if (item.container) {
                return (
                  <SortableContainer
                    key={item.id}
                    id={item.id}
                    obj={item.item}
                    getItems={getItems}
                    className={className}
                    handleModifyTaskList={handleModifyTaskList}
                    handleModifyTask={handleModifyTask}
                    handleNewTask={(e) => handleNewTask(e, item.item)}
                  />
                );
              }

              return (
                <SortableItem key={item.id} id={item.id}>
                  <Item id={item.id} />
                </SortableItem>
              );
            })}
          </div>
        </SortableContext>

        <DragOverlay>{getDragOverlay()}</DragOverlay>
      </DndContext>
      <TaskRightSheet
        open={!!selectedTask}
        onCancel={() => setSelectedTask(null)}
        defaultValues={selectedTask}
        onConfirm={onConfirm}
        team={project.team}
      />
    </div>
  );

  function handleModifyTaskList(e, item) {
    setSelectedTaskList(project.taskLists.filter((tl) => tl._id === item)[0]);
  }
  function handleModifyTask(e, item) {
    e.stopPropagation();
    setSelectedTask(item);
  }

  function isContainer(id) {
    const item = data.items.find((item) => item.id === id);

    return !item ? false : item.container;
  }

  function isRow(id) {
    const item = data.items.find((item) => item.id === id);

    return !item ? false : item.row;
  }

  function getItems(parent) {
    return data.items.filter((item) => {
      if (!parent) {
        return !item.parent;
      }

      return item.parent === parent;
    });
  }

  function getItemIds(parent) {
    return getItems(parent).map((item) => item.id);
  }

  function findParent(id) {
    const item = data.items.find((item) => item.id === id);
    return !item ? false : item.parent;
  }

  function getDragOverlay() {
    if (!activeId) {
      return null;
    }

    if (isContainer(activeId)) {
      const item = data.items.find((i) => i.id === activeId);

      return (
        <Container row={item.row}>
          {getItems(activeId).map((item) => (
            <Item key={item.id} id={item.id} />
          ))}
        </Container>
      );
    }

    return <Item id={activeId} />;
  }

  function handleDragStart(event) {
    const { active } = event;
    const { id } = active;

    setActiveId(id);
  }

  async function saveData() {
    console.log("dave data");
    const newTaskLists = data.items.filter((item) => item.container === true);

    for (const tl of newTaskLists) {
      const tasks = data.items.filter((item) => item.parent === tl.id);

      const updateData = {
        tasks: tasks.map((task) => task.id),
      };

      await api.updateTaskList(tl?.id, updateData, user.token);
    }
    const updateDataProject = {
      taskLists: newTaskLists.map((task) => task.id),
    };

    await api.updateProject(project.id, updateDataProject, user.token);
  }

  function handleDragOver(event) {
    const { active, over, draggingRect } = event;
    const { id } = active;
    let overId;
    if (over) {
      overId = over.id;
    }

    const overParent = findParent(overId);
    const overIsContainer = isContainer(overId);
    const activeIsContainer = isContainer(activeId);
    if (overIsContainer) {
      const overIsRow = isRow(overId);
      const activeIsRow = isRow(activeId);
      // only columns to be added to rows
      if (overIsRow) {
        if (activeIsRow) {
          return;
        }

        if (!activeIsContainer) {
          return;
        }
      } else if (activeIsContainer) {
        return;
      }
    }
    setData((prev) => {
      const activeIndex = data.items.findIndex((item) => item.id === id);
      const overIndex = data.items.findIndex((item) => item.id === overId);

      let newIndex = overIndex;
      const isBelowLastItem =
        over &&
        overIndex === prev.items.length - 1 &&
        draggingRect?.offsetTop > over.rect?.offsetTop + over.rect.height;

      const modifier = isBelowLastItem ? 1 : 0;

      newIndex = overIndex >= 0 ? overIndex + modifier : prev.items.length + 1;

      let nextParent;
      if (overId) {
        nextParent = overIsContainer ? overId : overParent;
      }

      prev.items[activeIndex].parent = nextParent;
      const nextItems = arrayMove(prev.items, activeIndex, newIndex);

      return {
        items: nextItems,
      };
    });
  }

  function handleDragEnd(event) {
    const { active, over } = event;
    const { id } = active;
    let overId;
    if (over) {
      overId = over.id;
    }

    const activeIndex = data.items.findIndex((item) => item.id === id);
    const overIndex = data.items.findIndex((item) => item.id === overId);

    let newIndex = overIndex >= 0 ? overIndex : 0;

    if (activeIndex !== overIndex) {
      setData((prev) => ({
        items: arrayMove(prev.items, activeIndex, newIndex),
        save: true,
      }));
    } else {
      saveData();
    }

    setActiveId(null);
  }
}
const StyledDraggableView = styled(DraggableView)`
  & {
    .wrapperStyle {
      display: flex;
      overflow-x: scroll;
      border-radius: 8;
      margin-top: 20;
      grid-auto-flow: column;

      grid-gap: 5px;
      padding: 10px;
    }
  }
`;

export default StyledDraggableView;
