import PropTypes from "prop-types";
import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import styled from "styled-components";
import { useSelector } from "react-redux";

import AuthPage from "./AuthPage";
import LoggedApp from "./LoggedApp";

// ----------------------------------------------------------------------------

function _RoutedApp(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props;
    const user = useSelector((state) => (state?.auth.user ? state.auth : null));

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    // -------------------------------------
    // Component local variables
    // -------------------------------------
    if (user) {
        return (
            <Routes>
                <Route path="/auth/*" element={<Navigate to="/" />} />
                <Route path="/*" element={<LoggedApp />} />
            </Routes>
        );
    } else {
        return (
            <Routes>
                <Route path="*" element={<Navigate to="auth" />} />
                <Route path="auth/*" element={<AuthPage />} />
            </Routes>
        );
    }
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_RoutedApp.propTypes = {
    className: PropTypes.string.isRequired,
};

_RoutedApp.defaultProps = {};

// ----------------------------------------------------------------------------

const RoutedApp = styled(_RoutedApp)`
    & {
    }
`;
// ----------------------------------------------------------------------------

export default RoutedApp;
