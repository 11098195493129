import PropTypes from "prop-types";
import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";

import { ReactComponent as SearchIcon } from "../../../../_shared/assets/icons/search.svg";
import { ReactComponent as Bell } from "../../../../_shared/assets/icons/bell.svg";
import AppInput from "../../../../_shared/components/AppInput";

// ----------------------------------------------------------------------------

function _SearchBox(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const {
        className,
        showNotification,
        handleNotificationClick,
        num,
        refer,
        handleSearchSubmit,
        handleOnChangeSearch,
    } = props;

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    //const [{ token }] = useStateValue()

    const ref = useRef();
    const inputRef = useRef();
    const [search, setSearch] = useState("");
    //useOnClickOutside(ref, () => setExpanded(false))

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    useEffect(() => {
        if (search.length > 2) handleSearchSubmit(search);
    }, [search]);

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function handleClick() {
        inputRef?.current?.focus();
    }

    async function handleSubmit(e) {
        e.preventDefault();
        if (search.length > 2) await handleSearchSubmit(search);
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    function renderBell() {
        if (showNotification)
            return (
                <div
                    className="bellDiv"
                    onClick={handleNotificationClick}
                    ref={refer}
                >
                    <Bell />
                </div>
            );
    }

    function renderNumber() {
        if (showNotification && num > 0)
            return <div className="num">{num}</div>;
    }
    return (
        <div ref={ref} className={`${className} `}>
            <form onSubmit={handleSubmit}>
                <AppInput
                    ref={inputRef}
                    className="input"
                    id="search-input"
                    placeholder="search"
                    value={search}
                    onChange={(e) => {
                        setSearch(e);
                        handleOnChangeSearch(e);
                    }}
                />
                <div className="icon" onClick={handleClick}>
                    <SearchIcon />
                </div>
            </form>
            {/* {renderBell()} 
			{renderNumber()} */}
        </div>
    );
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_SearchBox.propTypes = {
    className: PropTypes.string.isRequired,
};

_SearchBox.defaultProps = {
    showNotification: true,
};

// ----------------------------------------------------------------------------

const SearchBox = styled(_SearchBox)`
    & {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        margin-right: ${({ theme }) => theme.spacing.xs}px;
        .icon {
            position: relative;
            top: -37px;
            svg {
                width: 20px;
                height: 20px;
                margin-left: 9px;
            }
            :hover {
                cursor: text;
            }
        }
        form .input {
            width: 200px;
            .app-input {
                padding: 0px ${({ theme }) => theme.spacing.m}px;

                background-color: ${({ theme }) => theme.colors.grey200};
                border: none;
                border-radius: 4px;
            }
        }
        .bellDiv {
            background-color: ${({ theme }) => theme.colors.light};
            margin-left: 30px;
            width: 42px;
            height: 42px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 30px;
            margin-bottom: 13px;

            box-shadow: 0px 8px 16px rgba(108, 93, 211, 0.1);

            &:hover {
                cursor: pointer;
            }
        }
        .num {
            position: relative;
            right: 10px;
            top: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            color: ${({ theme }) => theme.colors.light};
            width: 14px;
            height: 14px;
            border-radius: 15px;
            background-color: #ff754c;
            align-self: flex-start;

            :hover {
                cursor: pointer;
            }
        }
    }
`;
// ----------------------------------------------------------------------------

export default SearchBox;
