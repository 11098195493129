import {React} from "react"
import styled from "styled-components"
import { useNavigate } from "react-router-dom"

import ShadowedCard from "../../../../_shared/components/ShadowedCard"

// ----------------------------------------------------------------------------

function SearchList(props) {

    // -------------------------------------    
    // Props destructuring
    // -------------------------------------
    const { className, data, refer} = props
    const navigate = useNavigate()

    const elements = []
    for (const property in data) {
        if(data[property].length !== 0){
            const values = []
            data[property].forEach(res => {
                switch(property){
                    case 'Aziende':     values.push({name: res.businessName, url: '/aziende/'+res.id})
                                        break
                    case 'Contatti':    values.push({name: res.fullName, url: '/'+res.id })
                                        break
                    case 'Offerte':     values.push({name: res.name, url: '/offerte/'+res.id})
                                        break
                    case 'Ddt':         values.push({name: res.name, url: '/ddt'})
                                        break 
                    // case 'Fatture':     values.push({name: res.number, url: })
                    //                     break   
                    case 'Ordini':      values.push({name: res.name, url: '/ordini'})
                                        break
                    case 'Prodotti':    values.push({name: res.code, url: '/prodotti/'+res.id})
                                        break
                    default: break
                }
            })
            elements.push({[property]: values})
        }
    }
    function handleElementClick(e, url){
        navigate(url)
    }
    
    return (
        <ShadowedCard className={className} refer={refer}>
            <div className="results">
                { elements.length !==0 ? elements.map((element, index) => {
                    const show = []
                    for ( const prop in element){
                        const div = 
                            <div key={index} className="row-result">
                               <div className="prop">{prop}</div>
                               <div className="values"> 
                                    {element[prop].map((value, index) => {
                                        return <span key={index} onClick={(e) => handleElementClick(e, value.url)}>{value.name}</span>
                                    })}  
                                </div> 
                            </div>
                        show.push(div)
                    }
                    return <div key={index}>{show}</div>
                }) : <div ><span>Nessun risultato</span></div>

            }
            </div>
        </ShadowedCard>
    )
}

const StyledSearchList = styled(SearchList) `
    & {
        z-index: 999;
        position: absolute;
        top: 70px;
        right:50px;
        width: 400px;
        height: fit-content;
        padding: 0px 20px;
        padding-bottom: 10px;
        .results{
            width: 100%;
            .row-result{
                margin-top: 10px;
                width: 100%;
                display: flex;
                flex-direction: column;
                padding-bottom: 5px;
                border-bottom: 1px solid ${({ theme }) => theme.colors.grey};
                .prop{
                    width:25%;
                    font-size: 11px;
                    font-weight: bold;
                }

                .values{
                    margin-top: 5px;
                    width:100%;
                    display: flex;
                    flex-direction: column;
                    span{
                        padding: 3px 0px;
                        padding-left: 10px;
                        margin-bottom: 0;
                        width:inherit;
                        :hover{
                            cursor: pointer;
                            border-radius: 7px;
                            background-color: #E7FAFF;
                        }
                    }
                }

            }
        }
    }
`
export default StyledSearchList